import React, { useState, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";

// Helpers
import { convertEmptyToStr } from "helpers";

// Assets
import { ReactComponent as CorreiosECTIcon } from "images/app-icons/correios-ect.svg";

// Components
import PageTitle from "components/page-title";

export default function CorreiosECT({
  storeUuid,
  loading: isSubmiting,
  appConfigs,
  uninstallHandler,
  submitHandler,
}) {
  const { state: locationState } = useLocation();
  const formRef = useRef();

  const dataModel = {
    isActive: false,
    isPasswordValid: true,
    login: "",
    password: "",
    autoGenerateBankslip: false,
  };

  const [state, setState] = useState(
    convertEmptyToStr({
      initialValues: {
        ...dataModel,
        ...appConfigs,
      },
      uninstallingApp: false,
      submiting: false,
      hasChanges: false,
    })
  );

  useEffect(() => {
    setState({
      ...state,
      initialValues: convertEmptyToStr({ ...state.initialValues, ...appConfigs }),
    });
  }, [appConfigs]);

  const handleSubmit = async (values) => {
    setState({
      ...state,
      submiting: true,
    });

    await submitHandler(
      {
        ...values,
        isPasswordValid: true,
        isActive: true,
      },
      dataModel
    );

    return setState({
      ...state,
      submiting: false,
    });
  };

  const handleCancel = (resetForm) => {
    resetForm();
    setState({
      ...state,
      hasChanges: false,
    });
  };

  return (
    <>
      <PageTitle pageTitle="Integrações: Correios Importador" />
      <div className="row mb35 mt35">
        {locationState?.code}
        <div className="col-sm-4 mb30">
          <CorreiosECTIcon className="mb15" />
          <h5>Correios Importador</h5>
          <p>Integre com os correios para buscar e apresentar o valor das taxas na Iumy.</p>

          {state.initialValues.isActive && (
            <button
              type="button"
              onClick={() => {
                setState((oldState) => ({ ...oldState, uninstallingApp: true }));
                uninstallHandler(dataModel);
              }}
              className="btn btn-outline-danger btn-sm"
              disabled={state.uninstallingApp}
            >
              <FaTrash /> {state.uninstallingApp ? "Removendo" : "Desinstalar App"}
            </button>
          )}

          <div className="callout mt-3">
            Preencha com o mesmo login utilizado no{" "}
            <a target="_blank" rel="noreferrer" href="https://cas.correios.com.br/">
              Portal do importador
            </a>
            . Certifique-se de digitar o login correto, pois não realizamos validação do mesmo.
          </div>
        </div>

        <div className="col">
          <div className="box-widget">
            <div className="inner heading">
              <h3>Integração Portal Importador</h3>
            </div>
            <hr />
            <Formik
              innerRef={formRef}
              initialValues={state.initialValues}
              enableReinitialize
              validationSchema={Yup.object().shape({
                login: Yup.string().required("Campo obrigatório."),
                password: Yup.string().required("Campo obrigatório."),
              })}
              validate={(a1) => {
                if (!_.isEqual(a1, state.initialValues)) {
                  setState((s) => ({
                    ...s,
                    hasChanges: true,
                  }));
                } else {
                  setState((s) => ({
                    ...s,
                    hasChanges: false,
                  }));
                }
              }}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="inner">
                  {!state.initialValues.isPasswordValid && (
                    <div className="alert alert-danger" role="alert">
                      Login ou senha invalido, revise e clique em salvar para tentarmos novamente.
                    </div>
                  )}

                  <div className="form-group">
                    <div className="form-group">
                      <span className="field-label" required>
                        Login
                      </span>
                      <Field
                        placeholder="Digite seu usuário"
                        name="login"
                        className="form-control"
                      />
                      <ErrorMessage component="div" className="error-message" name="login" />
                    </div>

                    <span className="field-label" required>
                      Senha
                    </span>
                    <Field
                      placeholder="Digite sua senha"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage component="div" className="error-message" name="password" />
                  </div>
                </div>

                <hr />
                <div className="inner">
                  <Field name="autoGenerateBankslip">
                    {(props) => (
                      <>
                        <div className="pretty p-switch p-fill">
                          <input
                            {...props.field}
                            type="checkbox"
                            checked={props.field.value}
                            onChange={() => {
                              props.form.setFieldValue("autoGenerateBankslip", !props.field.value);
                            }}
                          />
                          <div className="state  p-success">
                            <label>Gerar boletos automaticamente</label>
                          </div>
                        </div>
                        <br />
                        <small>
                          Ative esta opção para gerar os boletos das taxas automaticamente e obter o
                          código de barras no relatório.
                        </small>
                      </>
                    )}
                  </Field>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>

      <hr />

      <footer className="footer-actions">
        <div className="flex flex-right">
          <button
            disabled={!state.hasChanges || state.submiting}
            onClick={() => {
              handleCancel(formRef.current.resetForm);
            }}
            className="btn default size--medium-2 mr10"
            type="button"
          >
            Cancelar
          </button>

          <button
            className={`btn green size--medium-2 withLoader ${isSubmiting ? "loading" : ""}`}
            disabled={!state.hasChanges || state.submiting}
            type="button"
            onClick={() => formRef.current.handleSubmit()}
          >
            <span className="text">Salvar</span>
            <div className="dots">
              <div className="dot dot-1" />
              <div className="dot dot-2" />
              <div className="dot dot-3" />
            </div>
          </button>
        </div>
      </footer>
    </>
  );
}
