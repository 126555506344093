import React, { useState, useRef } from "react";
import { FaExchangeAlt, FaUndoAlt, FaImage, FaCommentAlt, FaRobot } from "react-icons/fa";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

// Sub components
import ModalComp from "components/modal";

function CustomCheckBox({ field, ...props }) {
  return (
    <label className={`checkbox ${field.value ? "active" : ""}`}>
      <input {...field} type="checkbox" checked={field.value} />
      {props.content}
    </label>
  );
}

export default function EditReasonsModal({ customRule, cancelHandler, submitHandler }) {
  // Validation
  const baseValidationSchema = {
    reason: Yup.string().required("Campo obrigatório"),
    deadlineInDays: Yup.number().typeError("Digite somente números").required("Campo obrigatório"),
  };

  // State
  const [state, setState] = useState({
    submiting: false,
    validationSchema: Yup.object(baseValidationSchema),
    initialValues: {
      uuid: "",
      reason: "",
      autoApprove: false,
      allowExchange: false,
      allowReturn: false,
      requireEvidence: false,
      requireComment: false,
      deadlineInDays: "",

      // Load rule
      ...customRule,
    },
  });

  const formRef = useRef(null);

  return (
    <ModalComp
      showModal
      className="edit-reason"
      title={!customRule.uuid ? "Criar regra " : "Editar regra"}
      cancelHandler={cancelHandler}
    >
      <Formik
        innerRef={formRef}
        validationSchema={state.validationSchema}
        initialValues={state.initialValues}
        validate={(values) => {
          if (values.allowExchange === false && values.allowReturn === false)
            setState((s) => ({
              ...s,
              validationSchema: Yup.object({
                ...baseValidationSchema,
                allowExchange: Yup.boolean().oneOf([true], "Escolha entre trocas ou devoluções"),
              }),
            }));
          else if (JSON.stringify(state.validationSchema) !== JSON.stringify(baseValidationSchema))
            setState((s) => ({
              ...s,
              validationSchema: Yup.object(baseValidationSchema),
            }));
        }}
        onSubmit={async (values) => {
          setState((s) => ({ ...s, submiting: true }));
          await submitHandler(values);
          // setState((s) => ({ ...s, submiting: false }));
        }}
      >
        <Form>
          <div className="modal-body-content">
            <div className="row mb10">
              <div className="col-6">
                <div className="form-floating form-field">
                  <Field
                    name="reason"
                    id="reason"
                    type="text"
                    placeholder="Motivo"
                    className="form-control"
                  />

                  <ErrorMessage name="reason" className="error-message" component="div" />

                  <label htmlFor="reason" className="f12 uppercase gray bold">
                    Motivo
                  </label>
                </div>
              </div>

              <div className="col-3">
                <div className="form-group" style={{ height: "100%" }}>
                  <Field
                    name="allowExchange"
                    content={
                      <>
                        Troca
                        <FaExchangeAlt className="decor" />
                      </>
                    }
                    component={CustomCheckBox}
                  />
                  <ErrorMessage name="allowExchange" className="error-message" component="div" />
                </div>
              </div>

              <div className="col-3">
                <Field
                  name="allowReturn"
                  content={
                    <>
                      Devolução
                      <FaUndoAlt className="decor" />
                    </>
                  }
                  component={CustomCheckBox}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <div className="form-floating due">
                  <Field
                    name="deadlineInDays"
                    id="duedate"
                    type="text"
                    placeholder="Prazo"
                    className="form-control"
                  />
                  <ErrorMessage name="deadlineInDays" className="error-message" component="div" />
                  <label htmlFor="reason" className="f12 uppercase gray bold">
                    Prazo
                  </label>
                  <span className="sub-label">dias</span>
                </div>
              </div>

              <div className="col-5">
                <Field
                  name="requireEvidence"
                  content={
                    <>
                      Exigir imagem
                      <FaImage className="decor" />
                    </>
                  }
                  component={CustomCheckBox}
                />
              </div>

              <div className="col-5">
                <Field
                  name="requireComment"
                  content={
                    <>
                      Exigir comentário
                      <FaCommentAlt className="decor" />
                    </>
                  }
                  component={CustomCheckBox}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="auto-approve">
              <FaRobot className="ico" /> Aprovação automática
              <div className="pretty p-switch p-fill ">
                <Field name="autoApprove">
                  {({ field }) => (
                    <input {...field} type="checkbox" checked={field.value} value="vrau" />
                  )}
                </Field>

                <div className="state p-success">
                  <label></label>
                </div>
              </div>
            </div>

            <div className="actions">
              <button
                onClick={cancelHandler}
                className="btn default size--medium mr10"
                type="button"
              >
                Cancelar
              </button>

              <button
                className={`btn green size--medium withLoader ${state.submiting ? "loading" : ""} `}
                disabled={state.submiting}
                type="submit"
              >
                <span className="text">Salvar</span>

                <div className="dots">
                  <div className="dot dot-1" />
                  <div className="dot dot-2" />
                  <div className="dot dot-3" />
                </div>
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </ModalComp>
  );
}
