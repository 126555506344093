import React from "react";

export default function FilterSwitch({ filter, handleFilter }) {
  const active = filter.values === null || filter.values === "" ? filter.options[0] : filter.values;

  return (
    <div className="pretty p-switch">
      <input
        type="checkbox"
        checked={!!active}
        onChange={() => handleFilter(filter.param, !active)}
      />
      <div className="state p-success">
        <label>{filter.label}</label>
      </div>
    </div>
  );
}
