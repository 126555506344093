import { useEffect, useRef } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

export default function LDClientIdentifier() {
  const { user } = useSelector((state) => state.auth);
  const ldClient = useLDClient();
  const registeredUser = useRef(null);

  useEffect(() => {
    if (ldClient && user?.email)
      ldClient.waitUntilReady().then(() => {
        if (!registeredUser.current || registeredUser.current !== user.email) {
          ldClient.identify({ key: user.email });
          registeredUser.current = user.email;
        }
      });
  }, [ldClient, user]);

  return null;
}
