import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FaFileInvoiceDollar, FaChartBar } from "react-icons/fa";
import { getSalesStatsRequest as getSalesStats } from "store/modules/merchants/actions";
import CardIcon from "../components/card-icon";

function SalesStatisticsContainer({ filter, storeUuid }) {
  const flags = useFlags();
  const { streamerMode } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    loading: true,
    error: null,
    paidSalesRevenue: 0,
    salesQuantity: 0,
  });

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getSalesStats(storeUuid, filter);

      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          paidSalesRevenue: data.paidSalesRevenue,
          salesQuantity: data.salesQuantity,
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  return !flags.isFaturamentoHide ? (
    <>
      <CardIcon
        number={state.paidSalesRevenue}
        prefix="R$ "
        label="Faturamento"
        className="revenues"
        decimals={2}
        loading={state.loading}
        icon={<FaFileInvoiceDollar className="card-icon" fontSize={27} />}
        action={getSalesStats}
        error={state.error}
        streamerMode={streamerMode}
      />

      <CardIcon
        number={state.salesQuantity}
        loading={state.loading}
        label="Vendas"
        className="orders"
        icon={<FaChartBar className="card-icon" fontSize={27} />}
        error={state.error}
        streamerMode={streamerMode}
      />
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SalesStatisticsContainer);
