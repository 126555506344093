import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaTrash, FaQuestionCircle, FaCheckCircle, FaCloudDownloadAlt } from "react-icons/fa";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import _, { min } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

import { doLoadUserRequest } from "store/modules/auth/thunks";

import SpinnerLoader from "components/loading-circle";
import { convertEmptyToStr } from "helpers";
import { ReactComponent as Shopifyicon } from "images/app-icons/shopifyapp.svg";
import {
  getEcommerceOrdersCountRequest,
  getAppSettingsByMerchantRequest,
  getShopifySingleOrder,
} from "store/modules/merchants/actions";
import SelectOrderModal from "../components/modal-select-orders";

export default function ShopifyApp({
  storeUuid,
  loading,
  appConfigs,
  submitHandler,
  uninstallHandler,
  user,
}) {
  const dispatch = useDispatch();
  const neededPermissions = [
    "write_customers",
    "read_customers",
    "write_content",
    "read_content",
    "write_orders",
    "read_orders",
    "write_products",
    "read_products",
  ];

  useEffect(() => {
    dispatch(doLoadUserRequest());
  }, []);

  const formRef = useRef();
  const importParam = new URLSearchParams(window.location.search).get("import");

  const isTrial = user.subscription.isTrial || false;

  const dataModel = {
    isActive: false,
    apiKey: "",
    sharedSecret: "",
    password: "",
    storeUrl: "",
    created_at_min: "",
  };

  const stateCopy = convertEmptyToStr({
    initialValues: {
      ...dataModel,
      ...appConfigs,
    },
    modalOpened: false,
    disableCancel: true,
    enableChangeKeys: false,
    importingSingle: false,
    uninstallingApp: false,
    pendingPermissions: [],
  });

  const [state, setState] = useState(stateCopy);

  useEffect(() => {
    setState({
      ...state,
      initialValues: convertEmptyToStr({ ...state.initialValues, ...appConfigs }),
      modalOpened: importParam || false,
    });
  }, [appConfigs]);

  const getShopifyStatus = async () => {
    const shopifyAppRequest = await getAppSettingsByMerchantRequest(storeUuid, "shopify");

    if (shopifyAppRequest.success && shopifyAppRequest.data.integrationInProgress) {
      setState((oldState) => ({
        ...oldState,

        initialValues: {
          ...oldState.initialValues,
          integrationInProgress: true,
          integrationStatusDescription: shopifyAppRequest.data.integrationStatusDescription,
        },
      }));
    } else {
      setState((oldState) => ({
        ...oldState,
        initialValues: { ...oldState.initialValues, integrationInProgress: false },
      }));
    }

    return shopifyAppRequest;
  };

  useEffect(() => {
    let interval;

    if (state.initialValues.integrationInProgress && !interval) {
      interval = setInterval(() => {
        getShopifyStatus().then((result) => {
          if (!result?.data?.integrationInProgress) clearInterval(interval);
        });
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [state.initialValues.integrationInProgress]);

  const modalToggleHandler = (show) =>
    setState((oldState) => ({
      ...oldState,
      modalOpened: show || false,
    }));

  const loadOrders = async (values) => {
    setState((oldState) => ({
      ...oldState,
      initialValues: values,
      modalOpened: true,
    }));
  };

  const loadSingleOrder = async (orderName) => {
    setState((oldState) => ({
      ...oldState,
      importingSingle: true,
    }));

    const { success, message } = await getShopifySingleOrder(storeUuid, orderName);

    if (success) {
      setState((oldState) => ({
        ...oldState,
        importingSingle: false,
      }));

      toast.success(`Pedido ${orderName} importado.`);
    } else {
      toast.error(message);

      setState((oldSate) => ({
        ...oldSate,
        importingSingle: false,
      }));
    }
  };

  const handleCancel = (resetForm) => {
    resetForm();
    setState({
      ...state,
      disableCancel: true,
    });
  };

  const handleSubmit = async (values, skipImport = true) => {
    setState({
      ...state,
      disableCancel: true,
    });

    const { success, message } = await submitHandler(
      {
        ...values,
        skipImport: typeof skipImport === "boolean" ? skipImport : true,
        isActive: true,
      },
      dataModel
    );

    let pending = [];

    if (!success) pending = message.match(/([a-z]+_[a-z]+)/g) || [];

    return setState({
      ...state,
      disableCancel: false,
      pendingPermissions: pending,
    });
  };

  return (
    <>
      {/* Configurações da Loja */}
      <div className="row mb35 mt35">
        <div className="col-sm-4 mb30">
          <Shopifyicon className="mb15" />
          <h5>Shopify</h5>
          <p>Aqui você integra sua loja para usar todas os benefícios da Iumy.</p>

          {state.initialValues.isActive && !state.initialValues.integrationInProgress && (
            <button
              type="button"
              onClick={() => {
                setState((oldState) => ({ ...oldState, uninstallingApp: true }));
                uninstallHandler(dataModel);
              }}
              className="btn btn-outline-danger btn-sm"
              disabled={state.uninstallingApp}
            >
              <FaTrash /> {state.uninstallingApp ? "Removendo" : "Desinstalar App"}
            </button>
          )}

          <div className="callout mt-3" style={{ lineHeight: "23px" }}>
            <strong>Permissões necessárias</strong>
            <br />
            {neededPermissions.map((p, i) => (
              <>
                <span
                  className={`text-callout ${
                    state.pendingPermissions.includes(p) ? "pending" : ""
                  }`}
                >
                  {p}
                </span>
                {neededPermissions.length > i && ", "}
              </>
            ))}
          </div>

          <a
            href="https://iumy.tawk.help/article/como-integrar-a-shopify"
            target="_blank"
            rel="noreferrer"
            className="doclink"
          >
            <FaQuestionCircle /> Como instalar?
          </a>
        </div>

        <div className="col">
          <div className="box-widget">
            <div className="inner heading">
              <h3>
                {state.initialValues.isActive && <FaCheckCircle className="maingreen" />} Chaves de
                API
              </h3>
              {!state.enableChangeKeys &&
                state.initialValues.isActive &&
                !state.initialValues.integrationInProgress && (
                  <button
                    type="button"
                    onClick={() =>
                      setState((oldState) => ({ ...oldState, enableChangeKeys: true }))
                    }
                  >
                    Trocar chaves
                  </button>
                )}
            </div>

            <hr />

            {(state.initialValues.integrationInProgress ||
              state.enableChangeKeys ||
              !state.initialValues.isActive) && (
              <div className="inner">
                <Formik
                  innerRef={formRef}
                  initialValues={state.initialValues}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    storeUrl: Yup.string().required("Campo obrigatório."),
                    apiKey: Yup.string().required("Campo obrigatório."),
                    sharedSecret: Yup.string().required("Campo obrigatório."),
                  })}
                  validate={(a1) => {
                    if (!_.isEqual(a1, state.initialValues))
                      setState({
                        ...state,
                        disableCancel: false,
                      });
                    else {
                      setState({
                        ...state,
                        disableCancel: true,
                      });
                    }
                  }}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    {!state.initialValues.integrationInProgress ? (
                      <>
                        <div className="form-group">
                          <span className="field-label" required>
                            URL da loja
                          </span>
                          <div className="w-2-5">
                            <Field
                              name="storeUrl"
                              className="form-control"
                              placeholder="minha-loja.myshopify.com"
                            />
                            <ErrorMessage
                              component="div"
                              className="error-message"
                              name="storeUrl"
                            />
                          </div>

                          <small>
                            Exemplo: sua-loja.myshopify.com <br />A URL não pode conter os seguintes
                            valores: www., https://, http://, /, .br
                          </small>
                        </div>
                        <div className="form-group">
                          <div className="form-group">
                            <span className="field-label" required>
                              Token de acesso da API Admin
                            </span>
                            <Field name="password" className="form-control" />
                            <ErrorMessage
                              component="div"
                              className="error-message"
                              name="password"
                            />
                          </div>

                          <span className="field-label" required>
                            Chave de API
                          </span>
                          <Field name="apiKey" className="form-control" />
                          <ErrorMessage component="div" className="error-message" name="apiKey" />
                        </div>

                        <div className="form-group">
                          <span className="field-label" required>
                            Chave secreta da API
                          </span>
                          <Field name="sharedSecret" className="form-control" />
                          <ErrorMessage
                            component="div"
                            className="error-message"
                            name="sharedSecret"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <h3>
                          Integração em andamento <br />
                          <small style={{ fontSize: 13 }}>
                            Voce poderá editar essas informações assim que a integração for
                            concluida
                          </small>
                        </h3>

                        <SpinnerLoader
                          containerElm="span"
                          pos="text-left"
                          value={`${state.initialValues.integrationStatusDescription}`}
                        />
                      </>
                    )}
                  </Form>
                </Formik>
              </div>
            )}

            {!state.enableChangeKeys &&
              !state.initialValues.integrationInProgress &&
              state.initialValues.isActive && (
                <div className="inner import-orders">
                  <div className="import-multiple-wrap">
                    <button
                      type="button"
                      className="btn size--medium green"
                      onClick={() => {
                        loadOrders(state.initialValues);
                      }}
                    >
                      Importar pedidos
                    </button>
                    <p>
                      {user.subscription.freeOrdersImportUsed
                        ? "Importe pedidos antigos, escolha uma data retroativa, pague pelos pedidos adicionais sem descontar de seu plano."
                        : "Use esta opção para fazer a importação dos pedidos retroativos."}
                    </p>
                  </div>

                  <hr />

                  <div className="import-single">
                    <form
                      className="form-import-single"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const orderName = e?.currentTarget?.orderName?.value || null;

                        if (orderName) loadSingleOrder(orderName);
                      }}
                    >
                      <input
                        name="orderName"
                        type="text"
                        className="form-control"
                        placeholder="Ex: #1023"
                      />
                      <button type="submit" className="btn green" disabled={state.importingSingle}>
                        <FaCloudDownloadAlt />
                      </button>
                    </form>
                    <p>
                      <strong>Importar pedido individual: </strong> importe um pedido especifico,
                      digite o número e busque, cada pedido será descontado da cota de seu plano.
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <hr />

      <footer className="footer-actions">
        <div className="flex flex-right">
          <button
            disabled={state.disableCancel && state.initialValues.isActive}
            onClick={() => {
              handleCancel(formRef.current.resetForm);
            }}
            className="btn default size--medium-2 mr10"
            type="button"
          >
            Cancelar
          </button>

          <button
            className={classnames("btn green size--medium-2 withLoader", {
              loading,
            })}
            disabled={state.disableCancel && state.initialValues.isActive}
            type="button"
            onClick={() => formRef.current.handleSubmit()}
          >
            <span className="text">Salvar</span>
            <div className="dots">
              <div className="dot dot-1" />
              <div className="dot dot-2" />
              <div className="dot dot-3" />
            </div>
          </button>
        </div>
      </footer>

      {state.modalOpened && (
        <SelectOrderModal
          modalToggleHandler={modalToggleHandler}
          handlePickNewDate={async (dateMin, dateMax) =>
            getEcommerceOrdersCountRequest(storeUuid, "shopify", {
              ...state.initialValues,
              created_at_min: moment(dateMin).format("YYYY-MM-DD"),
              created_at_max: moment(dateMax).format("YYYY-MM-DD"),
            })
          }
          submitHandler={(minInputValue, maxInputValue) =>
            handleSubmit(
              {
                ...state.initialValues,
                created_at_min: minInputValue,
                created_at_max: maxInputValue,
              },
              false
            )
          }
          appConfigs={state.initialValues}
          isTrial={isTrial}
        />
      )}
    </>
  );
}
