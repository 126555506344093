import React from "react";

export default function Card({ step }) {
  const { label, description, Icon, picture, content } = step;

  return (
    <div className="subcard">
      <div className="row">
        <div className="col">
          <span className="sub-icon">
            <Icon />
          </span>

          <h6>{label}</h6>
          <p>{description}</p>

          {content}
        </div>
        {picture && (
          <div className="col picture-col">
            <img height={360} src={picture} className="wizard-illustration" alt={label} />
          </div>
        )}
      </div>
    </div>
  );
}
