import { ReturnStatusTypes } from "models/returnal/returnal";

export default function calcRetunralValues(returnal) {
  if (!returnal) return {};

  const { items, transactions, coupons } = returnal;

  // Transactions
  const hasTransactions = transactions.length > 0;
  const totalRefundDone = transactions[0]?.totalRefundDone;

  // Refunds
  const refunds = items.filter(
    (i) => i.returnalType.code === ReturnStatusTypes.RETURN && i.conferenceQuantity > 0
  );
  const refundValueRequested = refunds.reduce((acc, current) => {
    if (!acc) return current.price.raw * current.conferenceQuantity;

    return acc + current.price.raw * current.conferenceQuantity;
  }, 0);
  const hasRefundPending = refunds.length && !transactions.length;
  const refundValue = hasTransactions ? totalRefundDone : refundValueRequested;
  const refundType = hasTransactions ? transactions[0].paymentMethod : "";

  // Coupons
  const hasCoupouns = coupons.length > 0;
  const totalCouponValue = coupons[0]?.price;

  // Exchanges
  const exchanges = items.filter(
    (i) => i.returnalType.code === ReturnStatusTypes.EXCHANGE && i.conferenceQuantity > 0
  );
  const hasExchangePending = exchanges.length && !coupons.length;
  const exchangeValue = hasCoupouns
    ? totalCouponValue
    : exchanges.reduce((acc, current) => {
        if (!acc) return current.price.raw * current.conferenceQuantity;

        return acc + current.price.raw * current.conferenceQuantity;
      }, 0);

  return {
    refundsLength: refunds.length,
    refundValueRequested,
    hasRefundPending,
    refundValue,
    refundType,

    exchangesLength: exchanges.length,
    exchangeValue,
    hasExchangePending,
    exchangeFreightValue: hasCoupouns ? coupons[0].freight : 0,
  };
}
