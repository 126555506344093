import React from "react";

// Components
import Modal from "components/modal";

export default function AttachmentModal({ viewingAttachmentUrl, toggleModal }) {
  return (
    <Modal
      title="Visualizar anexo"
      showModal={!!viewingAttachmentUrl}
      toggleModal={toggleModal}
      // buttonPrimaryLabel="Download"
      // buttonPrimarySubmit={() => window.open(viewingAttachmentUrl, "_blank")}
      // btnPrimaryDisabled={state.disableSaveBtn}

      customActions={() => (
        <a href={viewingAttachmentUrl} className="btn  green" target="_blank" rel="noreferrer">
          Download
        </a>
      )}
    >
      <img src={viewingAttachmentUrl} className="img-fluid" alt="Anexo enviado pelo cliente" />
    </Modal>
  );
}
