import React from "react";
import classnames from "classnames";

// Components
import Modal from "components/modal";
import PackageMove from "pages/main/orders/detail/components/package-move";

export default function TrackingModal({ moves = [], toggleModal }) {
  return (
    <Modal
      className="tracking-resume"
      title="Histórico de movimentações"
      showModal
      toggleModal={toggleModal}
    >
      <ul className="track-moves">
        {moves.map((move, i) => (
          <li className={classnames("f12", move.computedStatus.code)} key={i}>
            <span className={classnames("iumy-badge mb5", move.computedStatus.code)}>
              {move.computedStatus.label}
            </span>
            <br />
            <PackageMove move={move} detailed />
          </li>
        ))}
      </ul>
    </Modal>
  );
}
