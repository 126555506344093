import React, { useState, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useOutsideClick } from "helpers";

import { callPipeDreamToSave } from "store/modules/merchants/actions";

export default function Select({
  slug,
  label,
  selectorLabel,
  onSelect,
  multiple,
  selectorDescription,
  options,
  showSoon,
  showSoonHandler,
}) {
  const { user } = useSelector((state) => state.auth);
  const activeLabel = label;
  const icon = <span className="plus-icon">+</span>;

  const [internalState, setState] = useState({
    selectorOpened: false,
    selectedOptions: options.filter((opt) => opt.selected),
  });

  const toggleSelector = (option) => {
    setState((oldState) => ({
      ...oldState,
      selectorOpened: option === undefined ? !oldState.selectorOpened : option,
    }));
  };

  const selectOption = (option) => {
    if (option.soon) {
      if (!showSoon)
        callPipeDreamToSave(user.email, "giveupByPlatform", { platform: option.label });
      // Call pipe dream
      return showSoonHandler();
    }

    const optionWasSelected = internalState.selectedOptions.find(
      (opt) => opt.value === option.value
    );
    let newSelectedOptions = multiple ? internalState.selectedOptions : [];

    if (optionWasSelected)
      newSelectedOptions = internalState.selectedOptions.filter(
        (opt) => opt.value !== option.value
      );
    else newSelectedOptions.push(option);

    // Report above
    onSelect(slug, newSelectedOptions);

    return setState((oldState) => ({
      ...oldState,
      selectedOptions: newSelectedOptions,
      selectorOpened: multiple,
    }));
  };

  const isSelected = (option) =>
    internalState.selectedOptions.find((opt) => opt.value === option.value);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleSelector);

  return (
    <div className="onboarding-selector-wrap" ref={wrapperRef}>
      <button type="button" className="onboarding-selector" onClick={toggleSelector}>
        {icon} {activeLabel}
        {internalState.selectedOptions.length > 0 && (
          <ul className="onboarding-selector--selected-options">
            {internalState.selectedOptions.map((opt) => (
              <li key={opt.value}>
                <div className="selected-opt-group">
                  <span className="icon">{opt.icon}</span>
                  <span className="opt-name">{opt.label}</span>
                </div>

                <FaCheckCircle className="selected-option-check" />
              </li>
            ))}
          </ul>
        )}
      </button>

      <div
        className={classNames("the-selector", {
          opened: internalState.selectorOpened,
          multiple,
        })}
      >
        {selectorLabel && <span className="selector-label">{selectorLabel}</span>}

        {options.map((opt) => (
          <button
            type="button"
            key={opt.value}
            className={classNames("selector-option", {
              selected: isSelected(opt),
              soon: opt.soon && showSoon,
            })}
            onClick={() => selectOption(opt)}
          >
            <div className="icon-group">
              <span className="icon">{opt.icon}</span> {opt.label}
            </div>
            {opt.soon && showSoon ? (
              <span className="soon-label">Em breve</span>
            ) : (
              <div className="input" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
