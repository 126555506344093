import { immerable } from "immer";

export default class SubscriptionResume {
  constructor(options = {}) {
    this[immerable] = true;

    this.billingPlanName = options.billingPlanName;
    this.billingPlanSlug = options.billingPlanSlug;
    this.createdAt = options.createdAt;
    this.endedAt = options.endedAt;
    this.isTrial = options.isTrial;
    this.status = options.status;
    this.freeOrdersImportUsed = options.freeOrdersImportUsed;
  }
}
