import { immerable } from "immer";
import SubscriptionResume from "./subscription-resume";

export default class User {
  constructor(options = {}) {
    this.id = options.uuid;
    this.name = options.firstName;
    this.avatar = options.avatar;
    this.email = options.email;
    this.phone = options.phone;
    this.subscription =
      options.subscription && Object.keys(options.subscription).length
        ? new SubscriptionResume(options.subscription)
        : null;
  }
}

User[immerable] = true;
