import { immerable } from "immer";

export default class Status {
  constructor(statusCode) {
    this[immerable] = true;

    const traitedStatus = Status.statusToText(statusCode);

    this.code = traitedStatus.code;
    this.label = traitedStatus.label;
  }

  static statusToText(status) {
    switch (status) {
      case "EXCEPTION":
        return { label: "Atenção", code: status };

      case "TRACKING_CODE_GENERATED":
        return { label: "Rastreio gerado", code: status };

      case "POSTED":
        return { label: "Postado", code: status };

      case "RETURNED_TO_SENDER":
        return { label: "Devolvido", code: status };

      case "IN_TRANSIT":
        return { label: "Em trânsito", code: status };

      case "WAITING_WITHDRAW":
        return { label: "Aguardando Retirada", code: status };

      case "CANCELLED":
        return { label: "Cancelado", code: status };

      case "DELIVERED":
        return { label: "Entregue", code: status };

      case "IN_ROUTE_TO_DELIVERY":
        return { label: "Saiu p/ Entrega", code: status };

      case "WAITING_WITHDRAWAL":
        return { label: "Aguardando retirada", code: status };

      case "IS_LATE":
        return { label: "Atrasado", code: status };

      case "TAXED":
        return { label: "Taxado", code: status };

      case "IS_STALLED":
        return { label: "Sem atualização", code: status };

      case "TRACKING_CODE_CHANGED":
        return { label: "Rastreio mudou", code: status };

      case "ARCHIVED":
        return { label: "Arquivado", code: status };

      // => TF
      case "EXCHANGE":
        return { label: "Troca", code: status };

      case "REFUND":
        return { label: "Reembolso", code: status };

      // => FINANCIAL
      case "PENDING":
        return { label: "Aguardando pagamento", code: status };

      case "PAID":
        return { label: "Pagamento aprovado", code: status };

      case "REFUNDED":
        return { label: "Reembolsado", code: status };

      case "PARTIALLY_REFUNDED":
        return { label: "Reembolso parcial", code: status };

      case "PARTIALLY_PAID":
        return { label: "Pagamento parcial", code: status };

      default:
        return { label: "Não identificado", code: "UNDEFINED" };
    }
  }
}
