import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import { setDefaultLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import "moment/locale/pt-br";

// Components
// import LDProvider from "./components/ld-provider";
import App from "./App";

// Dates
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

// Toast
import "react-toastify/dist/ReactToastify.css";

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "styles/app.scss";

setDefaultLocale(pt);

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

renderApp();
