import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useOutsideClick, swalMiddleware as Swal } from "helpers";

function MerchantSelector({ merchants, dispatch, doSelectMerchant, user, streamerMode }) {
  const [isOpened, toggleOpened] = useState(false);

  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleOpened);

  const addNewStore = () => {
    const cantAddStore =
      user?.subscription?.billingPlanSlug === "trial" || user?.subscription?.status !== "ACTIVE";

    if (cantAddStore)
      return Swal.fire({
        title: "Você precisa de um plano",
        text: "Para adicionar mais de uma loja é necessário ativação de um plano.",
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Escolher um plano",
        cancelButtonText: "Fechar",
      }).then((result) => (result.isConfirmed ? history.push("/config/billing") : null));

    return window.location.replace("/onboarding");
  };

  return (
    <div className="store-select-wrap" ref={wrapperRef}>
      {merchants.merchantList.length > 0 && (
        <div
          className={classnames("store-select", {
            active: isOpened,
          })}
          role="button"
          onClick={() => toggleOpened(!isOpened)}
        >
          {merchants.currentMerchant && (
            <div className="current-store">
              {merchants.currentMerchant.icon && (
                <div className="icon-wrap">
                  <img
                    src={merchants.currentMerchant.icon}
                    className={`img-fluid ${streamerMode ? "blurry" : ""}`}
                    alt="Logo"
                  />
                </div>
              )}

              <div className={`store-name ${streamerMode ? "blurry" : ""}`}>
                {merchants.currentMerchant.name}
              </div>
            </div>
          )}

          <div className="other-store-list">
            <span className="store-title">Minhas lojas</span>
            {merchants.currentMerchant &&
              merchants.merchantList.map((merchant) => (
                <div
                  key={merchant.uuid}
                  className={`store-list-item ${
                    merchant.uuid === merchants.currentMerchant.uuid ? "active" : ""
                  } ${streamerMode ? "blurry" : ""}`}
                >
                  <Link
                    to={`/${merchant.uuid}/home`}
                    onClick={() => dispatch(doSelectMerchant(merchant.uuid))}
                  >
                    <div className="icon">
                      {merchant.icon ? (
                        <img
                          src={merchant.icon}
                          className={`img-fluid ${streamerMode ? "blurry" : ""}`}
                          alt="Logo"
                        />
                      ) : (
                        <span>{merchant.name[0]}</span>
                      )}
                    </div>

                    {merchant.name}
                  </Link>
                </div>
              ))}

            <button
              type="button"
              className="add-new-store btn green size-14-p11"
              onClick={() => addNewStore()}
            >
              + Adicionar loja
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MerchantSelector;
