import { immerable } from "immer";

export default class Product {
  constructor(product) {
    this.id = product.id;
    this.name = product.name;
    this.price = product.price;
    this.categories = product.categories;
    this.stock = product.stock;
    this.thumbnail = product.thumbnail;
  }
}

Product[immerable] = true;
