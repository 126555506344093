import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaCode, FaRedo, FaPlus, FaTrashAlt } from "react-icons/fa";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import * as Yup from "yup";
import classnames from "classnames";
import _ from "lodash";
import { connect, useSelector } from "react-redux";

import { convertEmptyToStr, selectText } from "helpers";
import { ReactComponent as IumyIcon } from "images/app-icons/iumy.svg";
import ImageUploader from "components/image-uploader";

import {
  doInstallShopifyTracking,
  doUninstallShopifyTracking,
  callPipeDreamToSave,
} from "store/modules/merchants/actions";
import { toast } from "react-toastify";
import ColorPicker from "components/color-picker";

function RastreioIumy({ currentMerchant, loading, appConfigs, submitHandler }) {
  const { user } = useSelector((state) => state.auth);
  const [color, setColor] = useState("blue");
  const [typeOfImage, setType] = useState("default");
  const [customImageUrl, setCustomImage] = useState(null);
  const [showCode, setshowCode] = useState(false);

  const colors = [
    {
      slug: "blue",
      label: "Azul",
    },
    {
      slug: "red",
      label: "Vermelho",
    },
    {
      slug: "yellow",
      label: "Amarelo",
    },
    {
      slug: "green",
      label: "Verde",
    },
    {
      slug: "gray",
      label: "Cinza",
    },
  ];

  const formikRef = useRef(null);

  const code = `<div id="iumy-app"><img id="iumy-first-loader" src="https://iumy-assets-bucket.s3.sa-east-1.amazonaws.com/loading.gif" style="margin: 0 auto; display: block;" /></div>
                <script>
                  window.iumyStoreUuid = '${currentMerchant.uuid}'; 
                  window.iumyVectorColor = "${color}";
                  window.iumyTypeOfImage = "${typeOfImage}";
                  ${customImageUrl ? `window.iumyCustomImageUrl = "${customImageUrl}";` : ""}
                  var a = document.createElement("script");
                  a.type = "module";
                  a.setAttribute("crossorigin", "");
                  a.src =
                    "https://d2dh0wytruw20a.cloudfront.net/plugin/index.js";

                  var c = document.createElement("link");
                  c.href =
                    "https://d2dh0wytruw20a.cloudfront.net/plugin/index.css";
                  c.rel = "stylesheet";
                  c.setAttribute("crossorigin", "");
                  document.head.appendChild(c);
                  document.head.appendChild(a);
                </script>`;

  const dataModel = {
    isActive: false,
    trackingUrl: "",
    hasShopifyPageCreated: false,
    orderUnfulfilledAfterDays: "",
    packageLateAfterDays: "",
    hideTaxedMoves: true,
    pluginAccentColor: "",
  };

  const stateCopy = convertEmptyToStr({
    initialValues: {
      ...dataModel,
      ...appConfigs,
    },
    setupingTrackingPage: false,
    activeTab: "1",
    disableCancel: true,
  });

  stateCopy.initialValues.trackingUrl = stateCopy.initialValues.trackingUrl.replace(
    "?tracking_number=",
    ""
  );

  const [state, setState] = useState(stateCopy);

  useEffect(() => {
    const configs = appConfigs;
    configs.trackingUrl = configs.trackingUrl?.replace("?tracking_number=", "");

    setState((oldState) => ({
      ...oldState,
      initialValues: convertEmptyToStr({ ...oldState.initialValues, ...appConfigs }),
    }));
  }, [appConfigs]);

  useEffect(() => {
    setshowCode(false);
  }, [typeOfImage, customImageUrl, color]);

  const toggleTab = (tabId) => {
    if (state.activeTab !== tabId) {
      setState((oldState) => ({
        ...oldState,
        activeTab: tabId,
      }));
    }
  };

  const handleInstall = async () => {
    setState((oldState) => ({ ...oldState, setupingTrackingPage: true }));

    // Save to PipeDream
    callPipeDreamToSave(user.email, "pluginV2Install", { domain: currentMerchant.domain });

    const { success, message, data } = await doInstallShopifyTracking(currentMerchant.uuid, code);

    if (success) {
      toast.success(
        <>
          Página criada/atualizada com sucesso.{" "}
          <a href={data.trackingUrl} rel="noopener noreferrer" target="_blank">
            Clique aqui
          </a>{" "}
          para visualizar
        </>
      );

      formikRef.current.setFieldValue(
        "trackingUrl",
        data.trackingUrl.replace("?tracking_number=", "")
      );

      return setState((oldState) => ({
        ...oldState,
        setupingTrackingPage: false,
        initialValues: {
          ...oldState.initialValues,
          trackingUrl: data.trackingUrl,
          hasShopifyPageCreated: true,
        },
      }));
    }

    toast.error(message);

    return setState((oldState) => ({ ...oldState, setupingTrackingPage: false }));
  };

  const handleUninstall = async () => {
    setState((oldState) => ({ ...oldState, setupingTrackingPage: true }));

    const { success } = await doUninstallShopifyTracking(currentMerchant.uuid);

    if (success) toast.success("Página removida com sucesso.");

    formikRef.current.setFieldValue("trackingUrl", "");

    return setState((oldState) => ({
      ...oldState,
      setupingTrackingPage: false,

      initialValues: {
        ...oldState.initialValues,
        hasShopifyPageCreated: false,
      },
    }));
  };

  return (
    <>
      <div className="row mb35 mt35">
        <div className="col-sm-4 mb30">
          <IumyIcon className="mb15" />
          <h5>Rastreio Iumy</h5>
          <p>Aqui você pode gerar o seu plugin de rastreio e customizar as cores que deseja!</p>
        </div>

        <div className="col">
          <div className="box-widget">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  Plugin de Rastreio
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === "2" })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Configurações
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1" className="plugin">
                <div className="inner">
                  <div className="row row-actions">
                    <div className="image-type col">
                      <span className="field-label" required>
                        <strong>Imagem de fundo</strong>
                      </span>

                      <div className="options">
                        <div className="pretty p-default p-round">
                          <input
                            type="checkbox"
                            checked={typeOfImage === "default"}
                            onChange={(e) => e.target.checked && setType("default")}
                          />
                          <div className="state p-success">
                            <label>Padrão</label>
                          </div>
                        </div>

                        <div className="pretty p-default p-round">
                          <input
                            type="checkbox"
                            checked={typeOfImage === "custom"}
                            onChange={(e) => e.target.checked && setType("custom")}
                          />
                          <div className="state p-success">
                            <label>Personalizada</label>
                          </div>
                        </div>

                        <div className="pretty p-default p-round">
                          <input
                            type="checkbox"
                            onChange={(e) => e.target.checked && setType("none")}
                            checked={typeOfImage === "none"}
                          />
                          <div className="state p-success">
                            <label>Nenhuma</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {typeOfImage !== "none" && (
                      <div className="col swatch-image-selector">
                        <span className="field-label" required>
                          <strong>Configurações </strong>-{" "}
                          {typeOfImage === "default" ? "Cor da imagem" : "Imagem personalizada"}
                        </span>

                        {typeOfImage === "custom" ? (
                          <ImageUploader
                            containerClass="el-dropzone f14"
                            // preload={[values.icon]}
                            hideThumbs
                            onUpload={(result) => {
                              if (result.success) {
                                setCustomImage(result.data.file);
                              }
                            }}
                            onRemove={() => {
                              setCustomImage(null);
                            }}
                          />
                        ) : (
                          <ul className="swap-actions">
                            {colors.map((c) => (
                              <li
                                key={c.slug}
                                className={`${c.slug} ${color === c.slug && "active"}`}
                              >
                                <input type="button" onClick={() => setColor(c.slug)} />
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="row row-colors">
                    <ColorPicker
                      color={state.initialValues.pluginAccentColor}
                      onChange={(_color) => {
                        submitHandler(
                          { ...state.initialValues, pluginAccentColor: _color },
                          dataModel
                        );
                      }}
                    />
                    <small className="mt-3">
                      Utilize preferencialmente cores escuras, pois as fontes sempre serão claras.
                    </small>
                  </div>

                  <span className="prev-label">Pré-visualização</span>
                  <div className="wrap-preview">
                    <iframe
                      className="iframe-preview"
                      scrolling="no"
                      style={typeOfImage === "none" ? { minHeight: 300 } : {}}
                      title="Customize seu plugin de rastreio"
                      src={"data:text/html," + encodeURIComponent(code)}
                    />
                  </div>

                  {!showCode && (
                    <div className="foot-actions text-center">
                      <div className="install">
                        <button
                          type="button"
                          disabled={state.setupingTrackingPage}
                          className="btn green size--medium-2"
                          onClick={() => handleInstall()}
                        >
                          {state.initialValues.hasShopifyPageCreated ? (
                            <>
                              <FaRedo /> Atualizar
                            </>
                          ) : (
                            <>
                              <FaPlus /> Criar página de rastreio
                            </>
                          )}
                        </button>
                        {state.initialValues.hasShopifyPageCreated && (
                          <button
                            type="button"
                            disabled={state.setupingTrackingPage}
                            className="btn red size--medium-2"
                            onClick={() => handleUninstall()}
                          >
                            <FaTrashAlt /> Remover página de rastreio
                          </button>
                        )}
                      </div>

                      <span className="or-line">ou</span>

                      <button
                        type="button"
                        className="btn gray size--medium"
                        onClick={() => setshowCode(true)}
                      >
                        <FaCode /> Gerar HTML
                      </button>
                    </div>
                  )}

                  {showCode && (
                    <>
                      <strong className="mb5 block">Copiar código:</strong>
                      <code id="code" onClick={() => selectText("code", code)} role="textbox">
                        {code}
                      </code>
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <Formik
                  innerRef={formikRef}
                  validateOnMount
                  enableReinitialize
                  initialValues={state.initialValues}
                  validationSchema={Yup.object().shape({
                    trackingUrl: Yup.string().url("Digite um URL válido"),
                  })}
                  validate={(a1) => {
                    if (!_.isEqual(a1, state.initialValues)) {
                      setState((oldState) => ({
                        ...oldState,
                        disableCancel: false,
                      }));
                    } else {
                      setState((oldState) => ({
                        ...oldState,
                        disableCancel: true,
                      }));
                    }
                  }}
                  onSubmit={(values) => {
                    const trackingUrl =
                      values.trackingUrl.indexOf("?tracking_number=") > -1 ||
                      values.trackingUrl === ""
                        ? values.trackingUrl
                        : `${values.trackingUrl}?tracking_number=`;

                    // setState((s) => ({ ...s, disableCancel: true }));

                    submitHandler({ ...values, trackingUrl }, dataModel);
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="inner">
                        <div className="form-group mb0">
                          <div className="row">
                            <span className="field-label col">URL de rastreio</span>
                          </div>

                          <Field name="trackingUrl">
                            {({ field }) => (
                              <input
                                {...field}
                                className="form-control"
                                placeholder="https://meusite.com.br/pages/rastreio"
                                value={field.value.replace("?tracking_number=", "")}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            component="div"
                            className="error-message"
                            name="trackingUrl"
                          />
                          <small>
                            Indique a URL completa da sua página de rastreio. O código de
                            rastreamento será adicionado ao final dessa URL nas notificações
                            enviadas ao cliente.
                            <br /> Exemplo: https://meusite.com.br/pages/rastreio.
                          </small>
                        </div>

                        {values.trackingUrl && (
                          <>
                            <strong className="mb5 block">
                              Pré-visualização da URL com rastreio
                            </strong>
                            <code>
                              {`${values.trackingUrl.replace(
                                "?tracking_number=",
                                ""
                              )}?tracking_number=`}
                              LB123123123HK
                            </code>
                          </>
                        )}
                      </div>
                      <hr />
                      <div className="inner">
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb0">
                              <span className="field-label" required>
                                <strong>Dias</strong> para considerar um pedido atrasado
                              </span>

                              <Field
                                name="packageLateAfterDays"
                                type="number"
                                className="form-control w-40"
                                placeholder="20 dias"
                              />
                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name="packageLateAfterDays"
                              />

                              <small>
                                Por padrão após 20 dias classificamos um pedido como atrasado. Você
                                pode ajustar conforme sua necessidade.
                              </small>
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group mb0">
                              <span className="field-label" required>
                                <strong>Dias</strong> (esperado) para adição do rastreio
                              </span>

                              <Field
                                name="orderUnfulfilledAfterDays"
                                className="form-control w-40"
                                placeholder="3 dias"
                                type="number"
                              />
                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name="orderUnfulfilledAfterDays"
                              />

                              <small>
                                Após 4 dias sem rastreio, um pedido gera uma notificação a você.
                                Você pode ajustar conforme sua necessidade.
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="inner">
                        <Field name="hideTaxedMoves">
                          {(props) => (
                            <div className="pretty p-switch p-fill">
                              <input
                                {...props.field}
                                type="checkbox"
                                checked={props.field.value}
                                onChange={() => {
                                  props.form.setFieldValue("hideTaxedMoves", !props.field.value);
                                }}
                              />
                              <div className="state  p-success">
                                <label>Ocultar taxações do cliente</label>
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>

      <hr />

      {state.activeTab === "2" && (
        <footer className="footer-actions">
          <div className="flex flex-right">
            <button
              disabled={state.disableCancel}
              onClick={() => {
                formikRef.current.handleReset();

                setState((oldState) => ({
                  ...oldState,
                  disableCancel: true,
                }));
              }}
              className="btn default size--medium-2 mr10"
              type="button"
            >
              Cancelar
            </button>

            <button
              className={classnames("btn green size--medium-2 withLoader", {
                loading,
              })}
              disabled={state.disableCancel}
              type="button"
              onClick={() => formikRef.current.handleSubmit()}
            >
              <span className="text">Salvar</span>
              <div className="dots">
                <div className="dot dot-1" />
                <div className="dot dot-2" />
                <div className="dot dot-3" />
              </div>
            </button>
          </div>
        </footer>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
  auth: state.auth,
});

export default connect(mapStateToProps, null)(RastreioIumy);
