import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import _ from "lodash";
// import { FaMagic, FaCheckDouble, FaBusinessTime, FaHandshake, FaFileInvoice } from "react-icons/fa";

// const TextArea = (field, ...props) => <textarea className="form-control" {...field} {...props} />;

export default function ConfigTab({ appConfigs: { privacyPolicyLink }, submitHandler }) {
  const initialValues = { privacyPolicyLink };

  const [state, setState] = useState({
    submiting: false,
    hasChanges: false,
  });

  // Handler: Submit
  const submitAction = async (values) => {
    setState((s) => ({ ...s, submiting: true }));

    const result = await submitHandler(values);

    setState((s) => ({
      ...s,
      submiting: false,
      ...(result.success ? { hasChanges: false, submiting: false } : {}),
    }));
  };

  return (
    <>
      <h2 className="tab-title">Configurações</h2>
      {/* <div className="box-widget-mini">
        <strong className="f14 box-title">Configurações gerais</strong>

        <div className="rule-list">
          <div className="rule-item">
            <div className="rule-infos">
              <FaMagic className="ico" />

              <div className="rule-description">
                <span className="block">Finalizar ao realizar o pagamento</span>
                <span className="gray f13">
                  Encerra uma solicitação assim que todos pagamentos forem emitidos
                </span>
              </div>
            </div>

            <div className="pretty p-switch p-fill">
              <input type="checkbox" />
              <div className="state p-success">
                <label></label>
              </div>
            </div>
          </div>

          <div className="rule-item">
            <div className="rule-infos">
              <FaCheckDouble className="ico" />

              <div className="rule-description">
                <span className="block">Habilitar aprovação sem logística reversa</span>
                <span className="gray f13">
                  Permite aprovação de uma reversa sem uma etiqueta (para casos em que não compense
                  o retorno)
                </span>
              </div>
            </div>

            <div className="pretty p-switch p-fill">
              <input type="checkbox" />
              <div className="state p-success">
                <label></label>
              </div>
            </div>
          </div>

          <div className="rule-item">
            <div className="rule-infos">
              <FaBusinessTime className="ico" />

              <div className="rule-description">
                <span className="block">Habilitar status “Processando Pagamento”</span>
                <span className="gray f13">
                  Para casos em que o financeiro trabalhe separado do receptivo
                </span>
              </div>
            </div>

            <div className="pretty p-switch p-fill">
              <input type="checkbox" />
              <div className="state p-success">
                <label></label>
              </div>
            </div>
          </div>

          <div className="rule-item">
            <div className="rule-infos">
              <FaHandshake className="ico" />

              <div className="rule-description">
                <span className="block">Habilitar aprovação com envio pelo cliente</span>
                <span className="gray f13">
                  Para casos em que o financeiro trabalhe separado do receptivo
                </span>
              </div>
            </div>

            <div className="pretty p-switch p-fill">
              <input type="checkbox" />
              <div className="state p-success">
                <label></label>
              </div>
            </div>
          </div>

          <div className="rule-item">
            <div className="rule-infos">
              <FaFileInvoice className="ico" />

              <div className="rule-description">
                <span className="block">Exigir status “Aguardando NF Devolução”</span>
                <span className="gray f13">
                  Permite com que o lojista adicione o número ou a chave DANFE da nota fiscal de
                  devolução.
                </span>
              </div>
            </div>

            <div className="pretty p-switch p-fill">
              <input type="checkbox" />
              <div className="state p-success">
                <label></label>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Formik
        initialValues={initialValues}
        onSubmit={submitAction}
        validate={async (values) => {
          const isEqual = _.isEqual(values, initialValues);

          return setState((s) => ({
            ...s,
            // enableSubmit: isValid && !isEqual,
            hasChanges: !isEqual,
            initialValues: values,
          }));
        }}
      >
        <Form>
          {/*
            <div className="box-widget-mini">
              <strong className="f14 box-title">Cupons</strong>

              <form className="return-point">
                <div className="form-floating form-field">
                  <Field
                    type="textarea"
                    name="coupon"
                    className="form-control"
                    rows="2"
                    placeholder="Descrição"
                    value="{{coupon_code}}: Cupom Iumy. Para troca do pedido Nr {{returnal_number}}"
                    component={TextArea}
                  />

                  <ErrorMessage name="estado" className="error-message" />

                  <label htmlFor="estado" className="f12 uppercase gray bold">
                    Descrição
                  </label>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="prefixo"
                        id="prefixo"
                        type="text"
                        placeholder="00000-000"
                        className="form-control"
                        value="IM"
                      />

                      <ErrorMessage name="prefixo" className="error-message" />
                      <label htmlFor="prefixo" className="f12 uppercase gray bold">
                        Prefixo
                      </label>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="validity"
                        id="validity"
                        type="text"
                        placeholder="Validade"
                        className="form-control"
                        value="12"
                      />

                      <ErrorMessage name="validity" className="error-message" />

                      <label htmlFor="validity" className="f12 uppercase gray bold">
                        Dias de validade
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div> 
          */}

          <div className="box-widget-mini">
            <strong className="f14 box-title">Link de políticas de troca e devolução</strong>
            <p className="f14 gray-500 ">
              Insira o link que remeta as suas políticas de trocas, caso este campo esteja
              preenchido o usuário só conseguirá solicitar após marcar que leu suas políticas.
            </p>
            <div className="return-point">
              <div className="form-floating form-field">
                <Field
                  type="text"
                  name="privacyPolicyLink"
                  className="form-control"
                  placeholder="Políticas de troca"
                />

                <ErrorMessage name="privacyPolicyLink" className="error-message" />

                <label htmlFor="estado" className="f12 uppercase gray bold">
                  URL das políticas de troca e devolução
                </label>
              </div>
            </div>
          </div>

          <hr />

          <footer className="footer-actions">
            <div className="flex flex-right">
              <button
                disabled={!state.hasChanges || state.submiting}
                onClick={() => {
                  setState((oldState) => ({
                    ...oldState,
                    disableCancel: true,
                  }));
                }}
                className="btn default size--medium-2 mr10"
                type="button"
              >
                Cancelar
              </button>

              <button
                className={`btn green size--medium-2 withLoader ${
                  state.submiting ? "loading" : ""
                } `}
                disabled={!state.hasChanges || state.submiting}
                type="submit"
              >
                <span className="text">Salvar</span>

                <div className="dots">
                  <div className="dot dot-1" />
                  <div className="dot dot-2" />
                  <div className="dot dot-3" />
                </div>
              </button>
            </div>
          </footer>
        </Form>
      </Formik>
    </>
  );
}
