import React, { useState } from "react";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import { swalMiddleware as Swal } from "helpers";

// Models
import ReturnalCustomRule from "models/returnal/returnal-custom-rule";

// Components
import EditReasonsModal from "./components/edit-reasons-modal";

export default function RulesTab({ customRules, submitHandler, removeCustomRuleHandler }) {
  const traitRules = customRules.map((cr) => new ReturnalCustomRule(cr));

  const [state, setState] = useState({
    editingReason: null,
  });

  // Open modal
  const editReasonHandler = (customRule) => {
    setState((s) => ({ ...s, editingReason: customRule }));
  };

  // Toggle off modal
  const cancelEditReasonHandler = () => {
    setState((s) => ({ ...s, editingReason: null }));
  };

  const submit = async (rule) => {
    const newCustomRules = traitRules.map((r) => {
      if (rule.uuid === r.uuid) return rule;

      return r;
    });

    if (!rule.uuid) newCustomRules.push(rule);

    await submitHandler({
      customRules: newCustomRules,
    });

    cancelEditReasonHandler();
  };

  const removeCustomRule = async (ruleId) => {
    const popupResult = await Swal.fire({
      title: "Excluir regra customizada",
      text: "Tem certeza que deseja excluir essa regra?",
      icon: "iumy",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Excluir",
      cancelButtonText: "Cancelar",

      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (!popupResult.isConfirmed) return false;

    return removeCustomRuleHandler(ruleId);
  };

  return (
    <>
      <header>
        <h2 className="tab-title">Regras</h2>
        <button
          type="button"
          className="add-reason-button"
          onClick={() =>
            setState((s) => ({
              ...s,
              editingReason: new ReturnalCustomRule({ isActive: true, isArchived: false }),
            }))
          }
        >
          Adicionar regra +
        </button>
      </header>

      {traitRules.length ? (
        <ul className="reasons-list">
          {traitRules.map((customRule, index) => (
            <li key={customRule.uuid} className="box-widget-mini reason-item">
              <div className="reason-heading">
                <div className="reason-infos">
                  <span className="reason-title">{customRule.reason}</span>
                  <span className="reason-time">
                    Prazo de {customRule.deadlineInDays} dias após entregue
                  </span>
                </div>

                <div className="reason-actions">
                  <button
                    type="button"
                    className="reason-edit"
                    onClick={() => editReasonHandler(customRule)}
                  >
                    <FaPen />
                  </button>
                  <button
                    type="button"
                    className="reason-remove"
                    onClick={() => removeCustomRule(customRule.uuid)}
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
              <div className="reason-labels">
                {customRule.allowExchange && <span className="iumy-badge EXCHANGE">Trocas</span>}
                {customRule.allowReturn && <span className="iumy-badge REFUND">Devolução</span>}
                {customRule.requireEvidence && (
                  <span className="iumy-badge REQUIRE_IMAGE">Exigir imagem</span>
                )}
                {customRule.requireComment && (
                  <span className="iumy-badge REQUIRE_COMMENT">Exigir comentário</span>
                )}
                {customRule.autoApprove && (
                  <span className="iumy-badge AUTO_APPROVAL">Aprovação automática</span>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="reasons-list none">Nenhuma regra cadastrada...</div>
      )}

      {state.editingReason && (
        <EditReasonsModal
          customRule={state.editingReason}
          cancelHandler={cancelEditReasonHandler}
          submitHandler={submit}
        />
      )}
    </>
  );
}
