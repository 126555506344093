import React from "react";
import { useDispatch } from "react-redux";

// Assets
import Logo from "images/logo-iumy-white.png";

// Actions
import { toggleSidebar } from "store/modules/context/reducer";

export default function Navbar(props) {
  const dispatch = useDispatch();

  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark d-block d-lg-none d-xl-none">
      <div className="container-fluid">
        <a href="/" className="navbar-brand">
          <img src={Logo} alt="" className="logo" />
        </a>
        <button onClick={() => dispatch(toggleSidebar())} className="navbar-toggler" type="button">
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </nav>
  );
}
