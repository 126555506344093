import { immerable } from "immer";

export default class StatusReturnal {
  constructor(statusCode) {
    this[immerable] = true;

    const traitedStatus = StatusReturnal.statusToText(statusCode);

    this.code = traitedStatus.code;
    this.label = traitedStatus.label;
  }

  static statusToText(status) {
    switch (status) {
      case "PENDING_QUOTE":
        return { label: "Em processamento", code: status };

      // RETURNAL
      case "RETURNAL_CREATED":
      case "PENDING_APPROVAL":
        return { label: "Pendente Aprovação", code: status };

      case "APPROVED":
      case "RETURNAL_APPROVED":
        return { label: "Solicitação aprovada", code: status };

      case "IN_TRANSIT":
        return { label: "Em trânsito", code: status };

      case "RETURNAL_REFUNDED":
        return { label: "Reversa Reembolsada", code: status };

      case "RETURNAL_COUPON_GENERATED":
        return { label: "Vale-trocas gerado", code: status };

      case "APPROVAL_IN_PROGRESS":
        return { label: "Aprovação em progresso", code: status };

      case "DELIVERED":
        return { label: "Solicitação Recebida", code: status };

      case "RETURNAL_CONFERRED":
      case "CONFERRED":
        return { label: "Solicitação conferida", code: status };

      case "CANCELLED":
      case "RETURNAL_CANCELLED":
        return { label: "Solicitação cancelada", code: status };

      case "ENDED":
        return { label: "Solicitação finalizada", code: status };

      // TYPES
      case "EXCHANGE":
        return { label: "Troca", code: status };

      case "RETURN":
        return { label: "Devolução", code: status };

      default:
        return { label: "Não identificado", code: "UNDEFINED" };
    }
  }
}
