import React, { useState, useRef } from "react";
import { ErrorMessage, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";

// Assets
import logo from "images/logo-black.png";
import PhoneInput from "components/custom-fields/phone";

// Actions
import { doSignupRequest } from "store/modules/auth/thunks";

// Classes
import { DTLayer } from "components/data-layer";
import PageTitle from "components/page-title";
import Wizard, { WizardStep } from "./wizard";

export default function Signup() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [signupSuccessful, setSignupSuccessful] = useState(false);

  const formikRef = useRef(null);

  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (signupSuccessful) {
    DTLayer.dispatch("lead");

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            signupSuccess: true,
          },
        }}
      />
    );
  }

  return (
    <main className="auth-screen signup">
      <PageTitle pageTitle="Cadastre-se" />

      <div className="box">
        <aside className="aside-side">
          <div className="mb40">
            <img src={logo} alt="iumy" width="146" />
          </div>
          <h1>Cadastre-se</h1>
          <p className="f16 black-40">Faça parte dessa evolução do e-commerce!</p>

          <Wizard
            ref={formikRef}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              passwordConfirmation: "",
              phone: "",
            }}
            submitButtonClass="btn green btn-block size--large"
            onSubmit={async (values) => {
              const result = await dispatch(doSignupRequest(values));

              if (result.success) {
                setSignupSuccessful(true);
              } else {
                toast.error(result.message);
              }
            }}
          >
            <WizardStep
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .max(100, "O tamanho máximo do nome é de 100 characteres")
                  .required("Preencha o nome"),
                email: Yup.string().email("E-mail inválido").required("Preencha o e-mail"),
                password: Yup.string()
                  .min(8, "A senha deve ter entre 8 e 21 characteres")
                  .max(21, "A senha deve ter entre 8 e 21 characteres")
                  .required("Preencha a senha"),
                passwordConfirmation: Yup.string()
                  .required("Preencha a confirmação da senha")
                  .oneOf([Yup.ref("password"), null], "As senhas não combinam"),
              })}
            >
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <Field
                      autoComplete="given-name"
                      component="input"
                      name="firstName"
                      placeholder="Nome"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage className="error-message" component="div" name="firstName" />
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <Field
                      autoComplete="given-name"
                      component="input"
                      name="lastName"
                      placeholder="Sobrenome"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage className="error-message" component="div" name="lastName" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <Field
                      autoComplete="email"
                      component="input"
                      name="email"
                      placeholder="E-mail"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage className="error-message" component="div" name="email" />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <Field name="phone" autoComplete="phone">
                      {(props) => (
                        <PhoneInput
                          {...props}
                          className="form-control"
                          placeholder="(99) 9999-99999"
                        />
                      )}
                    </Field>

                    <ErrorMessage className="error-message" component="div" name="whatsapp" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <Field
                      autoComplete="password"
                      component="input"
                      name="password"
                      placeholder="Senha"
                      type="password"
                      className="form-control"
                    />
                    <ErrorMessage className="error-message" component="div" name="password" />
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <Field
                      autoComplete="password"
                      component="input"
                      name="passwordConfirmation"
                      placeholder="Confirmação de senha"
                      type="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="passwordConfirmation"
                    />
                  </div>
                </div>
              </div>
            </WizardStep>
          </Wizard>

          <hr />

          <p className="text-center mb0 black-40">
            Já é cadastrado? <br />
            Então <Link to="/">clique aqui</Link> para entrar!
          </p>
        </aside>
        <div className="background-side" />
      </div>

      <ToastContainer />
    </main>
  );
}
