import { immerable } from "immer";

export default class PlanSummary {
  constructor(options = {}) {
    this[immerable] = true;
    this.uuid = options.uuid;
    this.name = options.name;
    this.description = options.description;

    // Details
    this.details = options?.details.map((d) => ({
      description: d.description,
      subtitle: d.subtitle,
      title: d.title,
      id: Math.random().toString(36).slice(2),
    }));

    this.details = [
      ...this.details,
      {
        id: Math.random().toString(36).slice(2),
        title: "E-mails de movimentações automáticos.",
      },
      {
        id: Math.random().toString(36).slice(2),

        title: "Notificações via WhatsApp",
        subtitle: "Envio em massa",
      },
      {
        id: Math.random().toString(36).slice(2),

        title: "Plugin de rastreio",
      },
    ];

    this.freeOrderImportQuantity = options.freeOrderImportQuantity;
    this.index = options.index;
    this.isActive = options.isActive;
    this.isTrial = options.isTrial;
    this.orderQuantity = options.orderQuantity;
    this.price = parseFloat(options.price);
    this.pricePerOrder = options.pricePerOrder;
    this.publiclyAvailable = options.publiclyAvailable;
    this.slug = options.slug;
  }
}
