import { immerable } from "immer";

export default class NotificationTemplate {
  constructor(object = {}) {
    this[immerable] = true;

    this.uuid = object.uuid;
    this.context = object.context;
    this.channel = object.channel;
    this.trigger = object.trigger;
    this.label = object.label;
    this.target = object.target;
    this.category = object.category;
    this.isActive = object.isActive;
    this.subject = object.subject;
    this.content = object.content;
    this.tags = object.tags || [];
  }
}
