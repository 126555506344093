export default class SelectedFilter {
  constructor({ slug, label, rawValues, type, defaultValue, hidden }) {
    this.slug = slug;
    this.label = label;
    this.values = rawValues;
    this.type = type;

    this.defaultValue = defaultValue;
    this.hidden = hidden || false;
  }
}
