import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function ModalComp({
  hiddenCloseBtn,
  className,
  children,
  title,
  buttonPrimaryLabel,
  buttonPrimarySubmit,
  buttonCancelLabel,
  showModal,
  toggleModal,
  cancelHandler,
  modalProps,
  backdrop = true,
  btnPrimaryDisabled,
  customActions,
  size,
}) {
  const modalHeadersProps = {};

  if (!hiddenCloseBtn) modalHeadersProps.toggle = cancelHandler || toggleModal;
  return (
    <div>
      <Modal
        backdrop={backdrop}
        isOpen={showModal}
        toggle={cancelHandler || toggleModal}
        className={className}
        size={size}
        {...modalProps}
      >
        <ModalHeader {...modalHeadersProps}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>

        {customActions ? (
          <ModalFooter>{customActions(cancelHandler || toggleModal)}</ModalFooter>
        ) : (
          buttonCancelLabel ||
          (buttonPrimaryLabel && (
            <ModalFooter>
              {buttonPrimaryLabel && (
                <Button
                  color="primary"
                  disabled={btnPrimaryDisabled}
                  onClick={buttonPrimarySubmit || toggleModal}
                >
                  {buttonPrimaryLabel}
                </Button>
              )}
              {buttonCancelLabel && (
                <Button color="secondary" onClick={cancelHandler || toggleModal}>
                  {buttonCancelLabel}
                </Button>
              )}
            </ModalFooter>
          ))
        )}
      </Modal>
    </div>
  );
}
