import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaArrowPointer, FaCreditCard, FaRightLeft, FaShop } from "react-icons/fa6";
import { Link } from "react-router-dom";

// Actions & Helpers
import { getAppSettingsByMerchantRequest } from "store/modules/merchants/actions";
import { renderRoute } from "pages/main/routes-list";
import { isAppActive } from "helpers";

// Assets
import OnboardingChannelSvg from "images/home-wizard/onboarding-channels.png";
import OnboardingPortalPng from "images/home-wizard/onboarding-portal.png";
import OnboardingTfPng from "images/home-wizard/onboarding-tf.png";
import OnboardingBillingPng from "images/home-wizard/onboarding-billing.png";

// Icons
import NuvemshopIcon from "images/app-icons/without-bg/nuvemshop-horizontal-sm.png";
import ShopifyIcon from "images/app-icons/without-bg/shopify-horizontal-sm.png";

// Components
import LoaderBG from "components/loader-bg";
import Card from "./card";

export default function Wizard() {
  const { user } = useSelector((state) => state.auth);
  const { currentMerchant } = useSelector((state) => state.merchants);

  const [state, setState] = useState({
    menvInstalled: false,
    iumyTfInstalled: false,
    iumyTrackerInstalled: false,
    marketplaceInstalled: false,
    loading: true,
    totalProgressPercentage: 0,

    steps: [
      {
        step: "marketplace",
        label: "Integre com seu canal de vendas",
        description:
          "Conecte com sua plataforma de vendas para começar a usar a Iumy. Isso garante a importação de suas vendas e envios para o CRM da Iumy.",
        isFinished: false,
        Icon: FaShop,
        picture: OnboardingChannelSvg,
        content: (
          <div className="actions">
            {/* <Link
              className="btn-store"
              style={{ maxWidth: 151 }}
              to={{
                pathname: renderRoute("appDetail", {
                  merchantId: currentMerchant.uuid,
                  appSlug: "nuvemshop",
                }),
                state: { fromHome: true },
              }}
            >
              <img src={NuvemshopIcon} alt="Logo Nuvemshop" />
            </Link> */}
            <Link
              className="btn-store"
              to={{
                pathname: renderRoute("appDetail", {
                  merchantId: currentMerchant.uuid,
                  appSlug: "shopify",
                }),
                state: { fromHome: true },
              }}
            >
              <img src={ShopifyIcon} alt="Logo Shopify" />
            </Link>
          </div>
        ),
      },
      {
        step: "portal",
        label: "Instale o portal do cliente em seu site",
        description:
          "O portal do cliente é uma ferramenta importante para centralizar o seu contato com o cliente em um ponto único e dentro do seu ambiente de vendas.",
        isFinished: false,
        picture: OnboardingPortalPng,
        Icon: FaArrowPointer,
        content: (
          <Link
            className="btn green size--medium"
            style={{ minWidth: 250, padding: 10, fontSize: 14, fontWeight: 400, borderRadius: 8 }}
            to={{
              pathname: renderRoute("appDetail", {
                merchantId: currentMerchant.uuid,
                appSlug: "iumy_tracker",
              }),
              state: { fromHome: true },
            }}
          >
            Instalar
          </Link>
        ),
      },
      {
        step: "troca-facil",
        label: "Ative o Troca Fácil!",
        description:
          "O Troca Fácil permite que seus clientes solicitem Trocas ou Devoluções através do Portal do cliente. Com Iumy você recebe a solicitação, gera a etiqueta de forma automatizada.",
        picture: OnboardingTfPng,
        isFinished: false,
        Icon: FaRightLeft,
        content: (
          <Link
            className="btn green size--medium"
            style={{ minWidth: 250, padding: 10, fontSize: 14, fontWeight: 400, borderRadius: 8 }}
            to={{
              pathname: renderRoute("appDetail", {
                merchantId: currentMerchant.uuid,
                appSlug: "iumy_returnal",
              }),
              state: { fromHome: true },
            }}
          >
            Instalar
          </Link>
        ),
      },
      {
        step: "billing",
        label: "Escolha o seu plano",
        description:
          "Escolha seu plano para aproveitar ao máximo do que a Iumy pode fazer pela sua loja! Um único plano pode ser dividido entre todas suas lojas, aproveite.",
        isFinished: !(
          !user.subscription ||
          user.subscription.billingPlanSlug === "trial" ||
          user?.subscription?.status === "CANCELLED"
        ),
        picture: OnboardingBillingPng,
        content: (
          <Link
            className="btn green size--medium"
            style={{ minWidth: 250, padding: 10, fontSize: 14, fontWeight: 400, borderRadius: 8 }}
            to={{
              pathname: renderRoute("config-billing"),
              state: { fromHome: true },
            }}
          >
            Ver planos
          </Link>
        ),
        Icon: FaCreditCard,
      },
    ],
  });

  useEffect(() => {
    async function init() {
      if (currentMerchant) {
        // Check store connection
        const hasMarketplaceApp = currentMerchant?.apps?.find(
          (app) => app.category === "ecommerce" && app.isActive
        );

        // Load portal settings
        const {
          success: hasPortalInstalled,
          data: { trackingUrl },
        } = await getAppSettingsByMerchantRequest(currentMerchant.uuid, "iumy_tracker");

        setState((_s) => ({
          ..._s,
          steps: _s.steps.map((s) => {
            switch (s.step) {
              case "marketplace":
                s.isFinished = hasMarketplaceApp;
                break;

              case "portal":
                s.isFinished = hasPortalInstalled && trackingUrl;
                break;

              case "troca-facil": {
                s.isFinished = isAppActive({ appSlug: "returnal-iumy" }, currentMerchant.apps);
                break;
              }

              default:
                break;
            }

            return s;
          }),
          loading: false,
        }));
      }
    }

    init();
  }, [currentMerchant]);

  const notConcludedSteps = state.steps.filter((s) => !s.isFinished);
  const currentStep = notConcludedSteps[0] || null;
  const concludedSteps = state.steps.filter((s) => s.isFinished);
  const totalProgressPercentage = (concludedSteps.length / state.steps.length) * 100;

  if (state.loading) return <LoaderBG blocks={3} />;

  return (
    currentStep && (
      <div className="box-widget main-card pending-actions">
        <header className="pending-header">
          <span className="main-card-title">Comece a configurar por aqui</span>

          <div className="pending-progress-bar">
            <span>
              {concludedSteps.length} de {state.steps.length}
            </span>
            <div className="progress-bar">
              <span style={{ width: `${totalProgressPercentage}%` }} />
            </div>
          </div>
        </header>
        <Card step={currentStep} />
      </div>
    )
  );
}
