import { createSlice } from "@reduxjs/toolkit";

// => Model
import Return from "models/returnal/returnal";

const initialState = {
  list: [],
};

const returnsSlice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    doGetReturnsSuccess: (state, action) => {
      const returnsGetted = action.payload || [];
      const returnsTraited = returnsGetted.map((returnal) => new Return(returnal));

      state.list = [...returnsTraited];
    },

    doGetReturnsFailure: (state, action) => {
      state.list = [];
    },
  },
});

export const { doGetReturnsSuccess, doGetReturnsFailure } = returnsSlice.actions;

// Export the slice reducer as the default export
export default returnsSlice.reducer;
