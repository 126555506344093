import React, { useEffect } from "react";
import { apiInstance } from "services/api";

export default function Unsubscribe() {
  const handleUnsub = async () => {
    const result = await apiInstance.get(`/api/email/unsubscribe${window.location.search}`);
  };

  useEffect(() => {
    handleUnsub();
  }, []);

  return (
    <div className="container" style={{ marginTop: 20 }}>
      <div className="jumbotron">
        <h1 className="display-4">Inscrição cancelada!</h1>
        <p className="lead">Você não vai mais receber notificações sobre o pedido.</p>
        <hr className="my-4" />
        <p>Removemos seu e-mail da nossa lista.</p>
      </div>
    </div>
  );
}
