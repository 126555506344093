/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { FaCalendarAlt, FaBox, FaFlag, FaStop, FaClock, FaCheck } from "react-icons/fa";
import classnames from "classnames";

// My Comps
import Accordion from "components/accordion";
import FilterCalendar from "components/orders/filters/filter-daterange";
import FilterCheckbox from "components/orders/filters/filter-checkbox";
import FilterSwitch from "components/orders/filters/filter-switch";

function Filters(props) {
  const { activeFilters, availableFilters, filterStatus, toggleFilter, handleFilter } = props;

  const traitedFilters = availableFilters.map((af) => {
    const isActive = activeFilters.find((filter) => filter.slug === af.param);

    let Icon;
    let Render;

    switch (af.type) {
      case "checkbox": {
        Icon = FaFlag;
        Render = FilterCheckbox;
        break;
      }
      case "daterange": {
        Icon = FaCalendarAlt;
        Render = FilterCalendar;
        break;
      }

      case "switch": {
        Render = FilterSwitch;
        // Icon = FaFlag;
        break;
      }

      default:
        break;
    }

    switch (af.param) {
      case "is_late":
        Icon = FaClock;
        break;

      case "is_stalled":
        Icon = FaStop;
        break;

      case "has_no_packages":
        Icon = FaBox;
        break;

      case "is_archived":
        Icon = FaCheck;
        break;

      default:
        break;
    }

    af.values = isActive ? isActive.values : null;
    af.Icon = Icon;
    af.Render = Render;

    return af;
  });

  return (
    <>
      {/* Filters */}
      <div
        className={classnames("holder-slide filters", {
          active: filterStatus,
        })}
      >
        <ul className="inner-slide">
          <li className="header">
            <span className="f-18">Filtrar</span>
            <span className="close-filter" onClick={() => toggleFilter()}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="close-icon"
              >
                <line fill="none" stroke="#999" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13" />
                <line fill="none" stroke="#999" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13" />
              </svg>
            </span>
          </li>

          <li className="main">
            <ul className="accordions-wrap">
              {traitedFilters.map(
                (availFilter) =>
                  !availFilter.hidden && (
                    <Accordion
                      key={availFilter.param}
                      containerElm="li"
                      contentElm="div"
                      icon={availFilter.Icon && <availFilter.Icon className="icon" />}
                      title={availFilter.label}
                    >
                      {availFilter.description && (
                        <div className="filter-description">
                          <strong className="f12">Observação</strong>
                          <br />

                          <span className="f12 gray">{availFilter.description}</span>
                        </div>
                      )}
                      {availFilter.Render && (
                        <availFilter.Render handleFilter={handleFilter} filter={availFilter} />
                      )}
                    </Accordion>
                  )
              )}
            </ul>
          </li>
        </ul>
        <span onClick={() => props.toggleFilter()} className="screen-pusher filters" />
      </div>
    </>
  );
}

export default Filters;
