import OrderNote from "models/order-note";
import React, { useState, useEffect, useRef } from "react";
import { FaUserAlt } from "react-icons/fa";

import AutoTextArea from "components/autotextarea";

// Models
import { createOrderNote } from "store/modules/orders/actions";
import OrderNoteItem from "./order-note-item";

function OrderNotes({ storeUuid, orderUuid, notes, modifyOrder }) {
  const [state, setState] = useState({
    newNoteContent: "",
    notesHistory: [],
    submiting: false,
    inputFocused: false,
  });

  const formRef = useRef();

  useEffect(() => {
    setState((oldState) => ({ ...oldState, notesHistory: notes }));
  }, [notes]);

  const handleSubmit = async (content) => {
    if (!content) return alert("Por favor digite um conteúdo para a nota");

    setState((oldState) => ({
      ...oldState,
      submiting: true,
    }));

    const result = await createOrderNote(storeUuid, orderUuid, content);

    if (result.success) {
      formRef.current.reset();
      const newNote = new OrderNote(result.data);

      setState((oldState) => ({
        ...oldState,
        newNoteContent: "",
        submiting: false,
      }));

      return modifyOrder((order) => {
        order.notes.push(newNote);

        return order;
      });
    }

    return setState((oldState) => ({
      ...oldState,
      submiting: false,
    }));
  };

  const removeNoteHandler = (noteUuid) => {
    modifyOrder((order) => {
      order.notes = order.notes.filter((note) => note.uuid !== noteUuid);

      return order;
    });

    // setState((oldState) => ({
    //   ...oldState,
    //   notesHistory: oldState.notesHistory.filter((note) => note.uuid !== noteUuid),
    // }));
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e?.target?.content?.value);
        }}
        className="order-notes-heading-form"
      >
        <div className="avatar-column">
          <div className="avatar-wrap">
            <span className="placeholder">
              <FaUserAlt />
            </span>
          </div>
        </div>

        <div className="note-textarea-wrap box-widget-mini">
          <AutoTextArea
            name="content"
            className="textarea"
            placeholder="Escrever um comentário..."
            rows={1}
            onChange={(e) => {
              e.persist();
              setState((oldState) => ({ ...oldState, newNoteContent: e?.target?.value }));
            }}
            onFocus={() => setState((oldState) => ({ ...oldState, inputFocused: true }))}
            onBlur={() => setState((oldState) => ({ ...oldState, inputFocused: false }))}
          />

          {(state.newNoteContent.length > 0 || state.inputFocused) && (
            <button type="submit" className="btn green size--small" disabled={state.submiting}>
              {state.submiting ? "Salvando..." : "Salvar"}
            </button>
          )}
        </div>
      </form>

      <div className="order-notes-history">
        {state.notesHistory.length ? (
          state.notesHistory.map((note) => (
            <OrderNoteItem
              key={note.uuid}
              storeUuid={storeUuid}
              orderUuid={orderUuid}
              editHandler={handleSubmit}
              note={note}
              removeHandler={removeNoteHandler}
            />
          ))
        ) : (
          <div className="no-notes">Ainda não há notas por aqui...</div>
        )}
      </div>
    </>
  );
}

export default OrderNotes;
