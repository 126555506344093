import React from "react";
import classnames from "classnames";
import { FaCircleNotch } from "react-icons/fa";

export default function LoadingCircle({ containerElm, type, value, pos }) {
  const ContainerElm = containerElm || "div";

  return (
    <ContainerElm className={classnames("spinner-loader", type || "light", pos || "text-center")}>
      <FaCircleNotch className="spinner" />

      <span className="label-spinner">{value && value}</span>
    </ContainerElm>
  );
}
