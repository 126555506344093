/* eslint-disable react/jsx-no-bind */
import React from "react";
import { toast } from "react-toastify";

// Actions
import { cleanArray, accessDotNotation } from "helpers";
import SelectedFilter from "models/filters-manager";

export default class PageList extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.queryStr = window.location.search;

    const filters = this.convertQsToObj(this.queryStr);

    this.state = {
      loading: true,

      allSelectorActive: false,

      list: [],
      listSelected: [],

      allItemsSelected: false,

      filters,

      metadata: {
        totalItems: 0,
        totalPages: 0,
        count: 0,
      },

      filterOpened: false,
    };

    this.defaultFilters = {};
    this.handleFilterTimer = null;
    this.toggleSelectExtraResources = this.toggleSelectExtraResources.bind(this);
    this.getMasterState = this.getMasterState.bind(this);
  }

  getMasterState() {
    return this.state;
  }

  // eslint-disable-next-line class-methods-use-this
  get availableFilters() {
    return [];
  }

  // On load:
  // Pré-load filters
  async componentDidMount() {
    const { filters, search } = this.state;
    this._isMounted = true;

    // pre load orders
    await this.loadResources(
      {
        ...search,
        ...PageList.filtersToObj(filters, true),
      },
      true
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static convertFilterValue(values, filter, isRaw = false) {
    let returnValues;

    switch (filter.type) {
      case "checkbox": {
        returnValues = isRaw ? values.split(",") : values || [];
        returnValues = returnValues
          .map((k) => filter.options.find((o) => o.slug === k))
          .filter((o) => o !== undefined);
        break;
      }

      case "daterange": {
        returnValues = cleanArray(isRaw ? values.split("|").sort() : values || []);
        const [min, max] = returnValues;
        returnValues = returnValues.length > 0 ? [min, max] : [];
        break;
      }

      case "switch": {
        returnValues = values === null || values === "" ? filter.options[0] : values;
        break;
      }

      default:
        returnValues = values;
        break;
    }
    return returnValues;
  }

  convertQsToObj(queryStr) {
    const qs = queryStr || window.location.search;
    const params = new URLSearchParams(qs);
    const selectedFilters = [];
    const { availableFilters } = this;

    // Proccess filter
    [...params.entries()].forEach((tuple) => {
      const [key, rawValues] = tuple;
      const foundFilter = availableFilters.find((f) => f.param === key);
      const filterAlreadyAdded = selectedFilters.find((f) => f.key === key);

      if (filterAlreadyAdded || !foundFilter) return;

      const values = PageList.convertFilterValue(rawValues, foundFilter, true);
      const selectedFilter = new SelectedFilter({
        slug: key,
        label: foundFilter.label,
        rawValues: values,
        type: foundFilter.type,
        hidden: foundFilter.hidden,
        defaultValue: foundFilter.defaultValue,
      });

      selectedFilters.push(selectedFilter);
    });

    return selectedFilters;
  }

  /* ===============
   * Filter options
   * =============== */
  // Adapt filter result to API
  static filtersToObj(filtersArr, exportForApi = false) {
    return filtersArr.reduce((acc, filter) => {
      if (exportForApi) {
        switch (filter.type) {
          case "daterange": {
            const [min, max] = filter.values;
            acc[`${filter.slug}_min`] = min;
            acc[`${filter.slug}_max`] = max;
            break;
          }

          case "checkbox": {
            acc[filter.slug] = filter.values.map((o) => o.slug);
            break;
          }

          default:
            acc[filter.slug] = filter.values;
            break;
        }
      } else {
        switch (filter.type) {
          case "daterange": {
            acc[filter.slug] = filter.values.join("|");
            break;
          }

          case "checkbox": {
            acc[filter.slug] = filter.values.map((o) => o.slug);
            break;
          }
          default:
            acc[filter.slug] = filter.values;
            break;
        }
      }

      return acc;
    }, {});
  }

  static updateUrlWithObject(urlObject) {
    // Update URL
    const queryString = new URLSearchParams(urlObject).toString();
    let url = `${window.location.origin}${window.location.pathname}`;
    url = queryString ? `${url}?${queryString}` : url;
    window.history.replaceState(null, null, url);
  }

  async handleFilter(slug, values) {
    const { filters } = this.state;
    const { availableFilters } = this;
    const filtersCopy = filters.slice();
    const baseFilter = availableFilters.find((b) => b.param === slug);

    if (!baseFilter) return;

    // Find filter in array
    const fIndex = filtersCopy.findIndex((f) => f.slug === slug);

    // If found is a modify
    if (fIndex > -1) {
      const foundFilter = filtersCopy[fIndex];
      if (!values || values.length < 1) filtersCopy.splice(fIndex, 1);
      else {
        // Apply new values due rules.
        switch (foundFilter.type) {
          case "checkbox": {
            foundFilter.values = values.map((k) => baseFilter.options.find((o) => o.slug === k));
            break;
          }

          default:
            foundFilter.values = values;
            break;
        }
      }
    } else {
      values = PageList.convertFilterValue(values, baseFilter);
      const selectedFilter = new SelectedFilter({
        slug: baseFilter.param,
        label: baseFilter.label,
        rawValues: values,
        type: baseFilter.type,
        hidden: baseFilter.hidden,
        defaultValue: baseFilter.defaultValue,
      });
      filtersCopy.push(selectedFilter);
    }

    if (slug !== "page") {
      const pagination = filtersCopy.find((f) => f.slug === "page");

      if (pagination) pagination.values = 1;
      else {
        const selectedFilter = new SelectedFilter({
          slug: "page",
          label: "Paginação",
          rawValues: 1,
          type: "string",
          hidden: true,
          defaultValue: 1,
        });
        filtersCopy.push(selectedFilter);
      }
    }

    // Apply new filters to state
    this.setState((state) => ({
      ...state,
      filters: filtersCopy,
    }));

    clearInterval(this.handleFilterTimer);
    if (!["page", "page_size"].includes(slug)) {
      await new Promise((resolve) => {
        this.handleFilterTimer = setTimeout(() => {
          resolve(true);
        }, 500);
      });
    }

    // Make object to API models,
    // joining page metas & filters.
    const urlObject = {
      ...PageList.filtersToObj(filtersCopy),
    };

    PageList.updateUrlWithObject(urlObject);

    // Send request
    await this.loadResources({
      ...PageList.filtersToObj(filtersCopy, true),
    });
  }

  async clearFilters() {
    // Make object to API models,
    // joining page metas & filters.
    const urlObject = {
      page: 1,
      page_size: 10,
    };

    // Apply new filters to state
    this.setState((state) => ({
      ...state,
      filters: [],
    }));

    PageList.updateUrlWithObject(urlObject);

    // Send request
    await this.loadResources(urlObject);
  }

  // Toggle sidebar with filters
  toggleFilter() {
    this.setState((state) => ({
      ...state,
      filterOpened: !state.filterOpened,
    }));
  }

  getFilterValue(filterSlug) {
    const { filters } = this.state;
    const foundFilter = filters.find((f) => f.slug === filterSlug);

    return foundFilter ? foundFilter.values : false;
  }

  // Method that load orders bases on loaded options
  async loadResources(filters = {}, firstLoading = false) {
    const { currentMerchant } = this.props;
    const Class = this.resourceClass;

    if (!firstLoading) {
      // Set Loader
      this.setState((state) => ({
        ...state,
        allSelectorActive: false,
        loading: true,
      }));
    }

    // Get orders
    const getResourcesResponse = await this.getResourceMiddleware(currentMerchant.uuid, {
      ...this.defaultFilters,
      ...filters,
    });

    if (!getResourcesResponse.success) {
      if (getResourcesResponse.status === 301) return;
      toast.error(
        getResourcesResponse.message ||
          "Não foi possível carregar os recursos. Tente novamente mais tarde!"
      );
    }

    const list = getResourcesResponse.success
      ? getResourcesResponse.data.data.map((resource) => new Class(resource))
      : [];

    // Render
    // eslint-disable-next-line no-unused-expressions
    this._isMounted &&
      this.setState((state) => ({
        ...state,

        // Orders
        list,

        // Update pagination settings
        metadata: {
          ...state.metadata,
          totalPages: accessDotNotation(getResourcesResponse, "data.metadata.totalPages") || 0,
          totalItems: accessDotNotation(getResourcesResponse, "data.metadata.totalItems") || 0,
          count: accessDotNotation(getResourcesResponse, "data.metadata.count") || 0,
        },

        loading: false,
      }));

    return { data: getResourcesResponse, filters };
  }

  async getResourceMiddleware(currentMerchantUuid, filters = {}) {
    const { dispatch } = this.props;

    return dispatch(this.loadAction(currentMerchantUuid, filters));
  }

  /* ===============
   * Order managing options
   * =============== */
  getAllSelectedLines() {
    const { list } = this.state;

    const returnObj = {
      list: {
        objs: [],
        uuids: [],
      },

      length: 0,
    };

    // Map selected orders => packages
    returnObj.list.objs = list.filter((order) => {
      if (order.isSelected) {
        returnObj.list.uuids.push(order.uuid);

        return order;
      }
      return false;
    });

    returnObj.length = returnObj.list.objs.length;

    return returnObj;
  }

  toggleSelectAllResources() {
    const { list } = this.state;

    const uuidsUnselected = list.filter((resource) => !resource.isSelected && resource.uuid);
    const uuidsSelected = list.filter((resource) => resource.isSelected && resource.uuid);

    const uuids = uuidsSelected > uuidsUnselected ? uuidsSelected : uuidsUnselected;

    const allSelectorActive = !(uuidsSelected > uuidsUnselected);

    this.setState((state) => ({
      ...state,
      allSelectorActive,
    }));

    return this.modifyLine(
      uuids.map((_o) => _o.uuid),
      (resource) => {
        resource.isSelected = !resource.isSelected;

        return resource;
      }
    );
  }

  toggleSelectExtraResources(allItemsSelected) {
    this.setState((state) => ({
      ...state,
      allItemsSelected,
    }));
  }

  async modifyLine(uuidArray, changeCb) {
    const { list } = this.state;
    const newList = await Promise.all(
      list.map(async (_resource) => {
        if (uuidArray.includes(_resource.uuid)) {
          return changeCb(_resource);
        }

        return _resource;
      })
    );

    return this.setState((state) => ({
      ...state,
      list: newList,
    }));
  }

  toggleSelectResource(resourceInput) {
    const selected = this.getAllSelectedLines();
    const unselectAll = selected.list.uuids.filter((r) => r !== resourceInput.uuid).length === 0;

    // console.log(
    //   `Resource ${resourceInput.uuid} ${resourceInput.isSelected ? "unselected" : "selected"}`
    // );

    if (unselectAll)
      this.setState((state) => ({
        ...state,
        allSelectorActive: false,
      }));

    return this.modifyLine([resourceInput.uuid], (resource) => {
      resource.isSelected = !resource.isSelected;
      return resource;
    });
  }

  render() {
    return "Insira um método de renderização.";
  }
}
