import React from "react";
import moment from "moment";

function PackageMove({ move, detailed }) {
  const fromTo = move.destination && move.source;
  const observation = detailed ? (
    <span style={{ display: "block" }}>{move.observation}</span>
  ) : null;

  const date = (
    <span className="move-date block">{moment(move.date).format("DD/MM/YYYY HH:mm:ss")}</span>
  );
  // eslint-disable-next-line no-nested-ternary
  let secondLine;

  if (move.provider === "MENV") {
    secondLine = (
      <span className="move-location">
        {move.location}
        {observation}
      </span>
    );
  } else if (fromTo) {
    secondLine = (
      <span className="move-from-to gray block">
        de {move.source} para {move.destination}
      </span>
    );
  } else if (move.location) {
    secondLine = (
      <span className="move-location">
        {move.location}
        {observation}
      </span>
    );
  } else secondLine = null;

  /*  const secondLine = fromTo ? (
    <span className="move-from-to gray block">
      de {move.source} para {move.destination}
    </span>
  ) : move.location ? (
    <span className="move-location">
      {move.location}
      {observation}
    </span>
  ) : null; */

  return (
    <span className="move-item">
      <span className="move-label bold">{move.label}</span>
      <span className="move-second-line gray block">
        {secondLine}
        {date}
      </span>
    </span>
  );
}

export default PackageMove;
