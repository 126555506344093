import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FaChevronDown, FaHistory, FaBox, FaArchive, FaBarcode } from "react-icons/fa";
import ClickCopy from "components/click-copy";
import { toast } from "react-toastify";

// Components
import Tooltip from "components/tooltip";
import DropdownButton from "components/dropdown-button";

// Assets
import { ReactComponent as Shopifyicon } from "images/app-icons/shopify-sm.svg";

// Actions
import { getPackageDetails, updatePackage } from "store/modules/orders/actions";
import LoadingCircle from "components/loading-circle";
import DaysCounter from "./days-counter";

function DesktopList(props) {
  const {
    orders,
    merchantId,
    activeFilters,
    archiveOrder,
    selectOrder,
    selectAllOrders,
    allSelectorActive,
    modifyLine,
    currentMerchant,
  } = props;

  const activeFiltersKeys = Object.keys(activeFilters).filter((k) => k.indexOf("page") === -1);
  const showConcludeButton = activeFiltersKeys.length > 0;

  const getLastMoveLabel = (storeUuid, orderUuid, packageUuid) =>
    getPackageDetails(storeUuid, orderUuid, packageUuid);

  const toggleTaxPaid = (orderUuid, packageUuid, isTaxPaid) =>
    updatePackage(currentMerchant.uuid, orderUuid, packageUuid, {
      isTaxPaid: !isTaxPaid,
    }).then((result) => {
      if (result.success) {
        return modifyLine(orderUuid, async (order) => {
          const pkg = order.packages.find((p) => p.uuid === packageUuid);

          pkg.isTaxPaid = !isTaxPaid;
          return order;
        });
      }

      return toast.error("Não foi possível marcar o pacote como pago.");
    });

  return (
    <table className="iumy-table desktop order-list-wrapper">
      <thead>
        <tr>
          <th className="td-15">
            <button
              type="button"
              aria-label="Selecionar/desselecionar todos os pedidos."
              onClick={() => selectAllOrders()}
              className={classnames("im-checkbox", { checked: allSelectorActive })}
            />
          </th>

          <th style={{ minWidth: 160 }} className="td-20">
            Número do pedido <FaChevronDown />
          </th>

          <th className="td-40">
            Data <FaChevronDown />
          </th>

          <th style={{ minWidth: 100 }}>
            Total <FaChevronDown />
          </th>

          <th>
            Status <FaChevronDown />
          </th>

          <th>
            Rastreio <FaChevronDown />
          </th>

          <th>
            Entrega <FaChevronDown />
          </th>

          {showConcludeButton && <th aria-label="Concluir pedidos" />}
          {/* <th aria-label="Enviar Whatsapp" /> */}
          <th aria-label="Ver pedido na Shopify" />
        </tr>
      </thead>

      <tbody>
        {orders ? (
          orders.map((order) => (
            <tr key={order.uuid} className={classnames({ updating: order.isUpdating })}>
              <td>
                <button
                  type="button"
                  aria-label="Selecionar pedido"
                  onClick={() => selectOrder(order)}
                  className={classnames("im-checkbox", { checked: order.isSelected })}
                />
              </td>
              <td>
                <strong className="f14">
                  <Link to={`/${merchantId}/orders/${order.uuid}`}>{order.name}</Link>
                </strong>
                <span className="f13 block gray">
                  {order.customer?.firstName ? (
                    <span
                      className="customer-name"
                      title={`${order.customer.firstName} ${order.customer.lastName}`}
                    >{`${order.customer.firstName} ${order.customer.lastName}`}</span>
                  ) : (
                    <span className="customer-name pending-sync">Sem dados</span>
                  )}
                </span>
              </td>
              <td className="td-no-right f12">
                {moment(order.createdAt).format("DD/MM/YYYY")}{" "}
                <span className="block gray f11">{moment(order.createdAt).fromNow()}</span>
              </td>
              <td className="f13">{order.totalPriceWithDiscount.formated}</td>

              {/* Status */}
              <td
                className={classnames("f13", {
                  "td-no-paddings": order.packages.length > 1,
                })}
              >
                {order.packages.length && (order.isPackagesSynced || order.isTransactionsSynced) ? (
                  order.packages.map((pkg) => (
                    <div
                      key={pkg.uuid}
                      className={`${pkg.isHidden ? "hidden" : ""} ${
                        order.packages.length > 1 ? "trow" : ""
                      }`}
                    >
                      {pkg.status.code === "TAXED" && (
                        <div>
                          <DropdownButton
                            classes={`tax-button iumy-badge ${
                              pkg.isTaxPaid ? "TAXED_PAID" : "TAXED"
                            }`}
                            label={pkg.isTaxPaid ? "Taxado pago" : "Taxado"}
                            container="div"
                            closeOnClick
                            useChevs={false}
                            onClickCb={() => {}}
                          >
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => toggleTaxPaid(order.uuid, pkg.uuid, pkg.isTaxPaid)}
                                >
                                  {pkg.isTaxPaid ? "Desmarcar como pago" : "Marcar como pago"}
                                </button>
                              </li>

                              {pkg.taxBankslipUrl && (
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      window.open(pkg.taxBankslipUrl, "_blank");
                                    }}
                                  >
                                    Baixar Boleto
                                  </button>
                                </li>
                              )}
                            </ul>
                          </DropdownButton>

                          {pkg.taxValue.raw && (
                            <span className="tax-badge">{pkg.taxValue.formated}</span>
                          )}
                        </div>
                      )}

                      {pkg.status.code !== "TAXED" && (
                        <Tooltip
                          className={classnames("iumy-badge inline-block f10 mb5", pkg.status.code)}
                          containerElm="span"
                          id={`package-status-${pkg.uuid}`}
                          content={pkg.status.label}
                          toolTipText={async (cb) => {
                            const response = await getLastMoveLabel(
                              props.merchantId,
                              order.uuid,
                              pkg.uuid
                            );
                            const message = response.success
                              ? response.data.moves.length
                                ? response.data.moves[0].label
                                : pkg.status.label
                              : pkg.status.label;

                            cb(message);

                            return message;
                          }}
                        />
                      )}
                      <span className="block f12 gray">
                        {moment(pkg.lastMove ? pkg.lastMove.date : pkg.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  ))
                ) : (
                  <div>
                    <span
                      className={classnames(
                        "iumy-badge inline-block f10 mb5",
                        order.financialStatus.code
                      )}
                    >
                      {order.financialStatus.label}
                    </span>
                    <span className="block f12 gray">
                      {moment(order.updatedAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </td>

              {/* Tracking Code */}
              <td
                className={classnames("f13", {
                  "td-no-paddings": order.packages.length > 1 ? "trow" : "",
                })}
              >
                {order.packages.length > 0 ? (
                  order.packages.map((pkg) => (
                    <div
                      key={pkg.uuid}
                      className={`${pkg.isHidden ? "hidden" : ""} ${
                        order.packages.length > 1 ? "trow" : ""
                      }`}
                    >
                      {order.isPackagesSynced || order.isTransactionsSynced ? (
                        <div className="package-infos">
                          <a
                            rel="noopener noreferrer"
                            href={pkg.trackingUrl}
                            target="_blank"
                            className="maingreen vertical-middle"
                          >
                            <FaBox className="icon-tracking" /> {pkg.trackingNumber}
                          </a>
                          <ClickCopy copyText={pkg.trackingNumber} />
                          {order.isPackagesSynced ? (
                            <span className="f12 block gray">
                              {pkg.originTrackingNumber && (
                                <>
                                  <FaHistory className="old-tracking-ico" />{" "}
                                  {pkg.originTrackingNumber}
                                </>
                              )}
                            </span>
                          ) : (
                            <LoadingCircle pos="text-left" value=" Sincronizando" />
                          )}
                        </div>
                      ) : (
                        <LoadingCircle pos="text-left" value=" Sincronizando" />
                      )}
                    </div>
                  ))
                ) : (
                  <hr />
                )}
              </td>

              <td
                className={classnames("f15", {
                  "td-no-paddings": order.packages.length > 1 ? "trow" : "",
                })}
              >
                {order.packages.length > 0 ? (
                  order.packages.map((pkg) => (
                    <div
                      key={pkg.uuid}
                      className={`${pkg.isHidden ? "hidden" : ""} ${
                        order.packages.length > 1 ? "trow" : ""
                      }`}
                    >
                      <DaysCounter order={order} moves={pkg.moves} />
                    </div>
                  ))
                ) : (
                  <hr />
                )}
              </td>

              {showConcludeButton && (
                <td className="td-20 td-no-right">
                  <ul className="actions ta-right">
                    <li>
                      {order.isArchived ? (
                        <span title="Pacote já concluído" className="action check checked">
                          <FaArchive />
                        </span>
                      ) : (
                        <button
                          type="button"
                          alt="Arquivar pedido"
                          title="Arquivar pedido"
                          onClick={() => archiveOrder(order, order.uuid)}
                          className="f18 btn btn-text action check"
                        >
                          <FaArchive />
                        </button>
                      )}
                    </li>
                  </ul>
                </td>
              )}

              <td className="td-20">
                <ul className="actions ta-right">
                  <li>
                    {order.appType === "SHOPIFY" && (
                      <a href={order.shopifyOrderUrl} target="blank" className="action">
                        <Shopifyicon />
                      </a>
                    )}
                  </li>
                </ul>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10" className="text-center">
              Nenhum pedido encontrado
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(
  (state) => ({
    currentMerchant: state.merchants.currentMerchant,
  }),
  mapDispatchToProps
)(DesktopList);
