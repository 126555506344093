import Status from "models/tracking/status";

export default class Move {
  constructor(options = {}) {
    this.uuid = options.uuid;
    this.date = options.date;
    this.status = options.status;
    this.computedStatus = new Status(options.status);
    this.label = options.label;
    this.location = options.location;
    this.source = options.source;
    this.destination = options.destination;
    this.provider = options.provider;
    this.observation = options.observation;
  }
}
