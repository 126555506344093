import React, { useState, useEffect } from "react";

import { getExceptionsStatsRequest } from "store/modules/merchants/actions";
import LoaderBG from "components/loader-bg";

export default function ExceptionStatisticsContainer({ filter, storeUuid }) {
  const [state, setState] = useState({
    loading: true,
    error: null,
    problems: [],
  });

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getExceptionsStatsRequest(storeUuid, filter);

      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          problems: data,
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  const trophys = ["🥇", "🥈", "🥉"];

  return (
    <div className="top-exceptions">
      <div className="iumy-card">
        <div className="inner">
          <span className="card-title">Top falhas de entrega</span>
        </div>
        <hr />
        {state.loading ? (
          <div className="inner">
            <LoaderBG blocks={1} />
          </div>
        ) : (
          <ul className="inner exceptions-list">
            {state.problems.length ? (
              state.problems.map((p, i) => (
                <li className="exception-item" key={i}>
                  <span className="medal-box">{trophys[i]}</span>
                  <span className="exception-description">{p.label}</span>

                  <span className="exception-quantity">
                    {p.quantity} <small>casos</small>
                  </span>
                </li>
              ))
            ) : (
              <span style={{ fontSize: 14, textAlign: "center", display: "block" }}>
                <span style={{ marginRight: 5, display: "inline-block" }} role="img">
                  🥺
                </span>{" "}
                Ops parece que não encontramos nenhum dado no período selecionado.
              </span>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
