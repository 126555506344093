import React, { useState } from "react";
import VMasker from "vanilla-masker";

const PhoneInput = (props) => {
  const { field, form, placeholder, className } = props;
  const { name, value } = field;

  const { setFieldValue } = form;

  const [fValue, setValue] = useState(aplicaMask(value));

  function limpa(v) {
    return v.replace(/[^\d]/g, "");
  }

  // 11 1-1111-1111
  // 11 1111-1111
  function maskBuilder(v) {
    const valor = v;

    if (!valor || valor.length === 0) return "";

    return valor.length > 10 ? "(99) 99999-9999" : "(99) 9999-9999";
  }

  function aplicaMask(valor) {
    const valorLimpo = limpa(valor);

    const maskApplyied = VMasker.toPattern(valorLimpo, maskBuilder(valorLimpo));

    return maskApplyied;
  }

  const handleKeyPress = (e) => {
    const valor = limpa(fValue) + e.key;

    if (valor.length > 10) {
      const masked = aplicaMask(valor);

      setValue(masked);
      setFieldValue(name, masked);
    }

    // Seta o valor do field

    // Seta o valor display
  };

  // Pega apaga
  const handleChange = (e) => {
    const valor = e.target.value;
    const masked = aplicaMask(valor);

    // Seta o valor display
    setValue(masked);

    // Seta o valor do field
    setFieldValue(name, masked);
  };

  return (
    <input
      type="tel"
      className={className}
      name={name}
      onKeyPress={handleKeyPress}
      value={fValue}
      onChange={handleChange}
      onBlur={field.onBlur}
      placeholder={placeholder}
    />
  );
};

export default PhoneInput;
