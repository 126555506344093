import React from "react";
import moment from "moment";
import { FaTimes } from "react-icons/fa";

function FilterOption({ filter, removeFilter }) {
  let prefix;
  let filterLabel;

  switch (filter.type) {
    case "daterange":
      filterLabel = `${moment(filter.values[0]).format("DD/MM/YYYY")} - ${moment(
        filter.values[1]
      ).format("DD/MM/YYYY")}`;
      prefix = `${filter.label}: `;
      break;

    case "option": {
      filterLabel = filter.label;
      break;
    }

    default:
      filterLabel = filter.label;
      break;
  }

  return (
    <button
      type="button"
      className="option"
      aria-label="Remover filtro"
      onClick={() => {
        removeFilter(filter.slug, filter.values);
      }}
    >
      <span>
        <span className="prefix">{prefix}</span>
        {filterLabel}
      </span>

      <span className="remove-tag">
        <FaTimes />
      </span>
    </button>
  );
}

export default function FiltersResume({ activeFilters, handleFilter, clearFilters, className }) {
  const traitedFilters = [];

  const removeFilter = (filter) => {
    if (filter.type === "option") {
      const allOptionsSameSlug = traitedFilters.filter(
        (f) => f.slug === filter.slug && f.values !== filter.values
      );

      return handleFilter(
        filter.slug,
        allOptionsSameSlug.map((o) => o.values)
      );
    }

    return handleFilter(filter.slug, "");
  };

  activeFilters.forEach((filter) => {
    if (!filter.values || filter.values.length === 0) return;

    if (filter.type === "checkbox") {
      filter.values.forEach((opt) => {
        traitedFilters.push({
          slug: filter.slug,
          label: opt.label,
          values: opt.slug,
          type: "option",
        });
      });
    } else {
      traitedFilters.push(filter);
    }
  });

  return (
    traitedFilters.length > 0 && (
      <div className={`filters-resume ${className}`}>
        {traitedFilters.map((filter) => (
          <FilterOption
            key={`${filter.slug}-${filter.values}`}
            filter={filter}
            removeFilter={() => removeFilter(filter)}
          />
        ))}

        <button
          type="button"
          aria-label="Limpar filtros"
          className="clear-filters"
          onClick={() => clearFilters()}
        >
          Limpar filtros
        </button>
      </div>
    )
  );
}
