import React, { useState } from "react";
import { FaWhatsapp, FaLink, FaBox, FaHistory, FaChevronRight } from "react-icons/fa";
import classnames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";

// Components
import Tooltip from "components/tooltip";
import ClickCopy from "components/click-copy";
import Modal from "components/modal";
import Status from "models/tracking/status";
import DropdownButton from "components/dropdown-button/dropdown";
import CoolDropdown from "components/dropdown-button";
import PackageMove from "./package-move";
import DaysCounter from "../../components/days-counter";

const DesktopTrackings = (props) => {
  // const { currentMerchant } = useSelector((state) => state.merchants);
  const [showHistory, setShowHistory] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const { order, packages, handleTogglePackageVisibility, handleToggleTaxPaid } = props;

  const showMoveHistory = (moves) => {
    setModalContent(
      <ul className="track-moves">
        {moves.map((move, i) => {
          const status = new Status(move.status);
          return (
            <li className={classnames("f12", status.code)} key={i}>
              <span className={classnames("iumy-badge mb5", status.code)}>{status.label}</span>
              <br />
              <PackageMove move={move} detailed />
            </li>
          );
        })}
      </ul>
    );
    setShowHistory(true);
  };

  return (
    <div className="tracking-lines-wrapper desktop">
      <table className="iumy-table tracking-header">
        <thead>
          <tr>
            <th>Cod. Rastreio</th>
            <th>Status</th>
            <th>Tempo</th>
            <th>Última Movimentação</th>
            <th />
          </tr>
        </thead>
      </table>

      {packages.map((packageItem, index) => (
        <div
          key={packageItem.uuid}
          className={classnames("tracking-item box-widget-mini", { hidden: packageItem.isHidden })}
        >
          <main>
            <table className="iumy-table">
              <tbody>
                <tr>
                  <td>
                    <div className="line-actions">
                      <a
                        className="tracking-code"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={packageItem.trackingUrl}
                      >
                        <FaBox className="icon-tracking" /> {packageItem.trackingNumber}{" "}
                      </a>

                      <div className="actions">
                        <ClickCopy
                          size={12}
                          tooltipLabel="Copiar código"
                          copyText={packageItem.trackingNumber}
                        />
                        <ClickCopy
                          size={12}
                          tooltipLabel="Copiar link"
                          icon={FaLink}
                          copyText={packageItem.trackingUrl}
                        />
                      </div>
                    </div>

                    {packageItem.originTrackingNumber && (
                      <div className="origin-tracking-no block gray">
                        <FaHistory className="old-tracking-ico" />{" "}
                        {packageItem.originTrackingNumber}
                      </div>
                    )}
                  </td>
                  <td>
                    {packageItem.status.code === "TAXED" && (
                      <div>
                        <CoolDropdown
                          classes={`tax-button iumy-badge ${
                            packageItem.isTaxPaid ? "TAXED_PAID" : "TAXED"
                          }`}
                          label={packageItem.isTaxPaid ? "Taxado pago" : "Taxado"}
                          container="div"
                          closeOnClick
                          useChevs={false}
                          onClickCb={() => {}}
                        >
                          <ul>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleToggleTaxPaid(order, packageItem)}
                              >
                                {packageItem.isTaxPaid ? "Desmarcar como pago" : "Marcar como pago"}
                              </button>
                            </li>
                          </ul>
                        </CoolDropdown>

                        {packageItem.taxValue.raw && (
                          <span className="tax-badge">{packageItem.taxValue.formated}</span>
                        )}
                      </div>
                    )}

                    {packageItem.status.code !== "TAXED" && (
                      <span
                        className={classnames(
                          "iumy-badge f10 mb5 inline-block",
                          packageItem.status.code
                        )}
                      >
                        {packageItem.status.label}
                      </span>
                    )}

                    <span className="f12 gray block">
                      {moment(
                        packageItem.lastMove ? packageItem.lastMove.date : packageItem.createdAt
                      ).format("DD/MM/YYYY")}
                    </span>
                  </td>
                  <td>
                    <DaysCounter order={props.order} moves={packageItem.moves} />
                  </td>
                  <td className="f12">
                    {packageItem.lastMove && <PackageMove move={packageItem.lastMove} />}
                  </td>
                  <td>
                    <ul className="package-actions">
                      <li>
                        <button
                          type="button"
                          title="Ver histórico de movimentações"
                          className="toggle-tracking"
                          onClick={() => {
                            showMoveHistory(packageItem.moves);
                          }}
                        >
                          <FaChevronRight />
                        </button>
                      </li>

                      <li>
                        <DropdownButton
                          label="..."
                          classes="btn default size--medium"
                          options={[
                            {
                              value: "hide",
                              label: packageItem.isHidden ? (
                                "Mostrar pacote"
                              ) : (
                                <>
                                  Ocultar pacote{" "}
                                  <Tooltip
                                    childClassName="info"
                                    placement="top"
                                    id="hide-package-action"
                                    content="i"
                                    toolTipText="Oculta um pacote do plugin de rastreio ao cliente."
                                  />
                                </>
                              ),
                              callback: () =>
                                handleTogglePackageVisibility(props.order, packageItem),
                            },

                            ...(packageItem.taxBankslipUrl
                              ? [
                                  {
                                    value: "taxSlip",
                                    label: `Baixar Boleto da taxa`,
                                    callback: () => {
                                      window.open(packageItem.taxBankslipUrl, "_blank");
                                    },
                                  },
                                ]
                              : []),

                            ...(packageItem.taxDitUrl
                              ? [
                                  {
                                    value: "taxSlip",
                                    label: `Baixar DIT da taxa`,
                                    callback: () => {
                                      window.open(packageItem.taxDitUrl, "_blank");
                                    },
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr className="tracking-details-row">
                  <td colSpan="1">
                    <ul className="tracking-details-list">
                      <li>
                        <span className="bold uppercase f11 gray">Transportadora</span>
                        <span className="bold f13 black block">{packageItem.trackingCompany}</span>
                      </li>

                      <li>
                        <span className="bold uppercase f11 gray">Código</span>
                        <span className="bold f13 black block">
                          {packageItem.trackingNumber}{" "}
                          <ClickCopy copyText={packageItem.trackingNumber} />
                        </span>
                      </li>

                      {packageItem.trackingUrl && (
                        <li>
                          <span className="bold uppercase f11 gray">Link de Rastreio</span>
                          <span className="bold f13 black block">
                            <a href={packageItem.trackingUrl}>{packageItem.trackingUrl}</a>{" "}
                            <ClickCopy copyText={packageItem.trackingUrl} />
                          </span>
                        </li>
                      )}
                    </ul>
                  </td>

                  <td>
                    <button type="button" className="notify-whatspp">
                      <FaWhatsapp /> Enviar
                    </button>
                  </td>

                  <td className="track-moves-col" colSpan="2">
                    <ul className="track-moves">
                      {packageItem.moves.slice(1).map((move, i) => (
                        <li className="f12" key={i}>
                          <PackageMove move={move} />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </div>
      ))}

      <Modal
        className="tracking-resume"
        title="Histórico de movimentações"
        showModal={showHistory}
        toggleModal={() => setShowHistory(!showHistory)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default DesktopTrackings;
