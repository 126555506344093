import axios from "axios";
import { swalMiddleware as Swal } from "helpers";

import { doLogout } from "store/modules/auth/reducer";

export let apiInstance;

const exceptions = ["/api/notification"];

function apiCreator(token, dispatch) {
  // const cancelToken = axios.CancelToken.source();
  const reqInProgress = [];

  const api = axios.create({
    // cancelToken,
    baseURL: process.env.REACT_APP_API_URL,
  });

  // If exists a token add to all requests
  if (token) {
    api.interceptors.request.use(
      async (config) => {
        if (!exceptions.includes(config.url)) {
          const cancelToken = axios.CancelToken.source();
          config.cancelToken = cancelToken.token;
          config.source = cancelToken;

          // Fing request to the same method && url
          let i;
          const foundReq = reqInProgress.find((req, index) => {
            if (config.url === req.url && config.method === req.method) {
              i = index;
              return true;
            }

            return false;
          });

          // If are other pending, cancel
          if (foundReq) {
            foundReq.source.cancel();
            reqInProgress.splice(i, 1);
          }

          reqInProgress.push(config);
        }

        config.headers.Authorization = token;

        return config;
      },
      (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
      (response) => {
        if (!exceptions.includes(response.config.url)) {
          let i;
          const foundReq = reqInProgress.find((req, index) => {
            if (response.config.url === req.url && response.config.method === req.method) {
              i = index;
              return true;
            }

            return false;
          });

          if (foundReq) reqInProgress.splice(i, 1);
        }

        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.resolve({ status: 301, error: { message: "Requisição cancelada" } });
        }

        if (error.response.status === 401) {
          Swal.fire({
            title: "Você não está autenticado!",
            text: "Por favor faça login novamente!",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false,
          }).then(() => dispatch(doLogout()));
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  }

  // Store the current api instance
  apiInstance = api;

  return apiInstance;
}

export default apiCreator;
