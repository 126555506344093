import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as NotFoundVector } from "images/vectors/not-found-men.svg";
import PageTitle from "components/page-title";

function NotFound({ title, description }) {
  const _title = title || "Página não encontrada";
  const _description =
    description || "Desculpe mas não foi possível encontrar a página que você estava procurando.";
  return (
    <div className="page not-found container">
      <PageTitle pageTitle={_title} />
      <NotFoundVector className="vector" />
      <h1 className="title">{_title}</h1>
      <p>{_description}</p>
      <Link className="btn green size--large" to="/">
        Ir ao início
      </Link>
    </div>
  );
}

export default NotFound;
