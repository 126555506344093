import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PageTitle from "components/page-title";

// App Icons
import { ReactComponent as Shopifyicon } from "images/app-icons/shopifyapp.svg";
import { ReactComponent as Whastappicon } from "images/app-icons/whatsapp.svg";
import { ReactComponent as IumyIcon } from "images/app-icons/iumy.svg";
import { ReactComponent as NuvemShopIcon } from "images/app-icons/nuvemshopapp.svg";
import { ReactComponent as MelhorEnvio } from "images/app-icons/melhor-envio.svg";
import { ReactComponent as CorreiosECT } from "images/app-icons/correios-ect.svg";

import { checkExtension } from "helpers";

function AppIcon({ slug }) {
  const icons = {
    shopify: Shopifyicon,
    whatsapp: Whastappicon,
    "rastreio-iumy": IumyIcon,
    "melhor-envio": MelhorEnvio,
    "correios-importador": CorreiosECT,
    nuvemshop: NuvemShopIcon,
  };

  const Icon = icons[slug] ?? IumyIcon;

  return <Icon />;
}

function Apps({ merchants }) {
  const [extensionInstalled, setExtensionInstalledStatus] = useState(false);
  const logisticsApps = merchants.currentMerchant.apps.filter(
    (app) => app.category === "logistics" && app.isListed
  );

  const ecommerceAppWasInstalled = merchants.currentMerchant.apps.find(
    (app) => app.category === "ecommerce" && app.isActive
  );

  useEffect(() => {
    async function init() {
      const isInstalled = await checkExtension();

      setExtensionInstalledStatus(isInstalled);
    }

    init();
  }, []);

  return (
    <div className="page apps container">
      <PageTitle pageTitle="Apps" />

      <header className="page-header">
        <div className="inner-container">
          <h1 className="title">Integrações</h1>
          <span role="img" aria-label="Foguete">
            Escolha as integrações que irão turbinar sua conta 🚀
          </span>
        </div>
      </header>

      <hr />

      <div className="inner-container">
        <h6 className="app-group-category-title mt35">Plataformas de Ecommerce</h6>
        <ul className="apps-list">
          {merchants.currentMerchant.apps
            .filter((app) => app.category === "ecommerce" && app.isListed)
            .map((app) => {
              const disabled =
                ecommerceAppWasInstalled && app.slug !== ecommerceAppWasInstalled.slug;

              return (
                <li key={app.type} className={`apps-list-item ${disabled ? "disabled" : ""}`}>
                  <div className="app-icon">
                    <AppIcon slug={app.slug} />
                  </div>
                  <div className="app-infos">
                    <div className="app-name">{app.name}</div>
                    <div className="app-description">{app.description}</div>
                  </div>
                  <div className="app-button">
                    {app.isActive && <span className="installed-label">Instalado</span>}

                    <Link
                      {...(disabled ? { disabled } : {})}
                      to={`/${merchants.currentMerchant.uuid}/apps/${app.type.toLowerCase()}`}
                      className={`installApp btn default size--medium ${
                        disabled ? "disabled" : ""
                      }`}
                    >
                      {!app.isActive ? "Instalar" : "Abrir"}
                    </Link>
                  </div>
                </li>
              );
            })}
        </ul>

        {logisticsApps.length > 0 && (
          <>
            <h6 className="app-group-category-title mt35">Aplicativos logísticos</h6>
            <ul className="apps-list">
              {logisticsApps.map((app) => (
                <li key={app.type} className="apps-list-item">
                  <div className="app-icon">
                    <AppIcon slug={app.slug} />
                  </div>
                  <div className="app-infos">
                    <div className="app-name">{app.name}</div>
                    <div className="app-description">{app.description}</div>
                  </div>
                  <div className="app-button">
                    {app.isActive && <span className="installed-label">Instalado</span>}

                    <Link
                      to={`/${merchants.currentMerchant.uuid}/apps/${app.type.toLowerCase()}`}
                      className="installApp btn default size--medium"
                    >
                      {!app.isActive ? "Instalar" : "Abrir"}
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        <h6 className="app-group-category-title mt35">Notificações</h6>
        <ul className="apps-list">
          {merchants.currentMerchant.apps
            .filter((app) => app.category === "atendimento" && app.isListed)
            .map((app) => (
              <li key={app.type} className="apps-list-item">
                <div className="app-icon">
                  <AppIcon slug={app.slug} />
                </div>
                <div className="app-infos">
                  <div className="app-name">{app.name}</div>
                  <div className="app-description">{app.description}</div>
                </div>
                <div className="app-button">
                  {app.type === "WHATSAPP" && extensionInstalled && (
                    <span className="installed-label">Instalado</span>
                  )}
                  {app.type === "WHATSAPP" ? (
                    <a
                      href="https://chrome.google.com/webstore/detail/iumy-extens%C3%A3o-whatsapp/ngnhddiplebeagjihbjopdndceopoadi"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="installApp btn default size--medium"
                    >
                      {!extensionInstalled ? "Instalar" : "Ver"}
                    </a>
                  ) : (
                    <Link
                      to={`/${merchants.currentMerchant.uuid}/apps/${app.type.toLowerCase()}`}
                      className="installApp btn default size--medium"
                    >
                      Instalar
                    </Link>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  merchants: state.merchants,
});

export default connect(mapStateToProps)(Apps);
