import React, { useState, useEffect } from "react";

import { getProductsStatsRequest } from "store/modules/merchants/actions";
import LoaderBG from "components/loader-bg";
import { useSelector } from "react-redux";

export default function ProductsStatisticsContainer({ filter, storeUuid }) {
  const { streamerMode } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    loading: true,
    error: null,
    products: [],
  });

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getProductsStatsRequest(storeUuid, filter);

      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          products: data.splice(0, 5),
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  return (
    <div className="iumy-card products">
      <div className="inner">
        <span className="card-title">Produtos mais vendidos</span>
      </div>
      <hr />

      {state.loading ? (
        <div className="inner">
          <LoaderBG blocks={1} />
        </div>
      ) : (
        <ul className="top-product-list">
          {state.products.map((p, i) => (
            <li className="product-list-item" key={i}>
              <img
                src={p.imageUrl}
                alt={p.title}
                className={`product-thumb ${streamerMode ? "blurry" : ""}`}
              />

              <div className="product-data">
                <span className={`product-name ${streamerMode ? "blurry" : ""}`}>{p.title}</span>
                <span className="product-sales">{p.quantity} vendidos</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
