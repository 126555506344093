import { createReducer } from "reduxsauce";

// Types
// import Package from "models/package";
import Order from "models/order-sumary";
import { Types } from "./actions";

const initialState = {
  list: [],
};

const doGetOrdersSuccess = (state = initialState, action) => {
  const ordersGetted = action.payload || [];
  const ordersTraited = ordersGetted.map((order) => new Order(order));

  return {
    ...state,
    list: [...ordersTraited],
  };
};

// const doUpdateOrderSuccess = (state = initialState, action) => {
//   const pkg = action.payload.package;
//   const traitedPackage = new Package(pkg);
//   const { orderUuid } = action.payload;

//   return {
//     ...state,
//     list: state.list.map((_order) => {
//       if (_order.uuid === orderUuid) {
//         _order.packages = _order.packages.map((_pkg) => {
//           if (_pkg.uuid === pkg.uuid) return traitedPackage;

//           return _pkg;
//         });
//       }

//       return _order;
//     }),
//   };
// };

export default createReducer(initialState, {
  [Types.DO_GET_ORDERS_SUCCESS]: doGetOrdersSuccess,
  // [Types.DO_UPDATE_ORDER_SUCCESS]: doUpdateOrderSuccess,
});
