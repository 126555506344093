import React, { useState, useEffect } from "react";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

// Components
import PageTitle from "components/page-title";
import LoaderBlock from "components/loader-bg";

// Assets
import Logo from "images/iumy.svg";

// Actions
import {
  doGetMerchantListRequest,
  doSelectMerchant,
  getAppsByUuidRequest,
} from "store/modules/merchants/actions";
import { doLogout } from "store/modules/auth/reducer";
import { doLoadUserRequest } from "store/modules/auth/thunks";

// Steps
import Intro from "./steps/intro";
import StoreInfos from "./steps/store-infos";
import Integrations from "./steps/integrations";

export default function Onboarding() {
  const dispatch = useDispatch();

  // Get Store UUID from params
  const storeUuid = new URLSearchParams(window.location.search).get("storeUuid");

  const [state, setState] = useState({
    currentStepKey: "INTRO",
    loading: true,

    steps: [
      {
        key: "INTRO",
        component: Intro,
        canBack: false,
        primaryButtonText: "Começar",
        complete: true,
        data: null,
      },
      {
        key: "STORE_INFO",
        component: StoreInfos,
        canBack: false,
        complete: false,
        data: null,
      },
      {
        key: "INTEGRATIONS",
        component: Integrations,
        canBack: false,
        data: null,
      },
    ],

    // Status
    onboardingUniqueMerchant: false,
  });

  const anim = {
    from: {
      opacity: 0,
      x: 100,
    },
    to: {
      opacity: 1,
      y: 0,
      x: 0,
    },
  };

  const [animation, api] = useSpring(() => anim);

  useEffect(() => {
    async function loadMerchants() {
      await dispatch(doLoadUserRequest());

      let currentStepKey = "INTRO";

      // List merchants
      const _merchants = await dispatch(doGetMerchantListRequest());

      let onboardingUniqueMerchant = true;

      if (_merchants.data) {
        // Check if you're onboarding the unique merchants available on account
        onboardingUniqueMerchant =
          _merchants.data.find((m) => m.uuid === storeUuid) && _merchants.data.length === 1;

        // Onboarding
        const currentOnboardingExists = _merchants.data.find((m) => m.uuid === storeUuid);

        // If yes, check whats step is needed
        if (currentOnboardingExists) {
          const merchantAppsRequest = await getAppsByUuidRequest(storeUuid);
          const merchantApps = merchantAppsRequest.data.data;
          const marketplaceAppActive = merchantApps.find(
            (m) => m.category === "ecommerce" && m.isActive
          );

          if (marketplaceAppActive) {
            dispatch(doSelectMerchant(storeUuid));
            currentStepKey = "FINISH";
          } else {
            currentStepKey = "INTEGRATIONS";
          }
        }
      }

      // Set state
      setState((oldState) => ({
        ...oldState,
        loading: false,
        onboardingUniqueMerchant: !!onboardingUniqueMerchant,
        currentStepKey,
      }));
    }

    loadMerchants();
  }, []);

  // Proccess current state
  const currentStep = state.steps.find((step) => step.key === state.currentStepKey);

  // Handler to next state
  const nextStep = (data) => {
    let nextStepKey = null;
    let nextStepData = null;

    api.start(anim);

    switch (currentStep.key) {
      case "INTRO": {
        nextStepKey = "STORE_INFO";
        break;
      }

      case "STORE_INFO":
        nextStepKey = "INTEGRATIONS";
        break;

      default:
        return false;
    }

    return setState(
      produce((draft) => {
        const found = draft.steps.find((step) => step.key === draft.currentStepKey);
        found.complete = true;
        found.data = data || null;

        if (nextStepData) {
          const step = draft.steps.find((stp) => stp.key === nextStepKey);
          step.data = nextStepData;
        }

        draft.currentStepKey = nextStepKey;
      })
    );
  };

  const previousStep = () => {
    let prevStepKey = null;

    switch (currentStep.key) {
      case "INTEGRATIONS":
        prevStepKey = "STORE_INFO";
        break;

      case "SHOPIFY_CONFIG":
        prevStepKey = "INTEGRATIONS";
        break;

      default:
        return false;
    }

    return setState(
      produce((draft) => {
        const found = draft.steps.find((step) => step.key === draft.currentStepKey);
        found.data = null;

        draft.currentStepKey = prevStepKey;
      })
    );
  };

  return state.currentStepKey !== "FINISH" ? (
    <div className="onboarding-container">
      {state.onboardingUniqueMerchant ? (
        <button
          className="return-top-button"
          type="button"
          onClick={() => {
            dispatch(doLogout());
          }}
        >
          <div className="container">
            <FaArrowLeft /> Sair
          </div>
        </button>
      ) : (
        <Link to="/" className="return-top-button" type="button">
          <div className="container">
            <FaArrowLeft /> voltar pro dashboard
          </div>
        </Link>
      )}

      <PageTitle pageTitle="Onboarding" />

      {/* Container */}
      <div className="wizard-container">
        <div className="container">
          <animated.div style={animation}>
            <img src={Logo} alt="Iumy" className="logo" />
            {state.loading ? (
              <LoaderBlock blocks={5} width={3} className="dark" />
            ) : (
              <currentStep.component
                animation={animation}
                nextStepHandler={nextStep}
                previousStepHandler={previousStep}
                stepConfigs={currentStep}
                data={currentStep.data}
                dispatch={dispatch}
                storeUuid={storeUuid}
              />
            )}
          </animated.div>
        </div>
      </div>

      <ToastContainer theme="dark" />
    </div>
  ) : (
    <Redirect to={`/${storeUuid}/home`} />
  );
}
