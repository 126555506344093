import React, { useState, useEffect } from "react";
import { FaTrash, FaCheckCircle, FaExclamationCircle, FaPlay } from "react-icons/fa";
import { useLocation } from "react-router-dom";

// Helpers
import { convertEmptyToStr } from "helpers";

// Assets
import { ReactComponent as MelhorEnvioIcon } from "images/app-icons/melhor-envio.svg";

// Components
import PageTitle from "components/page-title";

export default function MelhorEnvio({
  storeUuid,
  loading: isSubmiting,
  appConfigs,
  uninstallHandler,
  submitHandler,
}) {
  const { state: locationState } = useLocation();

  const dataModel = {
    isActive: false,
    isPaused: false,
    code: null,
  };

  const [state, setState] = useState(
    convertEmptyToStr({
      initialValues: {
        ...dataModel,
        ...appConfigs,
      },
      initOAuth: false,
      disableAuthButton: false,
      uninstallingApp: false,
    })
  );

  // FIRST START
  useEffect(() => {
    if (locationState?.code) {
      // DO ANY WITH CODE
      const { code } = locationState;
      delete locationState.code;
      window.history.replaceState(null, "");

      submitHandler({ isActive: true, code }, dataModel);
    }
  }, []);

  useEffect(() => {
    setState({
      ...state,
      initialValues: convertEmptyToStr({ ...state.initialValues, ...appConfigs }),
    });
  }, [appConfigs]);

  const initOAuth = () => {
    setState((s) => ({ ...s, disableAuthButton: true }));
    window.location.href = `${process.env.REACT_APP_MELHOR_ENVIO_URL}/oauth/authorize?client_id=${process.env.REACT_APP_MELHOR_ENVIO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MELHOR_ENVIO_REDIRECT_URI}&response_type=code&state=${storeUuid}&scope=cart-read cart-write companies-read companies-write coupons-read coupons-write notifications-read orders-read products-read products-write purchases-read shipping-calculate shipping-cancel shipping-checkout shipping-companies shipping-generate shipping-preview shipping-print shipping-share shipping-tracking ecommerce-shipping transactions-read users-read users-write`;
  };

  const unPause = async () => {
    setState((s) => ({ ...s, disableAuthButton: true }));

    const result = await submitHandler({ isPaused: false }, dataModel);

    if (result.succces) setState((s) => ({ ...s, disableAuthButton: false }));
  };

  return (
    <>
      <PageTitle pageTitle="Integrações: Melhor Envio" />
      <div className="row mb35 mt35">
        <div className="col-sm-4 mb30">
          <MelhorEnvioIcon className="mb15" />
          <h5>Melhor Envio</h5>
          <p>Integre com o melhor envio para operações logísticas aqui dentro da Iumy.</p>

          {state.initialValues.isActive && (
            <button
              type="button"
              onClick={() => {
                setState((oldState) => ({ ...oldState, uninstallingApp: true }));
                uninstallHandler(dataModel);
              }}
              className="btn btn-outline-danger btn-sm"
              disabled={state.uninstallingApp}
            >
              <FaTrash /> {state.uninstallingApp ? "Removendo" : "Desinstalar App"}
            </button>
          )}

          {/* <a
            href="https://docs.iumy.com.br/documentacao/como-integrar-com-a-shopify"
            target="_blank"
            rel="noreferrer"
            className="doclink"
          >
            <FaQuestionCircle /> Como instalar?
          </a> */}
        </div>

        <div className="col">
          <div className="box-widget">
            <div className="inner heading">
              <h3>Integração Melhor Envio</h3>
            </div>
            <hr />
            <div className="inner">
              {!state.initialValues.isActive && (
                <button
                  type="button"
                  className="btn size--medium green"
                  onClick={initOAuth}
                  disabled={state.disableAuthButton || isSubmiting}
                >
                  {isSubmiting ? "Integrando..." : "Autorizar Melhor Envio"}
                </button>
              )}

              {state.initialValues.isActive && !state.initialValues.isPaused && (
                <p>
                  <FaCheckCircle className="maingreen" /> Sua integração está ativa!
                </p>
              )}

              {state.initialValues.isPaused && (
                <>
                  <div className="alert alert-warning">
                    <div>
                      <FaExclamationCircle className="yellow" /> {state.initialValues.pauseReason}
                    </div>
                  </div>
                  {state.initialValues.pauseReason.indexOf("saldo") > -1 && (
                    <button
                      type="button"
                      className="btn size--medium green"
                      disabled={state.disableAuthButton || isSubmiting}
                      onClick={unPause}
                    >
                      <FaPlay /> Já recarreguei, reativar
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
