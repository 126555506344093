import React, { useState, useRef, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import classnames from "classnames";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import {
  doLoadNotificationsRequest,
  doMarkNotificationReadRequest,
  doMarkAllNotificationsRead,
} from "store/modules/notifications/actions";

import { useOutsideClick, generateInitialsFromName } from "helpers";

export default () => {
  const dispatch = useDispatch();

  const [isOpened, toggleOpened] = useState(false);
  const [lastLoadedNotificationId, setLastLoadedNotificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itAll, setItAll] = useState(false);

  // Data
  const notifications = useSelector((state) => state.notifications);

  const { list, unreadQty } = notifications;

  // Main function
  const loadNotifications = async (options = { channels: ["IN_APP"] }, reset = false) => {
    if (loading) return;

    setLoading(true);

    // Start load notifications async
    const response = await dispatch(doLoadNotificationsRequest(options, reset));

    if (response.success && response.data.data.length) {
      const _list = response.data.data;
      const lastId = _list[_list.length - 1].uuid;
      setLastLoadedNotificationId(lastId);
    } else {
      setItAll(true);
    }

    setLoading(false);
  };

  // Use Effect
  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    if (isOpened) {
      loadNotifications();
    }
  }, [isOpened]);

  // Click outside
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleOpened);

  // Handle click on notification (mark read)
  const handleReadNotification = (notification) => {
    toggleOpened();

    if (!notification.isRead) return dispatch(doMarkNotificationReadRequest(notification.uuid));

    return true;
  };

  const handleBatchReadNotifications = async () => dispatch(doMarkAllNotificationsRead());

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && !itAll) {
      loadNotifications({
        channels: ["IN_APP"],
        start_from: lastLoadedNotificationId,
      });
    }
  };

  return (
    <div
      className={classnames("user-notifications", {
        active: isOpened,
      })}
      ref={wrapperRef}
    >
      <button className="notification-btn" type="button" onClick={() => toggleOpened(!isOpened)}>
        <FaBell className="bell" />
        {unreadQty > 0 && <span className="number">{unreadQty > 99 ? "99+" : unreadQty}</span>}
        Notificações
      </button>

      <div className="notification-container">
        <header className="notify-header">
          Notificações
          <button
            type="button"
            className="clear-notifications-btn"
            onClick={() => handleBatchReadNotifications()}
          >
            Ler todas
          </button>
        </header>

        <ul className="notification-scroll" onScroll={handleScroll}>
          {list.map((notification) => {
            const initials = generateInitialsFromName(notification.storeName);
            const avatar = notification.storeIconUrl ? (
              <img
                src={notification.storeIconUrl}
                alt={notification.storeName}
                className="store-logo img-fluid"
              />
            ) : (
              <span className="store-logo initials">{initials}</span>
            );

            return (
              <li
                key={notification.uuid}
                className={classnames(
                  {
                    read: notification.isRead,
                  },
                  notification.context
                )}
              >
                <a
                  href={notification.url}
                  onClick={() => handleReadNotification(notification)}
                  className="content"
                >
                  <div className="col-img">
                    {avatar}

                    {notification.typeIcon && <span className="icon">{notification.typeIcon}</span>}
                  </div>

                  <div className="col-description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: notification.content,
                      }}
                    />
                    <span className="not-date">{moment(notification.created_at).fromNow()}</span>
                  </div>
                </a>
              </li>
            );
          })}

          {loading && (
            <li className="loading">
              <i className="loader dots">
                <span className="dot dot-1" />
                <span className="dot dot-2" />
                <span className="dot dot-3" />
              </i>
            </li>
          )}

          {itAll && <li className="itAll">Parace que isso é tudo!</li>}
        </ul>
      </div>
    </div>
  );
};
