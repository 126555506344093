import React from "react";

// Components
import PageTitle from "components/page-title";
import SystemAlerts from "components/system-alerts";
import Wizard from "./components/wizard";
import IumyAcademy from "./components/academy";

export default function Home() {
  return (
    <main className="page home">
      <PageTitle pageTitle="Bem-vindo" />

      <div className="container-sm home-content">
        <SystemAlerts showTitle />

        <Wizard />
        <IumyAcademy />
      </div>
    </main>
  );
}
