import React from "react";
import CountUp from "react-countup";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import LoaderBG from "components/loader-bg";

export default function CardIcon({
  number,
  icon,
  label,
  className,
  sufix,
  prefix,
  link,
  decimals,
  error,
  loading,
  streamerMode = false,
}) {
  const duration = number > 1000 ? 2 : 1;

  return (
    <div className={`iumy-card with-icon ${className}`}>
      <div className="icon-wrap">{icon}</div>

      {loading ? (
        <LoaderBG blocks={1} />
      ) : (
        <>
          {!error ? (
            <div className="card-content-wrap">
              <span className="card-label">{label}</span>
              <span className={`card-data ${streamerMode ? "blurry" : ""}`}>
                <CountUp
                  end={number}
                  start={1}
                  decimals={decimals || 0}
                  decimal=","
                  duration={duration}
                  separator="."
                  suffix={sufix || ""}
                  prefix={prefix || ""}
                />
              </span>
            </div>
          ) : (
            <div className="card-error">
              {error}
              <br />
              <small>Aguarde ou recarregue a página</small>
            </div>
          )}
        </>
      )}

      {link && (
        <div className="link-alt">
          <FaExternalLinkAlt fontSize={14} />

          <Link to={link} />
        </div>
      )}
    </div>
  );
}
