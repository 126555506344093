import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Actions
import PageTitle from "components/page-title";
import CalendarContainer from "components/orders/calendar";
import { setLoadingStatus } from "store/modules/context/reducer";

import LoaderBlock from "components/loader-bg";
import SalesStatisticsContainer from "./containers/sales-statistics";
import ShippingStatisticsContainer from "./containers/shipping-statistics";
import PackageConsolidateStatisticsContainer from "./containers/package-consolidated-statistics";
import PackagesStatisticsContainer from "./containers/packages-statistics";
import ExceptionStatisticsContainer from "./containers/exceptions-statistics";
import ProductsStatisticsContainer from "./containers/products-statistics";

function Dashboard({ currentMerchant, dispatch, firstName }) {
  const storeUuid = currentMerchant.uuid;
  const lastStoreUuid = useRef(currentMerchant.uuid);
  const [filter, setFilter] = useState({
    created_at_min: null,
    created_at_max: null,
  });

  const [render, setRender] = useState(true);

  useEffect(() => {
    if (storeUuid !== lastStoreUuid.current) {
      lastStoreUuid.current = storeUuid;

      dispatch(setLoadingStatus(true));
      setRender(false);
      setFilter({
        created_at_min: null,
        created_at_max: null,
      });

      setTimeout(() => {
        setRender(true);

        dispatch(setLoadingStatus(false));
      }, 500);
    }
  }, [currentMerchant]);

  const label =
    filter.created_at_min && filter.created_at_max
      ? `${moment(filter.created_at_min).format("DD/MM/YYYY")} - ${moment(
          filter.created_at_max
        ).format("DD/MM/YYYY")}`
      : "Filtrar";

  const selectRange = ([startDate, endDate]) => {
    setFilter({
      created_at_min: startDate,
      created_at_max: endDate,
    });
  };

  return (
    <div className="container page dashboard">
      <PageTitle pageTitle="Visão geral" />

      {/* Titulo + Filtro */}
      <header>
        <div className="titles">
          <h1>Bem vindo, {firstName} 🎉</h1>
          <p>Aqui está uma visão do que está acontecendo na sua loja.</p>
        </div>

        <div className="actions">
          <CalendarContainer
            label={label}
            enableClear
            className="filter-btn"
            startDate={filter.created_at_min}
            endDate={filter.created_at_max}
            onSelect={({ startDate, endDate }) => {
              selectRange([startDate, endDate]);
            }}
          />
        </div>
      </header>

      {render ? (
        <>
          <div className="top-cards">
            <SalesStatisticsContainer storeUuid={storeUuid} filter={filter} />
            <ShippingStatisticsContainer storeUuid={storeUuid} filter={filter} />
          </div>

          <div className="row reverse-column">
            <div className="col-md-8">
              {/* Chart */}
              <PackagesStatisticsContainer storeUuid={storeUuid} filter={filter} />
              <ExceptionStatisticsContainer storeUuid={storeUuid} filter={filter} />
            </div>
            <div className="col-md-4">
              {/* Card Status */}
              <PackageConsolidateStatisticsContainer storeUuid={storeUuid} filter={filter} />
              <ProductsStatisticsContainer storeUuid={storeUuid} filter={filter} />
            </div>
          </div>
        </>
      ) : (
        <LoaderBlock blocks={5} width={3} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
  firstName: state.auth.user.name,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
