import { createActions } from "reduxsauce";

import Product from "models/product";
import ProductThumb from "images/demo/product-list-thumb.webp";

export const { Types, Creators } = createActions(
  {
    getProductsRequest,
    getProductsSuccess: ["products"],
    getProductsFailure: ["err"],
  },
  {
    prefix: "@products/",
  }
);

function getProductsRequest(params) {
  return async function (dispatch) {
    const productList = [];

    for (let i = 0; i < 10; i++) {
      const product = new Product({
        id: i,
        name: `Cadeado inteligente ${i}`,
        thumbnail: ProductThumb,
        price: 23.12,
        categories: [
          {
            id: 0,
            name: "Categoria 1",
          },
        ],
        stock: 1212,
      });

      productList.push(product);
    }

    await new Promise(resolve => setTimeout(() => resolve(true), 2000));

    dispatch(Creators.getProductsSuccess(productList));
  };
}
