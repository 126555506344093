import { immerable } from "immer";
import Status from "./tracking/status";
import Price from "./price";
import Customer from "./customer";
import Package from "./tracking/package";

export default class OrderSummary {
  constructor(options) {
    this[immerable] = true;

    this.uuid = options.uuid;
    this.storeId = options.storeId;
    this.appType = options.appType;
    this.shopifyOrderUrl = options.shopifyOrderUrl;
    this.orderReferenceId = options.orderReferenceId;
    this.name = options.name;
    this.orderNumber = options.orderNumber;
    this.gateway = options.gateway;
    this.createdAt = options.createdAt;
    this.updatedAt = options.updatedAt;
    this.paidAt = options.paidAt;
    this.cancelledAt = options.cancelledAt;
    this.cancelReason = options.cancelReason;

    this.totalPrice = new Price(options.totalPrice, options.currency);
    this.totalDiscount = new Price(options.totalDiscount, options.currency);
    this.totalPriceWithDiscount = new Price(options.totalPriceWithDiscount, options.currency);

    // Statuses
    this.isPackagesSynced = options.isPackagesSynced;
    this.isTransactionsSynced = options.isTransactionsSynced;
    this.isArchived = options.isArchived;
    this.isHidden = options.isHidden;

    this.currency = options.currency;
    this.financialStatus = new Status(options.financialStatus);
    this.customer = options.customer ? new Customer(options.customer) : null;
    this.packages = options.packages.length
      ? options.packages.map((packageItem) => new Package(packageItem))
      : [];
  }
}
