import React, { useEffect, useState } from "react";

export default ({
  selectedCount,
  totalItems,
  maxPageCount,
  handleSelectAllItems,
  isExtraResourcesSelected,
  className,
}) => {
  const [state, setState] = useState({
    selectedCount,
    maxPageCount,
  });

  useEffect(() => {
    if (
      (maxPageCount !== state.maxPageCount || selectedCount !== state.selectedCount) &&
      isExtraResourcesSelected
    ) {
      handleSelectAllItems(false);
    }

    setState({
      selectedCount,
      maxPageCount,
    });
  }, [selectedCount, maxPageCount]);

  const showSelectExtra = state.selectedCount === state.maxPageCount;

  return (
    state.selectedCount > 0 && (
      <div className={`selection-resume ${className}`}>
        <strong className="current-selection">
          {isExtraResourcesSelected ? totalItems : state.selectedCount} selecionados.
        </strong>
        {showSelectExtra && !isExtraResourcesSelected && (
          <button
            type="button"
            className="toggle-selection btn f14 btn-link"
            onClick={() => handleSelectAllItems(true)}
          >
            Selecionar todos <strong>{totalItems}</strong> itens.
          </button>
        )}
      </div>
    )
  );
};
