import { createActions } from "reduxsauce";
import { accessDotNotation } from "helpers";

// Services
import { apiInstance } from "services/api";

// Create actions and Types
export const { Types, Creators } = createActions(
  {
    // LIST MERCHANTS
    doGetOrdersSuccess: ["payload"],
    doGetOrdersFailure: ["error"],

    // doUpdateOrderSuccess: ["payload"],
  },
  {
    prefix: "@orders/",
  }
);

// Priv Ajax
export async function syncOrder(storeUuid, orderUuid) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order/${orderUuid}/sync`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao sincronizar pedido.",
    };
  }
}
export async function updatePackage(storeUuid, orderUuid, packageUuid, options = {}) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/order/${orderUuid}/package/${packageUuid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(options),
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar pedidos da loja.",
    };
  }
}

async function massUpdateOrders(storeUuid, ordersUuid, options) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order/`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        orders: ordersUuid.map((uuid) => ({
          ...options,
          uuid,
        })),
      }),
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar pedidos da loja.",
    };
  }
}

async function getOrdersRequest(storeUuid, options) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order`, {
      method: "get",
      params: options,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        status: response.status,
        success: true,
        data: response.data,
      };
    }

    return {
      success: false,
      status: response.status || 500,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar pedidos da loja.",
    };
  }
}

export async function createOrderNote(storeUuid, orderUuid, content) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order/${orderUuid}/note`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: { content },
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao criar nota no pedido.",
    };
  }
}

export async function updateOrderNote(storeUuid, orderUuid, noteUuid, content) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/order/${orderUuid}/note/${noteUuid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: { content },
      }
    );

    if (response.status === 200 || 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao atualizar nota no pedido.",
    };
  }
}

export async function deleteOrderNote(storeUuid, orderUuid, noteUuid) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/order/${orderUuid}/note/${noteUuid}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200 || 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao remover nota do pedido.",
    };
  }
}

export async function addOrderNoteReminder(storeUuid, orderUuid, noteUuid, options) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/order/${orderUuid}/note/${noteUuid}/reminder`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: options,
      }
    );

    if (response.status === 200 || 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao criar lembrete de nota no pedido.",
    };
  }
}

export async function removeOrderNoteReminder(storeUuid, orderUuid, noteUuid, reminderUuid) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/order/${orderUuid}/note/${noteUuid}/reminder/${reminderUuid}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200 || 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao remover lembrete de nota no pedido.",
    };
  }
}

export async function getOrderDetailRequest(storeUuid, orderUuid) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order/${orderUuid}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao carregar detalhes do pedido.",
    };
  }
}

export async function getPackageDetails(store_uuid, order_uuid, package_uuid) {
  try {
    const response = await apiInstance(
      `/api/store/${store_uuid}/order/${order_uuid}/package/${package_uuid}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao carregar detalhes do pacote.",
    };
  }
}

export async function exportReport(storeUuid, params) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/order/report`, {
      method: "post",
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message: accessDotNotation(ex, "response.data.error.message") || "Erro ao gerar relatório.",
    };
  }
}

// Methods
export const doGetOrders = (storeUuid, options) => async (dispatch) => {
  const { success, data, message, status } = await getOrdersRequest(storeUuid, options);

  if (success) {
    dispatch(Creators.doGetOrdersSuccess(data.data));
  } else {
    dispatch(Creators.doGetOrdersFailure(message));
  }

  return {
    success,
    status,
    data,
  };
};

export const doArchivePackage = (storeUuid, orderUuid, packageUuid) => async (dispatch) => {
  const { success, data } = await updatePackage(storeUuid, orderUuid, packageUuid, {
    isArchived: true,
  });

  return {
    success,
    package: data,
    orderUuid,
  };
};

export const doHidePackage = async (storeUuid, orderUuid, packageUuid, options) => {
  const { success, data } = await updatePackage(storeUuid, orderUuid, packageUuid, {
    isHidden: options.isHidden,
  });

  return {
    success,
    data,
  };
};

export const doMassArchiveOrders = async (storeUuid, packageUuids) => {
  const { success, data } = await massUpdateOrders(storeUuid, packageUuids, {
    isArchived: true,
  });

  return {
    success,
    data,
  };
};

export const doMassUpdateOrders = async (storeUuid, orderUuids, options = {}) => {
  const { success, data } = await massUpdateOrders(storeUuid, orderUuids, options);

  return {
    success,
    data,
  };
};
