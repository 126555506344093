import { ReturnStatusTypes } from "models/returnal/returnal";
import React from "react";

export default function FastFilters({ currentStatusFilter = [], handleFilter }) {
  const handleFastFilters = (status) => {
    const _status = status ? [status] : [];
    handleFilter("status", _status);
  };

  const FilterButton = ({ label, slug }) => (
    <button
      className={
        (
          !slug
            ? currentStatusFilter.length !== 1
            : currentStatusFilter.length === 1 && currentStatusFilter[0]?.slug === slug
        )
          ? "active"
          : ""
      }
      type="button"
      onClick={() => handleFastFilters(slug)}
    >
      {label}
    </button>
  );

  return (
    <nav className="fast-filters">
      <FilterButton label="Todas" />
      <FilterButton label="Aguardando aprovação" slug={ReturnStatusTypes.PENDING} />
      <FilterButton label="Aguardando conferência" slug={ReturnStatusTypes.DELIVERED} />
      <FilterButton label="Aguardando pagamento" slug={ReturnStatusTypes.CONFERRED} />
    </nav>
  );
}
