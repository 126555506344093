import React from "react";

import MobileTrackingLine from "./mobile-tracking-line";

function MobileTrackings({ packages, order }) {
  return (
    <div className="tracking-lines-wrapper mobile">
      {packages.map((packageItem, index) => (
        <MobileTrackingLine
          key={index}
          packageItem={packageItem}
          orderReferenceId={order.orderReferenceId}
        />
      ))}
    </div>
  );
}

export default MobileTrackings;
