import React from "react";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import VMasker from "vanilla-masker";
import moment from "moment";
import classnames from "classnames";

function MobileOrderMessages({ messages, togglePreview }) {
  return (
    <div className="m-message-list">
      {messages.map((message) => {
        const Icon = message.channel === "WHATSAPP" ? FaWhatsapp : FaEnvelope;

        const contact =
          message.channel === "WHATSAPP"
            ? VMasker.toPattern(message.numberTo, "+99 (99) 99999-9999")
            : message.emailTo;

        return (
          <div key={message.uuid} className="message-item box-widget-mini">
            <main>
              <div className="messages-summary">
                <ul className="message-list list-no-list">
                  <li>
                    <span className="bold uppercase f11 gray block">Tipo</span>
                    <span className={classnames("iumy-badge", message.status.code)}>
                      {message.status.label}
                    </span>
                    <span />
                  </li>

                  <li>
                    <span className="bold uppercase f11 gray">Canal</span>
                    <span className="bold f13 black block">
                      <Icon size={14} className="message-type-icon" /> {message.channel}
                    </span>
                  </li>

                  <li>
                    <div className="message-infos">
                      <span className="bold uppercase f11 gray block">Mensagem</span>
                      <span className="message-title" onClick={() => togglePreview(message)}>
                        {message.subject}
                      </span>{" "}
                      <span className="f13 gray">{moment(message.sentAt).fromNow()}</span>
                      <span className="message-to block">
                        <span>Para:</span> {contact}
                      </span>
                    </div>
                  </li>

                  {message.channel === "WHATSAPP" && (
                    <li>
                      <a
                        href={message.whatsappSendUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="resend-message-button"
                      >
                        Reenviar
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </main>
          </div>
        );
      })}
    </div>
  );
}

export default MobileOrderMessages;
