import React from "react";
import { immerable } from "immer";
import { renderRoute } from "pages/main/routes-list";
import { FaBox } from "react-icons/fa";

export default class Notification {
  constructor(options) {
    this.uuid = options.uuid;
    this.created_at = options.createdAt;
    this.storeUuid = options.storeUuid;
    this.packageUuid = options.packageUuid;
    this.context = options.context;
    this.orderUuid = options.orderUuid;
    this.readAt = options.readAt;
    this.isRead = options.isRead;
    this.subject = options.subject;
    this.content = options.content;
    this.storeLogoUrl = options.storeLogoUrl;
    this.storeIconUrl = options.storeIconUrl;
    this.storeName = options.storeName;

    switch (options.context) {
      case "ORDER":
      case "PACKAGE":
        this.url = renderRoute("order", {
          merchantId: this.storeUuid,
          orderId: this.orderUuid,
        });

        this.typeIcon = <FaBox />;
        break;

      default:
        this.typeIcon = null;
    }
  }
}

Notification[immerable] = true;
