import React, { useState, createRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IMaskInput } from "react-imask";
import * as Yup from "yup";
import cep from "cep-promise";
import classnames from "classnames";
import { toast } from "react-toastify";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sanitizeObject } from "helpers";

import {
  Creators as BillingActions,
  doCreateBillingInfos,
  doUpdateBillingInfos,
} from "store/modules/billing/actions";

import PaymentCustomer from "models/payment-customer";
import Modal from "components/modal";
import PhoneInput from "components/custom-fields/phone";

const PaymentCustomerModal = ({ modalToggleHandler, paymentCustomer, dispatch }) => {
  const formikInitial = paymentCustomer || new PaymentCustomer();
  const [state, setState] = useState({
    modalTitle: "Alterar dados de faturamento",
    modalContent: null,
    submiting: false,
    disableCancel: true,
  });

  const handleBillingSubmit = async (values) => {
    const sanitized = sanitizeObject(values, ["email", "name"]);

    let response;

    if (paymentCustomer)
      response = await dispatch(doUpdateBillingInfos(paymentCustomer.uuid, sanitized));
    else response = await dispatch(doCreateBillingInfos(sanitized));

    if (response.success) {
      modalToggleHandler(null, true);
    } else {
      toast.error(response.message);
      setState((oldState) => ({
        ...oldState,
        submiting: false,
      }));
    }
  };

  const formRef = createRef();

  const handleCancelOrClose = () => {
    // formRef.current.resetForm();
    modalToggleHandler();
  };

  return (
    <Modal
      showModal
      toggleModal={() => formRef.current.submit()}
      cancelHandler={handleCancelOrClose}
      title={state.modalTitle}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .max(100, "O tamanho máximo do nome é de 100 caracteres")
            .required("Preencha o nome do contato da loja")
            .matches(/^\s*[\S]+(\s[\S]+)+\s*$/, "Informe também o sobrenome"),
          email: Yup.string().email("E-mail inválido").required("Preencha o e-mail de envio."),
          description: Yup.string().max(160, "O tamanho máximo do nome é de 160 caracteres"),
          document: Yup.string()
            .matches(
              /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
              "Formato de documento inválido."
            )
            .required("Número de documento obrigatório."),
          phone: Yup.string()
            .matches(/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/, "Digite um telefone completo.")
            .required("Número de telefone obrigatório."),

          addressZipcode: Yup.string()
            .required("Preencha o cep.")
            .matches(/^[0-9]{5}-[\d]{3}$/, "Formato inválido"),
          addressNeighborhood: Yup.string().required("Preencha o bairro."),
          addressStreet: Yup.string().required("Preencha o endereço."),
          addressNumber: Yup.string().required("Preencha o número."),
          addressCity: Yup.string().required("Preencha a cidade."),
          addressState: Yup.string().required("Preencha o Estado."),
        })}
        validate={(a1) => {
          if (!_.isEqual(a1, formikInitial))
            setState({
              ...state,
              disableCancel: false,
            });
          else {
            setState({
              ...state,
              disableCancel: true,
            });
          }
        }}
        initialValues={formikInitial}
        onSubmit={(values) => {
          setState((oldState) => ({
            ...oldState,
            submiting: true,
          }));
          handleBillingSubmit(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <Form>
            <h6>Contato</h6>
            <div className="select-container form-group">
              <Field name="entityType">
                {({ field }) => (
                  <div
                    className={classnames("select-wrapper", {
                      active: field.value === "PF",
                    })}
                  >
                    <div className="pretty p-default p-round">
                      <input {...field} value="PF" type="radio" checked={field.value === "PF"} />

                      <div className="state p-success">
                        <label>Pessoa Física</label>
                      </div>
                    </div>
                  </div>
                )}
              </Field>
              <Field name="entityType">
                {({ field }) => (
                  <div
                    className={classnames("select-wrapper", {
                      active: field.value === "PJ",
                    })}
                  >
                    <div className="pretty p-default p-round">
                      <input {...field} value="PJ" type="radio" checked={field.value === "PJ"} />

                      <div className="state p-success">
                        <label>Pessoa Jurídica</label>
                      </div>
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <span className="field-label" required>
                    {values.entityType === "PJ" ? "Razão social" : "Nome"}
                  </span>
                  <Field
                    name="name"
                    type="text"
                    placeholder={
                      values.entityType === "PJ" ? "ex: Minha Empresa LTDA" : "ex: John Silva"
                    }
                    className="form-control"
                  />
                  <ErrorMessage component="div" className="error-message" name="name" />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <span className="field-label" required>
                    E-mail de faturamento.
                  </span>
                  <Field name="email" className="form-control" placeholder="contato@mail.com" />
                  <ErrorMessage component="div" className="error-message" name="email" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <span className="field-label" required>
                    {values.entityType === "PJ" ? "CNPJ" : "CPF"}
                  </span>
                  <Field name="document">
                    {({ field }) => (
                      <IMaskInput
                        {...field}
                        autoComplete="cpf"
                        component="input"
                        placeholder={
                          values.entityType === "PF" ? "000.000.000-00" : "00.000.000/0000-00"
                        }
                        type="text"
                        className="form-control"
                        mask={values.entityType === "PF" ? "000.000.000-00" : "00.000.000/0000-00"}
                      />
                    )}
                  </Field>
                  <ErrorMessage component="div" className="error-message" name="document" />
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="form-group">
                  <span className="field-label" required>
                    Telefone
                  </span>
                  <Field name="phone">
                    {(props) => (
                      <PhoneInput
                        {...props}
                        className="form-control"
                        placeholder="(99) 9999-99999"
                      />
                    )}
                  </Field>
                  <ErrorMessage component="div" className="error-message" name="phone" />
                </div>
              </div>
            </div>

            <h6>Endereco</h6>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field name="addressZipcode">
                    {({ form, field }) => (
                      <IMaskInput
                        {...field}
                        type="tel"
                        autoComplete="postal-code"
                        className="form-control"
                        mask="00000-000"
                        onAccept={(value) => {
                          form.setFieldValue("addressZipcode", value);
                        }}
                        placeholder="CEP"
                        onBlur={(e) => {
                          form.handleBlur(e);

                          if (!form.errors.zipcode)
                            cep(e.target.value)
                              .then((address) => {
                                form.setFieldValue("addressStreet", address.street);
                                form.setFieldValue("addressCity", address.city);
                                form.setFieldValue("addressState", address.state);
                                form.setFieldValue("addressNeighborhood", address.neighborhood);
                              })
                              .catch((ex) => {
                                form.setFieldValue("addressStreet", "");
                                form.setFieldValue("addressCity", "");
                                form.setFieldValue("addressState", "");
                                form.setFieldValue("addressNeighborhood", "");

                                toast.error("Cep não encontrado.", {
                                  position: toast.POSITION.BOTTOM_CENTER,
                                });
                              });
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage component="div" className="error-message" name="addressZipcode" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group">
                  <Field
                    autoComplete="street-address"
                    component="input"
                    name="addressStreet"
                    placeholder="Endereço"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage component="div" className="error-message" name="addressStreet" />
                </div>
              </div>
            </div>

            <div className="form-group">
              <Field
                autoComplete="street-address"
                component="input"
                name="addressComplement"
                placeholder="Complemento"
                type="text"
                className="form-control"
              />
              <ErrorMessage component="div" className="error-message" name="addressComplement" />
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field
                    autoComplete="addressNumber"
                    component="input"
                    name="addressNumber"
                    placeholder="Nº"
                    type="tel"
                    className="form-control"
                  />
                  <ErrorMessage className="error-message" component="div" name="addressNumber" />
                </div>
              </div>
              <div className="col-lg-9">
                <div className="form-group">
                  <Field
                    autoComplete="address-level3"
                    component="input"
                    name="addressNeighborhood"
                    placeholder="Bairro"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="addressNeighborhood"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field
                    autoComplete="locality"
                    component="input"
                    name="addressCity"
                    placeholder="Cidade"
                    type="text"
                    className="form-control"
                    disabled
                  />
                  <ErrorMessage className="error-message" component="div" name="addressCity" />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <Field
                    autoComplete="region"
                    component="input"
                    name="addressState"
                    placeholder="Estado"
                    type="text"
                    disabled
                    className="form-control"
                  />
                  <ErrorMessage className="error-message" component="div" name="addressState" />
                </div>
              </div>
            </div>
            <hr />

            <div className="actions text-end">
              <button
                className="btn mr15 size--large default"
                type="button"
                onClick={() => handleCancelOrClose()}
              >
                Cancelar
              </button>
              <button
                className="btn green size--large"
                disabled={state.submiting || state.disableCancel}
                type="submit"
              >
                Alterar dados
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

// const map
const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators(
    {
      ...BillingActions,
    },
    dispatch
  );
  return {
    ...binders,
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(PaymentCustomerModal);
