import OrderNoteReminder from "models/order-note-reminder";

export default class OrderNote {
  constructor(options = {}) {
    this.uuid = options.uuid;
    this.content = options.content;
    this.createdAt = options.createdAt;
    this.createdByUserName = options.createdByUserName;
    this.deletedAt = options.deletedAt;
    this.modifiedByUserName = options.modifiedByUserName;
    this.reminder = options.reminder ? new OrderNoteReminder(options.reminder) : null;
    this.updatedAt = options.updatedAt;
  }
}
