import { apiInstance } from "services/api";
import { accessDotNotation } from "helpers";

// eslint-disable-next-line import/prefer-default-export
export const uploadImage = async (blob) => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", blob);

  try {
    const { status, data: response } = await apiInstance("/api/img", {
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
    });

    if (status === 201) {
      return {
        success: true,
        data: response.data,
      };
    }

    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message: accessDotNotation(ex, "response.data.error.message"),
    };
  }
};
