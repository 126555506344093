import VMasker from "vanilla-masker";
import { immerable } from "immer";

export default class PaymentCustomer {
  constructor(options = {}) {
    this[immerable] = true;
    this.uuid = options.uuid || "";
    this.name = options.name || "";
    this.email = options.email || "";
    this.phone = options.phone
      ? VMasker.toPattern(
          options.phone.replace("+55", ""),
          options.phone > 14 ? "(99) 99999-9999" : "(99) 9999-99999"
        )
      : "";

    this.document = options.document
      ? VMasker.toPattern(
          options.document,
          options.entityType === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
        )
      : "";
    this.entityType = options.entityType || "PF";
    this.gateway = options.gateway;
    this.addressStreet = options.addressStreet || "";
    this.addressNumber = options.addressNumber || "";
    this.addressComplement = options.addressComplement || "";
    this.addressNeighborhood = options.addressNeighborhood || "";
    this.addressCity = options.addressCity || "";
    this.addressState = options.addressState || "";
    this.addressZipcode = VMasker.toPattern(options.addressZipcode || "", "99999-999");
  }
}
