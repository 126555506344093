import React from "react";

function LoaderBG({ preWidth, blocks, className }) {
  const looper = [];
  const looperWidth = [];
  const width = preWidth || 5;
  const times = blocks || 2;

  for (let i = 0; i < times; i++) looper.push("row");
  for (let i = 0; i < width; i++) looperWidth.push("row");

  return (
    <div className={className || "loading-bg-container"}>
      {looper.map((block, index) => (
        <React.Fragment key={`block-${index}`}>
          {looperWidth.map((line, index2) => (
            <React.Fragment key={`block-${index}-line-${index2}`}>
              <div className="loader-bg" />
            </React.Fragment>
          ))}
          {index + 1 < times && <div className="loader-bg mt20" />}
        </React.Fragment>
      ))}
    </div>
  );
}

export default LoaderBG;
