import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import products from "./products";
import orders from "./orders";
import merchants from "./merchants";
import auth from "./auth/reducer";
import context from "./context/reducer";
import notifications from "./notifications";
import billing from "./billing";

const merchantPersistConfig = {
  key: "merchants",
  storage,
  whitelist: ["currentMerchant"],
};

const appReducer = combineReducers({
  context,
  merchants: persistReducer(merchantPersistConfig, merchants),
  products,
  orders,
  auth,
  notifications,
  billing,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "@auth/DO_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
