import React from "react";
import classnames from "classnames";
import moment from "moment";

// Calendar
import CalendarContainer from "components/orders/calendar";

function PreOption({ slug, label, param, range, currentRange, onClickHandler }) {
  const [min, max] = currentRange;

  return (
    <li className="mb5">
      <label
        className={classnames("radio-btn", {
          active: min === range[0] && max === range[1],
        })}
        htmlFor={`${param}-${slug}`}
      >
        <span className="radio" />
        <button
          className="btn btn-text"
          type="button"
          aria-label={label}
          id={`${param}-${slug}`}
          onClick={() => {
            onClickHandler([range[0], range[1]]);
          }}
        >
          {label}
        </button>
      </label>
    </li>
  );
}

export default function FilterDateRange({ filter, handleFilter }) {
  const [min, max] = filter.values || [];

  // Created Range Options Filter
  const today = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
  const yesterday = [
    moment().subtract(1, "day").format("YYYY-MM-DD"),
    moment().subtract(1, "day").format("YYYY-MM-DD"),
  ];
  const lastWeek = [
    moment().subtract(1, "week").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ];
  const lastMonth = [
    moment().subtract(1, "month").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ];

  const selectRange = (values = []) => {
    const [m, n] = values;
    handleFilter(
      filter.param,
      [m, n].filter((v) => v !== undefined)
    );
  };

  const isCustom = () => {
    const prefixRanges = [today, yesterday, lastWeek, lastMonth];

    const hasPrefix = prefixRanges.find((r) => r[0] === min && r[1] === max);

    return !hasPrefix && min && max;
  };

  return (
    <ul className="filters-daterange-list filters-list">
      <PreOption
        slug="today"
        label="Hoje"
        param={filter.param}
        range={today}
        currentRange={[min, max]}
        onClickHandler={selectRange}
      />

      <PreOption
        slug="yesterday"
        label="Ontem"
        param={filter.param}
        range={yesterday}
        currentRange={[min, max]}
        onClickHandler={selectRange}
      />

      <PreOption
        slug="lastweek"
        label="Últimos 7 dias"
        param={filter.param}
        range={lastWeek}
        currentRange={[min, max]}
        onClickHandler={selectRange}
      />

      <PreOption
        slug="lastmonth"
        label="Últimos 30 dias"
        param={filter.param}
        range={lastMonth}
        currentRange={[min, max]}
        onClickHandler={selectRange}
      />

      <li className="mb5">
        <label
          className={classnames("radio-btn step0", {
            active: isCustom(),
          })}
        >
          <span className="radio" />

          <CalendarContainer
            label="Selecionar período"
            startDate={min}
            endDate={max}
            onSelect={({ startDate, endDate }) => {
              selectRange([startDate, endDate]);
            }}
          />
        </label>
      </li>

      {(max || min) && (
        <li>
          <button
            className="btn btn-text f14"
            type="button"
            onClick={() => {
              selectRange([]);
            }}
          >
            Limpar
          </button>
        </li>
      )}
    </ul>
  );
}
