import { createActions } from "reduxsauce";
import axios from "axios";
import { accessDotNotation, sleep } from "helpers";

import Merchant from "models/merchant";
import { setLoadingStatus } from "store/modules/context/reducer";

// Services
import { apiInstance } from "services/api";

// Create actions and Types
export const { Types, Creators } = createActions(
  {
    // CREATE MERCHANT
    doCreateMerchantSuccess: ["payload"],
    doCreateMerchantFailure: ["error"],
    doResetCurrentMerchant: ["payload"],

    // UPDATE MERCHANT
    doUpdateMerchantSuccess: ["payload"],
    doUpdateMerchantFailure: ["error"],

    // LIST MERCHANTS
    doGetMerchantListSuccess: ["payload"],
    doGetMerchantListFailure: ["error"],

    // LIST APPS
    doGetAppsSuccess: ["payload"],
    doGetAppsFailure: ["error"],

    // GET APP DETAIL
    doGetAppDetailSuccess: ["payload"],

    // SAVE APP SETTINGS
    doSaveAppSettingsSuccess: ["payload"],
    doSaveAppSettingsFailure: ["error"],

    // Direct Methods
    updateCurrentMerchant: ["payload"],
  },
  {
    prefix: "@merchants/",
  }
);

// Merchants
export function doGetMerchantListRequest() {
  return async function (dispatch) {
    try {
      const { status, data: response } = await apiInstance("/api/store/", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (status === 200 && response.data.length) {
        dispatch(Creators.doGetMerchantListSuccess(response.data));
        return { success: true, data: response.data };
      }
      dispatch(Creators.doGetMerchantListFailure());
      return { success: false, message: response.error.message };
    } catch (ex) {
      dispatch(Creators.doGetMerchantListFailure());
      return {
        success: false,
        message: accessDotNotation(ex, "response.data.error.message"),
      };
    }
  };
}
export function doCreateMerchantRequest(options) {
  return async function (dispatch) {
    try {
      const { status, data: response } = await apiInstance("/api/store/", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          ...options,
        }),
      });

      if (status === 201) {
        const getAppsRequest = await apiInstance(`/api/store/${response.data.uuid}/app`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        });

        options.apps = getAppsRequest.data.data;

        dispatch(
          Creators.doCreateMerchantSuccess({
            ...options,
            uuid: response.data.uuid,
          })
        );
        return { success: true, data: response.data };
      }
      dispatch(Creators.doCreateMerchantFailure());
      return { success: false, message: response.error.message };
    } catch (ex) {
      dispatch(Creators.doCreateMerchantFailure());
      return {
        success: false,
        message: accessDotNotation(ex, "response.data.error.message"),
      };
    }
  };
}
export function doUpdateMerchantRequest(storeUuid, options) {
  return async (dispatch) => {
    try {
      const { status, data: response } = await apiInstance(`/api/store/${storeUuid}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          ...options,
        }),
      });

      if (status === 201) {
        dispatch(Creators.doUpdateMerchantSuccess(response.data));
        return {
          success: true,
        };
      }
      return { success: false, message: response.error.message };
    } catch (ex) {
      return {
        success: false,
        message: accessDotNotation(ex, "response.data.error.message"),
      };
    }
  };
}

// Apps
export function doSelectMerchant(uuid) {
  return async (dispatch) => {
    dispatch(setLoadingStatus(true));

    await sleep(1000);

    try {
      const merchantDetailsResponse = await getMerchantDetailsRequest(uuid);
      const merchantApps = await getAppsByUuidRequest(uuid);

      const newMerchant = new Merchant({
        ...merchantDetailsResponse.data.data,
        apps: [...merchantApps.data.data],
      });

      dispatch(Creators.updateCurrentMerchant(newMerchant));

      return {
        success: true,
        data: newMerchant,
      };
    } catch (ex) {
      return {
        success: false,
        message:
          accessDotNotation(ex, "response.data.error.message") ||
          "Erro ao carregar configurações da loja.",
      };
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };
}
export function doGetAppsbyMerchant(uuid) {
  return async (dispatch) => {
    const response = await getAppsByUuidRequest(uuid);

    if (response.success) {
      dispatch(Creators.doGetAppsSuccess(response.data.data));
    } else {
      dispatch(Creators.doGetAppsFailure());
    }
  };
}
export function doGetAppSettings(uuid, appSlug) {
  return async (dispatch) => {
    const { success, data, message } = await getAppSettingsByMerchantRequest(uuid, appSlug);

    if (success) {
      dispatch(Creators.doGetAppDetailSuccess(data));
      return {
        success,
        data,
      };
    }
    return {
      success,
      message,
    };
  };
}
export async function doSaveAppSettings(uuid, appSlug, options) {
  try {
    const { status, data, error } = await apiInstance(`/api/store/${uuid}/app/${appSlug}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify({ ...options }),
    });

    if ([201, 200].includes(status)) {
      return {
        success: true,
        data: {
          ...options,
          ...data.data,
          slug: appSlug,
        },
      };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao tentar salvar configurações.",
    };
  }
}

// Ajax calls
export async function getEcommerceOrdersCountRequest(uuid, appSlug, params) {
  try {
    const response = await apiInstance(`/api/store/${uuid}/app/${appSlug}/count`, {
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      // reesp,
    };
  } catch (ex) {
    const error = ex?.response?.data?.error;

    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}
export async function getShopifySingleOrder(uuid, orderName) {
  try {
    const response = await apiInstance(`/api/store/${uuid}/app/shopify/order/sync`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        orderName,
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    if (response.status === 404) {
      return {
        success: true,
        message: "Pedido não encontrado",
      };
    }

    return {
      success: false,
      message: "Erro ao buscar pedido, tente novamente.",
    };
  } catch (ex) {
    const error = ex?.response?.data?.error;

    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function doInstallShopifyTracking(uuid, data) {
  try {
    const response = await apiInstance(
      `/api/store/${uuid}/app/iumy_tracker/shopify_tracking_page`,
      {
        method: "put",
        data: {
          pluginCode: data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Falha ao criar ou atualizar página de rastreio.",
    };
  } catch (ex) {
    const { error } = ex.response.data;

    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function doUninstallShopifyTracking(uuid) {
  try {
    const response = await apiInstance(
      `/api/store/${uuid}/app/iumy_tracker/shopify_tracking_page`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Falha ao remover página de rastreio.",
    };
  } catch (ex) {
    const { error } = ex.response.data;

    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function getMerchantDetailsRequest(uuid) {
  try {
    const response = await apiInstance(`/api/store/${uuid}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
      };
    }
    return {
      success: false,
      error: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao carregar detalhes da loja.",
    };
  }
}

export async function getAppSettingsByMerchantRequest(uuid, appSlug) {
  try {
    const { status, data, error } = await apiInstance(`/api/store/${uuid}/app/${appSlug}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return {
        success: true,
        data: data.data,
      };
    }
    return {
      success: false,
      message: error.message,
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.response.status,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar configurações.",
    };
  }
}

export async function getAppsByUuidRequest(uuid) {
  try {
    const { status, data, error } = await apiInstance(`/api/store/${uuid}/app`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return { success: true, data };
    }

    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message: accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar apps.",
    };
  }
}

export async function callPipeDreamToSave(userEmail, eventType, saveData) {
  try {
    const { status, data, error } = await axios.post(`https://eo66rug41km84hi.m.pipedream.net`, {
      eventType,
      userEmail,
      ...saveData,
    });

    if (status === 200) {
      return { success: true, data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao salvar preferencias.",
    };
  }
}

// Stats
export async function getSalesStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/sales`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}

export async function getShippingStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/shipments`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}

export async function getPackageConsolidateStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/packages/consolidated`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}

export async function getExceptionsStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/problems`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}

export async function getProductsStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/products`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}

export async function getPackagesStatsRequest(storeUuid, params) {
  try {
    const { status, data, error } = await apiInstance(
      `/api/store/${storeUuid}/dashboard/stats/packages/detailed`,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (status === 200) {
      return { success: true, data: data.data };
    }
    return { success: false, message: error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar estatísticas.",
    };
  }
}
