import React from "react";
import { Switch, Route } from "react-router-dom";

// ROUTES
import Dashboard from "pages/main/dashboard";
import Home from "pages/main/home";

// => Orders
import Orders from "pages/main/orders";
import OrderDetail from "pages/main/orders/detail";

// => Returns
import Returns from "pages/main/returns";
import Return from "pages/main/returns/detail";

// => Message pool
import MessagePool from "pages/main/messages-pool";

// => App List
import AppsList from "pages/main/apps";
import AppContainer from "pages/main/apps/app-container-2";

// => Configs
import MerchantDetails from "pages/main/merchant/detail";
import ConfigIndex from "pages/main/config";
import Billing from "pages/main/config/billing";
import ConfigNotificationsIndex from "pages/main/config/notifications";
import ConfigNotificationsEdit from "pages/main/config/notifications/edit";

// Not Found
import NotFound from "pages/main/not-found";

// => Callbacks
import CallbackApps from "pages/main/apps/callback";

//= > Components
import routesList from "./routes-list";

function mergeRoute(rList) {
  return rList.map((r) => {
    switch (r.key) {
      case "create-merchant":
        r.component = MerchantDetails;
        break;

      case "home":
        r.component = Home;
        break;

      case "dashboard":
        r.component = Dashboard;
        break;

      case "orders":
        r.component = Orders;
        break;

      case "order":
        r.component = OrderDetail;
        break;

      case "apps":
        r.component = AppsList;
        break;

      case "appDetail":
        r.component = AppContainer;
        break;

      case "config-index":
        r.component = ConfigIndex;
        break;

      case "config-billing":
        r.component = Billing;
        break;

      case "config-merchant":
        r.component = MerchantDetails;
        break;

      case "config-notifications":
        r.component = ConfigNotificationsIndex;
        break;

      case "config-notifications-edit":
        r.component = ConfigNotificationsEdit;
        break;

      case "message-pool":
        r.component = MessagePool;
        break;

      case "callback-apps":
        r.component = CallbackApps;
        break;

      case "returns":
        r.component = Returns;
        break;

      case "return":
        r.component = Return;
        break;

      case "not-found":
        r.component = NotFound;
        break;

      default:
        throw Error("Rota não cadastrada");
    }

    return r;
  });
}

export default function Routes(props) {
  return (
    <Switch>
      {mergeRoute(routesList).map((route) => (
        <Route {...route} />
      ))}
    </Switch>
  );
}

// OLD ROUTES
// import Products from "pages/main/products";
// import Welcome from "pages/main/welcome";
// import UsersConfig from "pages/main/config/users";
// import UserDetails from "pages/main/config/users/create";
// import ProductDetail from "pages/main/products/detail";
