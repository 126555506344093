import { formatPrice } from "helpers";
import React, { useState, useEffect } from "react";

// Mask builders
function BRLMask(cleanValue) {
  const value = cleanValue;

  return formatPrice(value);
}

function BRLCleaner(value) {
  let val;

  if (typeof value === "number") {
    val = parseFloat(value).toFixed(2).replace(".", ",");
  } else {
    [val] = value === "" ? ["0"] : value.match(/([\d,.])+/g);
    val = val.replaceAll(".", "");
  }

  const commaPos = val.indexOf(",");
  const charsAfterComma = val.slice(commaPos + 1).length;

  // Was adding
  if (charsAfterComma > 2) {
    val = val.replace(",", "");
    val = [val.slice(0, val.length - 2), ",", val.slice(val.length - 2)].join("");
  }

  // Was deletting
  if (charsAfterComma < 2) {
    val = val.replace(",", "");
    val = [val.slice(0, commaPos - 1), ",", val.slice(commaPos - 1)].join("");
  }

  return val.replace(",", ".");
}

const builders = {
  BRL: BRLMask,
};

const cleaners = {
  BRL: BRLCleaner,
};

export default function MaskedInput({
  // Ons
  onBlur,
  onChange,
  onHandleKeyPress,

  // Builders
  maskBuilder,
  cleanFunction,
  enableReinitialize = false,

  // Field props
  type,
  value,
  name,
  className,

  ...props
}) {
  const _maskBuilder = typeof maskBuilder === "string" ? builders[maskBuilder] : maskBuilder;
  const limpa = typeof maskBuilder === "string" ? cleaners[maskBuilder] : cleanFunction;

  function aplicaMask(valor) {
    const valorLimpo = limpa(valor);

    const maskApplyied = _maskBuilder(valorLimpo, valor);

    return maskApplyied;
  }

  function exportValue(v) {
    if (maskBuilder === "BRL") {
      return limpa(v);
    }
    return v;
  }

  const [fValue, setValue] = useState(aplicaMask(value));

  useEffect(() => {
    if (onChange) onChange(name, exportValue(aplicaMask(value)));
  }, []);

  // Pega apaga
  const handleChange = (e) => {
    const valor = e.target.value;
    const masked = aplicaMask(valor);

    // Seta o valor display
    setValue(masked);

    // Seta o valor do field
    if (onChange) onChange(name, exportValue(masked));
  };

  useEffect(() => {
    if (enableReinitialize) {
      handleChange({ target: { value } });
    }
  }, [value]);

  return (
    <input
      type={type}
      className={className}
      name={name}
      value={fValue}
      onChange={handleChange}
      onBlur={onBlur}
      {...props}
    />
  );
}
