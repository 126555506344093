import React from "react";
import { Link } from "react-router-dom";
import { FaHistory } from "react-icons/fa";
import moment from "moment";
import classnames from "classnames";

// Comp
import ClickCopy from "components/click-copy";

// Assets
// import { ReactComponent as Aliexpressicon } from "images/app-icons/without-bg/aliexpress.svg";

function MobileList(props) {
  const { merchantId, orders } = props;

  return (
    <div className="order-list-wrapper mobile">
      {orders.map((order) => (
        <div key={order.uuid} className="order-item">
          <div className="order-summary">
            <strong className="f14">
              <Link to={`/${merchantId}/orders/${order.uuid}`}>{order.name}</Link>
            </strong>
            {order.customer?.firstName ? (
              <span
                className="customer-name f13"
                title={`${order.customer.firstName} ${order.customer.lastName}`}
              >
                {`${order.customer.firstName} ${order.customer.lastName}`}
              </span>
            ) : (
              <span className="customer-name pending-sync">Sem dados</span>
            )}
            <div className="order-date">
              <span className="date">{moment(order.createdAt).format("DD/MM/YYYY")}</span>
              <span className="ago-label gray">{moment(order.createdAt).fromNow()}</span>
            </div>

            <div className="f14 bold grey">{order.totalPriceWithDiscount.formated}</div>

            <span
              className={classnames(
                "package-status iumy-badge inline-block mb5",
                order.financialStatus.code
              )}
            >
              <span id="tooltip-package-status" href="#">
                {order.financialStatus.label}
              </span>
            </span>
          </div>
          {order.packages.length > 0 && (
            <ul className="packages">
              {order.packages.map((pkg) => (
                <li key={pkg.uuid} className="package-line-item">
                  <div className="package-tracking-code">
                    <span className="f12">
                      <a href={pkg.trackingUrl} className="maingreen vertical-middle">
                        {pkg.trackingNumber}
                      </a>
                      <ClickCopy copyText={pkg.trackingUrl} />
                    </span>

                    {pkg.originTrackingNumber && (
                      <span className="aliexpress-order-number block gray">
                        <FaHistory className="old-tracking-ico" /> {pkg.originTrackingNumber}
                      </span>
                    )}
                  </div>

                  <span
                    className={classnames(
                      "package-status iumy-badge inline-block mb5",
                      pkg.status.code
                    )}
                  >
                    <span id="tooltip-package-status" href="#">
                      {pkg.status.label}
                    </span>
                  </span>

                  <span className="package-status-date gray">
                    {moment(pkg.updatedAt).format("DD/MM/YYYY")}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}

export default MobileList;
