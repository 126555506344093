import { createReducer } from "reduxsauce";

// Types
import { Types } from "./actions";

const initialState = {
  list: [],
  loading: false,
};

const getProductsRequest = (state = initialState, action) => {
  return { ...state, loading: true };
};

const getProductsSuccess = (state = initialState, action) => {
  return {
    ...state,
    list: action.products,
    loading: false,
  };
};

export default createReducer(initialState, {
  [Types.GET_PRODUCTS_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
});
