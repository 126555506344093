import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import classnames from "classnames";
import qs from "qs";

// Componentes
import { accessDotNotation, swalMiddleware as Swal } from "helpers";
import PageTitle from "components/page-title";

import {
  doLoginRequest,
  doRecoveryRequest,
  doActivateAccountRequest,
} from "store/modules/auth/thunks";

// Assets
import logo from "images/logo-black.png";

export default function Login({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [recoveryFormState, setRecoveryFormState] = useState(false);
  const [lastEmailType, setLastEmail] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const redirectUrl = accessDotNotation(location, "state.referrer");

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Preencha o e-mail"),
    password: Yup.string()
      .min(5, "A senha deve ter entre 8 e 21 characteres")
      .max(21, "A senha deve ter entre 8 e 21 characteres")
      .required("Preencha a senha"),
  });

  const doRecovery = async (values) => {
    const result = await dispatch(doRecoveryRequest(values.email));

    if (result.success) {
      setRecoveryFormState(false);

      Swal.fire({
        title: "Verifique seu e-mail!",
        text: "Enviamos o link de recuperação de senha para o seu e-mail!",
        icon: "success",
        showConfirmButton: false,
        timer: 5000,
        heightAuto: false,
      });
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const doLogin = async (values) => {
    setIsLogging(true);
    const result = await dispatch(doLoginRequest(values.email, values.password));

    if (!result.success) {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      return setIsLogging(false);
    }

    const redurl = redirectUrl.indexOf("onboarding") > -1 ? "/" : redirectUrl;
    return history.push(redurl || "/");
  };

  useEffect(() => {
    async function effect() {
      //
      if (history.location.state && history.location.state.signupSuccess) {
        const state = { ...history.location.state };
        delete state.signupSuccess;
        history.replace({ ...history.location, state });

        Swal.fire({
          title: "Verifique seu e-mail para ativar sua conta!",
          text: "Acesse seu e-mail para confirmar seu cadastro.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          heightAuto: false,
        });
      }

      // Activate account
      if (history.location.pathname === "/activate") {
        const queryString = qs.parse(history.location.search, {
          ignoreQueryPrefix: true,
        });

        const validateAccountReq = await dispatch(doActivateAccountRequest(queryString.token));

        if (validateAccountReq.success) {
          await Swal.fire({
            title: "Conta ativada com sucesso!",
            text: "Sua conta está ativa e pronta para uso! Faça login para continuar! 🚀",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false,
          });
        } else {
          await Swal.fire({
            title: "Não foi possível ativar sua conta!",
            text: "Parece que esse token é inválido.",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            heightAuto: false,
          });
        }

        history.push("/", {});
      }
    }

    effect();
  }, []);

  return (
    <div className="auth-screen login">
      <PageTitle pageTitle="Entrar" />

      <div className="box">
        <div className="aside-side">
          <div className="mb40">
            <img src={logo} alt="iumy" width="146" />
          </div>

          <div className="panels-switcher">
            {recoveryFormState ? (
              <div className={classnames("panel recovery-password active")}>
                <h1>Recuperar senha</h1>
                <p className="f16 black-40">Digite seu e-mail da conta</p>

                <Formik
                  initialValues={{
                    email: lastEmailType,
                  }}
                  onSubmit={doRecovery}
                >
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <Field
                          name="email"
                          placeholder="john@iumy.com.br"
                          className="form-control"
                        />
                        <ErrorMessage className="error-message" name="name" />
                      </div>

                      <button type="submit" className="btn btn-block green size--large btn-submit">
                        Recuperar
                        <div className="dots">
                          <div className="dot dot-1" />
                          <div className="dot dot-2" />
                          <div className="dot dot-3" />
                        </div>
                      </button>

                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setRecoveryFormState(!recoveryFormState);
                        }}
                        className="btn btn-block btn-link"
                      >
                        Voltar
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <div className={classnames("panel auth-login active")}>
                <h1>Identifique-se</h1>
                <p className="f16 black-40">Digite seu e-mail e senha</p>

                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={loginSchema}
                  onSubmit={doLogin}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className={classnames("form-control", {
                            error: errors.email,
                          })}
                          placeholder="john@iumy.com.br"
                          tabIndex="1"
                          onChange={(e) => {
                            handleChange(e);
                            setLastEmail(e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          placeholder="Digite sua senha"
                          tabIndex="2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          className={classnames("form-control", {
                            error: errors.password,
                          })}
                        />
                        {errors.password && touched.password ? (
                          <div className="error-message">{errors.password}</div>
                        ) : null}
                        <button
                          type="button"
                          onClick={() => setRecoveryFormState(!recoveryFormState)}
                          className="f13 btn btn-text"
                        >
                          Esqueci minha senha
                        </button>
                      </div>

                      <div className="d-grid">
                        <button
                          type="submit"
                          className={`${
                            isLogging ? "sending" : ""
                          }  btn  green size--large btn-submit`}
                        >
                          {!isLogging ? "Entrar" : ""}
                          <div className="dots black">
                            <div className="dot dot-1" />
                            <div className="dot dot-2" />
                            <div className="dot dot-3" />
                          </div>
                        </button>
                      </div>

                      <hr />
                      <p className="text-center black-40 mb0">
                        Ainda não é cadastrado? <br />
                        Então <Link to="/signup">clique aqui</Link> e junte-se a nós!
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>

        <div className="background-side" />
      </div>
      <ToastContainer />
    </div>
  );
}
