import React, { useState, useRef } from "react";
import moment from "moment";
import { FaUserAlt, FaTimes, FaBell } from "react-icons/fa";
import { SingleDatePicker } from "react-dates";

// import OrderNote from "models/order-note";
import OrderNoteReminder from "models/order-note-reminder";

import { useOutsideClick } from "helpers";
import {
  updateOrderNote,
  deleteOrderNote,
  addOrderNoteReminder,
  removeOrderNoteReminder,
} from "store/modules/orders/actions";
import AutoTextArea from "components/autotextarea";
import DropdownButton from "components/dropdown-button";
import Status from "models/tracking/status";

function OrderNoteItem({ storeUuid, orderUuid, note, removeHandler }) {
  const [state, setState] = useState({
    editing: false,
    submiting: false,
    deleteBoxOpened: false,
    deleting: false,
    backupNoteContent: note.content,

    // Note Item
    note,

    // Controles of Reminder
    togglingReminder: false,
    showStatusReminders: false,
    datePickerFocused: false,
    reminderTimeHour: 9,
    reminderDate: null,
  });

  const enabledHours = (() => {
    let c = 0;
    let arr = [];
    while (c < 24) {
      arr.push(c);
      c++;
    }

    return arr;
  })();

  const reminderStatus = [
    "TRACKING_CODE_CHANGED",
    "IN_ROUTE_TO_DELIVERY",
    "DELIVERED",
    "WAITING_WITHDRAW",
    "RETURNED_TO_SENDER",
    "TAXED",
    "IS_LATE",
  ].map((s) => new Status(s));

  const toggleDeleteBox = () => {
    setState((oldState) => ({
      ...oldState,
      deleteBoxOpened: !oldState.deleteBoxOpened,
    }));
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleDeleteBox);

  const editNote = () => {
    setState((oldState) => ({
      ...oldState,
      backupNoteContent: oldState.note.content,
      editing: true,
    }));
  };

  const cancelEdit = () => {
    setState((oldState) => ({
      ...oldState,
      editing: false,
      note: {
        ...oldState.note,
        content: oldState.backupNoteContent,
      },
    }));
  };

  const deleteComment = async () => {
    setState((oldState) => ({
      ...oldState,
      deleting: true,
    }));

    const deleteResult = await await deleteOrderNote(storeUuid, orderUuid, note.uuid);

    if (deleteResult.success) {
      return removeHandler(note.uuid);
    }

    // TODO ERROR
    return setState((oldState) => ({
      ...oldState,
      deleting: false,
    }));
  };

  const submitHandler = async () => {
    setState((oldState) => ({
      ...oldState,
      submiting: true,
    }));

    const result = await updateOrderNote(storeUuid, orderUuid, note.uuid, state.note.content);

    if (result.success) {
      setState((oldState) => ({
        ...oldState,
        editing: false,
        submiting: false,
      }));
    }
  };

  const toggleStatusReminderSelector = (show) => {
    const status = typeof show != undefined ? show : !state.showStatusReminders;

    setState((oldState) => ({
      ...oldState,
      showStatusReminders: status,
    }));
  };

  const createReminder = async (type, data) => {
    setState((oldState) => ({
      ...oldState,
      togglingReminder: true,
      reminderTimeHour: 9,
      reminderDate: null,
      datePickerFocused: false,
    }));

    let options;

    switch (type) {
      case "DATE": {
        options = {
          trigger_type: "DATE",
          dateTrigger: data,
        };
        break;
      }

      case "PACKAGE_STATUS": {
        options = {
          trigger_type: "PACKAGE_STATUS",
          package_status_trigger: data,
        };
        break;
      }

      default:
        return false;
    }

    const createReminderResult = await addOrderNoteReminder(
      storeUuid,
      orderUuid,
      note.uuid,
      options
    );

    if (createReminderResult.success) {
      setState((oldState) => ({
        ...oldState,
        note: {
          ...oldState.note,
          reminder: new OrderNoteReminder(createReminderResult.data),
        },
      }));
    }

    setState((oldState) => ({
      ...oldState,
      togglingReminder: false,
    }));
  };

  const removeReminder = async (reminderUuid) => {
    setState((oldState) => ({
      ...oldState,
      togglingReminder: true,
    }));

    const removeReminderResult = await removeOrderNoteReminder(
      storeUuid,
      orderUuid,
      note.uuid,
      reminderUuid
    );

    if (removeReminderResult.success) {
      setState((oldState) => ({
        ...oldState,
        note: {
          ...oldState.note,
          reminder: null,
        },
      }));
    }

    setState((oldState) => ({
      ...oldState,
      togglingReminder: false,
    }));
  };

  return (
    <div className="order-note-item">
      <div className="avatar-column">
        <div className="avatar-wrap">
          <span className="placeholder">
            <FaUserAlt />
          </span>
        </div>
      </div>

      <div className="order-note-item--content-wrap">
        <div className="order-note-item--heading">
          <strong className="order-note-item--author-name">{state.note.createdByUserName}</strong>{" "}
          comentou em {moment(note.createdAt).format("DD/MM/YYYY - HH:mm")}
        </div>
        <div className="order-note-item--content box-widget-mini">
          {!state.editing ? (
            <span>{state.note.content}</span>
          ) : (
            <AutoTextArea
              name="content"
              className="textarea"
              placeholder="Escrever um comentário..."
              rows={1}
              defaultValue={state.note.content}
              onChange={(e) => {
                e.persist();
                setState((oldState) => ({
                  ...oldState,
                  note: { ...oldState.note, content: e?.target?.value },
                }));
              }}
              onFocus={() => setState((oldState) => ({ ...oldState, inputFocused: true }))}
              onBlur={() => setState((oldState) => ({ ...oldState, inputFocused: false }))}
            />
          )}

          {state.editing && (
            <ul className="order-note-editing--actions">
              <li>
                <button
                  type="button"
                  className="btn green size--small"
                  disabled={state.backupNoteContent === state.note.content || state.submiting}
                  onClick={submitHandler}
                >
                  Salvar
                </button>
              </li>

              <li>
                <button type="button" className="btn-text" onClick={cancelEdit}>
                  Cancelar
                </button>
              </li>
            </ul>
          )}
        </div>
        {!state.editing && (
          <div className="order-note-item--foot">
            <ul className="order-note-item--actions">
              <>
                <li>
                  <button type="button" className="btn-text" onClick={editNote}>
                    Editar
                  </button>
                </li>
                <li>
                  <button type="button" className="btn-text" onClick={toggleDeleteBox}>
                    Excluir
                  </button>

                  {state.deleteBoxOpened && (
                    <div className="pop-over" ref={wrapperRef}>
                      <div className="pop-over-header js-pop-over-header">
                        <span className="pop-over-header-title">Excluir Comentário?</span>
                        <button
                          type="button"
                          className="pop-over-header-close-btn"
                          onClick={toggleDeleteBox}
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <div className="pop-over-content">
                        <p>A exclusão de um comentário é permanente. Não é possível desfazer.</p>
                        <button
                          className="btn danger-2 remove-comment-btn"
                          type="button"
                          onClick={deleteComment}
                          disabled={state.deleting}
                        >
                          {state.deleting ? "Removendo..." : "Excluir Comentário"}
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              </>
            </ul>

            {!state.togglingReminder ? (
              <div
                className={`order-note-item--reminder-wrap ${
                  state.showStatusReminders ? "reminder-status" : ""
                }`}
              >
                {!state.note.reminder ? (
                  <DropdownButton
                    classes="btn-add-reminder btn-text"
                    label="+ Adicionar lembrete"
                    container="div"
                    closeOnClick={false}
                    useChevs={false}
                    onClickCb={() => toggleStatusReminderSelector(false)}
                  >
                    <>
                      {!state.showStatusReminders ? (
                        // Seletor de reminder
                        <div className="reminder-type-selector">
                          <span className="reminder-label">Tipo de lembrete</span>

                          <SingleDatePicker
                            numberOfMonths={1}
                            withPortal
                            date={state.reminderDate}
                            placeholder="Lembrete por Data"
                            onDateChange={(date) => {
                              setState((oldState) => ({ ...oldState, reminderDate: date }));
                            }} // PropTypes.func.isRequired
                            hideKeyboardShortcutsPanel
                            focused={state.datePickerFocused} // PropTypes.bool
                            small
                            block
                            renderCalendarInfo={() => (
                              <div className="hour-select-wrapper">
                                <div className="selector-line">
                                  <span className="the-label">Hora:</span>
                                  <select
                                    name="from"
                                    id="from"
                                    className="form-control"
                                    onChange={(e) => {
                                      const reminderTimeHour = e.target.value;

                                      setState((oldState) => ({
                                        ...oldState,
                                        reminderTimeHour,
                                      }));
                                    }}
                                  >
                                    {enabledHours.map((h) => (
                                      <option value={h} selected={h == 9}>
                                        {String(h).padStart(2, "0")}:00
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    type="button"
                                    className="btn green"
                                    disabled={!state.reminderDate}
                                    onClick={() => {
                                      const updatedDate = state.reminderDate
                                        .set("hour", state.reminderTimeHour)
                                        .format("YYYY-MM-DD[T]HH:00:00-0300");
                                      createReminder("DATE", updatedDate);
                                    }}
                                  >
                                    <FaBell />
                                  </button>
                                </div>

                                <button
                                  type="button"
                                  className="btn-text"
                                  onClick={() => {
                                    setState((oldState) => ({
                                      ...oldState,
                                      reminderTimeHour: 9,
                                      reminderDate: null,
                                      datePickerFocused: false,
                                    }));
                                  }}
                                >
                                  Cancelar
                                </button>
                              </div>
                            )}
                            noBorder
                            onFocusChange={({ focused }) => {
                              if (!focused) return;

                              setState((oldState) => ({
                                ...oldState,
                                datePickerFocused: focused,
                              }));
                            }}
                          />

                          <button type="button" onClick={toggleStatusReminderSelector}>
                            Lembrete por Status
                          </button>
                        </div>
                      ) : (
                        // Containers de reminder
                        <div className="status-reminder-selector">
                          {reminderStatus.map((statusObj) => (
                            <button
                              type="button"
                              onClick={() => createReminder("PACKAGE_STATUS", statusObj.code)}
                            >
                              <span className={`checkbox-label iumy-badge ${statusObj.code}`}>
                                {statusObj.label}
                              </span>
                            </button>
                          ))}
                        </div>
                      )}
                    </>
                  </DropdownButton>
                ) : (
                  <>
                    {state.note.reminder.status !== "NOTIFIED" ? (
                      <button
                        type="button"
                        className="remove-reminder-btn"
                        onClick={() => removeReminder(state.note.reminder.uuid)}
                      >
                        <span>
                          <FaBell className="bell" />
                          {state.note.reminder.triggerType === "DATE" ? (
                            `Lembrete agendado para em ${moment(
                              state.note.reminder.dateTrigger
                            ).format("DD/MM/YYYY [às] HH:mm")}`
                          ) : (
                            <>
                              Lembrete agendado para
                              <span
                                className={`checkbox-label iumy-badge ${state.note.reminder.packageStatusTrigger.code}`}
                              >
                                {state.note.reminder.packageStatusTrigger.label}
                              </span>
                            </>
                          )}
                        </span>
                        <span className="time-btn">
                          <FaTimes className="remove-icon" />
                        </span>
                      </button>
                    ) : (
                      <span className="reminder-notified">
                        {state.note.reminder.packageStatusTrigger && (
                          <span
                            className={`checkbox-label iumy-badge ${state.note.reminder?.packageStatusTrigger?.code}`}
                          >
                            {state.note.reminder?.packageStatusTrigger?.label}
                          </span>
                        )}

                        {state.note.reminder.triggerType !== "PACKAGE_STATUS"
                          ? "Notificado em "
                          : "em "}
                        {moment(state.note.reminder?.notifiedAt).format("DD/MM/YYYY [às] HH:mm")}
                      </span>
                    )}
                  </>
                )}
              </div>
            ) : (
              <span className="loading-reminder">Carregando...</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderNoteItem;
