import { immerable } from "immer";
import { formatPrice } from "helpers";

export default class Price {
  constructor(price, currency = "BRL") {
    this[immerable] = true;

    this.raw = price;
    this.formated = formatPrice(price, currency);
  }

  static format(price, currency) {
    return formatPrice(price, currency);
  }
}
