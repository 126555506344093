import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { Tooltip } from "reactstrap";

function ClickCopy({ copyText, size, icon }) {
  const Icon = icon || FaCopy;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copiar");
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipId] = useState(`Tooltip-${Math.random().toString(36).substring(2, 5)}`);

  return (
    <button
      type="button"
      className="click-copy"
      onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(copyText);
        setTooltipText("Copiado!");

        setTimeout(() => setTooltipText("Copiar"), 1000);
      }}
    >
      <Icon size={size} id={tooltipId} />

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={tooltipId}
        trigger="hover"
        toggle={toggle}
      >
        {tooltipText}
      </Tooltip>
    </button>
  );
}

export function ClickCopyChildren({ children, copyText }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copiar");
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipId] = useState(`Tooltip-${Math.random().toString(36).substring(2, 5)}`);

  return (
    <button
      type="button"
      className="click-copy"
      onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(copyText);
        setTooltipText("Copiado!");

        setTimeout(() => setTooltipText("Copiar"), 1000);
      }}
    >
      <div id={tooltipId}>{children}</div>

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={tooltipId}
        trigger="hover"
        toggle={toggle}
      >
        {tooltipText}
      </Tooltip>
    </button>
  );
}

export default ClickCopy;
