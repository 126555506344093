import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { DayPickerRangeController, isInclusivelyBeforeDay } from "react-dates";
import { START_DATE } from "react-dates/constants";
import { useOutsideClick } from "helpers";

export default (props) => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const [state, setState] = useState({
    display: false,
    focusedInput: START_DATE,
    isMobile: vw < 768,
    calendarOptions: {
      startDate: moment(props.startDate || new Date()),
      endDate: props.endDate ? moment(props.endDate) : null,
    },
  });

  const labelRef = useRef();
  const ref = useRef(null);

  const handleClickOutside = () =>
    setState((oldState) => ({
      ...oldState,
      display: false,
    }));

  useOutsideClick(ref, handleClickOutside);

  const setStyles = (cb, display = false) => {
    const callback = typeof cb === "function" ? cb : () => {};

    const el = labelRef.current;
    // Get queried DOM element's values (top, right, left, width, etc.)
    const bounds = el && el.getBoundingClientRect();
    // console.log('set', bounds, this.state);

    if (bounds) {
      const pos = {
        top: Math.floor(bounds.top) + bounds.height + 5,
      };

      const right = window.innerWidth - bounds.right;

      if (right < 600) pos.left = bounds.left - 619 + bounds.width;
      else if (bounds.left < 600) pos.left = bounds.left;

      setState((oldState) => ({
        ...oldState,
        ...pos,
        display,
      }));

      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setStyles);

    return () => window.removeEventListener("resize", setStyles);
  }, []);

  useEffect(() => {
    setState((oldState) => ({
      ...oldState,
      calendarOptions: {
        startDate: typeof props.startDate === "string" ? moment(props.startDate) : props.startDate,
        endDate: typeof props.endDate === "string" ? moment(props.endDate) : props.endDate,
      },
    }));
  }, [props.startDate, props.endDate]);

  // Airbnb
  const onFocusChange = (focusedInput) => {
    setState((oldState) => ({
      ...oldState,
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    }));
  };

  const getStyles = () => {
    const { top, bottom, left, right, display, isMobile } = state;

    const styles = {
      top: top || "auto",
      bottom: bottom || "auto",

      left: left || "auto",
      right: right || "auto",
      position: "fixed",
      zIndex: 1,
    };

    if (isMobile) {
      styles.left = 0;
      styles.top = 0;
      styles.width = "100%";
      styles.height = "100%";
    }

    // Hide tutorial tip during transitions to prevent flickering. (We'll talk about this later)
    if (!display) {
      styles.display = "none";
    }

    return styles;
  };

  const showCalendar = () => {
    setStyles(null, true);
  };

  const clear = () => {
    setState((oldState) => ({
      ...oldState,
      calendarOptions: {
        startDate: null,
        endDate: null,
      },
    }));

    return props.onSelect([]);
  };

  const styles = getStyles();
  const { label, onSelect, className, enableClear } = props;
  const { display, calendarOptions, focusedInput, isMobile } = state;

  return (
    <span className="calendar-container">
      <span ref={labelRef} role="button" className={className} onClick={showCalendar}>
        {label}
      </span>
      <div ref={ref} style={styles}>
        {display && (
          <div className="calendar-wrapper">
            <DayPickerRangeController
              minimumNights={0}
              numberOfMonths={2}
              startDate={calendarOptions.startDate}
              endDate={calendarOptions.endDate}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              hideKeyboardShortcutsPanel
              focusedInput={focusedInput}
              onFocusChange={onFocusChange}
              orientation={isMobile ? "vertical" : "horizontal"}
              onDatesChange={(event) => {
                const start = event.startDate;
                const end = event.endDate;

                if (!end)
                  return setState((oldState) => ({
                    ...oldState,
                    calendarOptions: {
                      ...oldState.calendarOptions,
                      startDate: moment(start),
                    },
                  }));

                if (state.calendarOptions.startDate && state.calendarOptions.endDate)
                  return setState((oldState) => ({
                    ...oldState,
                    calendarOptions: {
                      startDate: moment(start),
                      endDate: null,
                    },
                  }));

                setState((oldState) => ({
                  ...oldState,
                  display: false,
                }));

                return onSelect({
                  endDate: moment(end).format("YYYY-MM-DD"),
                  startDate: moment(start).format("YYYY-MM-DD"),
                });
              }}
            />
            {enableClear && calendarOptions.startDate && calendarOptions.endDate && (
              <div className="footer-actions active">
                <button type="button" className="clear-button" onClick={clear}>
                  Limpar
                </button>
              </div>
            )}
          </div>
        )}
        <div className="mobile-backdrop" onClick={() => handleClickOutside()} />
      </div>
    </span>
  );
};
