import React, { useState, forwardRef } from "react";
import { Form, Formik } from "formik";

export const WizardStep = ({ children }) => children;

const SignupWizard = forwardRef(({ children, initialValues, onSubmit, submitButtonClass }, ref) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  // const previous = (values) => {
  //   setSnapshot(values);
  //   setStepNumber(Math.max(stepNumber - 1, 0));
  // };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    }
    bag.setTouched({});
    next(values);
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => {
        if (ref) ref.current = { setFieldValue: formik.setFieldValue };

        return (
          <Form>
            {step}

            <div className="d-grid">
              <button className={submitButtonClass} disabled={formik.isSubmitting} type="submit">
                {isLastStep ? "Finalizar" : "Avançar"}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});

export default SignupWizard;
