import React from "react";
import Tooltip from "components/tooltip";

function DaysCounter({ order, moves }) {
  const todayDate = new Date();
  const orderPaidTimestamp =
    new Date(order.paidAt).getTime() || new Date(order.createdAt).getTime();

  const firstMoveFallBack = moves[moves.length - 1];
  const firstMoveTimestamp = firstMoveFallBack
    ? new Date(firstMoveFallBack.date).getTime()
    : todayDate.getTime();

  // const postedMove = props.moves.find((move) => move.status === "POSTED");
  const postedMoveTimestamp = firstMoveTimestamp; //  postedMove ? new Date(postedMove.date).getTime() : firstMoveTimestamp;

  const deliveredMove = moves.find((move) => move.status === "DELIVERED");
  const deliveredMoveTimestamp = deliveredMove
    ? new Date(deliveredMove.date).getTime()
    : todayDate.getTime();

  const daysBeforePost = Math.round(
    (postedMoveTimestamp - orderPaidTimestamp) / (1000 * 3600 * 24)
  );
  const daysUntilDelivery = Math.round(
    (deliveredMoveTimestamp - orderPaidTimestamp) / (1000 * 3600 * 24)
  );

  return (
    <ul className="shipment-days">
      <Tooltip
        containerElm="li"
        id="daysBeforePost"
        content={daysBeforePost}
        toolTipText="Número de dias do pagamento até a postagem."
      />
      <Tooltip
        containerElm="li"
        id="daysUntilToday"
        content={daysUntilDelivery}
        toolTipText="Número de dias corridos do pagamento até a entrega."
      />
    </ul>
  );
}

export default DaysCounter;
