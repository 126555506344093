import { createReducer } from "reduxsauce";
import produce from "immer";

import Notification from "models/notification";
import { pushIfNotExists } from "helpers";
import { Types } from "./actions";

const initialState = {
  list: [],
  loading: false,
  unreadQty: 0,
};

const doLoadNotificationsSuccess = (state = initialState, action) => {
  const { reset, data } = action.payload;
  let notificationsData = data.data || [];
  const unreadQty = data.metadata.unreadNotifications;

  return produce(state, (draft) => {
    draft.loading = false;
    draft.unreadQty = unreadQty;

    notificationsData = notificationsData.map((notification) => {
      const ntfy = new Notification(notification);
      if (!reset) pushIfNotExists(draft.list, "uuid", ntfy);

      return ntfy;
    });

    if (reset) draft.list = notificationsData;
  });
};

const doLoadNotificationsFailure = (state = initialState, action) => ({
  ...state,
  loading: false,
});

const doMarkNotificationReadSuccess = (state = initialState, action) =>
  produce(state, (draft) => {
    const foundNotificationIndex = draft.list.findIndex(
      (notification) => notification.uuid === action.payload.notificationUuid
    );

    if (foundNotificationIndex !== undefined) {
      draft.list[foundNotificationIndex].isRead = true;
      draft.unreadQty -= 1;
    }
  });

const doMarkAllNotificationsReadSuccess = (state = initialState, action) =>
  produce(state, (draft) => {
    draft.list.map((n) => {
      n.isRead = true;
      return n;
    });

    draft.unreadQty = 0;
  });

export default createReducer(initialState, {
  [Types.DO_LOAD_NOTIFICATIONS_SUCCESS]: doLoadNotificationsSuccess,
  [Types.DO_LOAD_NOTIFICATIONS_FAILURE]: doLoadNotificationsFailure,
  [Types.DO_MARK_NOTIFICATION_READ_SUCCESS]: doMarkNotificationReadSuccess,
  [Types.DO_MARK_ALL_NOTIFICATIONS_READ_SUCCESS]: doMarkAllNotificationsReadSuccess, // TODO ANY IN CASE OF FAILURE -> LOG?
});
