import React, { useState } from "react";
import classnames from "classnames";
import { FaBox, FaHistory } from "react-icons/fa";
import moment from "moment";
import ClickCopy from "components/click-copy";
// import { ReactComponent as Aliexpressicon } from "images/app-icons/without-bg/aliexpress.svg";
import PackageMove from "./package-move";

function MobileTrackingLine({ packageItem, orderReferenceId }) {
  const [showingMore, showMore] = useState(false);

  return (
    <div className="tracking-item box-widget-mini">
      <main>
        <div className="tracking-summary">
          <ul className="tracking-details-list">
            <li>
              <span className="bold uppercase f11 gray block mb5">Status</span>
              <span
                className={classnames("iumy-badge f10 mb5 inline-block", packageItem.status.code)}
              >
                {packageItem.status.label}
              </span>
              <span className="f12 gray">
                {" "}
                -{" "}
                {moment(
                  packageItem.lastMove ? packageItem.lastMove.date : packageItem.createdAt
                ).format("DD/MM/YYYY")}
              </span>
            </li>

            <li>
              <span className="bold uppercase f11 gray">Transportadora</span>
              <span className="bold f13 black block">{packageItem.trackingCompany}</span>
            </li>

            <li>
              <span className="bold uppercase f11 gray">Código</span>
              <span className="bold f13 black block">
                <FaBox /> {packageItem.trackingNumber}{" "}
                <ClickCopy copyText={packageItem.trackingNumber} />
              </span>

              {packageItem.keyTrackingNumber && (
                <div className="origin-tracking-no block gray">
                  <FaHistory className="old-tracking-ico" /> {packageItem.keyTrackingNumber}
                </div>
              )}
            </li>

            {/* <li>
              <span className="bold uppercase f11 gray">
                Aliexpress Order Nº
              </span>
              <span className="f13 bold black block aliexpress-order-no">
                <Aliexpressicon className="aliexpress-ico" />{" "}
                {packageItem.aliexpressOrderNumber}{" "}
                <ClickCopy copyText={packageItem.aliexpressOrderNumber} />
              </span>
            </li> */}

            {packageItem.trackingUrl && (
              <li>
                <span className="bold uppercase f11 gray">Link de Rastreio</span>
                <span className="bold f13 black block">
                  <a href={packageItem.trackingUrl}>
                    {packageItem.trackingUrl}
                    <ClickCopy copyText={packageItem.trackingUrl} />
                  </a>
                </span>
              </li>
            )}

            <li className="f13">
              <span className="bold uppercase f11 gray block">Última movimentação</span>
              <div className="track-last-move">
                {packageItem.lastMove && <PackageMove move={packageItem.lastMove} />}
              </div>
            </li>
          </ul>
        </div>

        <div
          className={classnames("tracking-details", {
            open: showingMore,
          })}
        >
          <ul className="track-moves">
            {packageItem.moves.slice(1).map((move, index) => (
              <li className="f13" key={index}>
                <PackageMove move={move} />
              </li>
            ))}
          </ul>
        </div>

        <div className="view-more" onClick={() => showMore(!showingMore)}>
          {!showingMore ? "Mais informações" : "Ocultar informações"}
        </div>
      </main>
    </div>
  );
}

export default MobileTrackingLine;
