import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImage } from "services/methods";
import { FaTrash } from "react-icons/fa";
import classnames from "classnames";

import LoadingDots from "components/loading-dots";

const Thumb = ({ file, onClickRemove }) => {
  const removeCb = onClickRemove || (() => {});

  return (
    <div className="thumb">
      <img src={file.preview} alt={file.name} />
      <button
        type="button"
        className="remove-file"
        aria-label="Remover arquivo"
        onClick={() => removeCb(file)}
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default function ImageUploader(props) {
  const { containerClass, maxFiles, onUpload, onRemove, preload, hideThumbs } = props;
  const uploadCb = onUpload || (() => {});
  const removeCb = onRemove || (() => {});
  const loadedImg = [];

  if (preload && preload.length) {
    preload.forEach((image) => {
      if (image)
        loadedImg.push({
          preview: image,
          id: image,
        });
    });
  }

  const [images, setImages] = useState(loadedImg);
  const [loading, setLoading] = useState(false);

  // On Drop upload and assing preview and ID.
  const onDrop = async (acceptedFiles) => {
    if (images.length > 0) return;
    setLoading(true);

    const arrPromise = acceptedFiles.map(async (file) => {
      const uploadResult = await uploadImage(file);

      if (uploadResult.success) {
        const newArr = [
          ...images,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileUrl: uploadResult.data.file,
            id: uploadResult.data.key,
          }),
        ];

        setImages(newArr);

        // Call above method
        uploadCb({ success: true, data: { id: file.id, file: uploadResult.data.file } });
      } else {
        uploadCb({ success: false });
      }
    });

    await Promise.all(arrPromise);

    setLoading(false);
  };

  const removeImage = (id) => {
    setImages(images.filter((image) => image.id !== id));

    return removeCb(id);
  };

  // Configure Dropzone
  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    noClick: true,
    maxFiles: maxFiles || 1,
    accept: "image/jpeg, image/png",
    onDropAccepted: onDrop,
  });

  return (
    <section className={classnames(containerClass, isDragAccept ? "active" : "")}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        {images.length === 0 && !loading && (
          <p className="default-message">
            Arraste o arquivo ou{" "}
            <button className="btn btn-text font-inherit" type="button" onClick={open}>
              clique aqui.
            </button>
          </p>
        )}

        {images.length > 0 &&
          !hideThumbs &&
          images.map((thumb, index) => (
            <Thumb onClickRemove={() => removeImage(thumb.id)} file={thumb} key={thumb.id} />
          ))}

        {images.length > 0 && hideThumbs && (
          <p className="default-message">
            <button
              type="button"
              className="btn btn-text font-inherit"
              onClick={() => removeImage(images[0].id)}
            >
              Remover imagem
            </button>
          </p>
        )}

        {loading && <LoadingDots type="dark" />}
      </div>
    </section>
  );
}
