import React from "react";
import classnames from "classnames";
import { Link, matchPath } from "react-router-dom";
import { connect } from "react-redux";
import { FaChevronLeft, FaWhatsapp, FaEnvelope, FaTicketAlt } from "react-icons/fa";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { format } from "@flasd/whatsapp-formatting";
import _ from "lodash";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";

// Helpers
import { renderRoute } from "pages/main/routes-list";

// Components
import LoaderBG from "components/loader-bg";
import PageTitle from "components/page-title";
import { ClickCopyChildren } from "components/click-copy";
import Modal from "components/modal";
import {
  getNotificationsTemplateById,
  updateNotificationTemplate,
  previewNotificationTemplate,
} from "store/modules/notifications/actions";
import NotificationTemplate from "models/notification-template";
import { accessDotNotation } from "helpers/index";
import CustomQuillEditor from "./components/custom-quill";

function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(`0x${p1}`)
    )
  );
}

class EditNotification extends React.Component {
  constructor(props) {
    super(props);

    const tpl = new NotificationTemplate();

    this.appendValition = {};

    this.state = {
      loading: true,
      template: tpl,
      formikData: tpl,
      previewOpened: false,
      previewContent: null,
      validationSchema: Yup.object({ ...this.appendValition }),
    };

    this.formRef = React.createRef();
  }

  async componentDidMount() {
    const { currentMerchant, location } = this.props;

    const { params } = matchPath(location.pathname, {
      path: "/:merchantId/config/notifications/:notificationId",
      exact: false,
    });

    const notificationTemplateRequest = await getNotificationsTemplateById(
      currentMerchant.uuid,
      params.notificationId
    );

    if (!notificationTemplateRequest.success) toast.error("Falha ao carregar essa template.");

    const tpl = new NotificationTemplate(notificationTemplateRequest.data);

    this.setState({
      // ...state,
      loading: false,
      template: tpl,
      disableCancel: true,
      validationSchema: Yup.object({
        ...this.appendValition,
        ...(tpl.channel !== "WHATSAPP" && { subject: Yup.string().required("Campo obrigatório") }),
      }),
    });
  }

  async previewTemplate(template) {
    const { currentMerchant } = this.props;
    let previewContent;
    let previewMessage = "";

    if (template) {
      const subject = accessDotNotation(this.formRef, "current.values.subject") || template.subject;
      const contenta =
        accessDotNotation(this.formRef, "current.values.content") || template.content;

      const { success, data } = await previewNotificationTemplate(
        currentMerchant.uuid,
        template.uuid,
        {
          subject,
          content: contenta,
        }
      );

      if (success) {
        previewContent =
          template.channel === "EMAIL"
            ? b64EncodeUnicode(data.renderedEmail.replace("\n", "<br />"))
            : data.content;
      }

      const content =
        template.channel === "WHATSAPP" ? (
          <span className="baloon" dangerouslySetInnerHTML={{ __html: format(previewContent) }} />
        ) : (
          <iframe
            src={`data:text/html;base64,${previewContent}`}
            title="Pré visualização"
            height="500"
          />
        );
      previewMessage = (
        <div className={template.channel === "WHATSAPP" ? "whatsapp-preview" : "email-preview"}>
          {content}
        </div>
      );
    }

    this.setState((state) => ({
      ...state,
      previewContent: previewMessage || null,
      previewOpened: !state.previewOpened,
    }));

    // Set Modal open
  }

  handleCancel() {
    this.formRef.current.resetForm();
    this.setState((state) => ({
      ...state,
      disableCancel: true,
    }));
  }

  async handleSubmit(values) {
    const { currentMerchant } = this.props;
    this.setState((state) => ({
      ...state,
      submiting: true,
    }));

    const updateTemplateRequest = await updateNotificationTemplate(
      currentMerchant.uuid,
      values.uuid,
      values
    );

    if (!updateTemplateRequest.success)
      toast.error("Ocorreu um erro ao atualizar o template, por favor tente novamente.");

    toast.success("Template atualizado.");
    this.setState((state) => ({
      ...state,
      template: new NotificationTemplate(values),
      submiting: false,
      disableCancel: true,
    }));
  }

  render() {
    const {
      template,
      loading,
      disableCancel,
      submiting,
      previewOpened,
      previewContent,
      validationSchema,
    } = this.state;
    const { currentMerchant } = this.props;
    const channelLabel = template.channel === "WHATSAPP" ? "Whatsapp" : "Email";
    const Icon = template.channel === "WHATSAPP" ? FaWhatsapp : FaEnvelope;

    const authorizedUseLinkStores = [
      "2212be6e-722c-4ab4-947f-772d95f48533",
      "ea093232-0768-41a5-8b83-c98846547d15",
    ];

    return (
      <div className="page notifications-config-edit container">
        <PageTitle pageTitle={`Editar notificação | ${template.label}`} />

        <header className="page-header">
          <div className="row align-items-end">
            <div className="col-8">
              <Link
                to={`${renderRoute("config-notifications", {
                  merchantId: currentMerchant.uuid,
                })}?channel=${template.channel}`}
                className="f13 black-70 backbtn"
              >
                <FaChevronLeft /> voltar para Notificações
              </Link>
              <h1 className="title">{template.label}</h1>
              <div className="labels">
                <span className={classnames("channel", template.channel)}>
                  <Icon /> {channelLabel}
                </span>
              </div>
            </div>
            <div className="col text-end">
              <button
                type="button"
                className="btn green size--small"
                onClick={() => this.previewTemplate(template)}
              >
                Pré-visualizar
              </button>
            </div>
          </div>
        </header>
        <hr />
        {loading ? (
          <LoaderBG />
        ) : (
          <div className="row">
            <div className="col-8">
              <div className="box-widget">
                <div className="inner">
                  <Formik
                    enableReinitialize
                    innerRef={this.formRef}
                    initialValues={template}
                    handleSubmit={this.handleSubmit}
                    validate={async (a1) => {
                      if (!_.isEqual(a1, template))
                        this.setState((state) => ({
                          ...state,
                          disableCancel: false,
                        }));
                      else {
                        this.setState((state) => ({
                          ...state,
                          disableCancel: true,
                        }));
                      }
                      const isValid = await validationSchema.isValid(a1, {
                        abortEarly: false,
                      });

                      if (isValid) return {};

                      const validationResult = await validationSchema
                        .validate(a1, { abortEarly: false })
                        .catch((err) => {
                          const validations = {};

                          err.inner.map((_err) => {
                            validations[_err.path] = _err.message;
                          });

                          return validations;
                        });

                      const found = Object.keys(this.formRef.current.touched).some((touched) =>
                        Object.keys(validationResult).includes(touched)
                      );
                      if (!found) this.formRef.current.setTouched(validationResult);

                      return validationResult;
                    }}
                    onSubmit={(values) => this.handleSubmit(values)}
                  >
                    <Form>
                      {template.channel !== "WHATSAPP" && (
                        <div className="form-group">
                          <label htmlFor="subject" style={{ marginBottom: 10 }}>
                            <strong>Assunto</strong>
                          </label>

                          <Field
                            name="subject"
                            id="subject"
                            className="form-control"
                            placeholder="Pedido entregue!"
                            required
                          />
                          <ErrorMessage component="div" className="error-message" name="subject" />
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="mensagem" style={{ marginBottom: 10 }}>
                          <strong>Mensagem</strong>
                        </label>
                        {template.channel === "WHATSAPP" ? (
                          <Field
                            name="content"
                            component="textarea"
                            cols="30"
                            rows="9"
                            className="form-control"
                            placeholder="Digite sua mensagem"
                          />
                        ) : (
                          <Field name="content">
                            {({ field, form }) => (
                              <CustomQuillEditor
                                value={field.value}
                                onChange={(v) => form.setFieldValue("content", v)}
                                customModules={{
                                  iumyCoupon: {
                                    icon: FaTicketAlt,
                                    handler: function iumyCoupon(quill) {
                                      const range = quill.getSelection();
                                      if (range) {
                                        quill.formatText(range, "spanblock", true);
                                      }
                                    },
                                  },
                                }}
                                toolbarModules={[
                                  ["bold", "italic", "underline", "strike"],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  [{ color: [] }],
                                  ...(authorizedUseLinkStores.includes(currentMerchant.uuid)
                                    ? [["link"]]
                                    : []),
                                  ["clean"],
                                ]}
                              />
                            )}
                          </Field>
                        )}

                        <ErrorMessage component="div" className="error-message" name="content" />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            <aside className="col sidebar">
              <div className="card custom-card">
                <div className="card-header">Variáveis disponíveis</div>

                <div className="card-body">
                  <p className="intro">
                    Utilize variáveis para personalizar suas notificações com algum dado dinâmico
                    disponível. As variáveis disponíveis são:
                  </p>

                  <ul className="variable-list">
                    {template.tags.map((tag) => (
                      <li key={tag.tag}>
                        <ClickCopyChildren copyText={tag.tag}>
                          <span className="variable">{tag.tag}</span>
                        </ClickCopyChildren>

                        <small className="variable-info">{tag.label}</small>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        )}
        <hr />
        <footer className="footer-actions">
          <div className="flex flex-right">
            <button
              className="btn default size--medium-2 mr10"
              type="button"
              disabled={disableCancel}
              onClick={() => this.handleCancel()}
            >
              Cancelar
            </button>
            <button
              className={classnames("btn green size--medium-2 withLoader", {
                loading: submiting,
              })}
              disabled={disableCancel}
              type="submit"
              onClick={() => {
                if (this.formRef.current) {
                  this.formRef.current.handleSubmit();
                }
              }}
            >
              <span className="text">Salvar</span>
              <div className="dots">
                <div className="dot dot-1" />
                <div className="dot dot-2" />
                <div className="dot dot-3" />
              </div>
            </button>
          </div>
        </footer>

        <Modal
          className="previewModal"
          title="Pré-visualizar mensagem"
          showModal={previewOpened}
          toggleModal={() => this.previewTemplate()}
        >
          {previewContent}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
});

export default connect(mapStateToProps)(EditNotification);
