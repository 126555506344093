import React, { useState } from "react";
import produce from "immer";

// Assets
import { ReactComponent as ShopifyIcon } from "images/app-icons/without-bg/shopify.svg";
import { ReactComponent as WoocommerceIcon } from "images/app-icons/without-bg/woocommerce.svg";
import { ReactComponent as YampiIcon } from "images/app-icons/without-bg/yampi.svg";
import { ReactComponent as CartpandaIcon } from "images/app-icons/without-bg/cartpanda.svg";
import { ReactComponent as NuvemshopIcon } from "images/app-icons/without-bg/nuvemshop.svg";

// Components
import Select from "../components/select";
import BottomActions from "../components/bottom-actions";
import { initOAuth } from "../../apps/marketplaces/nuvemshop";

function Integrations({ storeUuid, data = [], nextStepHandler, previousStepHandler }) {
  const initialValues = Object.assign(
    [
      {
        slug: "ecommerce",
        label: "Plataforma de ecommerce",
        selectorLabel: "Escolha a plataforma de sua loja",
        multiple: false,
        category: "marketplace",
        options: [
          {
            icon: <ShopifyIcon />,
            label: "Shopify",
            value: "shopify",
          },
          {
            icon: <NuvemshopIcon />,
            label: "NuvemShop",
            value: "nuvemshop",
            soon: true,
          },
          {
            icon: <YampiIcon />,
            label: "Yampi",
            value: "yampiloja",
            soon: true,
          },
          {
            icon: <CartpandaIcon />,
            label: "CartPanda",
            value: "cartpanda",
            soon: true,
          },
          {
            icon: <WoocommerceIcon />,
            label: "Woocommerce",
            value: "woocommerce",
            soon: true,
          },
        ],
        required: true,
      },
    ],
    data
  );
  const [options, setOptions] = useState(initialValues);
  const [showSoon, setShowSoon] = useState(false);
  const [loading, setLoading] = useState(false);

  const requiredOptions = options.filter((opt) => opt.required);

  const allRequiredFilled =
    requiredOptions.filter((opt) => opt.options.find((subOpt) => subOpt.selected)).length ===
    requiredOptions.length;

  const onSelect = (slug, selectedOptions) =>
    setOptions(
      produce((draft) => {
        // const ala = current(draft);
        const selector = draft.find((select) => select.slug === slug);
        selector.options.map((opt) => {
          if (selectedOptions.find((selectedOption) => selectedOption.value === opt.value))
            opt.selected = true;
          else opt.selected = false;

          return opt;
        });
      })
    );

  const onSubmit = (_data) => {
    setLoading(true);

    const marketplace = _data.find((o) => o.category === "marketplace");
    const marketplaceSelected = marketplace.options.find((o) => o.selected).value;

    if (marketplaceSelected === "shopify")
      return window.location.replace(`${storeUuid}/apps/shopify`);

    if (marketplaceSelected === "nuvemshop") {
      // nextStepKey = "NUVEMSHOP_CONFIG";
      return initOAuth(storeUuid, true);
    }

    return false;
  };

  return (
    <section className="wizard-step step-integrations">
      <h1 className="step-title">Selecione as integrações</h1>
      <p className="description">Escolha os aplicativos que utiliza em sua operação</p>
      <div className="selectors">
        {options.map((opt) => (
          <Select
            key={opt.slug}
            slug={opt.slug}
            label={opt.label}
            selectorLabel={opt.selectorLabel}
            options={opt.options}
            multiple={opt.multiple}
            onSelect={onSelect}
            showSoon={showSoon}
            showSoonHandler={() => setShowSoon(true)}
          />
        ))}
      </div>

      <BottomActions
        loading={loading}
        enableNext={allRequiredFilled}
        nextStepHandler={() => onSubmit(options)}
        previousStepHandler={previousStepHandler}
      />
    </section>
  );
}

export default Integrations;
