export const screenSizes = {
  TABLET_MAX: 768,
  DESKTOP_MIN: 769,
};

export const packageStatus = {
  TRACKING_CODE_GENERATED: "TRACKING_CODE_GENERATED",
  POSTED: "POSTED",
  IN_TRANSIT: "IN_TRANSIT",
  IN_ROUTE_TO_DELIVERY: "IN_ROUTE_TO_DELIVERY",
  DELIVERED: "DELIVERED",
  WAITING_WITHDRAW: "WAITING_WITHDRAW",
  RETURNED_TO_SENDER: "RETURNED_TO_SENDER",
  TAXED: "TAXED",
  EXCEPTION: "EXCEPTION",
  IS_LATE: "IS_LATE",
  IS_STALLED: "IS_STALLED",
  ARCHIVED: "ARCHIVED",
  UNKNOWN: "UNKNOWN",
};

export const orderFinancialStatus = {
  PENDING: "PENDING",
  AUTHORIZED: "AUTHORIZED",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PAID: "PAID",
  PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
  REFUNDED: "REFUNDED",
  VOIDED: "VOIDED",
  CANCELLED: "CANCELLED",
};

export const brasilProvinces = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goías",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraíma",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};
