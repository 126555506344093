import React, { useState, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

function DropdownButton(props) {
  const [isOpened, toggleOpened] = useState(false);
  const { classes, label, options, callback } = props;

  const wrapperRef = useRef(null);

  return (
    <Dropdown
      className={`d-md-inline-block default ${props.className}`}
      isOpen={isOpened}
      toggle={() => toggleOpened(!isOpened)}
    >
      <DropdownToggle caret ref={wrapperRef} className={classes}>
        {label}
      </DropdownToggle>

      <DropdownMenu>
        {options &&
          options.map((option) => (
            <DropdownItem
              disabled={option.disabled}
              key={option.value}
              className={option.className}
              onClick={() => {
                if (!option.disabled) {
                  const cb = option.callback || callback || function () {};
                  cb(option.value);
                }
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownButton;
