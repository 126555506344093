import { useOutsideClick } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

export default function ColorPicker({ color, onChange }) {
  const [defaultColor, setColor] = useState(color || "");
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const saveTimer = useRef(null);

  useOutsideClick(pickerRef, () => {
    setShowPicker(false);
  });

  useEffect(() => {
    clearTimeout(saveTimer.current);

    if (defaultColor !== color) saveTimer.current = setTimeout(() => onChange(defaultColor), 1000);
  }, [defaultColor]);

  return (
    <div className="iumy-color-picker">
      <div className="form-group">
        <label className="field-label" htmlFor="iumy-picker">
          <strong>Escolha a cor de destaque</strong>
        </label>
        <div ref={pickerRef} className="picker-inner">
          <span className="picker-preview" style={{ background: defaultColor }} />
          <input
            id="iumy-picker"
            type="text"
            className="form-control"
            placeholder="#000000"
            value={defaultColor}
            onChange={(e) => setColor(e.target.value)}
            onFocus={() => setShowPicker(true)}
          />
          {showPicker && (
            <SketchPicker
              color={defaultColor}
              className="float-picker"
              onChange={(colorPicked) => setColor(colorPicked.hex)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
