import { createSlice } from "@reduxjs/toolkit";

// Api
import { DTLayer } from "components/data-layer";

// Models
import User from "models/user";

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isLogout: false,
  streamerMode: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    doLoadUserSuccess: (state, action) => {
      DTLayer.dispatch("userDataLoad", {
        userData: {
          uuid: action.payload.uuid,
          name: action.payload.firstName,
          phone: action.payload.phone,
          email: action.payload.email,
          avatar: action.payload.avatar,
        },
      });

      state.user = new User(action.payload);
    },

    doLoadUserFailure: (state, action) => {
      state.user = new User();
      state.token = null;
      state.isAuthenticated = false;
    },

    doLoginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLogout = false;
    },

    doLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.isLogout = true;
    },

    enableStreamer: (state) => {
      state.streamerMode = true;
    },

    disableStreamer: (state) => {
      state.streamerMode = false;
    },
    doLoginFailure: () => {},
  },
});

export const {
  doLoadUserSuccess,
  doLoadUserFailure,
  doLoginSuccess,
  doLogout,
  doLoginFailure,

  enableStreamer,
  disableStreamer,
} = authSlice.actions;

// Export the slice reducer as the default export
export default authSlice.reducer;

// export default createReducer(initialState, {
//   // [Types.DO_LOGIN_SUCCESS]: doLoginSuccess,
//   // [Types.DO_LOGIN_FAILURE]: doLoginFailure,
//   [Types.DO_LOGOUT]: doLogout,
//   // [Types.DO_LOAD_USER_SUCCESS]: doLoadUserSuccess,
//   // [Types.DO_LOAD_USER_FAILURE]: doLoadUserFailure,
// });
