import moment from "moment";
import React from "react";
import VMasker from "vanilla-masker";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import classNames from "classnames";

function DesktopOrderMessages({ messages, togglePreview, streamerMode }) {
  return (
    <>
      <table className="iumy-table floating-box">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Canal</th>
            <th>Mensagem</th>
            <th aria-label="" />
          </tr>
        </thead>

        <tbody>
          <tr className="spacing" />
          {messages.map((message) => {
            const Icon = message.channel === "WHATSAPP" ? FaWhatsapp : FaEnvelope;
            const contact =
              message.channel === "WHATSAPP"
                ? VMasker.toPattern(message.numberTo, "+99 (99) 99999-9999")
                : message.emailTo;

            return (
              <React.Fragment key={message.uuid}>
                <tr>
                  <td>
                    <span className={classNames("iumy-badge", message.status.code)}>
                      {message.status.label}
                    </span>
                    {message.context === "PACKAGE" && (
                      <span className="block gray f13 mt3">{message.packageTrackingNumber}</span>
                    )}
                  </td>
                  <td>
                    <Icon size={30} className="message-type-icon" />
                  </td>
                  <td>
                    <div className="message-infos">
                      <span className="message-title" onClick={() => togglePreview(message)}>
                        {message.subject}
                      </span>{" "}
                      <span className="f13 gray">{moment(message.sentAt).fromNow()}</span>
                      <span className="message-to block">
                        <span>Para:</span>{" "}
                        <span className={streamerMode ? "blurry" : ""}>{contact}</span>
                      </span>
                    </div>
                  </td>
                  <td className="f13 bold">
                    {message.channel === "WHATSAPP" && (
                      <a
                        rel="noopener noreferrer"
                        href={message.whatsappSendUrl}
                        target="_blank"
                        className="resend-message-button"
                      >
                        Reenviar
                      </a>
                    )}
                  </td>
                </tr>
                <tr className="spacing" />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default DesktopOrderMessages;
