import TagManager from "react-gtm-module";

export const DTLayer = (() => {
  const self = {
    status: "pending",
    gtmId: null,

    // Functions
    dispatch: (event, dataLayer) => {
      if (self.status === "initialized")
        window.dataLayer.push({
          event,
          ...dataLayer,
        });
    },
  };

  return self;
})();

export default function createDataLayer(gtmId) {
  const tagManagerArgs = {
    gtmId,
    dataLayer: {
      env: process.env.REACT_APP_ENV,
    },
  };

  TagManager.initialize(tagManagerArgs);

  // Update Object
  DTLayer.status = "initialized";
  DTLayer.gtmId = gtmId;
}
