import { immerable } from "immer";

export default class Address {
  constructor(options) {
    this[immerable] = true;

    this.id = options.id;
    this.uuid = options.uuid;
    this.customerId = options.customerId;
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.name = options.name;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.city = options.city;
    this.province = options.province;
    this.provinceCode = options.provinceCode;
    this.country = options.country;
    this.countryCode = options.countryCode;
    this.zipcode = options.zipcode;
    this.company = options.company;
    this.phone = options.phone;
  }
}
