import React, { useState } from "react";
import classnames from "classnames";

function Accordion(props) {
  const Container = props.containerElm;
  const ContentContainer = props.contentElm;
  const [accordionOpened, toggleAccordion] = useState(false);

  return (
    <Container
      className={classnames("accordion-item", {
        opened: accordionOpened,
      })}
    >
      <div className="accordion-title" onClick={() => toggleAccordion(!accordionOpened)}>
        {props.icon && props.icon} <span className="title-text">{props.title}</span>
      </div>
      <ContentContainer className="accordion-content">{props.children}</ContentContainer>
    </Container>
  );
}

export default Accordion;
