import { immerable } from "immer";

export default class Customer {
  constructor(options) {
    this[immerable] = true;

    this.uuid = options.uuid;
    this.document = options.document;
    this.orderId = options.orderId;
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.email = options.email;
    this.hasNImported = !options.firstName;
  }
}
