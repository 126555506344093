import { immerable } from "immer";
import { renderRoute } from "pages/main/routes-list";
import Status from "./tracking/status";
import StatusReturnal from "./returnal/status-retunal";

function getChannelLabel(channelSlug) {
  switch (channelSlug) {
    case "WHATSAPP":
      return "Whatsapp";
    case "EMAIL":
      return "E-mail";
    default:
      return channelSlug;
  }
}

function getTriggerStatus(targetSlug, context) {
  const slug = targetSlug.indexOf("PACKAGE_") > -1 ? targetSlug.substr(8) : targetSlug;

  if (context === "RETURNAL") return new StatusReturnal(slug);

  return new Status(slug);
}

export default class MessageDto {
  constructor(model) {
    this[immerable] = true;

    this.uuid = model.uuid;
    this.createdAt = model.createdAt;
    this.storeUuid = model.storeUuid;
    this.orderUuid = model.orderUuid;
    this.orderName = model.orderName;
    this.packageUuid = model.packageUuid;
    this.orderUrl = renderRoute("order", {
      orderId: model.orderUuid,
      merchantId: model.storeUuid,
    });
    this.packageTrackingNumber = model.packageTrackingNumber;

    this.context = model.context;
    this.channel = model.channel;
    this.channelLabel = getChannelLabel(model.channel);
    this.target = model.target;
    this.file = model.file;

    this.emailTo = model.emailTo;
    this.numberTo = model.numberTo;
    this.whatsappSendUrl = model.whatsappSendUrl;

    this.readAt = model.readAt;
    this.sentAt = model.sentAt;

    this.hasDiscarded = model.hasDiscarded;
    this.hasSent = model.hasSent;

    this.isRead = model.isRead;
    this.subject = model.subject;
    this.content = model.content;

    this.status = getTriggerStatus(model.trigger, model.context);

    this.storeName = null;
    this.storeLogoUrl = null;
  }
}
