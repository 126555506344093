import { useEffect } from "react";
import Swal from "sweetalert2/src/sweetalert2";

// Hooks
export const useOutsideClick = (ref, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, cb]);
};

export const inputMasks = {
  creditCardMask: [
    {
      mask: "0000 000000 00000",
      regex: "^3[47]\\d{0,13}",
      cardtype: "american express",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
      cardtype: "discover",
    },
    {
      mask: "0000 000000 0000",
      regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
      cardtype: "diners",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
      cardtype: "mastercard",
    },

    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
      cardtype: "maestro",
    },

    {
      mask: "0000 0000 0000 0000",
      regex: "^4\\d{0,15}",
      cardtype: "visa",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^62\\d{0,14}",
      cardtype: "unionpay",
    },
    {
      mask: "0000 0000 0000 0000",
      cardtype: "Unknown",
    },
  ],
};

export const accessDotNotation = (object, pathString) => {
  const stringParts = pathString.split(".");
  const len = stringParts.length;
  let currentVal = object;

  for (let i = 0; i < stringParts.length; i++) {
    const key = stringParts[i];
    currentVal = currentVal[key];

    if (currentVal === false) return 0;

    if (!currentVal) return "";

    // If last
    if (i + 1 === len) return currentVal;
  }

  return false;
};

// Formats
export const sanitizeObject = (object, exceptions = []) => {
  const newObj = { ...object };

  Object.keys(newObj).forEach((k) => {
    if (newObj[k] && typeof newObj[k] === "object") {
      newObj[k] = sanitizeObject(newObj[k], exceptions);
      return;
    }

    const string = newObj[k];

    if (typeof string !== "string") return;

    const regex = /[&/\\#,+()$~%.'":*?<>{}\-]/;
    const hasMask = string.match(regex) !== null;
    const isEmail = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/.test(string);

    const canApply = !isEmail && hasMask;

    if (!exceptions.includes(k) && canApply) newObj[k] = removeMaskOfString(newObj[k]);
  });

  return newObj;
};

export const cleanString = (string) => {
  const find = [
    ".",
    '"',
    "'",
    " ",
    "’",
    ";",
    ",",
    ":",
    "à",
    "á",
    "â",
    "ã",
    "ä",
    "ç",
    "è",
    "é",
    "ê",
    "ë",
    "ì",
    "í",
    "î",
    "ï",
    "ñ",
    "ò",
    "ó",
    "ô",
    "õ",
    "ö",
    "ù",
    "ú",
    "û",
    "ü",
    "ý",
    "ÿ",
    "À",
    "Á",
    "Â",
    "Ã",
    "Ä",
    "Ç",
    "È",
    "É",
    "Ê",
    "Ë",
    "Ì",
    "Í",
    "Î",
    "Ï",
    "Ñ",
    "Ò",
    "Ó",
    "Ô",
    "Õ",
    "Ö",
    "Ù",
    "Ú",
    "Û",
    "Ü",
    "Ý",
  ];
  const replace = [
    "",
    "",
    "",
    "-",
    "",
    "",
    "",
    "",
    "a",
    "a",
    "a",
    "a",
    "a",
    "c",
    "e",
    "e",
    "e",
    "e",
    "i",
    "i",
    "i",
    "i",
    "n",
    "o",
    "o",
    "o",
    "o",
    "o",
    "u",
    "u",
    "u",
    "u",
    "y",
    "y",
    "A",
    "A",
    "A",
    "A",
    "A",
    "C",
    "E",
    "E",
    "E",
    "E",
    "I",
    "I",
    "I",
    "I",
    "N",
    "O",
    "O",
    "O",
    "O",
    "O",
    "U",
    "U",
    "U",
    "U",
    "Y",
  ];
  let replaceString = string;
  let regex;
  for (let i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    replaceString = replaceString.replace(regex, replace[i]);
  }
  replaceString = replaceString.toLowerCase();
  replaceString = replaceString.trim();

  return replaceString;
};

export const formatPrice = (price, currency = "BRL") => {
  const value = typeof price === "number" ? price : parseFloat(price);
  return value.toLocaleString("pt-br", {
    style: "currency",
    currency,
  });
};

export const generateInitialsFromName = (name) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  const initials = [...name.matchAll(rgx)] || [];

  return ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
};

export const convertEmptyToStr = (object) => {
  Object.keys(object).forEach(function (key, index) {
    if (this[key] == null) this[key] = "";

    if (this[key] instanceof Object) convertEmptyToStr(this[key]);
  }, object);

  return object;
};

export const addCommaToPrice = (price = "") => price.toString().replace(".", ",");

// App methods
export const removeMaskOfString = (string) => string.replace(/[&/\\#,+()$~%.'":*?<>{}\- ]/g, "");

export const isAppActive = ({ appSlug, appType }, appList) => {
  const key = appSlug ? "slug" : "type";
  const value = appSlug || appType;

  return appList.filter((app) => app[key] === value && app.isActive === true).length || false;
};

export const checkExtension = async () => {
  if (!window?.chrome?.runtime) return false;

  const extId = process.env.REACT_APP_EXTENSION_ID;

  return new Promise((resolve) =>
    window.chrome.runtime.sendMessage(extId, { type: "BG/IUMY_CHECK" }, (response) => {
      if (response && response.success) resolve(response.version);

      resolve(false);
    })
  );
};

export const getErrorMessage = (error) => {
  const niceError = accessDotNotation(error, "response.data.error.message");

  return niceError || error.message;
};

export const traitMoves = (moves = []) => {
  let dilmaAppeared = false;
  const result = moves
    .slice(0)
    .reverse()
    .filter((m) => {
      if (dilmaAppeared && m.provider !== "DILMA") m.hidden = true;
      if (m.provider === "DILMA") {
        dilmaAppeared = true;
      }

      return !m.hidden;
    });

  return result.reverse();
};

// Array helpers
export const pushIfNotExists = (array, key, object) => {
  if (!Array.isArray(array) || !(object instanceof Object) || !key) return false;

  // Check if this obj exists in array
  const exists = array.find((obj) => {
    if (!(obj instanceof Object)) return false;

    return obj[key] === object[key];
  });

  // If not exists push
  if (!exists) return array.push(object);

  return false;
};

export const cleanArray = (arr) => arr.filter((item) => item !== undefined && item !== "");

export const selectText = (containerid, code) => {
  if (document.selection) {
    // IE
    const range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  if (navigator.clipboard) {
    navigator.clipboard.writeText(code);
  } else if (window.clipboardData) {
    window.clipboardData.setData("Text", code);
  }
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const swalMiddleware = {
  ...Swal,
  fire: (options) => {
    const opt = {
      ...options,
      heightAuto: false,
    };

    if (options?.icon === "iumy") opt.customClass = { container: "swal-iumy" };

    return Swal.fire(opt);
  },
};

export const sleep = (timeout = 1000) => new Promise((resolve) => setTimeout(resolve, timeout));
