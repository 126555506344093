import React from "react";
import { FaCog, FaCreditCard, FaCubes, FaBell, FaExchangeAlt, FaPuzzlePiece } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import PageTitle from "components/page-title";
import { renderRoute } from "pages/main/routes-list";

export default function Configurations(props) {
  const { currentMerchant } = useSelector((state) => state.merchants);

  return (
    <div className="page configurations-index container">
      <PageTitle pageTitle="Configurações" />

      <header className="page-header">
        <h1 className="title">Configurações</h1>
      </header>

      <div className="box-widget">
        <div className="inner">
          <ul className="config-list">
            {/* Item */}

            <li className="config-list-item">
              <Link
                to={renderRoute("config-merchant", {
                  merchantId: currentMerchant.uuid,
                })}
                className="config-link"
              >
                <div className="icon-wrap">
                  <FaCog className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Geral</span>
                  <span className="config-description">
                    Veja e atualize as informações da sua loja.
                  </span>
                </div>
              </Link>
            </li>

            <li className="config-list-item">
              <Link to="/config/billing" className="config-link">
                <div className="icon-wrap">
                  <FaCreditCard className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Assinatura</span>
                  <span className="config-description">
                    Gerencie seu plano e as informações de pagamento.
                  </span>
                </div>
              </Link>
            </li>

            {/* <li className="config-list-item">
              <Link
                to={renderRoute("config-users", {
                  merchantId: props.currentMerchant.uuid,
                })}
                className="config-link"
              >
                <div className="icon-wrap">
                  <FaUser className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Usuários</span>
                  <span className="config-description">
                    Gerencie os usuários, grupos e permissões.
                  </span>
                </div>
              </Link>
            </li> */}

            <li className="config-list-item d-none d-sm-block">
              <Link
                to={renderRoute("apps", {
                  merchantId: currentMerchant.uuid,
                })}
                className="config-link"
              >
                <div className="icon-wrap">
                  <FaCubes className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Integrações</span>
                  <span className="config-description">
                    Gerencie os apps e integrações com sua loja.
                  </span>
                </div>
              </Link>
            </li>

            <li className="config-list-item d-none d-sm-block">
              <Link
                to={renderRoute("config-notifications", {
                  merchantId: currentMerchant.uuid,
                })}
                className="config-link"
              >
                <div className="icon-wrap">
                  <FaBell className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Notificações</span>
                  <span className="config-description">
                    Gerencie quais notificações seram enviadas para seus clientes e equipe.
                  </span>
                </div>
              </Link>
            </li>

            <li className="config-list-item d-none d-sm-block">
              <Link
                to={renderRoute("appDetail", {
                  merchantId: currentMerchant.uuid,
                  appSlug: "iumy_tracker",
                })}
                className="config-link"
              >
                <div className="icon-wrap">
                  <FaPuzzlePiece className="ico" />
                </div>
                <div className="config-infos">
                  <span className="config-title">Portal do Cliente</span>
                  <span className="config-description">
                    Configure/instale o plugin & rastreamento da Iumy em seu site.
                  </span>
                </div>
              </Link>
            </li>

            {currentMerchant.apps.find((app) => app.type === "IUMY_RETURNAL")
              .isTrocaFacilAvailable && (
              <li className="config-list-item d-none d-sm-block">
                <Link
                  to={renderRoute("appDetail", {
                    merchantId: currentMerchant.uuid,
                    appSlug: "iumy_returnal",
                  })}
                  className="config-link"
                >
                  <div className="icon-wrap">
                    <FaExchangeAlt className="ico" />
                  </div>
                  <div className="config-infos">
                    <span className="config-title">Trocas & Devoluções</span>
                    <span className="config-description">
                      Configure e gerencie as trocas e devoluções automáticas.
                    </span>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
