import React, { useEffect, useRef } from "react";
import { FaPlay, FaCircleQuestion } from "react-icons/fa6";

const videos = [
  {
    id: "kTdNaDhkoEc",
    thumb:
      "https://i.ytimg.com/vi/kTdNaDhkoEc/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD53mFTGjm06fONIo6Fzkm7qlqIJA",
    title: "Como automatizar as taxações Dropshipping?",
    description:
      "Coloque a Iumy para trabalhar pra você nas taxas aduaneiras, gere boletos de forma automática.",
  },

  {
    id: "-_DSbSMucb8",
    thumb:
      "https://i.ytimg.com/vi/-_DSbSMucb8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCMcjWaEYtOD2_FFH_0oG8AM0XDZQ",
    title: "Como integrar a Shopify à Iumy?",
    description: "Aprenda como conectar sua loja Shopify com a Iumy em poucos minutos.",
  },

  {
    id: "F8cLDcaHcV8",
    thumb: "https://i3.ytimg.com/vi/F8cLDcaHcV8/maxresdefault.jpg",
    title: "Enviar notificações pelo Whatsapp?",
    description: "Aprenda a enviar notificações em massa pelo Whatsapp.",
  },
];

export default function IumyAcademy() {
  const parent = useRef(null);

  let mouseDown = false;
  let startX;
  let scrollLeft;
  let interval;
  const mouseMoving = useRef(false);

  useEffect(() => {
    const videoLinks = document.querySelectorAll(".video-link");

    Array.from(videoLinks).forEach((e) =>
      e.addEventListener("click", (e) => {
        if (mouseMoving.current) e.preventDefault();
      })
    );
  }, []);

  function mouseIsDown(e) {
    e.preventDefault();
    mouseDown = true;
    startX = e.pageX - parent.current.offsetLeft;
    scrollLeft = parent.current.scrollLeft;
  }
  function mouseUp(e) {
    mouseDown = false;
  }
  function mouseLeave(e) {
    e.preventDefault();
    mouseDown = false;
  }

  function mouseMove(e) {
    e.preventDefault();

    if (!mouseDown) {
      return;
    }

    clearTimeout(interval);
    mouseMoving.current = true;

    interval = setTimeout(() => {
      mouseMoving.current = false;
    }, 200);

    const x = e.pageX - parent.current.offsetLeft;
    const scroll = x - startX;
    parent.current.scrollLeft = scrollLeft - scroll;
  }

  return (
    <div className="box-widget main-card academy">
      <header>
        <span className="main-card-title">Iumy Academy</span>
      </header>
      <div className="video-list-fade">
        <div
          ref={parent}
          className="video-wrap"
          onMouseDown={mouseIsDown}
          onMouseUp={mouseUp}
          onMouseLeave={mouseLeave}
          onMouseMove={mouseMove}
          role="navigation"
        >
          <div className="video-list">
            {videos.map((v) => (
              <div key={v.id} className="subcard">
                <a
                  className="video-link"
                  href={`https://www.youtube.com/watch?v=${v.id}`}
                  key={v.id}
                  target="_blank"
                  rel="noreferrer"
                />

                <img className="video-thumb" src={v.thumb} alt={v.title} />
                <div className="video-infos">
                  <span className="video-title">
                    <span className="play-icon">
                      <FaPlay />
                    </span>
                    {v.title}
                  </span>
                  <p className="video-description">{v.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <hr />

      <div>
        <h6>Ficou com dúvidas??</h6>
        <a href="https://iumy.tawk.help/" target="_blank" rel="noreferrer">
          <FaCircleQuestion /> Acesse nossa central de ajuda
        </a>
      </div>
    </div>
  );
}
