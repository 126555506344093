import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { isAppActive } from "helpers";
import { renderRoute } from "pages/main/routes-list";

// Actions
import { getAppSettingsByMerchantRequest } from "store/modules/merchants/actions";
import { setSystemAlertsCount } from "store/modules/context/reducer";

// Components
import LoaderBG from "./loader-bg";

// Types
export const ALERT_TYPES = {
  // Apps
  MENV: "MENV",
  CORREIOS_ECT: "CORREIOS_ECT",

  // Status
  PAUSED_APP: "PAUSED_APP",
  NEW_APP: "NEW_APP",
};

export default function SystemAlerts({ showTitle = false, filterTypes = [] }) {
  const dispatch = useDispatch();
  const { currentMerchant } = useSelector((state) => state.merchants);
  const [state, setState] = useState({
    alerts: [],
    loading: true,
  });

  // Mount alerts
  useEffect(() => {
    async function init() {
      const warnings = [];

      const [resultCorreiosEct, resultMenv] = await Promise.all([
        getAppSettingsByMerchantRequest(currentMerchant.uuid, "correios_importador"),
        getAppSettingsByMerchantRequest(currentMerchant.uuid, "melhor_envio"),
      ]);

      if (resultMenv.data.isPaused)
        warnings.push({
          title: "Melhor Envio",
          type: [ALERT_TYPES.MENV, ALERT_TYPES.PAUSED_APP],
          message: resultMenv.data.pauseReason,
          btnLabel: "Reativar aplicativo",
          link: renderRoute("appDetail", {
            merchantId: currentMerchant.uuid,
            appSlug: "melhor_envio",
          }),
        });

      if (
        isAppActive({ appSlug: "returnal-iumy" }, currentMerchant.apps) &&
        !resultMenv.data.isActive
      ) {
        warnings.push({
          title: "Melhor Envio",
          type: [ALERT_TYPES.MENV, ALERT_TYPES.NEW_APP],
          message:
            "Integre com sua conta do Melhor Envios para aprovar as Trocas e Devoluções pendentes.",
          link: renderRoute("appDetail", {
            merchantId: currentMerchant.uuid,
            appSlug: "melhor_envio",
          }),
        });
      }

      if (!resultCorreiosEct.data.isPasswordValid && resultCorreiosEct.data.isActive)
        warnings.push({
          title: "Correios Importador",
          type: [ALERT_TYPES.PAUSED_APP, ALERT_TYPES.CORREIOS_ECT],
          message: "Senha inválida, por favor verifique novamente.",
          link: renderRoute("appDetail", {
            merchantId: currentMerchant.uuid,
            appSlug: "correios_importador",
          }),
        });

      // Update state
      setState((s) => ({ ...s, loading: false, alerts: warnings }));
      dispatch(setSystemAlertsCount(warnings.length));
    }

    init();
  }, []);

  if (state.loading) return <LoaderBG blocks={1} />;

  return (
    state.alerts.length > 0 && (
      <div className="alerts">
        {showTitle && (
          <div className="hr-prhase left">
            <div className="content">Alertas</div>
          </div>
        )}
        {state.alerts
          .filter((alert) => {
            if (filterTypes.length) {
              let foundAtLeast = false;

              filterTypes.forEach((f) => {
                if (alert.type.includes(f)) foundAtLeast = true;
              });

              return foundAtLeast;
            }

            return true;
          })
          .map((a) => (
            <div
              key={a.title}
              className="alert alert-iumy d-flex align-items-center justify-content-between"
              role="alert"
            >
              <div>
                <strong>{a.title}: </strong>
                {a.message}
              </div>
              {a.link && (
                <Link className="btn green btn-sm p-2 px-3" to={a.link}>
                  {a.btnLabel ? a.btnLabel : "Resolver"}
                </Link>
              )}
            </div>
          ))}
      </div>
    )
  );
}
