import React from "react";
import classnames from "classnames";

import { ReactComponent as Logo } from "images/loader-icon.svg";

function LoadingScreen(props) {
  return (
    <div
      className={classnames("loading-screen", {
        active: props.active,
      })}
    >
      <Logo width="70" height="70" className="logo-spinner anim-pulse" />
    </div>
  );
}

export default LoadingScreen;
