import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

export const Tab = ({ children }) => children;

const Tabs = (props) => {
  const { children } = props;

  const tabs = React.Children.toArray(children);
  const [activeTab, setActiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div {...props}>
      <Nav tabs>
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              className={`${activeTab === index ? "active" : ""} ${tab.props.className} `}
              onClick={() => {
                toggle(index);
              }}
            >
              {tab.props.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {tabs.map((tab, index) => (
          <TabPane key={index} tabId={index} className={tab.props.className}>
            {tab.props.children}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default Tabs;
