import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  sidebarMobileOpened: false,
  systemAlerts: 0,
};

const contextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },

    toggleSidebar: (state, action) => {
      state.sidebarMobileOpened =
        action.payload != null ? action.payload : !state.sidebarMobileOpened;
    },

    setSystemAlertsCount: (state, action) => {
      state.systemAlerts = action.payload;
    },
  },
});

export const { setLoadingStatus, toggleSidebar, setSystemAlertsCount } = contextSlice.actions;

export default contextSlice.reducer;
