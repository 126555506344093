// notifications-config-index
import React from "react";
import { Link } from "react-router-dom";
import InputSwitch from "react-switch";
import { connect } from "react-redux";
import { FaChevronLeft, FaDotCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import _ from "lodash";
import classnames from "classnames";

// Import componentes
import PageTitle from "components/page-title";
import LoaderBG from "components/loader-bg";
import {
  getNotificationsTemplate,
  updateNotificationTemplate,
  updateNotificationCategory,
} from "store/modules/notifications/actions";
import { renderRoute } from "pages/main/routes-list";
import { isAppActive } from "helpers";

class NotificationsIndex extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const channel = params.get("channel");

    this.state = {
      loading: true,

      activeChannel: channel || "EMAIL",
      activeTarget: "CUSTOMER",

      channels: [
        {
          slug: "EMAIL",
          label: "E-mail",
          targets: [
            {
              slug: "CUSTOMER",
              label: "Cliente",
            },
            // {
            //   slug: "OWNER",
            //   label: "Time",
            // },
          ],
        },
        {
          slug: "WHATSAPP",
          label: "WhatsApp",
          targets: [
            {
              slug: "CUSTOMER",
              label: "Cliente",
            },
          ],
        },
      ],
      categories: [
        {
          label: "Rastreio",
          slug: "IUMY_TRACK",
        },

        {
          label: "Logística reversa",
          slug: "IUMY_RETURNAL",
        },
      ],

      templates: [],
      templatesLoaded: [],
    };
  }

  async componentDidMount() {
    const { currentMerchant } = this.props;
    const { activeChannel, activeTarget } = this.state;
    const templatesRequest = await getNotificationsTemplate(currentMerchant.uuid);

    if (!templatesRequest.success) return false;

    // Templates
    const templates = _.chain(
      templatesRequest.data.filter((t) => t.channel === activeChannel && t.target === activeTarget)
    )
      .orderBy(["trigger"], ["asc", "desc"])
      .groupBy("category")
      .map((templatesList, categorySlug) => ({
        category: NotificationsIndex.localizeCategories(categorySlug),
        templates: templatesList,
      }))
      .value();

    console.log(templates);

    return this.setState((state) => ({
      ...state,
      templates,
      templatesLoaded: templatesRequest.data,
      loading: false,
    }));
  }

  static localizeCategories(slug) {
    const categoryObj = {
      slug,
    };

    switch (slug) {
      case "IUMY_TRACKER":
        return {
          ...categoryObj,
          label: "Rastreio",
        };

      case "IUMY_RETURNAL":
        return {
          ...categoryObj,
          label: "Logística reversa",
        };
      default:
        return { ...categoryObj, label: categoryObj.slug };
    }
  }

  selectChannel(channelSlug, targetSlug) {
    const { templatesLoaded, channels } = this.state;
    const foundChannel = channels.find((c) => c.slug === channelSlug);

    if (!foundChannel) return toast.error("Nenhum canal encontrado.");

    const target = targetSlug
      ? foundChannel.targets.find((t) => t.slug === targetSlug)
      : foundChannel.targets[0];

    // Templates
    const templates = _.chain(
      templatesLoaded.filter((t) => t.channel === channelSlug && t.target === target.slug)
    )
      .orderBy(["trigger"], ["asc", "desc"])
      .groupBy("category")
      .map((templatesList, categorySlug) => ({
        category: NotificationsIndex.localizeCategories(categorySlug),
        templates: templatesList,
      }))
      .value();

    return this.setState((state) => ({
      ...state,
      activeChannel: channelSlug,
      activeTarget: target.slug,
      templates,
    }));
  }

  async toggleTemplate(template) {
    const { currentMerchant } = this.props;
    const { activeChannel, activeTarget } = this.state;
    const toggleTemplateRequest = await updateNotificationTemplate(
      currentMerchant.uuid,
      template.uuid,
      {
        isActive: !template.isActive,
      }
    );

    if (!toggleTemplateRequest.success)
      toast.error("Houve um erro ao atualizar o status do template. Tente novamente.");

    this.setState(
      (state) => ({
        ...state,
        templatesLoaded: state.templatesLoaded.map((t) => {
          if (t.uuid === template.uuid) t.isActive = !t.isActive;
          return t;
        }),
      }),
      () => this.selectChannel(activeChannel, activeTarget)
    );
  }

  async handleToggleCategory(category, channel, isActive) {
    const { currentMerchant } = this.props;

    const toggleCategoryRequest = await updateNotificationCategory(currentMerchant.uuid, {
      category,
      channel,
      isActive,
    });

    if (!toggleCategoryRequest.success)
      return toast.error("Erro ao atualizar os status dos templates.");

    return this.setState((state) => ({
      ...state,
      templatesLoaded: state.templatesLoaded.map((t) => {
        if (t.channel === channel) t.isActive = isActive;
        return t;
      }),
    }));
  }

  render() {
    const { loading, channels, activeChannel, activeTarget, templates } = this.state;
    const { currentMerchant } = this.props;

    return (
      <div className="page notifications-config-index container">
        <PageTitle pageTitle="Configurar notificações" />

        <header className="page-header">
          <Link to={`/${currentMerchant.uuid}/config`} className="f13 black-70 backbtn">
            <FaChevronLeft /> voltar para Configurações
          </Link>
          <h1 className="title">
            Notificações | {channels.find((c) => c.slug === activeChannel).label}
          </h1>
          <span role="img" aria-label="Foguete">
            Gerencie quais notificações seram enviadas para seus clientes e equipe.
          </span>
        </header>
        <hr />

        {loading ? (
          <LoaderBG />
        ) : (
          <div className="row">
            <aside className="col sidebar">
              <div className="card custom-card">
                <div className="card-header">Canais</div>

                <ul className="menu">
                  {channels.map((channel) => (
                    <li
                      key={channel.slug}
                      className={classnames("menu-item", {
                        active: activeChannel === channel.slug,
                      })}
                    >
                      <button
                        className="menu-link"
                        onClick={() => this.selectChannel(channel.slug)}
                        type="button"
                      >
                        <FaDotCircle size={15} className="ico" /> <span>{channel.label}</span>
                      </button>

                      <ul className="submenu">
                        {channel.targets.map((target) => (
                          <li
                            key={`${channel.slug}_${target.slug}`}
                            className={classnames("menu-item", {
                              active:
                                activeTarget === target.slug && activeChannel === channel.slug,
                            })}
                          >
                            <button
                              type="button"
                              onClick={() => this.selectChannel(channel.slug, target.slug)}
                              className="menu-link"
                            >
                              {target.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </aside>
            <div className="col-9">
              {templates.length ? (
                templates
                  .filter((g) => isAppActive({ appType: g.category.slug }, currentMerchant.apps))
                  .map((group) => (
                    <div key={group.category.slug} className="box-widget">
                      <header className="inner">
                        <h3>{group.category.label}</h3>
                        <InputSwitch
                          className="switcher"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={(v) =>
                            this.handleToggleCategory(group.category.slug, activeChannel, v)
                          }
                          height={20}
                          width={40}
                          handleDiameter={14}
                          onColor="#35d331"
                          checked={group.templates.filter((t) => t.isActive).length > 0}
                        />
                      </header>
                      <hr />
                      <div className="inner">
                        {/* Config Line */}
                        {group.templates.map((template) => (
                          <div key={template.uuid} className="setting-line row">
                            <div className="col">
                              <Link
                                to={renderRoute("config-notifications-edit", {
                                  merchantId: currentMerchant.uuid,
                                  notificationId: template.uuid,
                                })}
                                className={`iumy-badge ${template.trigger}`}
                              >
                                {template.label}
                              </Link>
                              <ul className="actions">
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => this.toggleTemplate(template)}
                                    className="action link"
                                  >
                                    {template.isActive ? "Desabilitar" : "Habilitar"}
                                  </button>
                                </li>
                                <li>
                                  <Link
                                    to={renderRoute("config-notifications-edit", {
                                      merchantId: currentMerchant.uuid,
                                      notificationId: template.uuid,
                                    })}
                                    className="action link"
                                  >
                                    Editar
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-8">
                              <p className="setting-description">{template.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="box-widget">
                  <div className="inner">Nenhuma notificação disponível para configuração</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
});

export default connect(mapStateToProps)(NotificationsIndex);
