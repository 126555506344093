import React, { useState, createRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IMaskInput } from "react-imask";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import _ from "lodash";

// Actions
import { doCreateCreditCard } from "store/modules/billing/actions";

// Components
import Modal from "components/modal";
import { DTLayer } from "components/data-layer";
import { GatewayTypes } from "store/modules/billing";

// Helpers
import { inputMasks } from "helpers";

// Assets
import svgCreditCard from "images/creditcard-svg";

export default function CreditCardModal({ modalToggleHandler }) {
  const dispatch = useDispatch();
  const {
    currentBilling: { paymentCustomer },
  } = useSelector((state) => state.billing);

  // Dispatch DataLayer Event
  DTLayer.dispatch("addPaymentInfo");

  const { Iugu } = window;
  const formikInitial = {
    cardholderName: "",
    ccnumber: "",
    securityNumber: "",
    expiration: "",
    cardholderDocument: "",
  };
  const [ccIcon, setCcIcon] = useState("");
  const [state, setState] = useState({
    modalTitle: "Adicionar/Trocar cartão de crédito",
    submiting: false,
  });

  const formRef = createRef();

  const handleSubmit = async (values) => {
    const expirationMonth = values.expiration.split("/")[0];
    const expirationYear = values.expiration.split("/")[1];
    const firstName = values.cardholderName.split(" ")[0];
    const lastName = values.cardholderName.split(" ")[1];

    const postBody = {};

    // Set loading
    setState((oldState) => ({
      ...oldState,
      submiting: true,
    }));

    switch (paymentCustomer.gateway) {
      case GatewayTypes.ASAAS: {
        postBody.cardHolderName = values.cardholderName;
        postBody.cardNumber = values.ccnumber.replace(/\s/, "");
        postBody.cardCvc = values.securityNumber;
        postBody.cardExpireMonth = parseInt(expirationMonth, 10);
        postBody.cardExpireYear = 2000 + parseInt(expirationYear, 10);
        break;
      }

      case GatewayTypes.IUGU:
      default: {
        const cc = Iugu.CreditCard(
          values.ccnumber.replace(/ /g, ""),
          expirationMonth,
          expirationYear,
          firstName,
          lastName,
          values.securityNumber
        );

        const id = await new Promise((resolve, reject) => {
          try {
            Iugu.createPaymentToken(cc, (response) => {
              if (response.errors) {
                resolve({ success: false, errors: response.errors });
              } else {
                resolve({ success: true, data: response.id });
              }
            });
          } catch (errors) {
            resolve({ success: false, errors });
          }
        });

        if (!id.success) {
          setState((oldState) => ({
            ...oldState,
            submiting: false,
          }));

          return toast.error(
            `Cartão inválido, por favor verifique e tente novamente! Motivo: ${JSON.stringify(
              id.errors
            )}`
          );
        }

        postBody.gatewayReferenceToken = id.data;
        break;
      }
      // break;
    }

    // Dispatch
    const response = await dispatch(doCreateCreditCard(postBody));

    // Response
    if (response.success) {
      return modalToggleHandler();
    }

    toast.error(response.message);
    return setState((oldState) => ({
      ...oldState,
      submiting: false,
    }));
  };

  const handleCancelOrClose = () => {
    formRef.current.resetForm();
    modalToggleHandler();
  };

  return (
    <Modal
      showModal
      toggleModal={handleSubmit}
      cancelHandler={handleCancelOrClose}
      title={state.modalTitle}
    >
      <Formik
        innerRef={formRef}
        validationSchema={Yup.object({
          cardholderName: Yup.string()
            .required("Preencha o nome do titular.")
            .matches(/^\s*[\S]+(\s[\S]+)+\s*$/, "Informe também o sobrenome"),
          ccnumber: Yup.string().required("Preencha o número do cartão."),
          securityNumber: Yup.string().required("Preencha o código de verificação."),
          expiration: Yup.string()
            .required("Preencha a data de validade.")
            .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, "Formato inválido."),
          cardholderDocument: Yup.string().required("Preencha a data de validade."),
        })}
        validate={(a1) => {
          if (!_.isEqual(a1, formikInitial))
            setState({
              ...state,
              disableCancel: false,
            });
          else {
            setState({
              ...state,
              disableCancel: true,
            });
          }
        }}
        initialValues={formikInitial}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <Form>
            <div className="form-group">
              <div
                className={classnames("credit-card-input-container", {
                  "mask-active": ccIcon,
                })}
              >
                <Field name="ccnumber">
                  {({ field, form }) => (
                    <IMaskInput
                      {...field}
                      autoComplete="cc-number"
                      placeholder="Número do cartão"
                      className="form-control"
                      mask={inputMasks.creditCardMask}
                      dispatch={(appended, dynamicMasked) => {
                        const number = (dynamicMasked.value + appended).replace(/\D/g, "");
                        for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
                          const re = new RegExp(dynamicMasked.compiledMasks[i].regex);
                          if (number.match(re) != null) {
                            setCcIcon(svgCreditCard(dynamicMasked.compiledMasks[i].cardtype));

                            return dynamicMasked.compiledMasks[i];
                          }
                        }
                      }}
                      onBlur={(e) => {
                        form.handleBlur(e);
                        form.setFieldValue("ccnumber", e.target.value);
                      }}
                      type="tel"
                    />
                  )}
                </Field>
                <svg
                  id="ccicon"
                  className="ccicon"
                  width="750"
                  viewBox="0 0 750 471"
                  version="1.1"
                  dangerouslySetInnerHTML={{ __html: ccIcon }}
                />
              </div>
              <ErrorMessage className="error-message" component="div" name="ccnumber" />
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field
                    autoComplete="cc-name"
                    component="input"
                    name="cardholderName"
                    placeholder="Nome do titular do cartão"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage className="error-message" component="div" name="cardholderName" />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <Field name="cardholderDocument">
                    {({ field }) => (
                      <IMaskInput
                        {...field}
                        autoComplete="cc-cpf"
                        component="input"
                        placeholder="CPF do titular"
                        type="text"
                        className="form-control"
                        mask="000.000.000-00"
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    className="error-message"
                    component="div"
                    name="cardholderDocument"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field name="expiration">
                    {({ field }) => (
                      <IMaskInput
                        {...field}
                        autoComplete="cc-exp"
                        component="input"
                        placeholder="Data de validade"
                        type="text"
                        className="form-control"
                        mask="00/00"
                      />
                    )}
                  </Field>
                  <ErrorMessage className="error-message" component="div" name="expiration" />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <Field
                    autoComplete="cc-csc"
                    component="input"
                    name="securityNumber"
                    placeholder="Código de verificação"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage className="error-message" component="div" name="securityNumber" />
                </div>
              </div>
            </div>
            <hr />

            <div className="actions text-end">
              <button
                className="btn mr15 size--large default"
                type="button"
                onClick={() => handleCancelOrClose()}
              >
                Cancelar
              </button>
              <button disabled={state.submiting} className="btn green size--large" type="submit">
                Adicionar cartão
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

// const mapDispatchToProps = (dispatch) => {
//   const binders = bindActionCreators(
//     {
//       ...BillingActions,
//     },
//     dispatch
//   );
//   return {
//     ...binders,
//     dispatch,
//   };
// };

// export default connect(null, mapDispatchToProps)(CreditCardModal);
