import React from "react";
import ReactPaginate from "react-paginate";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  FaChevronRight,
  FaFilter,
  FaChevronLeft,
  FaChevronDown,
  FaTrashAlt,
  FaChrome,
} from "react-icons/fa";
import { format } from "@flasd/whatsapp-formatting";
import { checkExtension, swalMiddleware as Swal } from "helpers";

// List comp
import moment from "moment";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import {
  getNotificationsRequest,
  updateNotificationsRequest,
  updateNotificationsBatchRequest,
} from "store/modules/notifications/actions";
import MessageTemplate from "models/message-template";

// Components
import Modal from "components/modal";
import PageList from "components/page-list";
import SelectionResume from "components/page-list/selection-resume";
import LoaderBlock from "components/loader-bg";
import DropdownButton from "components/dropdown-button/dropdown";
import Filters from "components/orders/filters";
import FiltersResume from "components/orders/filters-resume";
import PageTitle from "components/page-title";

class MessagePool extends PageList {
  // eslint-disable-next-line class-methods-use-this
  get availableFilters() {
    return [
      {
        id: 1,
        label: "Data de Criação",
        param: "created_at",
        type: "daterange",
      },

      {
        id: 3,
        label: "Status",
        param: "triggers",
        type: "checkbox",
        options: [
          {
            slug: "PACKAGE_TRACKING_CODE_GENERATED",
            label: "Rastreio gerado",
          },
          {
            slug: "PACKAGE_POSTED",
            label: "Postado",
          },

          {
            slug: "PACKAGE_IN_TRANSIT",
            label: "Em trânsito",
          },

          {
            slug: "PACKAGE_WAITING_WITHDRAW",
            label: "Aguardando retirada",
          },

          {
            slug: "PACKAGE_IN_ROUTE_TO_DELIVERY",
            label: "Saiu p/ entrega",
          },

          {
            slug: "PACKAGE_DELIVERED",
            label: "Entregue",
          },

          {
            slug: "PACKAGE_EXCEPTION",
            label: "Atenção",
          },

          {
            slug: "PACKAGE_RETURNED_TO_SENDER",
            label: "Devolvido",
          },

          {
            slug: "PACKAGE_TAXED",
            label: "Taxado",
          },

          {
            slug: "PACKAGE_TRACKING_CODE_CHANGED",
            label: "Rastreio mudou",
          },
        ],
      },

      {
        id: 4,
        label: "Somente enviadas",
        description: "Não inclui mensagens descartadas.",
        param: "has_sent",
        type: "switch",

        options: [false, true],
      },

      {
        id: 5,
        label: "Somente descartadas",
        param: "has_discarded",
        type: "switch",

        options: [false, true],
      },

      {
        id: 7,
        label: "Paginação",
        param: "page",
        type: "string",
        defaultValue: 1,
        hidden: true,
      },

      {
        id: 8,
        label: "Itens por página",
        param: "page_size",
        type: "string",
        defaultValue: 10,
        hidden: true,
      },
    ];
  }

  constructor(props) {
    super(props);
    const parentState = super.getMasterState(0);

    this.resourceClass = MessageTemplate;
    this.loadAction = getNotificationsRequest;

    this.state = {
      ...parentState,
      extensionEnabled: false,
      extensionNeedsUpdate: false,
      // lastExtensionVersion: '',
    };

    this.defaultFilters = {
      target: "CUSTOMER",
      channels: "WHATSAPP",
      has_sent: false,
      has_discarded: false,
      store_uuid: props.currentMerchant?.uuid,
    };
  }

  async componentDidMount() {
    const extensionEnabled = await checkExtension();
    const minVer = process.env.REACT_APP_EXTENSION_MIN_VERSION;
    const extensionNeedsUpdate = extensionEnabled && extensionEnabled !== minVer;

    this.setState((oldState) => ({
      ...oldState,
      extensionEnabled,
      extensionNeedsUpdate,
      minVer,
    }));

    super.componentDidMount();
  }

  async handleSendMessage(message) {
    const { allItemsSelected, filters, extensionEnabled, extensionNeedsUpdate } = this.state;

    if (allItemsSelected) {
      return toast.error("Selecione um lote de até 30 por vez.");
    }

    const confirm = await Swal.fire({
      title: "Enviar mensagens",
      text: `Ao confirmar, você está autorizando o envio das mensagens.`,
      icon: "success",
      confirmButtonText: "Enviar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      customClass: {
        container: "swal-iumy",
      },
    });

    if (!confirm.isConfirmed) return false;

    const isSingle = typeof message === "object";
    let messages = isSingle ? [message] : [];
    let messageUuids = isSingle ? [message.uuid] : [];

    if (!isSingle) {
      // Map selected orders;
      const selected = this.getAllSelectedLines(this.activeFilters);

      // Check
      if (!selected.length) return toast.warning("Nenhum pedido selecionado para enviar.");

      // Save orderUuids
      messageUuids = selected.list.uuids;
      messages = selected.list.objs;
    }

    // Update Loader
    this.modifyLine(messageUuids, (_message) => {
      _message.isUpdating = !_message.isUpdating;

      return _message;
    });

    let success = false;

    const numbers = ["5511991903105" /*"5511963588192", "5511953718251", "5511985748411"*/]; // Eu, Will, Let

    if (extensionEnabled && !extensionNeedsUpdate) {
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        {
          type: "BG/UI_SEND_MESSAGE",
          payload: messages.map((m) => ({
            uuid: m.uuid,
            message: m.content,
            to:
              window.location.hostname === "localhost"
                ? numbers[Math.floor(Math.random() * numbers.length)]
                : m.numberTo.replace(/\D+/g, ""),

            additionalData: {
              status: m.status,
              orderName: m.orderName,
              orderUrl: `https://app.iumy.com.br${m.orderUrl}`,
            },
          })),
        },
        (response) => {
          if (!response.success) success = false;
        }
      );
    } else {
      window.open(message.whatsappSendUrl.replace("web.whatsapp", "api.whatsapp"), "blank");
    }

    const { success: result } = await updateNotificationsRequest(
      messageUuids.map((uuid) => ({
        uuid,
        hasSent: true,
      }))
    );

    success = result;

    // If archive was success
    if (success) {
      toast.success(
        messageUuids.length > 1
          ? `${messageUuids.length} mensagens foram enviadas.`
          : `A mensagem foi enviada.`
      );

      // pre load orders
      return this.loadResources({
        ...MessagePool.filtersToObj(filters, true),
      });
    }

    toast.error("Ocorreu um erro ao enviar as mensagens. Por favor contate o suporte.");

    // Update Loader
    this.modifyLine(messageUuids, (order) => {
      order.isUpdating = false;

      return order;
    });

    return false;
  }

  async handleDiscardMessage(message) {
    const confirm = await Swal.fire({
      title: "Descartar mensagens?",
      text: `Deseja descartar as mensagens selecionadas?`,
      icon: "error",
      confirmButtonText: "Descartar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      heightAuto: false,
      customClass: {
        container: "swal-iumy",
      },
    });

    if (!confirm.isConfirmed) return false;

    const { filters, allItemsSelected, metadata } = this.state;
    const activeFilters = PageList.filtersToObj(filters, false);
    const { page, ...newActiveFilters } = activeFilters;

    const isSingle = typeof message === "object";
    let messageUuids = isSingle ? [message.uuid] : [];

    if (!isSingle) {
      // Map selected orders;
      const selected = this.getAllSelectedLines(this.activeFilters);

      // Check
      if (!selected.length) return toast.warning("Nenhum pedido selecionado para arquivar.");

      // Save orderUuids
      messageUuids = selected.list.uuids;
    }

    // Update Loader
    this.modifyLine(messageUuids, (_message) => {
      _message.isUpdating = !_message.isUpdating;

      return _message;
    });

    let success = false;

    if (allItemsSelected) {
      const { success: result } = await updateNotificationsBatchRequest(
        { ...newActiveFilters, channels: "WHATSAPP", target: "CUSTOMER" },
        {
          hasDiscarded: true,
        }
      );

      success = result;
    } else {
      const { success: result } = await updateNotificationsRequest(
        messageUuids.map((uuid) => ({
          uuid,
          hasDiscarded: true,
        }))
      );

      success = result;
    }

    // If archive was success
    if (success) {
      const count = allItemsSelected ? metadata.totalItems : messageUuids.length;

      toast.success(
        allItemsSelected || messageUuids.length > 1
          ? `${count} mensagens foram descartadas.`
          : `A mensagem foi descartada.`
      );

      // pre load orders
      return this.loadResources({
        ...MessagePool.filtersToObj(filters, true),
      });
    }

    toast.error("Ocorreu um erro ao descartar as mensagens. Por favor contate o suporte.");

    // Update Loader
    this.modifyLine(messageUuids, (order) => {
      order.isUpdating = false;

      return order;
    });

    return false;
  }

  async getResourceMiddleware(currentMerchantUuid, options) {
    return getNotificationsRequest(options);
  }

  togglePreview(message = {}) {
    const previewMessage = message.content ? (
      <div className="whatsapp-preview">
        <span className="baloon" dangerouslySetInnerHTML={{ __html: format(message.content) }} />
      </div>
    ) : (
      ""
    );

    this.setState((state) => ({
      ...state,
      previewMessage,
      previewOpened: !state.previewOpened,
    }));
  }

  render() {
    const {
      loading,
      metadata,
      filters,
      filterOpened,
      list,
      allSelectorActive,
      previewOpened,
      previewMessage,
      allItemsSelected,
      extensionNeedsUpdate,
      minVer,
      extensionEnabled,
    } = this.state;

    const activeFilters = PageList.filtersToObj(filters, false);

    return (
      <div className="page message-pool-list container list-with-filter">
        <PageTitle pageTitle="Fila de mensagens" />

        <header className="page-header">
          <div className="row">
            <div className="col flex-d-column">
              <h1 className="title">Lista de mensagens</h1>
              <span className="ml25 gray">{metadata.totalItems} mensagens encontrados</span>
            </div>

            {!extensionEnabled && (
              <div className="col text-end">
                <a
                  href="https://chrome.google.com/webstore/detail/iumy-extens%C3%A3o-whatsapp/ngnhddiplebeagjihbjopdndceopoadi/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn green size--medium"
                >
                  <FaChrome /> Baixar extensão
                </a>
              </div>
            )}
          </div>
        </header>

        {extensionNeedsUpdate && (
          <div className="card bg-warning-iumy">
            <div className="card-body">
              <p className="card-text">
                Sua extensão está desatualizada. Última versão disponível: {minVer}.Para atualizar
                <a
                  style={{ marginLeft: 5 }}
                  href="https://chrome.google.com/webstore/detail/iumy-extens%C3%A3o-whatsapp/ngnhddiplebeagjihbjopdndceopoadi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  clique aqui.
                </a>{" "}
                (remova e instale novamente)
              </p>
            </div>
          </div>
        )}
        <main className="container-page">
          <div className="filters">
            <button
              type="button"
              className="btn default size--medium"
              onClick={() => this.toggleFilter()}
            >
              <FaFilter /> Filtrar
            </button>

            <DropdownButton
              label="Ações"
              classes="btn default size--medium"
              options={[
                {
                  value: "discard",
                  label: "Descartar mensagens",
                  callback: () => this.handleDiscardMessage(),
                  disabled: !list.filter((o) => o.isSelected).length,
                },
                {
                  value: "send",
                  label: "Enviar mensagens",
                  callback: () => this.handleSendMessage(),
                  disabled:
                    !extensionEnabled ||
                    extensionNeedsUpdate ||
                    !list.filter((o) => o.isSelected).length ||
                    allItemsSelected,
                },
              ]}
            />
          </div>

          <FiltersResume
            activeFilters={filters.filter((f) => !f.hidden)}
            handleFilter={this.handleFilter.bind(this)}
            clearFilters={this.clearFilters.bind(this)}
            className="mb10"
          />

          <SelectionResume
            selectedCount={list.filter((o) => o.isSelected).length}
            totalItems={metadata.totalItems}
            maxPageCount={metadata.count}
            handleSelectAllItems={this.toggleSelectExtraResources}
            isExtraResourcesSelected={allItemsSelected}
            className="mb10"
          />

          {loading ? (
            <LoaderBlock blocks={8} width={5} />
          ) : (
            <table className="iumy-table desktop order-list-wrapper">
              <thead>
                <tr>
                  <th className="td-15">
                    <button
                      type="button"
                      aria-label="Selecionar/desselecionar todos os pedidos."
                      onClick={() => this.toggleSelectAllResources()}
                      className={classnames("im-checkbox", { checked: allSelectorActive })}
                    />
                  </th>

                  <th style={{ minWidth: 125 }}>
                    Pedido <FaChevronDown />
                  </th>

                  <th className="td-40">
                    Data de Criação <FaChevronDown />
                  </th>

                  <th>
                    Origem <FaChevronDown />
                  </th>

                  <th>
                    Tipo <FaChevronDown />
                  </th>

                  <th>
                    Mensagem <FaChevronDown />
                  </th>

                  <th aria-label="Enviar" />
                </tr>
              </thead>

              <tbody>
                {list.length > 0 ? (
                  list.map((message) => (
                    <tr key={message.uuid} className={classnames({ updating: message.isUpdating })}>
                      <td>
                        <button
                          type="button"
                          aria-label="Selecionar pedido"
                          onClick={() => this.toggleSelectResource(message)}
                          className={classnames("im-checkbox", { checked: message.isSelected })}
                        />
                      </td>

                      <td>
                        <strong className="f14">
                          <Link to={message.orderUrl}>{message.orderName}</Link>
                        </strong>
                        <small style={{ display: "block" }} className="gray">
                          {message.numberTo}
                        </small>
                      </td>
                      <td className="f12">
                        {moment().format("DD/MM/YYYY")}{" "}
                        <span className="block gray f11">
                          {moment(message.createdAt).fromNow()}
                        </span>
                      </td>
                      <td className="f13">
                        {message.context === "RETURNAL" ? (
                          "Log. Reversa"
                        ) : (
                          <>
                            Rastreio{" "}
                            <small style={{ display: "block" }} className="gray">
                              {message.packageTrackingNumber}
                            </small>
                          </>
                        )}
                      </td>

                      <td>
                        <span
                          className={classnames(
                            "iumy-badge inline-block f10 mb5",
                            message.status.code
                          )}
                        >
                          {message.status.label}
                        </span>
                      </td>

                      <td>
                        <button
                          type="button"
                          onClick={() => this.togglePreview(message)}
                          className="btn btn-text f13"
                        >
                          Pré-visualizar
                        </button>
                      </td>

                      <td className="actions">
                        {!message.hasDiscarded && (
                          <button
                            type="button"
                            onClick={() => this.handleDiscardMessage(message)}
                            className="btn btn-discard btn-danger size--small"
                          >
                            <FaTrashAlt />
                          </button>
                        )}

                        {message.hasSent ? (
                          <button
                            type="button"
                            className="btn gray size--small"
                            onClick={() => this.handleSendMessage(message)}
                          >
                            Já enviado
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => this.handleSendMessage(message)}
                            className="btn green size--small"
                          >
                            Enviar
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      Nenhuma notificação pendente, novas serão geradas assim que existirem
                      movimentações.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </main>

        <footer className="footer-actions">
          <div className="row">
            <div className="col">
              <span className="items-per-page">
                <DropdownButton
                  label={activeFilters.page_size ? activeFilters.page_size : 10}
                  classes="btn default size--medium"
                  callback={(option) => {
                    this.handleFilter("page_size", option);
                  }}
                  options={[
                    {
                      value: 10,
                      label: 10,
                    },
                    {
                      value: 20,
                      label: 20,
                    },
                    {
                      value: 30,
                      label: 30,
                    },
                  ]}
                />
                <span>itens por página</span>
              </span>
            </div>

            <div className="col flex-right">
              {!loading && (
                <ReactPaginate
                  containerClassName="pagination"
                  previousLabel={<FaChevronLeft />}
                  nextLabel={<FaChevronRight />}
                  disableInitialCallback
                  marginPagesDisplayed={1}
                  breakLabel="..."
                  activeClassName="active"
                  initialPage={activeFilters.page ? activeFilters.page - 1 : 0}
                  pageCount={metadata.totalPages}
                  onPageChange={(e) => this.handleFilter("page", e.selected + 1)}
                />
              )}
            </div>
          </div>
        </footer>

        <Filters
          availableFilters={this.availableFilters}
          filterStatus={filterOpened}
          activeFilters={filters}
          toggleFilter={this.toggleFilter.bind(this)}
          handleFilter={this.handleFilter.bind(this)}
          clearFilters={this.clearFilters.bind(this)}
          handle={this.handleFilter.bind(this)}
        />

        <Modal
          title="Pré-visualizar mensagem"
          showModal={previewOpened}
          toggleModal={() => this.togglePreview()}
          buttonPrimaryLabel="Ok, fechar!"
        >
          {previewMessage}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
});

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePool);
