import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { useOutsideClick } from "helpers";

function DropdownButton({
  classes,
  label,
  children,
  container,
  onClickCb,
  closeOnClick = true,
  useChevs = true,
}) {
  const [isOpened, toggleOpened] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleOpened);

  const Container = container || "ul";

  return (
    <div className="el-dropdown" ref={wrapperRef}>
      <button
        type="button"
        className={`${classes} ${isOpened ? "opened" : ""}`}
        onClick={() => {
          onClickCb();
          toggleOpened(!isOpened);
        }}
      >
        {label} {useChevs && <>{!isOpened ? <FaChevronDown /> : <FaChevronUp />}</>}
      </button>

      <Container
        onClick={() => (closeOnClick ? toggleOpened(!isOpened) : false)}
        className={`dropdown-wrapper ${isOpened ? "opened" : ""}`}
      >
        {children}
      </Container>
    </div>
  );
}

export default DropdownButton;
