import { getCookie, setCookie } from "helpers";
import React, { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { callPipeDreamToSave } from "store/modules/merchants/actions";

export default function FeedbackFloat() {
  const { user } = useSelector((state) => state.auth);
  const ignoreSuggest = !!getCookie("ignoreSuggest");

  useEffect(() => {
    if (window.Canny)
      window.Canny("identify", {
        appID: "66709bbbd75823792e73c606",
        user: {
          // Replace these values with the current user's data
          email: user.email,
          name: user.firstName,
          id: user.uuid,
        },
      });
  }, []);

  const [visible, setVisible] = useState(!ignoreSuggest);
  return (
    visible && (
      <div className="float-suggestion-label">
        <button
          type="button"
          onClick={() => {
            callPipeDreamToSave(user.email, "clickOnSuggestion");
            setVisible(false);
            setCookie("ignoreSuggest", false, 30);
          }}
        >
          <FaXmark />
        </button>
        <a data-canny-link href="https://iumy.canny.io/sugestoes" target="_blank" rel="noreferrer">
          Envie sugestão
        </a>
      </div>
    )
  );
}
