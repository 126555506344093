import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ auth, comp: Component, children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      if (auth.isAuthenticated) return Component ? <Component /> : children;
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { referrer: location.pathname },
          }}
        />
      );
    }}
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
