import React from "react";
import { useSelector } from "react-redux";
import { matchPath, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

function MenvCallback({ location }) {
  const { currentMerchant } = useSelector((state) => state.merchants);

  // Get params
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const storeUuid = searchParams.get("state");

  if (!storeUuid || !code) {
    toast.error("Não foi possível autorizar sua solicitação");
    return <Redirect to={`/${storeUuid || currentMerchant.uuid}/dashboard`} />;
  }

  return (
    <Redirect
      to={{
        pathname: `/${storeUuid}/apps/melhor_envio`,
        state: {
          code,
        },
      }}
    />
  );
}

function NuvemshopCallback({ location }) {
  const { currentMerchant } = useSelector((state) => state.merchants);

  function callbackError(storeUuid) {
    toast.error("Não foi possível autorizar sua solicitação");
    return <Redirect to={`/${storeUuid || currentMerchant.uuid}/dashboard`} />;
  }

  try {
    // Get params
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const { storeUuid } = JSON.parse(window.atob(state));

    // Put error if hasn`t props
    if (!storeUuid || !code) return callbackError(storeUuid);

    // Default redir
    const redirObj = {
      pathname: `/${storeUuid}/apps/nuvemshop`,
      state: {
        code,
        callbackType: "nuvemshop",
      },
    };

    return <Redirect to={redirObj} />;
  } catch (ex) {
    return callbackError();
  }
}

export default function CallbackApps({ location }) {
  const {
    params: { appSlug },
  } = matchPath(location.pathname, {
    path: "/apps/:appSlug/callback",
    exact: false,
  });

  switch (appSlug) {
    case "melhor-envio":
      return <MenvCallback location={location} />;

    case "nuvemshop":
      return <NuvemshopCallback location={location} />;

    default:
      throw Error("App não encontrado");
  }
}
