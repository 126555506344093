import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import * as Chartjs from "chart.js/auto";
import { Chart } from "react-chartjs-2";

import { getPackagesStatsRequest } from "store/modules/merchants/actions";
import LoaderBG from "components/loader-bg";

export default function SalesStatisticsContainer({ filter, storeUuid }) {
  const [state, setState] = useState({
    loading: true,
    error: null,
    chartType: "bar",
    // chartTypeComponent: Line,

    chartConfig: {
      plugins: {
        legend: {
          display: false,
        },
      },
      responsive: true,
      tension: 0.3,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
    chartData: {},
  });

  const changeChartType = (type = "bar") =>
    setState((oldState) => ({
      ...oldState,
      chartType: type,
      chartData: {
        ...oldState.chartData,
        datasets: oldState.chartData.datasets.map((dataset) => {
          dataset.type = type;

          if (type === "line") dataset.backgroundColor = dataset.lineColor;
          else dataset.backgroundColor = dataset.barColor;

          return dataset;
        }),
      },
    }));
  const createDataSet = (label, key, barColor, lineColor) => {
    const dataSet = {
      label,
      key,
      type: state.chartType,
      data: [],

      backgroundColor: state.chartType === "bar" ? barColor : lineColor,
      barColor,
      lineColor,
      borderColor: barColor,
      fill: true,
    };

    return dataSet;
  };

  const traitData = (data) => {
    // Models
    const labels = [];
    const datasets = [
      createDataSet("Devolvidos", "RETURNED_TO_SENDER", "#f59253", "#f5915363"),
      createDataSet("Atenção", "EXCEPTION", "#f9cf04", "#f9d00452"),
      createDataSet("Entregues", "DELIVERED", "#48e890", "#48e89044"),
    ];

    // Trait dates
    const groupByDate = _.chain(data).orderBy("date").groupBy("date").value();
    const keys = Object.keys(groupByDate);
    const diffDays = moment(keys[1]).diff(moment(keys[0]), "days", true);
    const diffType = diffDays > 28 && keys.length > 10 ? "month" : "days";

    // Trait
    // if (diffType === "month") {
    keys.forEach((date) => {
      const dates = groupByDate[date];

      // Create labels
      const label =
        diffType === "month"
          ? moment.utc(date).format("MMMM/YY")
          : moment.utc(date).format("DD/MM");
      labels.push(label);

      datasets.forEach((dset) => {
        const found = dates.find((d) => d.status === dset.key);
        if (found) dset.data.push(found.quantity);
        else dset.data.push(0);
      });
    });
    // }

    return {
      labels,
      datasets,
    };
  };

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getPackagesStatsRequest(storeUuid, filter);

      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          fetchedData: data,
          chartData: data.length > 0 ? traitData(data) : null,
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  return (
    <div className="main-chart">
      <div className="iumy-card">
        <div className="inner heading">
          <div className="description">
            <span className="card-title">Visão geral</span>
            <span className="card-description">
              Veja a diferença entre entregas, falhas e devoluções
            </span>
          </div>

          {state.chartData && (
            <div className="chart-type-selector">
              <select
                className="form-control"
                defaultValue={state.chartType}
                onChange={(e) => changeChartType(e.target.value)}
              >
                <option value="bar">Barras</option>
                <option value="line">Linhas</option>
              </select>
            </div>
          )}
        </div>
        <hr />
        <div className="inner">
          {state.loading ? (
            <LoaderBG />
          ) : (
            <>
              {!state.error ? (
                <>
                  {!state.chartData ? (
                    <span style={{ fontSize: 14, textAlign: "center", display: "block" }}>
                      <span style={{ marginRight: 5, display: "inline-block" }}>🥺</span> Ops parece
                      que não encontramos nenhum dado no período selecionado.
                    </span>
                  ) : (
                    <Chart options={state.chartConfig} data={state.chartData} redraw />
                  )}
                </>
              ) : (
                state.error
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
