import { immerable } from "immer";

import Status from "./status";
import Price from "../price";
import Move from "./move";

export default class Package {
  constructor(options) {
    this[immerable] = true;
    this.createdAt = options.createdAt;
    this.fulfillmentReferenceId = options.fulfillmentReferenceId;
    this.aliexpressOrderNumber = options.aliexpressOrderNumber;
    this.originTrackingNumber = options.originTrackingNumber;
    this.trackingUrl = options.trackingUrl;
    this.status = new Status(options.status);

    // Tax
    this.isTaxPaid = options.isTaxPaid;
    this.taxValue = new Price(options.taxValue);
    this.taxBankslipBarCode = options.taxBankslipBarCode;
    this.taxBankslipDueDate = options.taxBankslipDueDate;
    this.taxBankslipUrl = options.taxBankslipUrl;
    this.taxDitUrl = options.taxDitUrl;

    this.isHidden = options.isHidden;
    this.trackingCompany = options.trackingCompany;
    this.trackingNumber = options.trackingNumber;
    this.updatedAt = options.updatedAt;
    this.uuid = options.uuid;
    this.moves = [...options?.moves?.map((m) => new Move(m))];
    this.lastMove = this.moves.length ? this.moves[0] : null;
  }
}
