import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";

// Types
import { ReturnStatusTypes } from "models/returnal/returnal";

// Componenets
import CheckItemsModal from "./check-items-modal";

export default function ReturnProductList({
  products = [],
  currentStatus,
  viewAttachment,
  updateItemAction,
}) {
  const [currentCheckingItem, setCheckItem] = useState(null);

  return (
    <>
      <ul className="returnal-product-list">
        {products.map((p) => {
          let checkStatus;

          // Define check status
          if (p.conferenceQuantity === p.quantity) checkStatus = "full";
          else if (p.conferenceQuantity < p.quantity && p.conferenceQuantity > 0)
            checkStatus = "partially";
          else checkStatus = "none";

          return (
            <li key={p.uuid} className="returnal-product-list--item">
              <div className="rpl--item-first-line">
                {/* Product Thumb + Qty */}
                <div className="rpl--item-product-thumb-qty-wrap">
                  <div className="rpl--item-product-thumb-wrap">
                    <img src={p.imageSrc} alt={p.name} className="rpl--item-product-thumb" />
                  </div>

                  <span alt="Quantidade" className="rpl--item-product-qty">
                    {p.quantity}
                  </span>
                </div>

                {/* Product Name, Price, Reason... */}
                <div className="rpl--item-product-infos-wrap">
                  <span className="rpl--item-product-title block">
                    <strong>{p.title}</strong> | {p.variantTitle}
                  </span>

                  <span className="rpl--item-product-returnal-reason block">
                    Motivo: <u>{p.rule.reason}</u>
                  </span>

                  <div className="rpl--item-product-returnal-price-n-payment">
                    <span className="rpl--item-product-returnal-price">{p.price.formated}</span>
                    <span className={`iumy-badge ${p.returnalType.code}`}>
                      {p.returnalType.label}
                    </span>
                    {/* COUPON */}
                  </div>
                </div>

                {/* Product Actions like edit or checkbox... */}
                <div className="rpl--item-product-actions">
                  {/* {currentStatus === ReturnStatusTypes.PENDING && (
                    <button type="button" className="rpl--item-product-edit-action">
                      Editar
                    </button>
                  )} */}

                  {currentStatus === ReturnStatusTypes.DELIVERED && (
                    <button
                      type="button"
                      className={`rpl--item-product-check-action ${checkStatus}`}
                      onClick={() => setCheckItem(p)}
                      data-comment={p.conferenceComment}
                    >
                      {checkStatus === "full" && <FaCheck />}
                      {checkStatus === "partially" && <span className="line-stroke" />}
                    </button>
                  )}

                  {currentStatus === ReturnStatusTypes.CONFERRED && checkStatus === "none" && (
                    <span className="rpl--item-product-check-status none">Não recebido</span>
                  )}

                  {currentStatus === ReturnStatusTypes.CONFERRED && checkStatus === "partially" && (
                    <span className="rpl--item-product-check-status partially">
                      Parcialmente recebido
                    </span>
                  )}
                </div>
              </div>

              <div className="rpl--item-second-line">
                <div className="rpl--item-product-returnal-comment-wrap">
                  <strong className="rpl--item-product-returnal-block-title block">
                    Comentário do cliente
                  </strong>
                  <p className="rpl--item-product-returnal-comment">
                    {p.comment ? p.comment : "-"}
                  </p>
                </div>

                {p.attachments.length > 0 && (
                  <div className="rpl--item-product-attachment-wrap">
                    <strong className="rpl--item-product-returnal-block-title block">Anexos</strong>

                    <ul className="rpl--item-product-attachment-list">
                      {p.attachments.map((a) => (
                        <li className="rpl--item-product-attachment-item">
                          <button
                            type="button"
                            onClick={() => viewAttachment(a)}
                            className="rpl--item-product-attachment-item--view-btn"
                          >
                            <img
                              src={a}
                              className="rpl--item-product-attachment-item-image"
                              alt="Anexo pelo cliente"
                            />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {currentCheckingItem && (
        <CheckItemsModal
          item={currentCheckingItem}
          toggle={() => setCheckItem()}
          updateItemAction={updateItemAction}
        />
      )}
    </>
  );
}
