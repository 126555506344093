export default () => {
  const script = document.createElement("script");
  script.src = "https://js.iugu.com/v2";
  script.async = true;
  document.body.appendChild(script);

  const env = process.env.REACT_APP_ENV;

  script.addEventListener("load", () => {
    // DTM is loaded
    const { Iugu } = window;

    // Setup IUGU
    Iugu.setAccountID("DBE5D719B3E84B8993F964A1415719DC");
    Iugu.setTestMode(env === "local");

    console.log("Iugu loaded", env === "local");
  });
};
