import React, { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");

// Register Spanblock
class SpanBlock extends Inline {
  static formats() {
    return true;
  }
}

SpanBlock.blotName = "spanblock";
SpanBlock.className = "iumy--coupon";
SpanBlock.tagName = "span";
Quill.register(SpanBlock);

/*
 * Custom toolbar component including insertStar button and dropdowns
 */

const renderModule = (mod) => {
  if (typeof mod === "string") {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    return <button type="button" className={`ql-${mod}`} />;
  }

  if (typeof mod === "object") {
    const key = Object.keys(mod)[0];
    const param = mod[key];

    if (typeof param === "string") {
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <button type="button" className={`ql-${key}`} value={param} />;
    }

    if (typeof param === "object" && Array.isArray(param)) {
      return (
        <select className={`ql-${key}`}>
          {param.map((p) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <option value={p} />
          ))}
        </select>
      );
    }

    if (key.indexOf("custom--") > -1 && param instanceof Object) {
      const nkey = key.replace("custom--", "");

      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return (
        <button type="button" className={`ql-${nkey}`}>
          <param.icon />
        </button>
      );
    }
  }

  return false;
};

const CustomToolbar = ({ modules }) => (
  <div id="toolbar">
    {modules.map((m) => {
      if (typeof m === "object") {
        return <span className="ql-formats">{m.map((subM) => renderModule(subM))}</span>;
      }

      return m.map((subM) => renderModule(subM));
    })}

    {/* <span className="ql-formats">
      <button className="ql-insertStar">Estrela</button>
    </span> */}
  </div>
);

const CustomQuillEditor = ({
  placeholder,
  onChange,
  value,
  toolbarModules,
  customModules,
  formats = [],
}) => {
  const handleChange = (html) => onChange(html);

  const quillRef = useRef();

  const customModulesHandlers = customModules
    ? Object.keys(customModules).reduce((obj, key) => {
        const mod = customModules[key];

        return Object.assign(obj, { [key]: mod.handler });
      }, {})
    : {};

  const modules = {
    toolbar: {
      container: "#toolbar",
      // handlers: {
      //   ...customModulesHandlers,
      // },
    },
  };

  const customToolbarModules = customModules
    ? Object.keys(customModules).map((key) => {
        const mod = customModules[key];
        return {
          [`custom--${key}`]: mod,
        };
      })
    : [];

  useEffect(() => {
    const quill = quillRef.current.getEditor();

    Object.keys(customModulesHandlers).forEach((key) => {
      const mod = customModules[key];
      const buttonElement = document.querySelector(".ql-iumyCoupon");
      buttonElement.addEventListener("click", () => mod.handler(quill));
    });
  }, []);

  return (
    <div className="text-editor">
      <CustomToolbar modules={[...toolbarModules, [...customToolbarModules]]} />

      <ReactQuill
        ref={quillRef}
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        value={value}
      />
    </div>
  );
};

export default CustomQuillEditor;
