import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaExchangeAlt,
  FaCreditCard,
  FaChartBar,
  FaDollarSign,
  FaEye,
  FaEyeSlash,
  FaCog,
  FaSignOutAlt,
  FaCubes,
  FaWhatsapp,
  FaHome,
} from "react-icons/fa";
import { Link, matchPath, withRouter } from "react-router-dom";
import classnames from "classnames";
import MediaQuery from "react-responsive";

// Actions
import { doLogout, enableStreamer, disableStreamer } from "store/modules/auth/reducer";
import { doSelectMerchant } from "store/modules/merchants/actions";
import { toggleSidebar } from "store/modules/context/reducer";

// Components
import MerchantSelector from "components/sidebar/selector";
import Notifications from "components/sidebar/notifications";

// Helpers
import { screenSizes } from "helpers/constants";
import { renderRoute } from "pages/main/routes-list";

// Assets
import Logo from "images/logo-iumy-white.png";

export default withRouter(({ location }) => {
  const { auth, merchants, context } = useSelector((state) => state);
  const { streamerMode } = auth;

  const dispatch = useDispatch();
  const useRouteMatch = (path, exact = false) => matchPath(location.pathname, { path, exact });
  const toggleStreamerMode = () =>
    streamerMode ? dispatch(disableStreamer()) : dispatch(enableStreamer());

  return (
    <div
      className={classnames("sidebar-wrapper", {
        active: context.sidebarMobileOpened,
      })}
    >
      <aside className="main-sidebar">
        <nav>
          {/* Logo */}
          <MediaQuery minWidth={screenSizes.DESKTOP_MIN}>
            <Link to={`/${merchants.currentMerchant.uuid}/dashboard`} className="sidebar-brand">
              {/* <img src={Logo} alt="" className="logo" /> */}
              <img src={Logo} alt="Logo Iumy" className="logo" />
            </Link>
          </MediaQuery>

          <MerchantSelector
            user={auth.user}
            merchants={merchants}
            doSelectMerchant={doSelectMerchant}
            dispatch={dispatch}
            streamerMode={streamerMode}
          />

          {/* Merchant Select Needed */}
          {merchants.currentMerchant && (
            <ul className="menu-items">
              <li
                className={classnames("menu-item", {
                  active: useRouteMatch("/:merchantId/home", true),
                })}
              >
                <Link
                  to={renderRoute("home", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      gap: 12,
                    }}
                  >
                    <span className="icon">
                      <FaHome />
                    </span>
                    Início
                  </div>

                  {context.systemAlerts > 0 && (
                    <span
                      className="system-alert-count"
                      style={{
                        width: 18,
                        height: 18,
                        background: "#C7FD44",
                        borderRadius: 30,
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 12,
                      }}
                    >
                      {context.systemAlerts}
                    </span>
                  )}
                </Link>
              </li>

              <li
                className={classnames("menu-item", {
                  active: useRouteMatch("/:merchantId/dashboard", true),
                })}
              >
                <Link
                  to={renderRoute("dashboard", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <span className="icon">
                    <FaChartBar />
                  </span>
                  Visão geral
                </Link>
              </li>

              <li
                className={classnames("menu-item", {
                  active: useRouteMatch("/:merchantId/orders"),
                })}
              >
                <Link
                  to={renderRoute("orders", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <span className="icon">
                    <FaDollarSign />
                  </span>
                  Pedidos
                </Link>
              </li>

              {merchants.currentMerchant.apps.find((app) => app.type === "IUMY_RETURNAL")
                .isTrocaFacilAvailable && (
                <li
                  className={classnames("menu-item d-none d-sm-block", {
                    active: useRouteMatch("/:merchantId/returns"),
                  })}
                >
                  <Link
                    to={renderRoute("returns", {
                      merchantId: merchants.currentMerchant.uuid,
                    })}
                    className="link-menu"
                  >
                    <span className="icon">
                      <FaExchangeAlt />
                    </span>
                    Trocas/Devoluções
                  </Link>
                </li>
              )}

              <li
                className={classnames("menu-item d-none d-sm-block", {
                  active: useRouteMatch("/:merchantId/message-pool", true),
                })}
              >
                <Link
                  to={renderRoute("message-pool", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <span className="icon">
                    <FaWhatsapp />
                  </span>
                  Fila de Mensagens
                </Link>
              </li>

              {/* Configurations */}
              <li
                className={classnames("menu-item", {
                  active:
                    useRouteMatch("/:merchantId/config", false) ||
                    useRouteMatch("/:merchantId/apps/iumy_returnal", true) ||
                    useRouteMatch("/:merchantId/apps/iumy_tracker", true),
                })}
              >
                <Link
                  to={renderRoute("config-index", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <span className="icon">
                    <FaCog />
                  </span>
                  Configurações
                </Link>
              </li>

              {/* Apps */}
              <li
                className={classnames("menu-item d-none d-sm-block", {
                  active:
                    useRouteMatch("/:merchantId/apps", false) &&
                    !useRouteMatch("/:merchantId/apps/iumy_returnal", true) &&
                    !useRouteMatch("/:merchantId/apps/iumy_tracker", true),
                })}
              >
                <Link
                  to={renderRoute("apps", {
                    merchantId: merchants.currentMerchant.uuid,
                  })}
                  className="link-menu"
                >
                  <span className="icon">
                    <FaCubes />
                  </span>
                  Integrações
                </Link>
              </li>
            </ul>
          )}

          {/* Non-auth methods */}
          <ul className="menu-items">
            <li
              className={classnames("menu-item", {
                active: useRouteMatch("/config/billing", false),
              })}
            >
              <Link to={renderRoute("config-billing")} className="link-menu">
                <span className="icon">
                  <FaCreditCard />
                </span>
                Assinatura
              </Link>
            </li>
          </ul>
        </nav>

        <nav className="user-profile">
          <button
            onClick={toggleStreamerMode}
            className={`streamer-mode-button ${streamerMode ? "active" : ""}`}
            type="button"
          >
            <div className="ico">{streamerMode ? <FaEyeSlash /> : <FaEye />}</div>
            {streamerMode ? "Desativar" : "Modo streamer"}
          </button>
          <ul className="user-actions">
            <li>
              <Notifications />
            </li>

            <li>
              <button
                type="button"
                aria-label="exit"
                className="action-link"
                onClick={() => dispatch(doLogout())}
              >
                <FaSignOutAlt className="exit-icon" /> Sair
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      <button
        type="button"
        aria-label="mobile pusher"
        onClick={() => dispatch(toggleSidebar())}
        className="screen-pusher sidebar"
      />
    </div>
  );
});
