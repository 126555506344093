import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { DTLayer } from "components/data-layer";
import { useSelector } from "react-redux";
import isValidDomain from "is-valid-domain";
import * as Yup from "yup";

// Helpers
import { sanitizeObject } from "helpers";

// Actions
import { doCreateMerchantRequest, doSelectMerchant } from "store/modules/merchants/actions";

// Components
import ImageUploader from "components/image-uploader";
import BottomActions from "../components/bottom-actions";

const initialModel = {
  name: "",
  domain: "",
  logo: "",
  senderEmail: "",
};

export default function StoreInfos({ data, nextStepHandler, dispatch }) {
  const [state, setState] = useState({
    formikInitial: {
      ...initialModel,
      ...data,
    },
    stepValid: false,
    requesting: false,
  });

  const next = async (currentData) => {
    const sanitized = sanitizeObject(currentData, ["email", "senderEmail", "logo", "domain"]);

    // Update state
    setState((oldState) => ({ ...oldState, requesting: true }));

    // Create store
    const createStoreResponse = await dispatch(
      doCreateMerchantRequest({
        ...sanitized,
      })
    );

    // Create ai
    if (createStoreResponse.success) {
      window.history.pushState("", "", `/onboarding?storeUuid=${createStoreResponse.data.uuid}`);

      dispatch(doSelectMerchant(createStoreResponse.data.uuid));

      // TRIAL START
      if (createStoreResponse?.data?.hasCreatedTrialSubscription) {
        DTLayer.dispatch("trialStarted");
      }

      return nextStepHandler(currentData);
    }

    // Todo error
    toast.error(
      createStoreResponse.message ||
        "Ocorreu um erro ao tentar criar a loja, entre em contato com o suporte.",
      {
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );

    return setState((oldState) => ({ ...oldState, requesting: false }));
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(100, "O tamanho máximo do nome é de 100 caracteres")
      .required("Preencha o nome loja"),
    senderEmail: Yup.string()
      .required("Informe um e-mail de remetente dos seus e-mails")
      .email("Digite um e-mail válido"),
    domain: Yup.string()
      .max(100, "O tamanho máximo do dominio é de 100 caracteres")
      .required("Preencha o domínio da loja")
      .test("test-name", "Utilize um domínio válido (sem www. ou http://).", (value) =>
        isValidDomain(value, { subdomain: true })
      ),
  });

  return (
    <section className="wizard-step step-store-infos">
      <h1 className="step-title">Sobre a loja</h1>
      <p className="description">Conte-nos mais sobre seu negócio!</p>
      <div className="wizard-form-wrap">
        <Formik
          validationSchema={validationSchema}
          validate={(values) => {
            const isValid = validationSchema.isValidSync(values, { abortEarly: false });

            setState((oldState) => ({ ...oldState, formikInitial: values, stepValid: isValid }));
          }}
          initialValues={state.formikInitial}
        >
          {(props) => (
            <Form>
              <div className="form-group">
                <label htmlFor="name">Nome da loja</label>
                <Field
                  className="form-input"
                  type="text"
                  placeholder="Minha Loja Incrível"
                  name="name"
                />
                <ErrorMessage className="input-error" component="span" name="name" />
              </div>
              <div className="form-group">
                <label htmlFor="domain">Domínio da loja (sem www)</label>
                <Field name="domain">
                  {({ field, form }) => (
                    <input
                      {...field}
                      placeholder="minhaloja.com"
                      className="form-input"
                      onChange={(e) => {
                        const val = e.target.value;
                        form.setFieldValue("senderEmail", `nao-responda@${val}`);

                        field.onChange(e);
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage component="span" className="input-error" name="domain" />
              </div>

              <div className="form-group">
                <label htmlFor="senderEmail">
                  E-mail remetente
                  <small>Os clientes verão isso caso você envie e-mail para eles</small>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  placeholder="nao-responda@minhaloja.com"
                  name="senderEmail"
                />
                <ErrorMessage component="span" className="input-error" name="senderEmail" />
              </div>

              <div className="droparea-wrap">
                <label htmlFor="dropAreaInput">
                  Logo Horizontal <small>Utilizado nos e-mails. De preferencia sem fundo</small>
                </label>
                <Field name="logo">
                  {({ form, field }) => (
                    <ImageUploader
                      containerClass="el-dropzone droparea"
                      preload={[field.value]}
                      onUpload={(result) => {
                        if (result.success) {
                          form.setFieldValue("logo", result.data.file);
                        }
                      }}
                      onRemove={() => {
                        form.setFieldValue("logo", "");
                      }}
                    />
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <BottomActions
        enableNext={state.stepValid}
        nextStepHandler={() => next(state.formikInitial)}
        loading={state.requesting}
      />
    </section>
  );
}
