import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FaChevronLeft,
  FaTicketAlt,
  FaTruck,
  FaFileAlt,
  FaExternalLinkAlt,
  FaBox,
} from "react-icons/fa";
import { toast } from "react-toastify";
import classnames from "classnames";
import moment from "moment";
import { swalMiddleware as Swal } from "helpers";
import { renderRoute } from "pages/main/routes-list";
import VMasker from "vanilla-masker";

// => Models
import Returnal, { ReturnStatusTypes } from "models/returnal/returnal";

// => Actions
import {
  doGetReturnDetail,
  doApproveReturnRequest,
  doUpdateReturnalRequest,
  doUpdateReturnalItemRequest,
  doRefundRequest,
  doExchangeRequest,
  doEndRequest,
  doCancelReturnRequest,
} from "store/modules/returns/actions";

// => Componennts
import PageTitle from "components/page-title";
import LoaderBG from "components/loader-bg";
import NotFound from "pages/main/not-found";
import ReturnalItem from "models/returnal/returnal-item";
import PackageMove from "pages/main/orders/detail/components/package-move";
import ReturnProductList from "../components/return-product-list";
import AttachmentModal from "./components/attachment-modal";
import PaymentWidget from "./components/payment-widget";
import TrackingModal from "./components/tracking-modal";

// Helpers
import calcRetunralValues from "../return-helpers";

export default function ReturnDetail({ match }) {
  const { currentMerchant } = useSelector((state) => state.merchants);

  // State
  const [state, setState] = useState({
    returnal: null,
    hasRefundPending: false,
    hasExchangePending: false,

    loading: true,

    // Wizard
    wizardSubmiting: false,
    canceling: false,

    // Attachment modal
    viewingAttachmentUrl: null,

    // Address Modal Status
    editAddressModalOpened: false,
    addressInitialValues: {
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
      city: "",
      provinceCode: "",
      zipcode: "",
    },

    // Moves modal status
    showMoves: false,
  });

  // Rename prop & Status
  const { returnal } = state;

  const { hasRefundPending, hasExchangePending } = calcRetunralValues(returnal);

  const disabledNextStepBtn =
    returnal?.status?.code === ReturnStatusTypes.APPROVAL_IN_PROGRESS ||
    returnal?.status?.code === ReturnStatusTypes.ENDED;

  const actionsStatus = { btnLabel: "", currentStatusDescription: "" };

  if (state.wizardSubmiting) actionsStatus.btnLabel = "Aguarde...";

  if (!state.wizardSubmiting && returnal?.computedStatus.code === ReturnStatusTypes.PENDING) {
    actionsStatus.btnLabel = "Aprovar";
    actionsStatus.currentStatusDescription = "Aprovar ou cancelar reversa";
  } else if (
    !state.wizardSubmiting &&
    [
      ReturnStatusTypes.APPROVAL_IN_PROGRESS,
      ReturnStatusTypes.APPROVED,
      ReturnStatusTypes.TRANSIT,
    ].includes(returnal?.computedStatus.code)
  ) {
    actionsStatus.btnLabel = "Receber";
    actionsStatus.currentStatusDescription = "Forçar entrega ou cancelar reversa";
  } else if (
    !state.wizardSubmiting &&
    returnal?.computedStatus.code === ReturnStatusTypes.DELIVERED
  ) {
    actionsStatus.btnLabel = "Conferir";
    actionsStatus.currentStatusDescription = "Após receber, confira os itens do pacote";
  } else if (
    !state.wizardSubmiting &&
    returnal?.computedStatus.code === ReturnStatusTypes.CONFERRED
  ) {
    actionsStatus.btnLabel = "Finalizar";
    actionsStatus.currentStatusDescription = "Após conferir, realize os pagamentos.";
  }
  // First loading
  useEffect(() => {
    async function loadResource(storeUuid, returnId) {
      const response = await doGetReturnDetail(storeUuid, returnId);

      const stateEvent = {};

      if (response.success) {
        stateEvent.returnal = new Returnal(response.data);
      } else {
        toast.error(response.message);
      }
      stateEvent.loading = false;

      setState((s) => ({
        ...s,
        ...stateEvent,
      }));

      return true;
    }

    const { returnId } = match.params;

    // load order
    loadResource(currentMerchant.uuid, returnId);
  }, []);

  // ===> ACTIONS <===
  // Address Modal Handler
  const toggleAddressModal = () =>
    setState((s) => ({ ...s, editAddressModalOpened: !s.editAddressModalOpened }));

  // View attachment
  const viewAttachment = (viewingAttachmentUrl) =>
    setState((s) => ({ ...s, viewingAttachmentUrl }));

  const toggleShowMoves = () => {
    setState((s) => ({ ...s, showMoves: !s.showMoves }));
  };

  // 1. Approve
  const approveRequest = async () => {
    const { success, data, message } = await doApproveReturnRequest(
      currentMerchant.uuid,
      returnal.uuid
    );

    if (success) {
      const newReturnal = new Returnal(data);

      return { success, stateUpdate: { returnal: newReturnal } };
    }

    return { success, message };
  };

  // 2. Mark received
  const receivePackage = async () => {
    const { success, data, message } = await doUpdateReturnalRequest(
      currentMerchant.uuid,
      returnal.uuid,
      {
        considerDelivered: true,
      }
    );

    if (success) {
      const newReturnal = new Returnal(data);

      return { success, stateUpdate: { returnal: newReturnal } };
    }

    return { success, message };
  };

  // 3. Check Item
  const checkItem = async (itemUuid, options) => {
    const { success, data, message } = await doUpdateReturnalItemRequest(
      currentMerchant.uuid,
      returnal.uuid,
      itemUuid,
      options
    );

    if (success) {
      const newReturnalItem = new ReturnalItem(data);
      const modifiedReturnalItems = returnal.items.map((i) => {
        if (i.uuid === newReturnalItem.uuid) return newReturnalItem;

        return i;
      });

      // Update just modified items
      setState((s) => ({ ...s, returnal: { ...s.returnal, items: modifiedReturnalItems } }));

      return { success, stateUpdate: { returnalItem: newReturnalItem } };
    }

    return { success, message };
  };

  // 4. Check Request
  const checkReturnal = async () => {
    const result = await Swal.fire({
      icon: "iumy",
      title: "Você deseja conferir os itens?",
      text: "Está certo de sua escolha? O cliente deve receber um e-mail de conferência (caso o mesmo esteja habilitado).",
      showConfirmButton: true,
      confirmButtonText: "Confirmar conferência",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (!result.isConfirmed) return { success: false, message: "" };

    const { success, data, message } = await doUpdateReturnalRequest(
      currentMerchant.uuid,
      returnal.uuid,
      {
        status: "CONFERRED",
      }
    );

    if (success) {
      const newReturnal = new Returnal(data);

      return { success, stateUpdate: { returnal: newReturnal } };
    }

    return { success, message };
  };

  // 5.0
  const endReturnalHandler = async () => {
    if (hasRefundPending || hasExchangePending)
      return toast.error("Realize todos pagamentos antes de finalizar.");

    const { success, data, message } = await doEndRequest(currentMerchant.uuid, returnal.uuid);

    if (success) {
      const newReturnal = new Returnal(data);

      return { success, stateUpdate: { returnal: newReturnal } };
    }

    return { success, message };
  };

  // 5.1
  const refundHandler = async (totalRefundDone, paymentMethod, receitUrl) => {
    const { success, data, message } = await doRefundRequest(
      currentMerchant.uuid,
      returnal.uuid,
      totalRefundDone,
      paymentMethod,
      receitUrl
    );

    if (!success) {
      toast.error(message);
      return { success: false, message };
    }

    toast.success("Reembolso confirmado.");

    const newReturnal = new Returnal(data);

    setState((s) => ({ ...s, returnal: newReturnal }));

    return { success };
  };

  // 5.2
  const exchangeHandler = async (code, dueAt, price, freight) => {
    const { success, data, message } = await doExchangeRequest(
      currentMerchant.uuid,
      returnal.uuid,
      code,
      dueAt,
      price,
      freight
    );

    if (!success) {
      toast.error(message);
      return { success: false, message };
    }

    toast.success("Vale-trocas emitido.");

    const newReturnal = new Returnal(data);

    setState((s) => ({ ...s, returnal: newReturnal }));

    return { success };
  };

  // ==> Wizard Submit <==
  const approveHandler = async () => {
    setState((s) => ({ ...s, wizardSubmiting: true }));

    let newState = {};
    let errorMessage = {};

    switch (returnal.computedStatus.code) {
      // Approve request
      case ReturnStatusTypes.PENDING: {
        const { success, stateUpdate, message } = await approveRequest();

        if (success) newState = { ...stateUpdate };
        else errorMessage = message;
        break;
      }

      // Receive package
      case ReturnStatusTypes.TRANSIT:
      case ReturnStatusTypes.APPROVED: {
        const { success, stateUpdate, message } = await receivePackage();

        if (success) newState = stateUpdate;
        else errorMessage = message;
        break;
      }

      // Confer order
      case ReturnStatusTypes.DELIVERED: {
        const { success, stateUpdate, message } = await checkReturnal();

        if (success) newState = stateUpdate;
        else errorMessage = message;

        break;
      }

      case ReturnStatusTypes.CONFERRED: {
        const { success, stateUpdate, message } = await endReturnalHandler();

        if (success) newState = stateUpdate;
        else errorMessage = message;
        break;
      }

      default:
        alert("Erro ao tomar a próxima ação na reversa. Consulte o suporte.");
        break;
    }

    // State
    if (errorMessage) toast.error(errorMessage);

    return setState((s) => ({ ...s, ...newState, wizardSubmiting: false }));
  };

  // ==> Wizard Reject <==
  const rejectHandler = async () => {
    const popupResult = await Swal.fire({
      title: "Deseja cancelar essa reversa?",
      text: "Informe o motivo do cancelamento (este motivo vai para o cliente)",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Salvar",
      cancelButtonText: "Cancelar",
      input: "textarea",
      inputAttributes: {
        placeholder: "Motivo do cancelamento",
        rows: "3",
      },
      customClass: {
        container: "swal-iumy",
      },
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (!popupResult.isConfirmed) return false;

    setState((s) => ({ ...s, canceling: true }));

    const { success, data, message } = await doCancelReturnRequest(
      currentMerchant.uuid,
      returnal.uuid,
      popupResult.value
    );
    setState((s) => ({ ...s, canceling: false }));

    if (!success) return toast.error(message);

    // Update local state
    return setState((s) => ({ ...s, returnal: new Returnal(data) }));
  };

  // Finish returnal if all paymets done
  useEffect(() => {
    async function runFinish() {
      if (
        !hasExchangePending &&
        !hasRefundPending &&
        returnal &&
        returnal.computedStatus.code !== ReturnStatusTypes.ENDED
      ) {
        const { success, stateUpdate } = await endReturnalHandler();

        if (success) setState((s) => ({ ...s, ...stateUpdate, wizardSubmiting: false }));
      }
    }
    runFinish();
  }, [hasExchangePending, hasRefundPending, returnal]);

  // Not found return
  if (!state.loading && !state.returnal) return <NotFound />;

  // Loading return
  if (state.loading)
    return (
      <div className="container">
        <LoaderBG blocks={8} width={5} />
      </div>
    );

  return (
    <div className="page return-detail container">
      <PageTitle pageTitle={`Reversa · ${returnal.name}`} />

      <header className="page-header">
        <Link to={`/${currentMerchant.uuid}/returns`} className="f13 black-70 backbtn">
          <FaChevronLeft /> ver todas as solicitações
        </Link>

        <h1 className="title">
          <div className="header-line-1 mb5">
            Reversa: {returnal.name}
            <span className={classnames("iumy-badge", returnal.status.code)}>
              {returnal.status.label}
            </span>
          </div>

          <small className="created-at block f14 gray">
            Criada em {moment(returnal.createdAt).format("DD/MM/YYYY [às] HH:mm")}
          </small>
        </h1>
      </header>

      <div className="row">
        <div className="col-md-9">
          {/* Returnal Progress Bar */}
          {![ReturnStatusTypes.PENDING_QUOTE, ReturnStatusTypes.CANCELLED].includes(
            returnal.computedStatus.code
          ) && (
            <div className="box-widget-mini returnal-progress-bar-wrap">
              <ul
                className={`returnal-progress-bar ${
                  returnal.computedStatus.code === ReturnStatusTypes.ENDED ? "steps-complete" : ""
                }`}
              >
                <li
                  className={`rpb--step-item ${
                    returnal.computedStatus.code === ReturnStatusTypes.CONFERRED
                      ? "current-step"
                      : ""
                  }`}
                >
                  <hr className="rpb--step-bar" />

                  <span className="rpb--step-number">
                    5 <span className="rpb--step-label">Finalizado</span>
                  </span>
                </li>

                <li
                  className={`rpb--step-item ${
                    [ReturnStatusTypes.TRANSIT, ReturnStatusTypes.DELIVERED].includes(
                      returnal.computedStatus.code
                    )
                      ? "current-step"
                      : ""
                  }`}
                >
                  <hr className="rpb--step-bar" />

                  <span className="rpb--step-number">
                    4 <span className="rpb--step-label">Conferido</span>
                  </span>
                </li>

                <li
                  className={`rpb--step-item ${
                    [ReturnStatusTypes.APPROVED, ReturnStatusTypes.APPROVAL_IN_PROGRESS].includes(
                      returnal.computedStatus.code
                    )
                      ? "current-step"
                      : ""
                  }`}
                >
                  <hr className="rpb--step-bar" />

                  <span className="rpb--step-number">
                    3{" "}
                    <span className="rpb--step-label">
                      {returnal.computedStatus.code === ReturnStatusTypes.DELIVERED
                        ? "Recebido"
                        : "Em trânsito"}
                    </span>
                  </span>
                </li>

                <li
                  className={`rpb--step-item ${
                    returnal.computedStatus.code === ReturnStatusTypes.PENDING ? "current-step" : ""
                  }`}
                >
                  <hr className="rpb--step-bar" />

                  <span className="rpb--step-number ">
                    2 <span className="rpb--step-label">Aprovado</span>
                  </span>
                </li>

                <li className="rpb--step-item">
                  <span className="rpb--step-number">
                    1 <span className="rpb--step-label">Pendente</span>
                  </span>
                </li>
              </ul>

              {/* Payment box */}
              {[ReturnStatusTypes.CONFERRED, ReturnStatusTypes.ENDED].includes(
                returnal.computedStatus.code
              ) && (
                <PaymentWidget
                  returnal={returnal}
                  refundHandler={refundHandler}
                  exchangeHandler={exchangeHandler}
                />
              )}

              {returnal.computedStatus.code !== ReturnStatusTypes.ENDED && (
                <div className="returnal-progress-action-line">
                  <div className="returnal-progress-action-label">
                    <strong>Próxima ação</strong>
                    <span>{actionsStatus.currentStatusDescription}</span>
                  </div>

                  <div className="returnal-progress-actions">
                    {returnal.computedStatus.code !== ReturnStatusTypes.CONFERRED && (
                      <button
                        type="button"
                        className="action primary"
                        onClick={approveHandler}
                        disabled={state.wizardSubmiting || disabledNextStepBtn}
                      >
                        {actionsStatus.btnLabel}
                      </button>
                    )}

                    <button
                      onClick={rejectHandler}
                      type="button"
                      className="action danger"
                      disabled={state.wizardSubmiting || state.canceling}
                    >
                      {state.canceling ? "Cancelando..." : "Cancelar"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Returnal Products */}
          {returnal.computedStatus.code === "CANCELLED" && (
            <div className="box-widget-mini returnal-product-list-wrap">
              <header>Motivo de cancelamento</header>

              <p style={{ padding: 15, margin: 0 }}>{returnal.cancelReason}</p>
            </div>
          )}

          {/* Returnal Products */}
          <div className="box-widget-mini returnal-product-list-wrap">
            <header>Produtos</header>

            <ReturnProductList
              products={returnal.items}
              currentStatus={returnal.computedStatus.code}
              viewAttachment={viewAttachment}
              updateItemAction={checkItem}
            />
          </div>

          {/* Returnal Activities */}
          <div className="box-widget-mini returnal-activities">
            <header>Atividades</header>

            <table className="returnal-activity-table">
              <thead>
                <tr>
                  <th>Ação</th>
                  <th>Data</th>
                  <th>Responsável</th>
                </tr>
              </thead>

              <tbody>
                {returnal.logs.map((log, i) => (
                  <tr key={i}>
                    <td>{log.description}</td>
                    <td>{moment(log.createdAt).format("DD/MM/YYYY [às] HH:mm")}</td>
                    <td>{log.doneBy}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-3 side-column">
          {/* Returnal customer */}
          <div className="box-widget-mini returnal-customer">
            <header>Cliente</header>

            <div className="returnal-customer-wrap">
              <Link
                to={`${renderRoute("orders", {
                  merchantId: currentMerchant.uuid,
                })}?search=${returnal.customer.document || returnal.customer.email}`}
                className="customer-name block"
              >
                <strong>
                  {returnal.customer.firstName} {returnal.customer.lastName}
                </strong>
              </Link>
              {returnal.customer.email} <br />
              CPF: {VMasker.toPattern(returnal.customer.document, "999.999.999-99")} <br />
              {/* (11) 99190-0123 */}
            </div>

            <header className="address-header">
              Endereço
              {/* {returnal.status.code === ReturnStatusTypes.PENDING && (
                <button type="button" className="edit-btn" onClick={toggleAddressModal}>
                  Editar
                </button>
              )} */}
            </header>

            <div className="returnal-address-wrap">
              {returnal.address.address1} <br />
              {returnal.address.address2} {returnal.address.address2 && <br />}
              {returnal.address.city}/{returnal.address.provinceCode}
              <br />
              CEP: {returnal.address.zipcode}
            </div>
          </div>

          {/* Returnal infos */}
          <div className="box-widget-mini returnal-infos">
            <header>Informações</header>

            <ul className="returnal-infos-list">
              <li>
                <div className="returnal-infos-ico">
                  <FaTicketAlt size={20} />
                </div>

                <div className="returnal-infos-content">
                  <strong className="block">Valor em Produtos</strong>
                  {returnal.totalPrice.formated}
                </div>
              </li>

              <li>
                <div className="returnal-infos-ico">
                  <FaTruck size={20} />
                </div>

                <div className="returnal-infos-content">
                  <strong className="block">Valor da Etiqueta</strong>
                  {returnal.selectedFreight ? returnal.selectedFreight.finalPrice.formated : "-"}
                </div>
              </li>

              <li>
                <div className="returnal-infos-ico">
                  <FaFileAlt size={20} />
                </div>

                <div className="returnal-infos-content">
                  <strong className="block">Pedido</strong>
                  <Link
                    to={renderRoute("order", {
                      merchantId: currentMerchant.uuid,
                      orderId: returnal.orderUuid,
                    })}
                  >
                    <b>{returnal.orderName}</b>
                  </Link>
                </div>

                <Link
                  to={renderRoute("order", {
                    merchantId: currentMerchant.uuid,
                    orderId: returnal.orderUuid,
                  })}
                  style={{ marginLeft: "auto" }}
                >
                  <FaExternalLinkAlt />
                </Link>
              </li>
            </ul>
          </div>

          {/* Returnal tracking */}
          {returnal.packages.length > 0 && returnal.packages[0].moves.length > 0 && (
            <div className="box-widget-mini returnal-tracking">
              <header>Rastreio da reversa</header>

              <div className="tracking-card">
                <div className="tracking-wrap">
                  <div className="tracking-header">
                    <FaBox /> <strong>{returnal.packages[0].trackingNumber}</strong>
                  </div>
                  <div className="tracking-info">
                    <span
                      className={`iumy-badge ${returnal.packages[0].lastMove.computedStatus.code}`}
                    >
                      {returnal.packages[0].lastMove.computedStatus.label}
                    </span>{" "}
                    <br />
                    <PackageMove move={returnal.packages[0].lastMove} />
                  </div>

                  <button type="button" onClick={toggleShowMoves}>
                    Ver mais...
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Address Modal */}
      {/* <AddressModal
        editAddressModalOpened={state.editAddressModalOpened}
        toggleModal={toggleAddressModal}
      /> */}

      <AttachmentModal
        viewingAttachmentUrl={state.viewingAttachmentUrl}
        toggleModal={() => viewAttachment(null)}
      />

      {state.showMoves && (
        <TrackingModal toggleModal={toggleShowMoves} moves={returnal.packages[0]?.moves} />
      )}
    </div>
  );
}
