import { generatePath } from "react-router-dom";

const routesList = [
  // => ONBOARDING & DASH
  {
    path: "/create-merchant",
    exact: true,
    key: "create-merchant",
  },

  {
    path: "/:merchantId/home",
    exact: true,
    key: "home",
  },

  {
    path: "/:merchantId/dashboard",
    exact: true,
    key: "dashboard",
  },

  // => ORDERS
  {
    path: "/:merchantId/orders",
    exact: true,
    key: "orders",
  },
  {
    path: "/:merchantId/orders/:orderId",
    exact: true,
    key: "order",
  },

  // => MESSAGE POOL
  {
    path: "/:merchantId/message-pool",
    exact: true,
    key: "message-pool",
  },

  // => Reversas
  {
    path: "/:merchantId/returns",
    exact: true,
    key: "returns",
  },
  {
    path: "/:merchantId/returns/:returnId",
    exact: true,
    key: "return",
  },

  // => Products
  // {
  //   path: "/:merchantId/products",
  //   exact: true,
  //   key: "products",
  //   component: Products,
  // },
  // {
  //   path: "/:merchantId/products/:productId",
  //   exact: true,
  //   key: "productDetail",
  //   component: ProductDetail,
  // },

  // => APPS
  {
    path: "/:merchantId/apps",
    exact: true,
    key: "apps",
  },
  {
    path: "/:merchantId/apps/:appSlug",
    exact: true,
    key: "appDetail",
  },

  // => CONFIGS
  {
    path: "/:merchantId/config",
    key: "config-index",
    exact: true,
  },
  {
    path: "/config/billing",
    key: "config-billing",
    exact: true,
  },
  {
    path: "/:merchantId/config/general",
    exact: true,
    key: "config-merchant",
  },

  // Callbacks
  {
    path: "/apps/:appSlug/callback",
    key: "callback-apps",
    exact: true,
  },

  // Notifications
  {
    path: "/:merchantId/config/notifications",
    key: "config-notifications",
    exact: true,
  },
  {
    path: "/:merchantId/config/notifications/:notificationId",
    key: "config-notifications-edit",
    exact: true,
  },
  // {
  //   path: "/:merchantId/config/users",
  //   exact: true,
  //   key: "config-users",
  //   component: UsersConfig,
  // },
  // {
  //   path: "/:merchantId/config/user/create",
  //   key: "config-user-create",
  //   exact: true,
  //   component: UserDetails,
  // },
  // {
  //   path: "/:merchantId/config/group/create",
  //   key: "config-group-create",
  //   exact: true,
  //   component: UserDetails,
  // },

  {
    key: "not-found",
  },
];

export function renderRoute(routeName, params) {
  const route = routesList.find((item) => item.key === routeName);

  if (!route) throw new Error(`ERROR = ${routeName} NOT ROUTE FOUND`);

  return generatePath(route.path, params);
}

export default routesList;
