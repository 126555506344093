import React, { useEffect, useRef } from "react";

function AutoTextArea(props) {
  const textareaRef = useRef();

  const onInput = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const textAreaValue = textareaRef?.current?.value;

  useEffect(() => {
    onInput();
  }, [textAreaValue]);

  return (
    <textarea
      {...props}
      onChange={(e) => {
        onInput();

        if (props.onChange) props.onChange(e);
      }}
      onReset={onInput}
      ref={textareaRef}
    />
  );
}

export default AutoTextArea;
