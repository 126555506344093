import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { formatPrice } from "helpers";
import { Button } from "reactstrap";
import moment from "moment";

// Components
import LoaderBG from "components/loader-bg";
import Modal from "components/modal";

function ModalSelectOrders({
  modalToggleHandler,
  handlePickNewDate,
  loading,
  isTrial,
  submitHandler,
}) {
  const [minInputValue, setMinInput] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [maxInputValue, setMaxInput] = useState(moment().format("YYYY-MM-DD"));

  const [state, setState] = useState({
    isSubmiting: false,
    loading: false,
    preImportData: {
      ordersQty: 0,
      price: 0,
      minRange: minInputValue,
      freeOrderImportQuantity: 0,
    },
  });

  const { ordersQty, price, freeOrderImportQuantity } = state.preImportData;

  const getMaxDate = () => {
    const dtToday = new Date();

    let month = dtToday.getMonth() + 1;
    let day = dtToday.getDate();
    const year = dtToday.getFullYear();
    if (month < 10) month = `0${month.toString()}`;
    if (day < 10) day = `0${day.toString()}`;

    const maxDate = `${year}-${month}-${day}`;
    return maxDate;
  };

  const _handlePickNewData = async (_minInputValue, _maxInputValue) => {
    setState((s) => ({ ...s, loading: true }));

    const { success, data } = await handlePickNewDate(
      _minInputValue || minInputValue,
      _maxInputValue || maxInputValue
    );

    if (success)
      setState((oldState) => ({
        ...oldState,
        isSubmiting: false,
        loading: false,
        preImportData: {
          minRange: moment(_minInputValue).format("YYYY-MM-DD"),
          ordersQty: data.paidOrdersQuantity,
          price: data.estimatedPrice,
          freeOrderImportQuantity: data.freeOrderImportQuantity,
        },
      }));
    else setState((s) => ({ ...s, isSubmiting: false, loading: false }));
  };

  const handleSubmit = async () => {
    setState((s) => ({
      ...s,
      isSubmiting: true,
    }));

    await submitHandler(minInputValue, maxInputValue);

    setState((s) => ({
      ...s,
      isSubmiting: false,
    }));
  };

  useEffect(() => {
    _handlePickNewData();
  }, []);

  const intervalTyping = useRef(null);
  const importExceded = ordersQty > freeOrderImportQuantity;
  const importButtonLabel = price > 0 ? "Pagar & Importar" : "Importar pedidos";

  return (
    <Modal
      showModal
      title="Importar pedidos"
      className="shopify-orders-select-modal"
      modalProps={{
        size: "md",
        style: {
          maxWidth: "580px",
          width: "100%",
        },
      }}
      customActions={() => (
        <Button
          color="primary"
          disabled={state.loading || (isTrial && importExceded) || state.isSubmiting}
          onClick={handleSubmit}
        >
          {isTrial ? "Importar pedidos" : importButtonLabel}
        </Button>
      )}
      btnPrimaryDisabled={loading || (isTrial && importExceded)}
      cancelHandler={() => modalToggleHandler()}
    >
      <div className="row">
        <div className="col-6">
          <h5>Definições da importação</h5>
          <p className="micro">Escolha uma data de inicio para importar pedidos retroativos.</p>

          <div className="calendar-select-wrap">
            <label htmlFor="calendar-select" className="mb5">
              Escolha a data de início
            </label>
            <div className="calendar-box mb5">
              <input
                type="date"
                id="calendar-select"
                defaultValue={minInputValue}
                max={getMaxDate()}
                onBlur={(e) => {
                  setMinInput(e.target.value);
                }}
                onChange={(e) => {
                  clearTimeout(intervalTyping.current);
                  const lastVal = e.target.value;

                  intervalTyping.current = setTimeout(async () => {
                    setMinInput(lastVal);
                    _handlePickNewData(lastVal, maxInputValue);
                  }, 1000);
                }}
              />
              <button
                type="button"
                className="btn btn-search"
                onClick={() => _handlePickNewData(minInputValue, maxInputValue)}
              >
                <FaSearch />
              </button>
            </div>

            <label htmlFor="calendar-select" className="mb5">
              Escolha a data final
            </label>
            <div className="calendar-box">
              <input
                type="date"
                id="calendar-select"
                defaultValue={maxInputValue}
                max={getMaxDate()}
                onBlur={(e) => {
                  setMaxInput(e.target.value);
                }}
                onChange={(e) => {
                  clearTimeout(intervalTyping.current);
                  const lastVal = e.target.value;

                  intervalTyping.current = setTimeout(async () => {
                    setMaxInput(lastVal);
                    _handlePickNewData(minInputValue, lastVal);
                  }, 1000);
                }}
              />
              <button
                type="button"
                className="btn btn-search"
                onClick={() => _handlePickNewData(minInputValue, maxInputValue)}
              >
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          {state.loading ? (
            <LoaderBG />
          ) : (
            <>
              <h5>Resumo da importação</h5>
              <ul className="import-resume">
                <li>
                  <span className="bigger">
                    {ordersQty}{" "}
                    {freeOrderImportQuantity > 0 && (
                      <>
                        /
                        <span className="green">
                          {freeOrderImportQuantity} <small>grátis</small>
                        </span>
                      </>
                    )}
                  </span>
                  <span className="minor">pedidos encontrados</span>
                </li>

                {!isTrial && (
                  <li>
                    <span className="bigger">{formatPrice(price)}</span>
                    <span className="minor">a ser debitado</span>
                  </li>
                )}
              </ul>

              <p className="charge-info micro gray">
                O valor é calculado com base no valor extra unitário de cada plano. Os pacotes não
                serão descontados da franquia já contratada.
              </p>
            </>
          )}
        </div>
      </div>
      {isTrial && importExceded && (
        <div className="trial-limit-message">
          <strong>Limite de importação gratuíto excedido!</strong> Para remover a limitação, escolha
          um de nossos planos e volte nesta tela novamente ou escolha uma data inicial mais recente.{" "}
          <a href="/config/billing">Escolher plano.</a>
        </div>
      )}
    </Modal>
  );
}

export default ModalSelectOrders;
