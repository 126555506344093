/* eslint-disable max-classes-per-file */
import { immerable } from "immer";
import VMasker from "vanilla-masker";

class Address {
  constructor(address = {}) {
    this.address = address.address || "";
    this.address2 = address.address2 || "";
    this.city = address.city || "";
    this.neighborhood = address.neighborhood || "";
    this.number = address.number || "";
    this.state = address.state || "";
    this.uuid = address.uuid || "";
    this.zipcode = address.zipcode || "";
  }
}

export class Contact {
  constructor(contact = {}) {
    this.address = new Address(contact.address);
    this.document = contact.document || "";
    this.email = contact.email || "";
    this.entityType = contact.entityType || "";
    this.name = contact.name || "";
    this.phone = contact.phone || "";
    this.uuid = contact.uuid || "";
    this.whatsapp = contact.whatsapp || "";
  }
}

export default class Merchant {
  constructor(merchant = {}) {
    this.uuid = merchant.uuid || "";
    this.name = merchant.name || "";
    this.icon = merchant.icon || "";
    this.logo = merchant.logo || "";
    this.description = merchant.description || "";
    this.domain = merchant.domain || "";
    this.senderEmail = merchant.senderEmail || "";

    this.contact = new Contact(merchant.contact);

    this.apps = Array.isArray(merchant.apps) ? [...merchant.apps] : [];
  }

  enableMasks() {
    const copy = this;

    // Document
    copy.contact.document = VMasker.toPattern(
      copy.contact.document,
      copy.contact.entityType === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
    );

    // Address
    copy.contact.address.zipcode = VMasker.toPattern(copy.contact.address.zipcode, "99999-999");
    copy.contact.whatsapp = VMasker.toPattern(copy.contact.whatsapp, "(99) 9999-99999");
    copy.contact.phone = VMasker.toPattern(copy.contact.phone, "(99) 9999-99999");

    return copy;
  }
}

Merchant[immerable] = true;
