import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { matchPath, Redirect, useRouteMatch } from "react-router-dom";
import { withLDProvider } from "launchdarkly-react-client-sdk";

// Components
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import LoadingScreen from "components/loading-screen";

// Main Actions
import { doLoadUserRequest } from "store/modules/auth/thunks";

// => Helpers
import { getCookie } from "helpers";
import LDClientIdentifier from "components/ldclient-identifier";
import FeedbackFloat from "components/feedback-float";

import {
  Creators as MerchantActions,
  doGetMerchantListRequest,
  doSelectMerchant,
} from "store/modules/merchants/actions";
import routesList from "./routes-list";
import Routes from "./routes";

function Main({
  user,
  context,
  currentMerchant,
  doResetCurrentMerchant,
  dispatch,
  location,
  history,
}) {
  const [state, setState] = useState({
    loading: true,
  });

  const loadMerchant = async () => {
    // Initiate loading merchant list
    const { data: merchantsReq } = await dispatch(doGetMerchantListRequest());
    const merchants = merchantsReq || [];
    const uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

    let selectUuid = false;
    let returnType = "home";

    // Tem loja na conta ?
    if (merchants.length) {
      // Testa pra /QUALQUER-COISA
      const matches = matchPath(location.pathname, {
        path: "/:merchantId",
        exact: false,
      });

      // É /QUALQUER-COISA e é um UUID
      if (matches && uuidRegex.test(matches?.params?.merchantId)) {
        const uuid = matches.params.merchantId.match(uuidRegex)[0];

        // Essa loja ai existe na conta do mano?
        if (merchants.find((m) => m.uuid === uuid)) {
          selectUuid = uuid;
          returnType = "true";
        } else {
          const { uuid: _uuid } = merchants[0];
          selectUuid = _uuid;
          returnType = "home";
        }
      } else {
        const currentExists = currentMerchant
          ? merchants.find((m) => m.uuid === currentMerchant.uuid)
          : false;

        // Tem pré-seleção salva? Ela existe?
        if (currentExists) {
          selectUuid = currentExists.uuid;
          if (
            routesList.find(
              (r) =>
                matchPath(window.location.pathname, {
                  path: r.path,
                  exact: r.exact || false,
                })

              // return r.path === location.pathname
            )
          )
            returnType = "true";
          else returnType = "home";
        }

        // Pega qualquer loja dele então
        else {
          const { uuid } = merchants[0];
          selectUuid = uuid;
          returnType = "home";
        }
      }

      // Seleciona a loja
      // if (selectUuid !== currentMerchant?.uuid)
      await dispatch(doSelectMerchant(selectUuid));

      return returnType === "home" ? history.push(`/${selectUuid}/home`) : true;
    }

    dispatch(doResetCurrentMerchant());
    return history.push("/onboarding");
  };

  useEffect(() => {
    let isCancelled = false;

    async function loading() {
      // Load user details sync
      await dispatch(doLoadUserRequest());

      // Starts merchants sync
      await loadMerchant();

      if (!isCancelled)
        setState((oldState) => ({
          ...oldState,
          loading: false,
        }));
    }

    loading();

    return () => {
      isCancelled = true;
    };
  }, []);

  const isConfigScreen = useRouteMatch("/config/billing");
  const isMasterAuth = Number(getCookie("x73jkh390"));

  // Date validation
  const endedDate = user?.subscription?.endedAt ? moment(user?.subscription?.endedAt) : false;
  const today = moment();
  const isDateValid = endedDate ? today.isBefore(endedDate) : false;
  const isCancelledAndInvalid = user?.subscription?.status === "CANCELLED" && !isDateValid;

  if (
    (["CANCELLED_IN_DEBT", "PENDING"].includes(user?.subscription?.status) ||
      isCancelledAndInvalid) &&
    !isConfigScreen &&
    !isMasterAuth
  ) {
    return <Redirect to="/config/billing" />;
  }

  return !state.loading ? (
    <div className="main-app master-container">
      <LDClientIdentifier />

      <Navbar />
      <main className="content-area">
        <Sidebar />
        <div className="content-wrap">
          <Routes />
        </div>
      </main>
      <FeedbackFloat />
      <ToastContainer theme="dark" />
      <LoadingScreen active={context.loading} />
    </div>
  ) : (
    <LoadingScreen active={state.loading} />
  );
}

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
  context: state.context,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators(
    {
      ...MerchantActions,
    },
    dispatch
  );
  return {
    ...binders,
    dispatch,
  };
};

export default withLDProvider({
  clientSideID: "62d4900b9426221133d89723",
})(connect(mapStateToProps, mapDispatchToProps)(Main));
