import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import moment from "moment";

export default function ReturnalList({ list, select, selectAll, allSelectorActive, merchantId }) {
  return (
    <table className="iumy-table desktop order-list-wrapper">
      <thead>
        <tr>
          <th className="td-15">
            <button
              type="button"
              aria-label="Selecionar/desselecionar todos os pedidos."
              onClick={() => selectAll()}
              className={classnames("im-checkbox", { checked: allSelectorActive })}
            />
          </th>

          <th style={{ minWidth: 100 }} className="td-20">
            ID
          </th>
          <th className="td-40">Número do Pedido</th>
          <th style={{ minWidth: 130 }}>Data</th>
          <th>Status</th>
          <th>Solicitação</th>
          <th>Frete Estimado</th>
          <th>Valor dos produtos</th>
        </tr>
      </thead>

      <tbody>
        {list.length > 0 ? (
          list.map((resource) => (
            <tr key={resource.uuid} className={classnames({ updating: resource.isUpdating })}>
              {/* Check */}
              <td>
                <button
                  type="button"
                  aria-label="Selecionar pedido"
                  onClick={() => select(resource)}
                  className={classnames("im-checkbox", { checked: resource.isSelected })}
                />
              </td>

              {/* Return ID */}
              <td>
                <strong className="f14">
                  <Link to={`/${merchantId}/returns/${resource.uuid}`}>{resource.name}</Link>
                </strong>
              </td>

              {/* Order ID */}
              <td className="f12">
                <strong className="f14">{resource.orderName}</strong>

                <span className="f13 block gray">
                  {resource.customer && (
                    <span
                      className="customer-name elipsis elipsis-100"
                      title={`${resource.customer.firstName} ${resource.customer.lastName}`}
                    >{`${resource.customer.firstName} ${resource.customer.lastName}`}</span>
                  )}
                </span>
              </td>

              {/* Data */}
              <td className="f13">
                {moment(resource.createdAt).format("DD/MM/YYYY")}
                <span className="block gray f11">{moment(resource.createdAt).fromNow()} </span>
              </td>

              {/* Status */}
              <td>
                <span
                  className={classnames(
                    "iumy-badge returnal inline-block f10 mb5",
                    resource.computedStatus.code
                  )}
                >
                  {resource.computedStatus.label}
                </span>
              </td>

              {/* Return Type */}
              <td>{resource.returnalType}</td>

              {/* Tipo retorno */}
              <td>
                {resource.selectedFreight ? resource.selectedFreight.finalPrice.formated : "-"}
              </td>

              <td className="actions">{resource.totalPrice.formated}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10" className="text-center">
              Nenhuma reversa encontrada, novas serão geradas assim que clientes solicitarem.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
