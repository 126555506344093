import React, { useEffect, useState } from "react";
import { FaSearch, FaChevronCircleLeft } from "react-icons/fa";

export default function FilterSearchInput({
  searchValue = "",
  handleSearch,
  placeholder = "CPF, rastreio, e-mail ou pedido",
}) {
  const [lastQuery, setQuery] = useState(searchValue);
  const [input, setInput] = useState(searchValue);
  const [timeOutId, setTimer] = useState(0);

  const handleChange = (v) => {
    setInput(v);
  };

  const resetInput = () => setInput("");

  useEffect(() => {
    clearTimeout(timeOutId);
    setTimer(0);

    // Se a ultima pesquisa for igual a primeira
    if (
      (lastQuery === input && searchValue !== "") ||
      (searchValue === "" && input === "" && lastQuery === "")
    ) {
      return;
    }

    // Devemos limpar tudo ?
    if (searchValue === "" && input !== "" && lastQuery !== "" && !timeOutId) {
      setInput("");
      setQuery("");
    }

    // Se não inicia o timer de pesquisa
    else {
      // Atualiza a ultima pesquisa
      setQuery(input);
      clearTimeout(timeOutId);

      setTimer(
        setTimeout(() => {
          if (input !== searchValue) handleSearch(input);
        }, 700)
      );
    }

    // if (searchValue === undefined && input) setInput(searchValue);
  }, [input, searchValue]);

  return (
    <div className="search-box">
      <div className="icon-holder">
        <FaSearch />
      </div>
      <input
        type="text"
        className="form-control"
        value={input}
        placeholder={placeholder}
        onChange={(event) => handleChange(event.target.value)}
      />
      {input.length > 0 && (
        <button type="button" onClick={() => resetInput()} className="clear-btn">
          <FaChevronCircleLeft />
        </button>
      )}
    </div>
  );
}
