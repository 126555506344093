import React, { useState } from "react";
import { Tooltip } from "reactstrap";

function TooltipElem(props) {
  const { toolTipText, containerElm, className, id, content, childClassName, placement } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [message, setMessage] = useState(
    typeof toolTipText === "function" ? "Carregando..." : toolTipText
  );
  let cbWasExecuted = false;
  const isCb = typeof toolTipText === "function";

  const toggle = () => {
    if (isCb && !tooltipOpen && !cbWasExecuted) {
      props.toolTipText((message2) => setMessage(message2));
      cbWasExecuted = true;
    }

    setTooltipOpen(!tooltipOpen);
  };

  const Container = containerElm || "div";

  return (
    <Container className={className}>
      <span className={childClassName} id={`tooltip-${id}`} href="#">
        {content}
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        placement={placement || "bottom"}
        target={`tooltip-${id}`}
        toggle={toggle}
      >
        {message}
      </Tooltip>
    </Container>
  );
}

export default TooltipElem;
