import { createActions } from "reduxsauce";

// Services
import { apiInstance } from "services/api";

// Create actions and Types
export const { Types, Creators } = createActions(
  {
    // PaymentCustomer
    doSavePaymentCustomerSuccess: ["payload"],
    doSaveBillingFailure: ["error"],

    // Change plan
    doChangeSubscriptionSuccess: ["payload"],
    doCreateSubscriptionSuccess: ["payload"],
    doCancelSubscriptionSuccess: ["payload"],

    // CreditCard
    doSaveCreditCardSuccess: ["payload"],

    // Invoice
    doPayInvoiceSuccess: ["payload"],
    doUpdateInvoice: ["payload"],

    // Load billing
    doLoadBillingDetailsSuccess: ["payload"],

    setLoadingStatus: ["payload"],
  },
  {
    prefix: "@billing/",
  }
);

// #region Ajax
export async function doLoadBillingDetailsRequest() {
  try {
    const response = await apiInstance(`/api/billing`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel carregar os detalhes de faturamento",
    };
  } catch (ex) {
    return {
      success: false,
      error: ex,
    };
  }
}

// PaymentUser
export async function doUpdateBillingInfosRequest(userId, values) {
  try {
    const response = await apiInstance(`/api/billing/payment-customer/${userId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...values,
      }),
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel atualizar os detalhes de faturamento",
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}
export async function doCreateBillingInfosRequest(values) {
  try {
    const response = await apiInstance(`/api/billing/payment-customer/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...values,
      }),
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel atualizar os detalhes de faturamento",
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

// Credit Card
export async function doCreateCreditCardRequest(postBody) {
  try {
    const response = await apiInstance(`/api/billing/payment-method/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(postBody),
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel criar o meio de pagamento.",
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

// Subscription
export async function doCreateSubscriptionRequest(planSlug) {
  try {
    const response = await apiInstance(`/api/billing/subscription/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        planSlug,
      }),
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel criar sua assinatura. Contate o suporte.",
      response,
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function doChangePlanRequest(subscriptionId, planSlug) {
  try {
    const response = await apiInstance(`/api/billing/subscription/${subscriptionId}/migrate`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        planSlug,
      }),
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel realizar a migração do plano. Contate o suporte",
      response,
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function doCalcMigratePlanPendingValuesRequest(subscriptionUuid) {
  try {
    const response = await apiInstance(`/api/billing/subscription/${subscriptionUuid}/migrate`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel calcular o valor devido para migração do plano.",
      response,
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function doCancelSubscriptionRequest(subscriptionUuid, cancelReason) {
  try {
    const response = await apiInstance(`/api/billing/subscription/${subscriptionUuid}/`, {
      method: "delete",
      data: { cancelReason },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Não foi possivel cancelar sua assinatura. Entre em contato com o suporte.",
      response,
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

// Invoice
export async function doPayInvoiceRequest(invoiceUuid) {
  try {
    const response = await apiInstance(`/api/billing/payment/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        invoiceUuid,
      }),
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Transação recusada. Tente novamente ou entre em contato",
    };
  } catch (ex) {
    const { error } = ex.response.data;
    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

export async function generateASAASPixRequest(invoiceUuid) {
  try {
    const response = await apiInstance.get(`/api/billing/${invoiceUuid}/pix`);

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      message: "Erro ao trocar meio de pagamento.",
    };
  } catch (ex) {
    const { error } = ex.response.data;

    return {
      success: false,
      errorCode: error.code,
      message: error.message,
    };
  }
}

// #endregion

// Actions
export function doLoadBillingDetails(uuid) {
  return async (dispatch) => {
    dispatch(Creators.setLoadingStatus(true));

    const response = await doLoadBillingDetailsRequest(uuid);

    if (response.success) {
      dispatch(Creators.doLoadBillingDetailsSuccess(response.data));
    } else {
      // dispatch(Creators.doGetAppsFailure());
    }
  };
}

export function doUpdateBillingInfos(paymentCustomerId, values) {
  return async (dispatch) => {
    const { success, data, message } = await doUpdateBillingInfosRequest(paymentCustomerId, values);

    if (success) {
      dispatch(Creators.doSavePaymentCustomerSuccess(data));

      return { success: true };
    }

    return { success: false, message };
  };
}

export function doCreateCreditCard(postBody) {
  return async (dispatch) => {
    const { success, data, message } = await doCreateCreditCardRequest(postBody);

    if (success) {
      dispatch(Creators.doSaveCreditCardSuccess(data));

      return { success: true };
    }

    return { success: false, message };
  };
}

export function doCreateBillingInfos(values) {
  return async (dispatch) => {
    const { success, data, message } = await doCreateBillingInfosRequest(values);

    if (success) {
      dispatch(Creators.doSavePaymentCustomerSuccess(data));

      return { success: true };
    }

    return { success: false, message };
  };
}

export function doChangePlan(subscriptionId, planSlug) {
  return async (dispatch) => {
    const { success, data, message } = await doChangePlanRequest(subscriptionId, planSlug);

    if (success) {
      dispatch(Creators.doChangeSubscriptionSuccess(data));

      return { success: true, data };
    }

    return { success: false, message };
  };
}

export function doCancelSubscription(subscriptionId, cancelReason) {
  return async (dispatch) => {
    const { success, data, message } = await doCancelSubscriptionRequest(
      subscriptionId,
      cancelReason
    );

    if (success) {
      dispatch(Creators.doCancelSubscriptionSuccess(data));

      return { success: true, data };
    }

    return { success: false, message };
  };
}

export function doCreateSubscription(planSlug) {
  return async (dispatch) => {
    const { success, data, message } = await doCreateSubscriptionRequest(planSlug);

    if (success) {
      dispatch(Creators.doCreateSubscriptionSuccess(data));

      return { success: true, data };
    }

    return { success: false, message };
  };
}

export function doPayInvoice(invoiceUuid) {
  return async (dispatch) => {
    const { success, data, message } = await doPayInvoiceRequest(invoiceUuid);

    if (success) {
      dispatch(Creators.doPayInvoiceSuccess(invoiceUuid));

      return { success: true };
    }

    return { success: false, message };
  };
}
