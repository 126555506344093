import React, { useState, useEffect } from "react";
import { FaTrash, FaQuestionCircle, FaCheckCircle, FaCloudDownloadAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import _ from "lodash";

// Actions
import {
  getEcommerceOrdersCountRequest,
  getAppSettingsByMerchantRequest,
  getShopifySingleOrder,
} from "store/modules/merchants/actions";
import { doLoadUserRequest } from "store/modules/auth/thunks";

// Assets
import { ReactComponent as NuvemshopIcon } from "images/app-icons/nuvemshopapp.svg";

// Helpers
import { convertEmptyToStr } from "helpers";

// Components
import SelectOrderModal from "../components/modal-select-orders";
// import SpinnerLoader from "components/loading-circle";
// import SelectOrderModal from "./components/modal-select-orders";

export const initOAuth = (storeUuid, fromOnboarding = false) => {
  const stateKey = window.btoa(
    JSON.stringify({
      storeUuid,
      fromOnboarding,
    })
  );

  window.location.href = `${process.env.REACT_APP_NUVEMSHOP_URL}?state=${stateKey}`;
};

export default function NuvemshopApp({
  storeUuid,
  loading,
  appConfigs,
  submitHandler,
  uninstallHandler,
  user,
}) {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const isTrial = user.subscription.isTrial || false;
  const importParam = new URLSearchParams(window.location.search).get("import");

  // State
  const dataModel = {
    isActive: false,
    code: "",
  };

  const stateCopy = convertEmptyToStr({
    initialValues: {
      ...dataModel,
      ...appConfigs,
    },

    // Import orders
    modalOpened: false,
    modalLoading: false,

    // Others status
    disableCancel: true,
    enableChangeKeys: false,
    importingSingle: false,
    uninstallingApp: false,

    preImportData: {
      ordersQty: 0,
      price: 0,
      minRange: moment().subtract(30, "days").format("YYYY-MM-DD"),
      freeOrderImportQuantity: 0,
    },
  });

  const [state, setState] = useState(stateCopy);

  useEffect(() => {
    setState({
      ...state,
      initialValues: convertEmptyToStr({ ...state.initialValues, ...appConfigs }),
      modalOpened: importParam || false,
    });
  }, [appConfigs]);

  // Reload user stats.
  useEffect(() => {
    dispatch(doLoadUserRequest());

    if (locationState?.code) {
      const { code } = locationState;
      delete locationState.code;
      window.history.replaceState(null, "");

      submitHandler({ isActive: true, code, skipImport: true }, dataModel);
    }
  }, []);

  /* const handleCancel = (resetForm) => {
    resetForm();
    setState({
      ...state,
      disableCancel: true,
    });
  }; */

  const handleSubmit = async (values, skipImport = true) => {
    setState({
      ...state,
      disableCancel: true,
    });

    const { success, message } = await submitHandler(
      {
        ...values,
        skipImport: typeof skipImport === "boolean" ? skipImport : true,
        isActive: true,
      },
      dataModel
    );

    let pending = [];

    if (!success) pending = message.match(/([a-z]+_[a-z]+)/g) || [];

    return setState({
      ...state,
      disableCancel: false,
      pendingPermissions: pending,
    });
  };

  const callOAuth = () => {
    setState((s) => ({ ...s, disableAuthButton: true }));

    return initOAuth(storeUuid, false);
  };

  const modalToggleHandler = (show) =>
    setState((oldState) => ({
      ...oldState,
      modalOpened: show || false,
    }));

  const loadOrders = async (values) => {
    // TODO VALUES
    setState((oldState) => ({
      ...oldState,
      initialValues: values,
      modalOpened: true,
    }));
  };

  return (
    <>
      {/* Configurações da Loja */}
      <div className="row mb35 mt35">
        <div className="col-sm-4 mb30 d-flex flex-column row-gap-3">
          <NuvemshopIcon className="" />
          <h5 className="m-0">Nuvemshop</h5>
          <p className="m-0">Aqui você integra sua loja para usar todas os benefícios da Iumy.</p>

          {state.initialValues.isActive && (
            <button
              type="button"
              onClick={() => {
                setState((oldState) => ({ ...oldState, uninstallingApp: true }));
                uninstallHandler(dataModel);
              }}
              className="btn btn-outline-danger btn-sm w-fit"
              disabled={state.uninstallingApp}
            >
              <FaTrash /> {state.uninstallingApp ? "Removendo" : "Desinstalar App"}
            </button>
          )}

          <a href="#as" target="_blank" rel="noreferrer" className="doclink">
            <FaQuestionCircle /> Como instalar?
          </a>
        </div>

        <div className="col">
          <div className="box-widget">
            <div className="inner heading">
              <h3>Integração Nuvemshop</h3>
            </div>

            <hr />

            <div className="inner">
              {!state.initialValues.isActive && (
                <button
                  type="button"
                  className="btn green size--medium"
                  onClick={callOAuth}
                  disabled={state.disableAuthButton || loading}
                >
                  {loading ? "Integrando..." : "Integrar"}
                </button>
              )}

              {state.initialValues.isActive && (
                <span>
                  <FaCheckCircle className="maingreen" /> Sua integração está ativa!
                </span>
              )}
            </div>

            {state.initialValues.isActive && (
              <>
                <hr />

                <div className="import-orders inner">
                  <div className="import-multiple-wrap ">
                    <button
                      type="button"
                      className="btn size--medium green"
                      onClick={() => {
                        loadOrders(state.initialValues);
                      }}
                    >
                      Importar pedidos
                    </button>
                    <p>
                      {user.subscription.freeOrdersImportUsed
                        ? "Importe pedidos antigos, escolha uma data retroativa, pague pelos pedidos adicionais sem descontar de seu plano."
                        : "Use esta opção para fazer a importação dos pedidos retroativos."}
                    </p>
                  </div>

                  <hr />

                  <div className="import-single">
                    <form
                      className="form-import-single"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const orderName = e?.currentTarget?.orderName?.value || null;

                        // if (orderName) loadSingleOrder(orderName);
                      }}
                    >
                      <input
                        name="orderName"
                        type="text"
                        className="form-control"
                        placeholder="Ex: #1023"
                      />
                      <button type="submit" className="btn green" disabled={state.importingSingle}>
                        <FaCloudDownloadAlt />
                      </button>
                    </form>
                    <p>
                      <strong>Importar pedido individual: </strong> importe um pedido especifico,
                      digite o número e busque, cada pedido será descontado da cota de seu plano.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {state.modalOpened && (
        <SelectOrderModal
          modalToggleHandler={modalToggleHandler}
          handlePickNewDate={(dateMin, dateMax) =>
            getEcommerceOrdersCountRequest(storeUuid, "nuvemshop", {
              ...state.initialValues,
              created_at_min: moment(dateMin).format("YYYY-MM-DD"),
              created_at_max: moment(dateMax).format("YYYY-MM-DD"),
            })
          }
          preImportData={{ ...state.preImportData, formik: state.initialValues }}
          submitHandler={(minInputValue, maxInputValue) =>
            handleSubmit(
              {
                ...state.initialValues,
                created_at_min: minInputValue,
                created_at_max: maxInputValue,
              },
              false
            )
          }
          loading={state.modalLoading}
          appConfigs={state.initialValues}
          isTrial={isTrial}
        />
      )}
    </>
  );
}
