import React, { useEffect, useState } from "react";
import { FaToggleOff, FaBox, FaPuzzlePiece } from "react-icons/fa";
import { useSelector } from "react-redux";

// Helpers
import { convertEmptyToStr } from "helpers";

// Actions
import { doRemoveAppCustomRuleRequest } from "store/modules/returns/actions";

// Componentes
import GeneralTab from "./tabs/general";
import ReasonTab from "./tabs/rules";
import RulesTab from "./tabs/configurations";

const TABS_TYPE = {
  GENERAL: "GENERAL",
  REASONS: "REASONS",
  RULES: "RULES",
};

export default function IumyReturnal({ appConfigs, submitHandler, loading }) {
  const { currentMerchant } = useSelector((state) => state.merchants);

  const dataModel = {
    isActive: false,
    rejectMultipleOrderReturnals: false,
    insuranceValuePercentage: "",
    privacyPolicyLink: "",
    customRules: [],
    defaultPackageConfig: {
      width: 8,
      height: 1,
      length: 13,
      weightInGrams: 300,
    },
    address: {
      address: "",
      address2: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      zipcode: "",
    },
  };

  const [state, setState] = useState({
    initialValues: convertEmptyToStr({ ...dataModel, ...appConfigs }),
    activeTab: TABS_TYPE.GENERAL,
  });

  const toggleTab = (tabId) => {
    if (state.activeTab !== tabId) {
      setState((oldState) => ({
        ...oldState,
        activeTab: tabId,
      }));
    }
  };

  const handleSubmit = (content) =>
    submitHandler({ ...state.initialValues, ...content }, dataModel);

  const removeCustomRule = async (ruleId) => {
    const { success, message, data } = await doRemoveAppCustomRuleRequest(
      currentMerchant.uuid,
      "iumy_returnal",
      ruleId
    );

    setState((s) => ({
      ...s,
      initialValues: {
        ...s.initialValues,
        customRules: s.initialValues.customRules.filter((cs) => cs.uuid !== ruleId),
      },
    }));

    return { success, message, data };
  };

  const toggleStatus = async () => {
    const isActive = !state.initialValues.isActive;

    const { success } = await handleSubmit({ isActive });

    if (success)
      setState((s) => ({
        ...s,
        initialValues: { ...s.initialValues, isActive },
      }));
  };

  useEffect(() => {
    setState({
      ...state,
      initialValues: convertEmptyToStr({ ...state.initialValues, ...appConfigs }),
    });
  }, [appConfigs]);

  return (
    <div className="config-wrapper">
      {/* {JSON.stringify(loading)} */}
      <div className="line">
        {/* Left menu */}
        <div className="aside-menu">
          <div className="iumy-card">
            <div className="iumy-card-header">Configurações</div>

            <div className="iumy-card-body">
              <div className="sidemenu">
                <button
                  type="button"
                  className={`menu-item ${state.activeTab === TABS_TYPE.GENERAL ? "active" : ""}`}
                  onClick={() => toggleTab(TABS_TYPE.GENERAL)}
                >
                  <FaBox className="menu-icon" /> Informações gerais
                </button>

                <button
                  type="button"
                  className={`menu-item ${state.activeTab === TABS_TYPE.REASONS ? "active" : ""}`}
                  onClick={() => toggleTab(TABS_TYPE.REASONS)}
                >
                  <FaToggleOff className="menu-icon" /> Regras
                </button>

                <button
                  type="button"
                  className={`menu-item ${state.activeTab === TABS_TYPE.RULES ? "active" : ""}`}
                  onClick={() => toggleTab(TABS_TYPE.RULES)}
                >
                  <FaPuzzlePiece className="menu-icon" /> Configurações
                </button>
              </div>
            </div>
          </div>

          <strong className="f14 mb3">Status do sistema de Trocas</strong>
          <p className="f14 app-description">
            Habilite ou desabilite a função de trocas e devoluções para o seus clientes.
          </p>

          <div
            className={`box-widget-mini status-box ${state.initialValues.isActive ? "active" : ""}`}
          >
            <span className="status-label">
              {state.initialValues.isActive ? "ativo" : "inativo"}
            </span>
            <button className="status-toggle-btn" onClick={toggleStatus} type="button">
              {state.initialValues.isActive ? "desativar" : "ativar"}
            </button>
          </div>
        </div>

        {/* Content */}
        <div className={`settings-content ${state.activeTab}`}>
          {state.activeTab === TABS_TYPE.GENERAL && (
            <GeneralTab
              type={TABS_TYPE.GENERAL}
              defaultPackageConfig={state.initialValues?.defaultPackageConfig}
              address={state.initialValues?.address}
              insuranceValuePercentage={state.initialValues?.insuranceValuePercentage}
              submitHandler={handleSubmit}
            />
          )}
          {state.activeTab === TABS_TYPE.REASONS && (
            <ReasonTab
              type={TABS_TYPE.REASONS}
              customRules={state.initialValues?.customRules}
              submitHandler={handleSubmit}
              removeCustomRuleHandler={removeCustomRule}
            />
          )}
          {state.activeTab === TABS_TYPE.RULES && (
            <RulesTab type={TABS_TYPE.RULES} appConfigs={appConfigs} submitHandler={handleSubmit} />
          )}
        </div>
      </div>
    </div>
  );
}
