export default class ReturnalCustomRule {
  constructor(options = {}) {
    this.uuid = options.uuid;
    this.isActive = options.isActive ?? true;
    this.isArchived = options.isArchived ?? false;
    this.autoApprove = options.autoApprove ?? false;
    this.reason = options.reason ?? "";
    this.allowExchange = options.allowExchange ?? false;
    this.allowReturn = options.allowReturn ?? false;
    this.requireEvidence = options.requireEvidence ?? false;
    this.requireComment = options.requireComment ?? false;
    this.deadlineInDays = options.deadlineInDays ?? 7;
  }
}
