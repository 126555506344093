import React, { useEffect, useState } from "react";
import { Link, matchPath, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { swalMiddleware as Swal } from "helpers";
import { FaChevronLeft } from "react-icons/fa";
import { toast } from "react-toastify";

// Components
import LoaderBlock from "components/loader-bg";

import {
  Creators,
  doSaveAppSettings,
  getAppSettingsByMerchantRequest,
} from "store/modules/merchants/actions";

import PageTitle from "components/page-title";

// Apps
import ShopifyApp from "./marketplaces/shopify";
import RastreioApp from "./iumy-tracking";
import IumyReturnal from "./iumy-returnal";
import MelhorEnvio from "./melhor-envio";
import CorreiosECT from "./correios-ect";
import NuvemshopApp from "./marketplaces/nuvemshop";

const apps = {
  shopify: {
    title: "Plataformas: Integração Shopify",
    app: ShopifyApp,
  },

  nuvemshop: {
    title: "Plataformas: Integração Nuvemshop",
    app: NuvemshopApp,
  },

  melhor_envio: {
    title: "Logística: Melhor Envio",
    app: MelhorEnvio,
  },
  correios_importador: {
    title: "Configurações: Correios Importador",
    app: CorreiosECT,
  },

  // IUMY
  iumy_tracker: {
    title: "Rastreamento & Portal Iumy",
    description: "Configure/instale o plugin e ajustes de rastreamento em seu site.",
    app: RastreioApp,
    backlink: (storeUuid) => `/${storeUuid}/config`,
  },
  iumy_returnal: {
    title: "Configurar Trocas e Devoluções",
    description: "Gerencie as opções para Trocas e Devoluções automáticas pelo sistema.",
    app: IumyReturnal,
    backlink: (storeUuid) => `/${storeUuid}/config`,
  },
};

export default function AppContainer() {
  const { pathname, state: locationState } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentMerchant } = useSelector((state) => state.merchants);
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    appSlug: null,
    found: false,
    loading: true,
    submiting: false,
    appConfigs: {},
  });

  const save = async (values, dataModel) => {
    const { appSlug } = state;

    setState((s) => ({ ...s, submiting: true }));

    const { success, data, message } = await doSaveAppSettings(currentMerchant.uuid, appSlug, {
      ...values,
      isActive: values.isActive ?? true,
    });

    if (success) {
      dispatch(Creators.doSaveAppSettingsSuccess(data));

      Object.keys(dataModel).forEach((key) => {
        dataModel[key] = data[key];
      });

      setState((s) => ({ ...s, appConfigs: dataModel, submiting: false }));

      toast.success("Configurações salvas com sucesso.");

      if (appSlug === "shopify" && !values.skipImport) {
        Swal.fire({
          title: "Iniciando importação...",
          html: `Avisaremos por e-mail quando tudo estiver pronto.<br />
          <div class="dots-loader dark text-center" style="margin-top: 10px ">
              <i class="loader dots">
                <span class="dot dot-1" ></span>
                <span class="dot dot-2" ></span>
                <span class="dot dot-3" ></span>
              </i>
            Importando pedidos...
          </div>`,
          icon: "success",
          confirmButtonText: "Entendi, vou aguardar o e-mail",
          showConfirmButton: true,
          heightAuto: false,
        }).then(() => history.push(`/${currentMerchant.uuid}/dashboard`));
      }

      return { success: true, data };
    }

    // ERR CASE
    toast.error(message);

    setState((oldState) => ({ ...oldState, submiting: false }));

    return { success: false, message };
  };

  const loadConfigs = async (appSlug) => {
    // Set loading status
    setState((oldState) => ({
      ...oldState,
      loading: true,
    }));

    const { success, data, status, message } = await getAppSettingsByMerchantRequest(
      currentMerchant.uuid,
      appSlug
    );

    if (success || status === 404) {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        appConfigs: {
          ...data,
        },
      }));
    } else {
      setState((oldState) => ({
        ...oldState,
        loading: false,
      }));

      toast.error(message);
    }
  };

  const uninstallHandler = async (model) => {
    const { appSlug } = state;

    const uninstallAppResponse = await doSaveAppSettings(currentMerchant.uuid, appSlug, {
      ...model,
      isActive: false,
    });

    if (uninstallAppResponse.success) {
      await loadConfigs(appSlug);

      await dispatch(
        Creators.doSaveAppSettingsSuccess({
          ...model,
          slug: appSlug,
          isActive: false,
        })
      );
    }
  };

  useEffect(() => {
    async function init() {
      // Get param
      const { params } = matchPath(pathname, {
        path: "/:merchantId/apps/:appSlug",
        exact: false,
      });

      // Search app local
      const found = apps[params.appSlug];

      setState((s) => ({ ...s, appSlug: params.appSlug, found: !!found }));
      return loadConfigs(params.appSlug);
    }
    init();
  }, []);

  const { appSlug, found, loading, submiting, appConfigs } = state;
  const foundApp = apps[appSlug];
  const App = foundApp && foundApp.app;

  const { fromHome } = locationState || {};
  let backlink;

  // Set backlink
  if (fromHome) backlink = `/${currentMerchant.uuid}/home`;
  else if (foundApp?.backlink) backlink = foundApp.backlink(currentMerchant.uuid);
  else backlink = `/${currentMerchant.uuid}/apps`;

  return (
    found && (
      <div className={`page app-detail container ${appSlug}`}>
        <PageTitle pageTitle={foundApp.title} />
        <header className="page-header">
          <Link to={backlink} className="f13 black-70 backbtn">
            <FaChevronLeft /> voltar
          </Link>
          <h1 className="title">{foundApp.title}</h1>
          {foundApp.description && <span role="definition">{foundApp.description}</span>}
        </header>
        <hr />

        {loading ? (
          <LoaderBlock blocks={8} width={5} />
        ) : (
          <App
            storeUuid={currentMerchant.uuid}
            appSlug={appSlug}
            appConfigs={appConfigs}
            submitHandler={save}
            loading={submiting}
            user={user}
            uninstallHandler={uninstallHandler}
          />
        )}
      </div>
    )
  );
}
