import Status from "./tracking/status";

export default class OrderNoteReminder {
  constructor(options = {}) {
    this.createdAt = options.createdAt;
    this.createdByUserName = options.createdByUserName;
    this.dateTrigger = options.dateTrigger;
    this.deletedAt = options.deletedAt;
    this.modifiedByUserName = options.modifiedByUserName;
    this.notifiedAt = options.notifiedAt;
    this.status = options.status;
    this.triggerType = options.triggerType;
    this.packageStatusTrigger =
      options.packageStatusTrigger && new Status(options.packageStatusTrigger);
    this.updatedAt = options.updatedAt;
    this.uuid = options.uuid;
  }
}
