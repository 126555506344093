import React from "react";
import classnames from "classnames";

export default function FilterCheckbox({ filter, handleFilter }) {
  const selectedValues = filter.values || [];
  const selectedOptions = selectedValues.map((opt) => opt.slug);

  const isSelected = (optionSlug) =>
    selectedOptions.filter((slug) => slug === optionSlug).length > 0;

  const handleSelect = (param, optSlug, remove) => {
    const values = remove
      ? selectedOptions.filter((slug) => slug !== optSlug)
      : [...selectedOptions, optSlug];

    return handleFilter(param, values);
  };

  return (
    <ul className="filters-checkbox-list filters-list">
      {filter.options.map((opt) => (
        <li className={`mb5 ${opt.slug === "PENDING" && "top-border"}`} key={opt.slug}>
          <label className="filter-opt-label" htmlFor={`${filter.param}-${opt.slug}`}>
            <button
              type="button"
              aria-label="act"
              onClick={() => handleSelect(filter.param, opt.slug, isSelected(opt.slug))}
              className={classnames("checkbox im-checkbox", { checked: isSelected(opt.slug) })}
              id={`${filter.param}-${opt.slug}`}
            />

            {/* iumy-badge */}
            <span className={classnames("checkbox-label iumy-badge", opt.slug)}>{opt.label}</span>
          </label>
        </li>
      ))}
    </ul>
  );
}
