import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import Slider from "react-slick";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { swalMiddleware as Swal } from "helpers";

import {
  doChangePlan,
  doCreateSubscription,
  doLoadBillingDetails,
  doCalcMigratePlanPendingValuesRequest,
} from "store/modules/billing/actions";

import { DTLayer } from "components/data-layer";
import Modal from "components/modal";

function PlansModal({ modalToggleHandler, plans, dispatch, currentSubscription }) {
  const { user } = useSelector((state) => state.auth);
  const { currentMerchant } = useSelector((state) => state.merchants);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [state, setState] = useState({
    planSubmiting: null,
    pendingValues: null,
  });

  const isActive = (planSlug) =>
    planSlug === currentSubscription?.billingPlanSlug &&
    currentSubscription?.status?.code.indexOf("CANCELLED") === -1;

  useEffect(() => {
    async function calc() {
      if (currentSubscription) {
        const response = await doCalcMigratePlanPendingValuesRequest();

        if (response.data?.price) setState({ pendingValues: response.data.priceFormatted });
      }
    }
    calc();
  }, []);

  const handleSelectPlan = async (planSlug) => {
    const plan = plans.find((p) => p.slug === planSlug);

    setState((oldState) => ({
      ...oldState,
      planSubmiting: planSlug,
    }));

    const proccedToSubscribe = await Swal.fire({
      title: "Autorizar cobrança",
      text: `Ao confirmar, você está autorizando a cobrança de R$ ${plan.price} no seu cartão e a vigência do novo plano será a partir da data de hoje.`,
      icon: "warning",
      confirmButtonText: "Pagar e assinar plano",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (!proccedToSubscribe.isConfirmed)
      return setState((oldState) => ({
        ...oldState,
        planSubmiting: null,
      }));

    if (state.pendingValues) {
      const values = await Swal.fire({
        title: "Existem valores pendentes!",
        icon: "info",
        text: `Detectamos que seu plano atual possui ${state.pendingValues} pendente de pagamento.`,
        confirmButtonText: "Pagar e Continuar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      });

      if (!values.isConfirmed)
        return setState((oldState) => ({
          ...oldState,
          planSubmiting: null,
        }));
    }

    let response;

    if (currentSubscription && currentSubscription.status.code.indexOf("CANCELLED") === -1)
      response = await dispatch(doChangePlan(currentSubscription.uuid, planSlug));
    else response = await dispatch(doCreateSubscription(planSlug));

    if (response.success) {
      DTLayer.dispatch("subscribe", {
        planSubscribed: {
          currency: "BRL",
          value: plan.price,
          id: plan.uuid,
          planName: plan.name,
        },
      });

      await Swal.fire({
        title: "Assinatura concluida!",
        text: "Vamos realizar a autorização junto ao seu banco e em breve seu plano já estará em vigor!",
        icon: "success",
        showConfirmButton: true,
        heightAuto: false,
      });

      await dispatch(doLoadBillingDetails());

      if (!response?.data?.freeOrdersImportUsed)
        Swal.fire({
          title: "Importar pedidos retroativos",
          confirmButtonText: "Importar agora",
          icon: "info",
        }).then(() => {
          window.location.href = `/${currentMerchant.uuid}/apps/shopify?import=true`;
        });

      modalToggleHandler(null, true);
    } else {
      if (state.pendingValues) {
        Swal.fire({
          title: "Falha ao realizar a migração!",
          text: response.message,
          icon: "error",
          showConfirmButton: true,
        }).then(() => dispatch(doLoadBillingDetails()));

        modalToggleHandler(null);
      } else toast.error(response.message);

      setState((oldState) => ({
        ...oldState,
        planSubmiting: null,
      }));
    }

    return true;
  };

  return (
    <Modal
      backdrop={state.planSubmiting ? "static" : true}
      hiddenCloseBtn={state.planSubmiting}
      showModal
      title="Escolha o plano"
      className="plans-modal"
      modalProps={{
        size: "lg",
        style: {
          maxWidth: "940px",
        },
      }}
      toggleModal={modalToggleHandler}
      cancelHandler={modalToggleHandler}
    >
      <Slider className="plans-list" {...settings}>
        {plans.map(
          (p, i) =>
            p.publiclyAvailable && (
              <li key={p.uuid} className={`plan-item-wrap ${isActive(p.slug) ? "active" : ""}`}>
                {isActive(p.slug) && <span className="active-plan-banner">Plano atual</span>}
                <span className="plan-name">{p.name}</span>
                <div className="plan-price-wrap">
                  <span className="plan-currency">R$</span>
                  <span className="plan-value">{p.price}</span>
                  <span className="plan-period">/mês</span>
                </div>

                <ul className="plan-benefits">
                  {p.details.map((d) => (
                    <li key={d.id}>
                      <FaCheckCircle className="icon-check" />
                      <p>
                        {d.title}
                        <span className="under">{d.subtitle}</span>
                      </p>
                    </li>
                  ))}
                </ul>

                <button
                  disabled={isActive(p.slug) || state.planSubmiting === p.slug}
                  onClick={() => handleSelectPlan(p.slug)}
                  className="select-plan-btn"
                  type="button"
                >
                  {state.planSubmiting === p.slug ? "Carregando..." : "Escolher plano"}
                </button>
              </li>
            )
        )}
      </Slider>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(PlansModal);
