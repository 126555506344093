import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// => Routes <=
import PrivateRoute from "components/private-route";

// => Screens <=
// Auth
import Login from "pages/auth/login";
import Signup from "pages/auth/signup";
import ResetPassword from "pages/auth/reset-password";

// Main
import Main from "pages/main";
import Onboarding from "pages/main/onboarding";

// Others
import Unsubscribe from "pages/unsubscribe";

// => Services <=
import apiCreator from "services/api";
import createDataLayer from "components/data-layer";

function App({ auth, dispatch }) {
  if (process.env.REACT_APP_ENV !== "local") createDataLayer("GTM-5CJ8TSV");

  // Create api instance
  apiCreator(auth.token, dispatch);

  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/activate" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/unsubscribe" component={Unsubscribe} />

          <PrivateRoute path="/">
            <Switch>
              <Route path="/onboarding" component={Onboarding} />
              <Route path="/" component={Main} />
            </Switch>
          </PrivateRoute>

          <Route path="*">
            <Redirect to="/orders" />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
