import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

// Components
import MaskedInput from "components/masked-input";

// Helpers
import calcRetunralValues from "../../return-helpers";

export default function PaymentWidget({ returnal, refundHandler, exchangeHandler }) {
  const {
    refundsLength,
    refundValueRequested,
    hasRefundPending,
    refundValue,
    hasExchangePending,
    exchangesLength,
    exchangeValue,
    exchangeFreightValue,
    refundType,
  } = calcRetunralValues(returnal);

  // Coupon due date
  const dueAt = returnal.coupons.length
    ? moment(returnal.coupons[0].dueAt)
    : moment().add(30, "days");

  // Form fields
  const [state, setState] = useState({
    refundValue,
    refundType,

    // Values
    couponCode: returnal.coupons.length ? returnal.coupons[0].code : "",
    exchangeValue,
    exchangeFreight: exchangeFreightValue,

    // Valid
    couponValidity: dueAt.toDate(),

    // Status
    submitingRefund: false,
    submitingExchange: false,

    errors: {},
  });

  const doRefund = async () => {
    setState((s) => ({ ...s, submitingRefund: true }));

    const errors = {};

    if (!state.refundType) {
      errors.refundType = "Selecione a forma de reembolso";

      return setState((s) => ({
        ...s,
        errors: { ...s.errors, ...errors },
        submitingRefund: false,
      }));
    }

    await refundHandler(state.refundValue, state.refundType, null);

    return setState((s) => ({ ...s, submitingRefund: false }));
  };

  const doExchange = async () => {
    setState((s) => ({ ...s, submitingExchange: true }));

    const errors = {};

    // Trait errors
    if (!state.couponCode || parseInt(state.exchangeValue, 10) === 0) {
      errors["coupon-code"] = !state.couponCode ? "Digite o cupom" : "";
      errors["coupon-value"] =
        parseInt(state.exchangeValue, 10) === 0 ? "Digite o valor do cupom" : "";

      return setState((s) => ({
        ...s,
        errors: { ...s.errors, ...errors },
        submitingExchange: false,
      }));
    }

    await exchangeHandler(
      state.couponCode,
      state.couponValidity.toISOString(),
      state.exchangeValue,
      state.exchangeFreight
    );

    return setState((s) => ({ ...s, submitingExchange: false }));
  };

  // Tem Refund Pago
  // eslint-disable-next-line no-nested-ternary
  const refundButtonLabel = state.submitingRefund
    ? "Confirmando..."
    : !hasRefundPending && refundsLength
    ? "Reembolso confirmado"
    : "Confirmar reembolso";

  // eslint-disable-next-line no-nested-ternary
  const exchangeButtonLabel = state.submitingExchange
    ? "Confirmando..."
    : !hasExchangePending && exchangesLength
    ? "Vale-trocas emitido"
    : "Emitir Vale-trocas";

  return (
    <div className="payment-container">
      <form className="payment-step">
        {/* Refund side */}
        <div className="refund-side">
          <div className="iumy-badge REFUND">Reembolso</div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="request-value">Valor solicitado</label>
                <MaskedInput
                  type="text"
                  name="request-value"
                  id="request-value"
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={refundValueRequested}
                  disabled
                  maskBuilder="BRL"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label htmlFor="value-to-pay">Valor à pagar</label>
                <MaskedInput
                  type="text"
                  name="value-to-pay"
                  id="value-to-pay"
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={state.refundValue}
                  onChange={(fieldName, maskedValue) =>
                    setState((s) => ({ ...s, refundValue: maskedValue }))
                  }
                  disabled={!hasRefundPending}
                  maskBuilder="BRL"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="returnType">Forma de reembolso</label>

            <select
              name="refundType"
              className="form-select"
              id="refundType"
              defaultValue={state.refundType}
              disabled={!hasRefundPending}
              onChange={(e) => {
                const { name, value } = e.target;

                setState((s) => ({
                  ...s,
                  refundType: value,
                  errors: {
                    ...s.errors,
                    refundType: "",
                  },
                }));
              }}
            >
              <option value="" disabled>
                Selecione...
              </option>
              <option value="CC">Cartão de credito</option>
              <option value="PIX">PIX</option>
            </select>

            {state.errors.returnType && (
              <div component="div" className="error-message">
                {state.errors.returnType}
              </div>
            )}
          </div>

          <button
            type="button"
            className="btn darky-green"
            disabled={!hasRefundPending || state.submitingRefund}
            onClick={doRefund}
          >
            {refundButtonLabel}
          </button>
        </div>

        {/* Coupon */}
        <div className="coupon-side">
          <div className="iumy-badge EXCHANGE">Vale-trocas</div>

          <div className="row">
            <div className="col-8">
              <div className="form-group">
                <label htmlFor="coupon-code">Código do cupom</label>
                <input
                  type="text"
                  name="coupon-code"
                  id="coupon-code"
                  className="form-control "
                  placeholder="XPTO-1111"
                  value={state.couponCode}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    return setState((s) => ({
                      ...s,
                      couponCode: value,
                      errors: {
                        ...s.errors,
                        "coupon-code": "",
                      },
                    }));
                  }}
                  disabled={!hasExchangePending}
                />

                {state.errors["coupon-code"] && (
                  <div component="div" className="error-message">
                    {state.errors["coupon-code"]}
                  </div>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label htmlFor="valid">Válidade</label>

                <DatePicker
                  name="valid"
                  minDate={new Date()}
                  className="form-control"
                  selected={state.couponValidity}
                  dateFormat="dd/MM/yyyy"
                  disabled={!hasExchangePending}
                  onChange={(date) => {
                    const dt = moment(date);
                    if (dt.isValid())
                      setState((oldState) => ({ ...oldState, couponValidity: date }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="coupon-value">Valor</label>

                <MaskedInput
                  type="text"
                  name="coupon-value"
                  id="coupon-value"
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={state.exchangeValue}
                  onChange={(fieldName, maskedValue) =>
                    setState((s) => ({
                      ...s,
                      exchangeValue: maskedValue,
                      errors: {
                        ...s.errors,
                        "coupon-value": "",
                      },
                    }))
                  }
                  disabled={!hasExchangePending}
                  maskBuilder="BRL"
                />
                {state.errors["coupon-value"] && (
                  <div component="div" className="error-message">
                    {state.errors["coupon-value"]}
                  </div>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label htmlFor="freight-value">Frete</label>

                <MaskedInput
                  type="text"
                  name="freight-value"
                  id="freight-value"
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={state.exchangeFreight}
                  onChange={(fieldName, maskedValue) =>
                    setState((s) => ({ ...s, exchangeFreight: maskedValue }))
                  }
                  disabled={!hasExchangePending}
                  maskBuilder="BRL"
                />
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label htmlFor="total-value">Total</label>

                <MaskedInput
                  type="text"
                  name="total-value"
                  id="total-value"
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={parseFloat(state.exchangeFreight) + parseFloat(state.exchangeValue)}
                  disabled
                  enableReinitialize
                  maskBuilder="BRL"
                />
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn darky-green"
            onClick={doExchange}
            disabled={!hasExchangePending || state.submitingExchange}
          >
            {exchangeButtonLabel}
          </button>
        </div>
      </form>

      <div className="callout">
        Atente-se aos descontos na hora de emitir os vouchers e reembolsos, a Iumy não os calcula
        automaticamente.
      </div>

      <hr />
    </div>
  );
}
