import { immerable } from "immer";

import Price from "../price";
import StatusReturnal from "./status-retunal";

export default class ReturnalItem {
  constructor(options) {
    this[immerable] = true;

    const image = options.productImages
      ? options.productImages.find((i) => i.isFeatured).url || options.productImages[0].url
      : null;

    this.uuid = options.uuid;
    this.name = options.name;
    this.title = options.title;
    this.price = new Price(options.price);
    this.variantTitle = options.variantTitle;
    this.imageSrc = image;
    this.quantity = options.quantity;
    this.rule = options.rule;
    this.attachments = options.images;
    this.comment = options.comment;
    this.returnalType = new StatusReturnal(options.returnalType);

    // Conference props
    this.conferenceQuantity =
      options.conferenceQuantity !== null
        ? parseInt(options.conferenceQuantity, 10)
        : options.quantity;
    this.conferenceComment = options.conferenceComment;
  }
}
