import classNames from "classnames";
import React from "react";
import { FaCircleNotch } from "react-icons/fa";

function BottomActions({
  nextStepHandler,
  previousStepHandler,
  canBack = false,
  enableNext = false,
  nextStepBtnText = "Próximo",
  loading,
}) {
  const onPreviousClick = () => previousStepHandler();
  const onNextClick = () => nextStepHandler();

  return (
    <div className="bottom-actions">
      {canBack && previousStepHandler && (
        <button
          type="button"
          style={{ padding: "10px 40px" }}
          className="btn gray btn-bordered f14"
          onClick={onPreviousClick}
          disabled={loading}
        >
          Voltar ao passo anterior
        </button>
      )}

      <button
        type="button"
        style={{ padding: "10px 40px" }}
        className={classNames("btn green f14", {
          loading,
        })}
        onClick={onNextClick}
        disabled={!enableNext || loading}
      >
        {loading ? <FaCircleNotch /> : nextStepBtnText}
      </button>
    </div>
  );
}

export default BottomActions;
