import React, { useState, useEffect } from "react";
import {
  FaDollarSign,
  FaArrowAltCircleLeft,
  FaExclamationTriangle,
  FaHourglassEnd,
  FaInbox,
  FaUnlink,
  FaStopCircle,
} from "react-icons/fa";

import { renderRoute } from "pages/main/routes-list";
import { getPackageConsolidateStatsRequest } from "store/modules/merchants/actions";

// Components
import CardIcon from "../components/card-icon";

export default function SalesStatisticsContainer({ filter, storeUuid }) {
  const [state, setState] = useState({
    loading: true,
    error: null,
    packageTaxedQty: 0,
    packageReturnedQty: 0,
    packageExceptionQty: 0,
    packageLateQty: 0,
    packageStalledQty: 0,
    packageWithdrawQty: 0,
    packageUnlinkQty: 0,
  });

  const getStats = (status, array) => {
    try {
      return array.find((stats) => stats.status === status).quantity;
    } catch (err) {
      return 0;
    }
  };

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getPackageConsolidateStatsRequest(storeUuid, filter);
      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          packageTaxedQty: getStats("TAXED", data),
          packageReturnedQty: getStats("RETURNED_TO_SENDER", data),
          packageExceptionQty: getStats("EXCEPTION", data),
          packageLateQty: getStats("LATE", data),
          packageStalledQty: getStats("STALLED", data),
          packageUnlinkQty: getStats("NO_PACKAGE", data),
          packageWithdrawQty: getStats("WAITING_WITHDRAW", data),
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  const generateLink = (status) => {
    let fltr;
    const allMinsDeliverReturned =
      "&status=TRACKING_CODE_GENERATED,POSTED,IN_TRANSIT,WAITING_WITHDRAW,IN_ROUTE_TO_DELIVERY,EXCEPTION,TAXED,PENDING";
    const allMinsDeliverReturnedPending =
      "&status=TRACKING_CODE_GENERATED,POSTED,IN_TRANSIT,WAITING_WITHDRAW,IN_ROUTE_TO_DELIVERY,EXCEPTION,TAXED";
    if (!["LATE", "NO_PACKAGES", "STALLED"].includes(status)) fltr = `?status=${status}`;
    else if (status === "LATE") fltr = `?is_late=true${allMinsDeliverReturned}`;
    else if (status === "STALLED") fltr = `?is_stalled=true${allMinsDeliverReturnedPending}`;
    else if (status === "NO_PACKAGES") fltr = "?has_no_packages=true&status=PAID";

    let link = `${renderRoute("orders", { merchantId: storeUuid })}${fltr}`;

    if (filter.created_at_min && filter.created_at_max) {
      link += `&created_at=${filter.created_at_min}|${filter.created_at_max}`;
    }

    return link;
  };

  return (
    <div className="side-cards">
      <CardIcon
        label="Pedidos taxados"
        className="order-taxed"
        sufix=" pedidos"
        icon={<FaDollarSign className="card-icon" fontSize={27} />}
        number={state.packageTaxedQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("TAXED")}
      />

      <CardIcon
        label="Pedidos devolvidos"
        className="order-returned"
        sufix=" pedidos"
        icon={<FaArrowAltCircleLeft className="card-icon" fontSize={27} />}
        number={state.packageReturnedQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("RETURNED_TO_SENDER")}
      />

      <CardIcon
        label="Pedidos com falha"
        className="order-exception"
        sufix=" pedidos"
        icon={<FaExclamationTriangle className="card-icon" fontSize={27} />}
        number={state.packageExceptionQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("EXCEPTION")}
      />

      <CardIcon
        label="Pedidos atrasados"
        className="order-late"
        sufix=" pedidos"
        icon={<FaHourglassEnd className="card-icon" fontSize={27} />}
        number={state.packageLateQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("LATE")}
      />

      <CardIcon
        label="Pedidos sem atualizações"
        className="order-stalled"
        sufix=" pedidos"
        icon={<FaStopCircle className="card-icon" fontSize={27} />}
        number={state.packageStalledQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("STALLED")}
      />

      <CardIcon
        label="Aguardando retirada"
        className="order-withdraw"
        sufix=" pedidos"
        icon={<FaInbox className="card-icon" fontSize={27} />}
        number={state.packageWithdrawQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("WAITING_WITHDRAW")}
      />

      <CardIcon
        label="Pedidos sem rastreio"
        className="order-unlink"
        sufix=" pedidos"
        icon={<FaUnlink className="card-icon" fontSize={27} />}
        number={state.packageUnlinkQty}
        error={state.error}
        loading={state.loading}
        link={generateLink("NO_PACKAGES")}
      />
    </div>
  );
}
