import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { Formik, Field, ErrorMessage } from "formik";
import { swalMiddleware as Swal } from "helpers";
import * as Yup from "yup";
import qs from "qs";

import { doChangePasswordRequest } from "store/modules/auth/thunks";
import PageTitle from "components/page-title";

import logo from "images/iumy.svg";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const queryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [redirectToLogin, setRedirect] = useState(false);

  const { token } = queryString;

  if (!token || redirectToLogin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="auth-screen reset-password">
      <PageTitle pageTitle="Redefinir senha" />

      <div className="box">
        <div className="aside-side">
          <div className="40">
            <img src={logo} alt="iumy checkout" width="146" />
          </div>

          <h1>Criar nova senha</h1>
          <p className="f16 black-40">Digite a nova senha e confirme.</p>

          <Formik
            initialValues={{ password: "", passwordConfirmation: "" }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, "A senha deve ter entre 8 e 21 characteres")
                .max(21, "A senha deve ter entre 8 e 21 characteres")
                .required("Preencha a senha"),
              passwordConfirmation: Yup.string()
                .required("Preencha a confirmação da senha")
                .oneOf([Yup.ref("password"), null], "As senhas não combinam"),
            })}
            onSubmit={async (values) => {
              const result = await dispatch(
                doChangePasswordRequest(values.password, values.passwordConfirmation, token)
              );

              if (result.success) {
                setErrorMessage(null);

                Swal.fire({
                  title: "Senha alterada com sucesso!",
                  text: "Sua nova senha já está valendo, agora você pode usá-la para acessar sua conta.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  heightAuto: false,
                }).then(() => {
                  setRedirect(true);
                });
              } else {
                setErrorMessage(result.message);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Escolha sua senha"
                    tabIndex="2"
                    className={classnames("form-control", {
                      error: errors.password,
                    })}
                  />
                  <ErrorMessage component="div" name="password" className="error-message" />
                </div>

                <div className="form-group">
                  <Field
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirme sua senha"
                    tabIndex="2"
                    className={classnames("form-control", {
                      error: errors.password,
                    })}
                  />
                  <ErrorMessage
                    component="div"
                    name="passwordConfirmation"
                    className="error-message"
                  />
                </div>

                {errorMessage && <div className="error-block">{errorMessage}</div>}

                <button type="submit" className="btn btn-block green size--large btn-submit">
                  Alterar
                  <div className="dots">
                    <div className="dot dot-1" />
                    <div className="dot dot-2" />
                    <div className="dot dot-3" />
                  </div>
                </button>

                {/* <hr />
                <p className="text-center black-40 mb0">
                  Ainda não é cadastrado? <br />
                  Então <Link to="/signup">clique aqui</Link> e junte-se a nós!
                </p> */}
              </form>
            )}
          </Formik>
        </div>

        <div className="background-side" />
      </div>
    </div>
  );
}
