import { immerable } from "immer";

export default class PaymentMethod {
  constructor(options = {}) {
    this[immerable] = true;
    this.cardBrand = options.cardBrand;
    this.cardDisplayNumber = options.cardDisplayNumber;
    this.cardDueDate = options.cardDueDate;
    this.cardDueMonth = options.cardDueMonth;
    this.cardDueYear = options.cardDueYear;
    this.cardHolderName = options.cardHolderName;
    this.cardLastDigits = options.cardLastDigits;
    this.createdAt = options.createdAt;
    this.isDefault = options.isDefault;
    this.uuid = options.uuid;
  }
}
