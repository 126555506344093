import React from "react";
import classnames from "classnames";

export default function LoadingDots({ containerElm, type, value, pos }) {
  const ContainerElm = containerElm || "div";

  return (
    <ContainerElm className={classnames("dots-loader", type || "light", pos || "text-center")}>
      <i className="loader dots">
        <span className="dot dot-1" />
        <span className="dot dot-2" />
        <span className="dot dot-3" />
      </i>

      {value && value}
    </ContainerElm>
  );
}
