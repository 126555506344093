import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaCube } from "react-icons/fa";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import cep from "cep-promise";
import VMasker from "vanilla-masker";

// Components
import ZipCodeButton from "pages/main/returns/detail/components/zip-button";
import { FaShieldHalved } from "react-icons/fa6";

export default function GeneralTab({
  defaultPackageConfig,
  address,
  insuranceValuePercentage,
  submitHandler,
}) {
  const formRef = useRef();

  // Define initial values
  const initialValues = { defaultPackageConfig, address, insuranceValuePercentage };

  // Form schema
  const validationSchema = Yup.object({
    insuranceValuePercentage: Yup.number()
      .typeError("Digite um número de 0 a 100")
      .max(100, "O valor máximo é 100")
      .min(0, "O valor minimo é 0")
      .required("Campo obrigatório"),

    defaultPackageConfig: Yup.object({
      width: Yup.number().typeError("Digite um número").required("Campo obrigatório"),
      height: Yup.number().typeError("Digite um número").required("Campo obrigatório"),
      length: Yup.number().typeError("Digite um número").required("Campo obrigatório"),
      weightInGrams: Yup.number().typeError("Digite um número").required("Campo obrigatório"),
    }),

    address: Yup.object({
      name: Yup.string()
        .max(100, "O tamanho máximo do nome é de 100 caracteres")
        .required("Preencha o nome do contato da loja")
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/, "Informe também o sobrenome"),
      document: Yup.string().required("Campo obrigatório"),
      address: Yup.string().required("Campo obrigatório"),
      neighborhood: Yup.string().required("Campo obrigatório"),
      zipcode: Yup.string().required("Campo obrigatório"),
      number: Yup.string().required("Campo obrigatório"),
      city: Yup.string().required("Campo obrigatório"),
      state: Yup.string().required("Campo obrigatório"),
    }),
  });

  // Build state
  const [state, setState] = useState({
    initialValues,

    submiting: false,
    hasChanges: false,
  });

  // Buttons States
  const disableSubmit = (!state.hasChanges && !state.submiting) || !formRef.current.isValid;

  // Handler: Submit
  const submitAction = async (values) => {
    setState((s) => ({ ...s, submiting: true }));

    const result = await submitHandler(values);

    setState((s) => ({
      ...s,
      submiting: false,
      ...(result.success ? { hasChanges: false, submiting: false } : {}),
    }));
  };

  const blurCepHandler = async (e, setValues) => {
    if (!e.target.value) return;

    setState((s) => ({ ...s, hasChanges: false }));

    const { values } = formRef.current;

    try {
      const resultCep = await cep(e.target.value);

      setValues({
        ...values,
        address: {
          ...values.address,
          state: resultCep.state,
          city: resultCep.city,
          address: resultCep.street,
          neighborhood: resultCep.neighborhood,
        },
      });

      // province =
    } catch (error) {
      setValues({
        ...values,
        address: {
          ...values.address,
          state: "",
          city: "",
          address: "",
          neighborhood: "",
        },
      });

      toast.error("Cep não encontrado.");
    }
  };

  return (
    <>
      <h2 className="tab-title">Informações gerais</h2>

      <Formik
        innerRef={formRef}
        initialValues={state.initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submitAction}
        validate={(values) => {
          const isEqual = _.isEqual(values, initialValues);

          return setState((s) => ({
            ...s,
            // enableSubmit: isValid && !isEqual,
            hasChanges: !isEqual,
            initialValues: values,
          }));
        }}
      >
        {({ values, setValues }) => (
          <Form>
            <div className="box-widget-mini">
              <strong className="f14 box-title">Dimensões do Pacote</strong>
              <p className="f14 gray-500 ">
                A dimensão padrão do pacote será utilizada para realizar a cotação das formas de
                envio cadastradas. Caso o objeto postado seja maior ou menor do que o cadastrado, a
                própria transportadora ou os correios irão fazer a correção do preço no momento da
                postagem.
              </p>

              <div className="box-size">
                <div className="form-group">
                  <label htmlFor="width" className="uppercase gray f12 bold mb3">
                    Largura
                  </label>

                  <div className="input-flex">
                    <FaCube />
                    <Field
                      name="defaultPackageConfig.width"
                      id="width"
                      type="text"
                      placeholder="10"
                    />
                    <span>cm</span>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="defaultPackageConfig.width"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="height" className="uppercase gray f12 bold mb3">
                    Altura
                  </label>

                  <div className="input-flex">
                    <FaCube />
                    <Field
                      name="defaultPackageConfig.height"
                      id="height"
                      type="text"
                      placeholder="10"
                    />
                    <span>cm</span>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="defaultPackageConfig.height"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="length" className="uppercase gray f12 bold mb3">
                    Comprimento
                  </label>

                  <div className="input-flex">
                    <FaCube />
                    <Field
                      name="defaultPackageConfig.length"
                      id="length"
                      type="text"
                      placeholder="10"
                    />
                    <span>cm</span>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="defaultPackageConfig.length"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="weightInGrams" className="uppercase gray f12 bold mb3">
                    Peso
                  </label>

                  <div className="input-flex">
                    <FaCube />
                    <Field
                      name="defaultPackageConfig.weightInGrams"
                      id="weightInGrams"
                      type="text"
                      placeholder="100"
                    />
                    <span>g</span>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="defaultPackageConfig.weightInGrams"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="insuranceValuePercentage" className="uppercase gray f12 bold mb3">
                    Percentual segurado
                  </label>

                  <div className="input-flex" style={{ maxWidth: 100 }}>
                    <FaShieldHalved />
                    <Field
                      name="insuranceValuePercentage"
                      id="insuranceValuePercentage"
                      type="number"
                      placeholder="100"
                      max={100}
                      min={0}
                    />
                    <span>%</span>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-message"
                    name="insuranceValuePercentage"
                  />
                </div>
              </div>
            </div>

            <div className="box-widget-mini">
              <strong className="f14 box-title">Dados do destinatário</strong>
              <p className="f14 gray-500 ">
                Nome e documentos serão usados como destinatário das devoluções
              </p>

              <div className="row">
                <div className="col-6">
                  <div className="form-floating form-field">
                    <Field
                      name="address.name"
                      id="name"
                      type="text"
                      placeholder="John Doe"
                      className="form-control"
                    />

                    <ErrorMessage component="div" name="address.name" className="error-message" />
                    <label htmlFor="name" className="f12 uppercase gray bold">
                      Nome completo
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-floating form-field">
                    <Field
                      name="address.document"
                      id="document"
                      type="text"
                      className="form-control"
                    >
                      {({ field, form }) => (
                        <input
                          {...field}
                          autoComplete="cpf"
                          type="text"
                          placeholder="000.000.000-00"
                          className="form-control"
                          onChange={(e) => {
                            const { value } = e.target;

                            const masked = VMasker.toPattern(
                              value,
                              value.length < 15 ? "999.999.999-99" : "99.999.999/9999-99"
                            );

                            form.setFieldValue(field.name, masked);
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage
                      component="div"
                      name="address.document"
                      className="error-message"
                    />
                    <label htmlFor="document" className="f12 uppercase gray bold">
                      Documento
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-widget-mini">
              <strong className="f14 box-title">Ponto de Devolução</strong>
              <p className="f14 gray-500 ">
                Este é o endereço que será usado pelos clientes para envio da reversas.
              </p>

              <div className="return-point">
                <div className="row">
                  {/* CEP */}
                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="address.zipcode"
                        id="cep"
                        type="text"
                        placeholder="00000-000"
                        onBlur={(e) => blurCepHandler(e, setValues)}
                        className="form-control"
                        component={ZipCodeButton}
                      />

                      <ErrorMessage
                        component="div"
                        name="address.zipcode"
                        className="error-message"
                      />
                      <label htmlFor="cep" className="f12 uppercase gray bold">
                        CEP
                      </label>
                    </div>
                  </div>

                  {/* Av/Rua */}
                  <div className="col-8">
                    <div className="form-floating form-field">
                      <Field
                        name="address.address"
                        id="logradouro"
                        type="text"
                        placeholder="Digite a rua/av"
                        className="form-control"
                      />

                      <ErrorMessage
                        component="div"
                        name="address.address"
                        className="error-message"
                      />

                      <label htmlFor="logradouro" className="f12 uppercase gray bold">
                        Logradouro
                      </label>
                    </div>
                  </div>

                  {/* Number */}
                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="address.number"
                        id="numero"
                        type="text"
                        placeholder="Número"
                        className="form-control"
                      />

                      <ErrorMessage
                        component="div"
                        name="address.number"
                        className="error-message"
                      />

                      <label htmlFor="numero" className="f12 uppercase gray bold">
                        Número
                      </label>
                    </div>
                  </div>

                  {/* Complemento */}
                  <div className="col-5">
                    <div className="form-floating form-field">
                      <Field
                        name="address.address2"
                        id="complemento"
                        type="text"
                        placeholder="Complemento"
                        className="form-control"
                      />

                      <ErrorMessage
                        component="div"
                        name="address.address2"
                        className="error-message"
                      />

                      <label htmlFor="complemento" className="f12 uppercase gray bold">
                        Complemento
                      </label>
                    </div>
                  </div>

                  {/* Cidade */}
                  <div className="col-3">
                    <div className="form-floating form-field">
                      <Field
                        name="address.neighborhood"
                        id="bairro"
                        type="text"
                        placeholder="Bairro"
                        className="form-control"
                      />

                      <ErrorMessage
                        component="div"
                        name="address.neighborhood"
                        className="error-message"
                      />

                      <label htmlFor="bairro" className="f12 uppercase gray bold">
                        Bairro
                      </label>
                    </div>
                  </div>
                  {/* Cidade */}
                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="address.city"
                        id="cidade"
                        type="text"
                        placeholder="Número"
                        className="form-control"
                      />

                      <ErrorMessage component="div" name="address.city" className="error-message" />

                      <label htmlFor="cidade" className="f12 uppercase gray bold">
                        Cidade
                      </label>
                    </div>
                  </div>

                  {/* Estado */}
                  <div className="col-2">
                    <div className="form-floating form-field">
                      <Field
                        name="address.state"
                        id="estado"
                        type="text"
                        placeholder="Estado"
                        className="form-control"
                      />

                      <ErrorMessage
                        component="div"
                        name="address.state"
                        className="error-message"
                      />

                      <label htmlFor="estado" className="f12 uppercase gray bold">
                        Estado
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <footer className="footer-actions">
              <div className="flex flex-right">
                <button
                  disabled={!state.hasChanges || state.submiting}
                  onClick={() => {
                    setState((oldState) => ({
                      ...oldState,
                      initialValues,
                      hasChanges: false,
                    }));
                  }}
                  className="btn default size--medium-2 mr10"
                  type="button"
                >
                  Cancelar
                </button>

                <button
                  className={`btn green size--medium-2 withLoader ${
                    state.submiting ? "loading" : ""
                  } `}
                  disabled={disableSubmit}
                  type="submit"
                >
                  <span className="text">Salvar</span>

                  <div className="dots">
                    <div className="dot dot-1" />
                    <div className="dot dot-2" />
                    <div className="dot dot-3" />
                  </div>
                </button>
              </div>
            </footer>
          </Form>
        )}
      </Formik>
    </>
  );
}
