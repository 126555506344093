import { apiInstance as api } from "services/api";

import { accessDotNotation } from "helpers";

// Reducer actions
import { doLoginSuccess, doLoginFailure, doLoadUserSuccess, doLoadUserFailure } from "./reducer";

// Thunks Actions
export function doActivateAccountRequest(token) {
  return async () => {
    try {
      const { status, data: response } = await api("/api/auth/activate", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token,
        }),
      });

      if (status === 200) {
        return { success: true };
      }
      return { success: false, message: response.error.message };
    } catch (ex) {
      return { success: false, message: ex.response.data.error.message };
    }
  };
}

export function doLoginRequest(email, password) {
  return async (dispatch) => {
    try {
      const { status, data: response } = await api("/api/auth/login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email,
          password,
        }),
      });

      if (status === 200) {
        dispatch(
          doLoginSuccess({
            token: response.data.access_token,
          })
        );
        return { success: true };
      }
      dispatch(doLoginFailure());
      return { success: false, message: response.error.message };
    } catch (ex) {
      dispatch(doLoginFailure());
      return { success: false, message: ex.response.data.error.message };
    }
  };
}

export function doSignupRequest(data) {
  return async () => {
    try {
      // costruct request body before
      const requestBody = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      };

      // If fbtoken is present add to request header.
      if (data.fbToken) requestBody.headers.Authorization = `Bearer ${data.fbToken}`;

      const { status, data: response } = await api("/api/auth/signup", {
        ...requestBody,

        // add data model
        data: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          phone: data.phone.replace(/\D/g, ""),
        }),
      });

      if (status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false, message: response.error.message };
    } catch (ex) {
      return {
        success: false,
        ex,
        message:
          accessDotNotation(ex, "response.data.error.message") ||
          "Ocorreu um erro, por favor tente novamente.",
      };
    }
  };
}

export function doRecoveryRequest(email) {
  return async () => {
    try {
      const { status, data: response } = await api("/api/auth/forgot-password", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email,
        }),
      });

      if (status === 200) {
        return { success: true };
      }
      return { success: false, message: response.error.message };
    } catch (ex) {
      return { success: false, message: ex.response.data.error.message };
    }
  };
}

export function doChangePasswordRequest(password, passwordConfirmation, token) {
  return async () => {
    try {
      const { status, data: response } = await api("/api/auth/reset-password", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          password,
          passwordConfirmation,
          token,
        }),
      });

      if (status === 200) {
        return { success: true };
      }
      return { success: false, message: response.error.message };
    } catch (ex) {
      return { success: false, message: ex.response.data.error.message };
    }
  };
}

export function doLoadUserRequest() {
  return async (dispatch) => {
    try {
      const { status, data: response } = await api("/api/auth/me", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (status === 200) {
        dispatch(doLoadUserSuccess(response.data));
        return { success: true, data: response.data };
      }
      dispatch(doLoadUserFailure());
      return { success: false, message: response.error.message };
    } catch (ex) {
      dispatch(doLoadUserFailure());
      return {
        success: false,
        message:
          accessDotNotation(ex, "response.data.error.message") ||
          "Erro ao tentar salvar configurações.",
      };
    }
  };
}
