import React from "react";
import ReactPaginate from "react-paginate";
import { FaFilter, FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Base Component
import RFC from "components/page-list/rfc";

// => Models
import OrderSummary, { ReturnStatusTypes } from "models/returnal/returnal";

// => Action
import { doGetReturns } from "store/modules/returns/actions";

// => Filters
import Filters from "components/orders/filters";
import FilterSearchInput from "components/orders/filters/filter-search-input";
import FiltersResume from "components/orders/filters-resume";

// Other components
import PageTitle from "components/page-title";
import LoaderBlock from "components/loader-bg";
import DropdownButton from "components/dropdown-button/dropdown";
import SystemAlerts, { ALERT_TYPES } from "components/system-alerts";
import List from "./components/list";
import FastFilters from "./components/fast-filters";

const availableFilters = [
  {
    id: 1,
    label: "Data de Criação",
    param: "created_at",
    type: "daterange",
  },

  {
    id: 2,
    label: "Data de Cancelamento",
    param: "cancelled_at",
    type: "daterange",
  },

  // {
  //   id: 2,
  //   label: "Data de Atualização",
  //   param: "updated_at",
  //   type: "daterange",
  // },

  {
    id: 3,
    label: "Status",
    param: "status",
    type: "checkbox",
    options: [
      {
        slug: ReturnStatusTypes.PENDING,
        label: "Pendente Aprovação",
      },
      {
        slug: ReturnStatusTypes.APPROVED,
        label: "Aprovado",
      },

      {
        slug: ReturnStatusTypes.DELIVERED,
        label: "Recebido",
      },

      {
        slug: ReturnStatusTypes.CONFERRED,
        label: "Conferido",
      },

      {
        slug: ReturnStatusTypes.ENDED,
        label: "Finalizada",
      },

      {
        slug: ReturnStatusTypes.CANCELLED,
        label: "Cancelada",
      },
    ],
  },

  {
    id: 4,
    label: "Tipos de solicitação",
    param: "returnal_type",
    type: "checkbox",
    options: [
      {
        slug: "RETURN",
        label: "Devoluções",
      },
      {
        slug: "EXCHANGE",
        label: "Trocas",
      },
    ],
  },

  {
    id: 8,
    label: "Paginação",
    param: "page",
    type: "string",
    defaultValue: 1,
    hidden: true,
  },

  {
    id: 9,
    label: "Itens por página",
    param: "page_size",
    type: "string",
    defaultValue: 10,
    hidden: true,
  },

  {
    id: 10,
    label: "Pesquisar",
    param: "search",
    type: "string",
    hidden: true,
  },
];

export default () => (
  <RFC availableFilters={availableFilters} loadAction={doGetReturns} resourceClass={OrderSummary}>
    {({
      state: { metadata, filters, loading, filterOpened, list, allSelectorActive },
      actions: {
        toggleFilter,
        handleFilter,
        clearFilters,
        modifyLine,
        toggleSelectResource,
        toggleSelectAllResources,
        loadResources,
      },
      helpers: { filtersToObj },

      activeFilters,
      currentMerchant,
      setState,
    }) => {
      const currentStatusFilter = filters.find((f) => f.slug === "status")?.values;

      return (
        <div className="page returns-list container list-with-filter">
          <PageTitle pageTitle="Trocas/Devoluções" />

          <header className="page-header">
            <div className="row">
              <div className="col head-left flex-d-column">
                <h1 className="title">Trocas/Devoluções</h1>
                <span className="ml25 gray">{metadata.totalItems} reversas</span>
              </div>

              <div className="col flex-right flex-d-column">
                <button
                  type="button"
                  className="btn default size--medium d-block d-sm-none"
                  onClick={() => toggleFilter()}
                >
                  <FaFilter /> Filtrar
                </button>
              </div>
            </div>
          </header>

          <SystemAlerts
            filterTypes={[ALERT_TYPES.MENV, ALERT_TYPES.PAUSED_APP, ALERT_TYPES.NEW_APP]}
          />

          {/* Fast filters */}
          <FastFilters handleFilter={handleFilter} currentStatusFilter={currentStatusFilter} />

          <main className="container-page">
            <div className="filters">
              <button
                type="button"
                className="btn default size--medium d-none d-md-inline-block"
                onClick={() => toggleFilter()}
              >
                <FaFilter /> Filtrar
              </button>

              <FilterSearchInput
                searchValue={activeFilters.search}
                handleSearch={(searchValue) => handleFilter("search", searchValue)}
                placeholder="CPF, e-mail ou pedido"
              />

              {/* <DropdownButton
                label="Ações"
                className="d-none d-md-inline-block"
                classes="btn default size--medium"
                options={[
                  {
                    value: "approve",
                    label: "Aprovar solicitações",
                    callback: () => {},
                    disabled: true, //activeFilters.isArchived || !list.filter((o) => o.isSelected).length,
                  },
                ]}
              /> */}
            </div>

            <FiltersResume
              activeFilters={filters.filter((f) => !f.hidden)}
              handleFilter={handleFilter}
              clearFilters={clearFilters}
            />

            {loading ? (
              <LoaderBlock blocks={8} width={5} />
            ) : (
              <>
                <List
                  allSelectorActive={allSelectorActive}
                  list={list}
                  selectAll={toggleSelectAllResources}
                  select={toggleSelectResource}
                  merchantId={currentMerchant.uuid}
                />
              </>
            )}
          </main>

          <footer className="footer-actions">
            <div className="row">
              <div className="col">
                <span className="items-per-page">
                  <DropdownButton
                    label={activeFilters.page_size ? activeFilters.page_size : 10}
                    classes="btn default size--medium"
                    callback={(option) => {
                      handleFilter("page_size", option);
                    }}
                    options={[
                      {
                        value: 10,
                        label: 10,
                      },
                      {
                        value: 20,
                        label: 20,
                      },
                      {
                        value: 30,
                        label: 30,
                      },
                    ]}
                  />
                  <span>itens por página</span>
                </span>
              </div>

              <div className="col flex-right">
                {!loading && (
                  <ReactPaginate
                    containerClassName="pagination"
                    previousLabel={<FaChevronLeft />}
                    nextLabel={<FaChevronRight />}
                    disableInitialCallback
                    marginPagesDisplayed={1}
                    breakLabel="..."
                    activeClassName="active"
                    initialPage={activeFilters.page ? activeFilters.page - 1 : 0}
                    pageCount={metadata.totalPages}
                    onPageChange={(e) => handleFilter("page", e.selected + 1)}
                  />
                )}
              </div>
            </div>
          </footer>

          <Filters
            availableFilters={availableFilters}
            filterStatus={filterOpened}
            activeFilters={filters}
            toggleFilter={toggleFilter}
            handleFilter={handleFilter}
            clearFilters={clearFilters}
            handle={handleFilter}
          />
        </div>
      );
    }}
  </RFC>
);
