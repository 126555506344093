import { immerable } from "immer";

export default class SubscriptionStatus {
  constructor(statusCode) {
    this[immerable] = true;

    const traitedStatus = SubscriptionStatus.statusToText(statusCode);

    this.code = traitedStatus.code;
    this.label = traitedStatus.label;
    this.css = `BILLING_${traitedStatus.code}`;
  }

  static statusToText(status) {
    switch (status) {
      // => FINANCIAL
      case "PAID":
        return { label: "Paga", code: status };

      case "PENDING":
        return { label: "Pagamento recusado", code: status };

      case "ACTIVE":
        return { label: "Ativa", code: status };

      case "INACTIVE":
        return { label: "Autorizando pagamento", code: status };

      case "CANCELLED_IN_DEBT":
        return { label: "Pagamento pendente", code: status };

      case "CANCELLED":
        return { label: "Cancelada", code: status };

      default:
        return { label: "Não identificado", code: "UNDEFINED" };
    }
  }
}
