import React, { useEffect, useState } from "react";
import VMasker from "vanilla-masker";

function applyMask(rawValue) {
  const masked = VMasker.toPattern(
    rawValue?.normalize("NFD")?.replace(/[^a-zA-Z0-9s]/g, ""),
    "99999-999"
  );
  const cleanV = masked?.normalize("NFD")?.replace(/[^a-zA-Z0-9s]/g, "");

  return { masked, cleaned: cleanV };
}

export default function ZipCodeButton({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(applyMask(field?.value || "").masked);
  }, []);

  const handleKeyUp = (e, v) => {
    e.preventDefault();

    const maskedValue = applyMask(v);
    setValue(maskedValue.masked);

    form.setFieldValue("address.zipcode", maskedValue.cleaned);
  };

  return (
    <input
      {...field}
      {...props}
      type="tel"
      autoComplete="postal-code"
      className="form-control"
      placeholder="CEP"
      onChange={(e) => handleKeyUp(e, e.target.value)}
      value={value}
    />
  );
}
