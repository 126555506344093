import React from "react";

// Components
import BottomActions from "../components/bottom-actions";

export default function Intro({ stepConfigs, nextStepHandler }) {
  return (
    <section className="wizard-step step-welcome">
      <h1 className="step-title">Seja bem vindo(a) a Iumy!</h1>
      <p className="description">
        Preparamos uma jornada incrível para que você crie e configure sua conta aqui na plataforma!
        Vamos começar?
      </p>

      <BottomActions enableNext nextStepBtnText="Começar" nextStepHandler={nextStepHandler} />
    </section>
  );
}
