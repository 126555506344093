/* eslint-disable max-classes-per-file */

import { immerable } from "immer";
import PlanStatus from "./plan-status";
import PaymentCustomer from "./payment-customer";
import PaymentMethod from "./payment-method";
import PlanSummary from "./plan-summary";
import InvoiceSummary from "./invoice-summary";

export default class Billing {
  constructor(options = {}) {
    this[immerable] = true;

    this.plans = options?.plans?.map((p) => new PlanSummary(p)) || [];

    const matchPlan =
      this?.plans?.find((p) => p.slug === options?.currentSubscription?.billingPlanSlug) || {};

    this.currentSubscription = options.currentSubscription
      ? {
          uuid: options?.currentSubscription?.uuid,
          billingPlanName: options?.currentSubscription?.billingPlanName,
          billingPlanSlug: options?.currentSubscription?.billingPlanSlug,
          billingPlanDescription: matchPlan?.description,
          billingPlanPrice: matchPlan.price,
          billingPlanDetails: matchPlan.details,
          orderQuantity: matchPlan.orderQuantity,
          freeOrdersImportUsed: options?.currentSubscription?.freeOrdersImportUsed || true,
          billingNextDate:
            options?.currentSubscription?.nextChargeAt ||
            new Date().setDate(new Date().getDate() + 30),
          planQuotaUsed: options?.currentSubscription?.planQuotaUsed,
          isTrial: options?.currentSubscription?.isTrial,
          endedAt: options?.currentSubscription?.endedAt,
          status: new PlanStatus(options?.currentSubscription?.status),
        }
      : null;

    this.paymentCustomer = options.paymentCustomer
      ? new PaymentCustomer(options.paymentCustomer)
      : null;

    this.paymentMethod = options.paymentMethod ? new PaymentMethod(options.paymentMethod) : null;
    this.invoices = options?.invoices
      ?.map((invoice) => new InvoiceSummary(invoice))
      .sort((a, b) => new Date(b.dueAt) - new Date(a.dueAt));
  }
}
