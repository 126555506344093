import { immerable } from "immer";
import Status from "./plan-status";

export default class InvoiceSummary {
  constructor(options) {
    const description = options?.description?.split("-") || [];

    this[immerable] = true;
    this.uuid = options?.uuid;
    this.createdAt = options?.createdAt;
    this.dueAt = options?.dueAt;
    this.price = options?.price;
    this.description = description[0];
    this.subTitle = description[1];
    this.pixQrcode = options?.pixQrcode;
    this.pixQrcodeText = options?.pixQrcodeText;
    this.status = new Status(options?.status);
    this.gateway = options?.gateway || "IUGU";
  }
}
