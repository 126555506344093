import Billing from "models/billing-plan";
import PaymentCustomer from "models/payment-customer";
import PaymentMethod from "models/payment-method";
import Status from "models/plan-status";
import { createReducer } from "reduxsauce";

import { Types } from "./actions";

export const GatewayTypes = {
  ASAAS: "ASAAS",
  IUGU: "IUGU",
};

const initialState = {
  currentBilling: new Billing(),
  loading: true,
};

const setLoadingStatus = (state = initialState, action) => ({
  ...state,
  loading: action.payload,
});

const doLoadBillingDetailsSuccess = (state = initialState, action) => ({
  ...state,
  currentBilling: new Billing(action.payload),
  loading: false,
});

const doSavePaymentCustomerSuccess = (state = initialState, action) => ({
  ...state,
  loading: false,
  currentBilling: {
    ...state.currentBilling,
    paymentCustomer: new PaymentCustomer(action.payload),
  },
});

const doSaveCreditCardSuccess = (state = initialState, action) => ({
  ...state,
  loading: false,
  currentBilling: {
    ...state.currentBilling,
    paymentMethod: new PaymentMethod(action.payload),
  },
});

const doPayInvoiceSuccess = (state = initialState, action) => ({
  currentBilling: {
    ...state.currentBilling,
    invoices: state.currentBilling.invoices.map((i) => {
      if (i.uuid === action.payload) i.status = new Status("PAID");

      return i;
    }),
  },
});

const doUpdateInvoice = (state = initialState, action) => ({
  currentBilling: {
    ...state.currentBilling,
    invoices: state.currentBilling.invoices.map((i) => {
      if (i.uuid === action.payload.uuid) {
        return {
          ...i,
          pixQrcode: action.payload.pixQrcode,
          pixQrcodeText: action.payload.pixQrcodeText,
        };
      }

      return i;
    }),
  },
});

export default createReducer(initialState, {
  [Types.DO_SAVE_PAYMENT_CUSTOMER_SUCCESS]: doSavePaymentCustomerSuccess,
  [Types.DO_LOAD_BILLING_DETAILS_SUCCESS]: doLoadBillingDetailsSuccess,
  [Types.DO_SAVE_CREDIT_CARD_SUCCESS]: doSaveCreditCardSuccess,
  [Types.SET_LOADING_STATUS]: setLoadingStatus,
  [Types.DO_PAY_INVOICE_SUCCESS]: doPayInvoiceSuccess,
  [Types.DO_UPDATE_INVOICE]: doUpdateInvoice,
});
