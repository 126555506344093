import { createReducer } from "reduxsauce";
import produce from "immer";

// Types
import Merchant from "models/merchant";
import { Types } from "./actions";

const initialState = {
  merchantList: [],
  currentMerchant: null,
};

const doCreateMerchantSuccess = (state = initialState, action) => {
  const newMerchant = new Merchant(action.payload);

  return produce(state, (draftState) => {
    draftState.merchantList.push(newMerchant);
  });
};

const doUpdateMerchantSuccess = (state = initialState, action) => {
  const newPayloadData = action.payload;
  const isCurrent = state.currentMerchant.uuid === newPayloadData.uuid;
  let storeToUpdate;

  if (isCurrent) {
    storeToUpdate = state.currentMerchant;
  } else
    storeToUpdate = state.merchantList.find((merchant) => merchant.uuid === newPayloadData.uuid);

  const newMerchant = {
    ...storeToUpdate,
    ...newPayloadData,
  };

  const newState = {
    ...state,
    merchantList: state.merchantList.map((merchant) => {
      if (merchant.uuid === newPayloadData.uuid) {
        return newMerchant;
      }

      return merchant;
    }),
  };

  if (isCurrent) newState.currentMerchant = newMerchant;

  return newState;
};

const doGetMerchantListSuccess = (state = initialState, action) => {
  const merchants = action.payload.map((merchant) => new Merchant(merchant));

  return produce(state, (draftState) => {
    draftState.merchantList = merchants;
  });
};

const doGetAppsSuccess = (state = initialState, action) =>
  produce(state, (draftState) => {
    if (draftState.currentMerchant) draftState.currentMerchant.apps = [...action.payload];
  });

const doGetAppDetailSuccess = (state = initialState, action) =>
  produce(state, (draftState) => {
    if (draftState.currentMerchant) {
      const index = draftState.currentMerchant.apps.findIndex(
        (app) => app.type === action.payload.type
      );

      if (index > -1) draftState.currentMerchant.apps[index] = action.payload;
    }
  });

const updateCurrentMerchant = (state = initialState, action) =>
  produce(state, (draftState) => {
    draftState.currentMerchant = action.payload;
  });

const doSaveAppSettingsSuccess = (state = initialState, action) =>
  produce(state, (draftState) => {
    if (draftState.currentMerchant) {
      draftState.currentMerchant.apps.map((app) => {
        if (app.type.toLowerCase() === action.payload.slug) app.isActive = action.payload.isActive;

        return app;
      });
    }
  });

const doResetCurrentMerchant = () => ({ ...initialState, currentMerchant: null });

export default createReducer(initialState, {
  [Types.DO_CREATE_MERCHANT_SUCCESS]: doCreateMerchantSuccess,
  [Types.DO_UPDATE_MERCHANT_SUCCESS]: doUpdateMerchantSuccess,
  [Types.DO_GET_MERCHANT_LIST_SUCCESS]: doGetMerchantListSuccess,
  [Types.DO_GET_APPS_SUCCESS]: doGetAppsSuccess,
  [Types.DO_GET_APP_DETAIL_SUCCESS]: doGetAppDetailSuccess,
  [Types.UPDATE_CURRENT_MERCHANT]: updateCurrentMerchant,
  [Types.DO_SAVE_APP_SETTINGS_SUCCESS]: doSaveAppSettingsSuccess,
  [Types.DO_RESET_CURRENT_MERCHANT]: doResetCurrentMerchant,
});
