import Iugu from "libs/iugu";

import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaCheckCircle, FaCalendarAlt, FaQrcode } from "react-icons/fa";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { useLocation, Link } from "react-router-dom";

// Helpers
import {
  Creators as BillingActions,
  doPayInvoice,
  doLoadBillingDetails,
  doCancelSubscription,
  generateASAASPixRequest,
  doCalcMigratePlanPendingValuesRequest,
} from "store/modules/billing/actions";

// Helpers
import { formatPrice, swalMiddleware as Swal } from "helpers";

// Components
import PageTitle from "components/page-title";
import TooltipElem from "components/tooltip";
import Vector from "images/vectors/planos-vector.png";
import svgCreditCard from "images/creditcard-svg";
import LoaderBG from "components/loader-bg";

// Inner components
import BillingModal from "./components/billing-modal";
import CreditCardModal from "./components/credit-card-modal";
import PlansModal from "./components/plans-modal";
import PixModal from "./components/pix-modal";

const CANCEL_REASONS = {
  dificult: "Dificuldade de uso",
  otherApp: "Trocando por outro app mais barato",
  otherAppUse: "Trocando por outro app mais fácil de usar",
  pause: "Pausei a loja",
};

function BillingIndex(props) {
  const { billing, loading, dispatch, doUpdateInvoice, currentMerchant } = props;
  const { pathname, state: locationState } = useLocation();

  const [state, setState] = useState({
    modalOpened: null,
    wizard: false,
    cancelingSubscription: false,
    showPixModal: false,
    currentPix: null,
  });

  const planUsage =
    (100 * billing?.currentSubscription?.planQuotaUsed) /
    billing?.currentSubscription?.orderQuantity;
  const surplusOrders =
    billing?.currentSubscription?.planQuotaUsed - billing?.currentSubscription?.orderQuantity;

  useEffect(() => {
    Iugu();
  }, []);

  useEffect(() => {
    dispatch(doLoadBillingDetails());
  }, [dispatch]);

  const modalToggleHandler = (modalSlug, success) => {
    if (modalSlug === "plans" && (!billing.paymentCustomer || !billing.paymentMethod))
      return setState((oldState) => ({
        ...oldState,
        modalOpened: !billing.paymentCustomer ? "billing" : "payment",
      }));

    const wizard = !billing.paymentCustomer && !billing.paymentMethod;

    if (state.modalOpened === "billing" && !modalSlug && wizard && success)
      return setState((oldState) => ({
        ...oldState,
        modalOpened: "payment",
      }));

    if (!modalSlug) return setState({ ...state, modalOpened: null });

    return setState({ ...state, modalOpened: modalSlug });
  };

  const manualPayBilling = (invoiceUuid, price) => {
    Swal.fire({
      icon: "warning",
      title: "Realizar pagamento",
      confirmButtonColor: "#36d87e",
      cancelButtonText: "Cancelar",
      text: `Deseja realizar o pagamento no valor de ${formatPrice(price)} no cartão de final ${
        billing.paymentMethod.cardLastDigits
      }?`,
      showCancelButton: true,
      confirmButtonText: "Pagar agora!",
      showLoaderOnConfirm: true,

      preConfirm: async () => {
        const { success, message } = await dispatch(doPayInvoice(invoiceUuid));

        if (!success) Swal.showValidationMessage(message);

        return success;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result) {
        if (!result.isDismissed)
          Swal.fire({
            title: `Pagamento realizado!`,
            icon: "success",
            text: "Muito obrigado, seu pagamento foi recebido com sucesso!",
          }).then(() => dispatch(doLoadBillingDetails()));
      }
    });
  };

  const cancelSubscription = async () => {
    const response = await doCalcMigratePlanPendingValuesRequest();
    const pendingValues = response.data?.price ? response.data?.priceFormatted : false;

    const text = pendingValues
      ? `Ao cancelar sua assinatura será cobrado o valor de ${pendingValues} referente à pedidos que ultrapassaram o limite do seu plano.`
      : "Tem certeza que deseja cancelar sua assinatura?";

    return Swal.fire({
      title: "Deseja cancelar sua assinatura?",
      text,
      input: "select",
      icon: "warning",
      confirmButtonText: "Quero cancelar minha assinatura!",
      showCancelButton: true,

      cancelButtonText: "Não",

      inputPlaceholder: "Informe o motivo do cancelamento",
      inputOptions: {
        ...CANCEL_REASONS,
      },
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,

      inputValidator: (value) =>
        new Promise((resolve) => {
          if (!value) {
            resolve("Informe um motivo.");
          } else {
            resolve();
          }
        }),

      preConfirm: async (value) => {
        const cancelReason = CANCEL_REASONS[value];
        const currentSubId = billing.currentSubscription.uuid;
        const cancelResponse = await dispatch(doCancelSubscription(currentSubId, cancelReason));

        if (!cancelResponse.success) {
          Swal.close();

          return Swal.fire({
            icon: "error",
            title: "Falha ao cancelar assinatura!",
            text: "Por favor entre em contato com o suporte através do e-mail contato@iumy.com.br ou pelo botão de chat no canto inferior direito da tela.",
            showCancelButton: false,
          });
        }

        const errorMessage =
          cancelResponse.data.status === "CANCELLED_IN_DEBT"
            ? "Ocorreu um erro ao cobrar os valores pendentes de sua assinatura, por favor regularize a situação urgentemente."
            : "Ocorreu um erro ao cancelar sua assinatura, por favor entre em contato com o suporte.";

        if (cancelResponse.success && cancelResponse.data.status !== "CANCELLED_IN_DEBT") {
          return Swal.fire({
            title: "Assinatura cancelada!",
            text: "Sua assinatura foi cancelada com sucesso.",
            icon: "success",
            showConfirmButton: true,
            heightAuto: false,
          }).then(() => dispatch(doLoadBillingDetails()));
        }

        return Swal.fire({
          title: "Falha ao cancelar assinatura!",
          text: errorMessage,
          icon: "error",
          showConfirmButton: true,
          heightAuto: false,
        }).then(() => dispatch(doLoadBillingDetails()));
      },
    });
  };

  const togglePixModal = (pixOptions = null) => {
    setState({
      ...state,
      showPixModal: !state.showPixModal,
      currentPix: pixOptions,
    });
  };

  const getASAASPix = async (invoiceUuid) => {
    const result = await Swal.fire({
      title: "Deseja gerar um pix para esta fatura?",
      icon: "iumy",
      text: "Após transformar essa cobrança em pix não será possível pagá-la via cartão, somente mediante a ativação do suporte.",
      confirmButtonText: `Gerar PIX`,
      cancelButtonText: "Cancelar e pagar no cartão.",
      showCancelButton: true,
    });

    if (!result.isConfirmed) return false;

    const { data, success, message } = await generateASAASPixRequest(invoiceUuid);

    if (success) {
      dispatch(doUpdateInvoice(data));

      return togglePixModal({
        pixQrcode: data.pixQrcode,
        pixQrcodeText: data.pixQrcodeText,
      });
    }

    return toast.error(message);
  };

  const { fromHome } = locationState || {};
  let backlink;
  if (fromHome) backlink = `/${currentMerchant.uuid}/home`;
  else backlink = `/${currentMerchant}/config`;

  return (
    <div className="page billing-index container">
      <PageTitle pageTitle="Plano & Faturamento" />

      <header className="page-header">
        <Link to={backlink} className="f13 black-70 backbtn">
          <FaChevronLeft /> voltar
        </Link>
        <h1 className="title">Plano & Faturamento</h1>
      </header>

      <hr />

      {loading ? (
        <LoaderBG />
      ) : (
        <>
          {!billing.currentSubscription ? (
            <div className="row plan-welcome">
              <div className="col-lg-4 image">
                <img src={Vector} alt="Vetor planos" />
              </div>
              <div className="col welcome-message">
                <h2>
                  Escolha um plano ideal <br />
                  para Você e sua Loja!
                </h2>
                <p>
                  Chegou a hora de mudar a forma como você gerencia sua operação, escolha um plano e
                  vamos começar!
                </p>
                <button
                  type="button"
                  className="btn green"
                  onClick={() => modalToggleHandler("plans")}
                >
                  Escolher plano!
                </button>
              </div>
            </div>
          ) : (
            <div className="row plan-infos">
              <div className="col-md-4">
                <h5 className="side-info-title">Informações do plano</h5>
                <p className="side-info-description">
                  Gerencie a assinatura da Iumy e veja as informações de seu plano.
                </p>
              </div>

              <div className="col">
                {(billing.currentSubscription.status.code === "CANCELLED_IN_DEBT" ||
                  billing.currentSubscription.status.code === "PENDING") &&
                  !billing.currentSubscription.isTrial && (
                    <div className="alert-boxes danger mb15">
                      <div className="row">
                        <div className="col-8">
                          Você possui faturas pendentes, por favor realize o pagamento para
                          regularizar seu plano.
                        </div>

                        <div className="col text-end">
                          <a className="btn-red-light" href={`${pathname}#invoices`}>
                            Ver faturas
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="box-widget mb30">
                  <div className="inner">
                    <div className="row">
                      <div className="col-md-7">
                        <h3 className="plan-title">
                          <small>PLANO</small>
                          {billing.currentSubscription.billingPlanName}
                        </h3>
                        <p className="plan-description">
                          {billing.currentSubscription.billingPlanDescription}
                        </p>
                      </div>
                      <div className="col offset-md-1 flex-right">
                        {/* Widget */}
                        <div className="plan-price-box">
                          <div className="plan-price-wrap">
                            <span className="plan-currency">R$</span>
                            <span className="plan-price">
                              {billing.currentSubscription.billingPlanPrice}
                            </span>
                            <span className="plan-debit-period">/mês</span>
                          </div>
                          <div className="plan-next-billing">
                            <FaCalendarAlt size={18} className="calendar-icon" />
                            <div className="plan-billing-date-wrapper">
                              <span className="billing-date-label">
                                {billing.currentSubscription.isTrial ||
                                billing.currentSubscription.status.code.indexOf("CANCELLED") > -1
                                  ? "Vigente até"
                                  : "Próxima renovação"}
                              </span>
                              <span className="billing-date">
                                {moment(
                                  billing.currentSubscription.isTrial ||
                                    billing.currentSubscription.status.code === "CANCELLED"
                                    ? billing.currentSubscription.endedAt
                                    : billing.currentSubscription.billingNextDate
                                ).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="inner">
                    <h5 className="mini-title">O PLANO INCLUI</h5>

                    <ul className="plan-benefits">
                      {billing.currentSubscription.billingPlanDetails.map((d) => (
                        <li key={d.id}>
                          <FaCheckCircle className="icon-check" />
                          <p>
                            {d.title}
                            <span className="under">{d.subtitle}</span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr />
                  <div className="inner plan-usage">
                    <h5 className="mini-title">USO DO PLANO</h5>

                    <div className="progress-bar-wrap">
                      <div
                        className={`progress-bar ${planUsage > 100 ? "red" : "green"}`}
                        style={{
                          width: `${planUsage}%`,
                        }}
                      />
                    </div>
                    <span className="usage-description">
                      Você processou{" "}
                      <strong>{billing.currentSubscription.planQuotaUsed} pedidos</strong> do limite
                      atual de seu plano que é de{" "}
                      <strong>{billing.currentSubscription.orderQuantity} pedidos. </strong>
                      Totalizando {surplusOrders < 0 ? 0 : surplusOrders} pedidos excedentes.{" "}
                      <i>Excedentes são cobrados semanalmente.</i>
                    </span>
                  </div>
                  <hr />

                  <div className="plan-footer">
                    <div className="plan-status">
                      <h6>Status da assinatura</h6>
                      <span className={`iumy-badge ${billing.currentSubscription.status.css}`}>
                        {billing.currentSubscription.status.label}
                      </span>
                    </div>

                    <div className="inner plan-actions">
                      {billing.currentSubscription.status.code.indexOf("CANCELLED") === -1 &&
                        !billing.currentSubscription.isTrial && (
                          <button
                            type="button"
                            onClick={cancelSubscription}
                            className="btn size-14-p11 btn-bordered red"
                          >
                            Cancelar assinatura
                          </button>
                        )}
                      <button
                        type="button"
                        disabled={
                          billing.currentSubscription.status.code === "PENDING" ||
                          billing.currentSubscription.status.code === "INACTIVE" ||
                          billing.currentSubscription.status.code === "CANCELLED_IN_DEBT"
                        }
                        className="btn size-14-p11 green"
                        onClick={() => modalToggleHandler("plans")}
                      >
                        {billing.currentSubscription.isTrial ||
                        billing?.currentSubscription?.status?.code.indexOf("CANCELLED") > -1
                          ? "Escolher plano"
                          : "Trocar plano"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row plan-payment">
            <div className="col-md-4">
              <h5 className="side-info-title">Faturamento & Pagamento</h5>
              <p className="side-info-description">
                Descreva quem será o beneficiário do plano e tomador das notas fiscais, como também
                um cartão de crédito para cobrança.
              </p>
            </div>

            <div className="col">
              <div className="box-widget mb30">
                <div className="inner">
                  <h3 className="f16">Dados de faturamento e pagamento</h3>
                </div>
                <hr />

                {!billing.paymentCustomer ? (
                  <div className="inner">
                    <div className="warning-box">
                      <strong>💳 Você ainda não cadastrou os dados de faturamento e cartão.</strong>
                      <button type="button" onClick={() => modalToggleHandler("billing")}>
                        + Cadastrar dados de faturamento e cartão
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="inner">
                      <div className="invoice-resume-wrap">
                        <div className="invoice-resume">
                          <strong>{billing.paymentCustomer.name}</strong>
                          <br />
                          <span className="invoice-document f14">
                            Documento: {billing.paymentCustomer.document}
                            <br />
                            <span className="gray-500">{billing.paymentCustomer.email}</span>
                          </span>
                        </div>

                        <button
                          type="button"
                          onClick={() => modalToggleHandler("billing")}
                          className="btn btn-text blue f14 h14"
                        >
                          Alterar dados de faturamento
                        </button>
                      </div>
                      <p className="invoice-data">
                        {`${billing.paymentCustomer.addressStreet}, ${billing.paymentCustomer.addressNumber}`}
                        {billing.paymentCustomer.addressComplement && (
                          <>
                            <br />
                            {billing.paymentCustomer.addressComplement}
                          </>
                        )}
                        <br /> {billing.paymentCustomer.addressNeighborhood}
                        <br /> {billing.paymentCustomer.addressCity} /{" "}
                        {billing.paymentCustomer.addressState}
                        <br /> CEP: {billing.paymentCustomer.addressZipcode}
                      </p>
                    </div>
                    <hr />

                    <div className="inner">
                      <h5 className="mini-title">MEIO DE PAGAMENTO</h5>

                      {billing.paymentMethod ? (
                        <div className="payment-row">
                          <div className="credit-card-resume">
                            <svg
                              id="ccicon"
                              className="ccicon"
                              width="750"
                              viewBox="0 0 750 471"
                              version="1.1"
                              dangerouslySetInnerHTML={{
                                __html: svgCreditCard(billing.paymentMethod.cardBrand),
                              }}
                            />
                            {billing.paymentMethod.cardBrand} com final{" "}
                            {billing.paymentMethod.cardLastDigits}
                          </div>

                          <button
                            type="button"
                            onClick={() => modalToggleHandler("payment")}
                            className="btn btn-text blue f14 h14"
                          >
                            Alterar forma de pagamento
                          </button>
                        </div>
                      ) : (
                        <div className="warning-box">
                          <strong>💳 Você ainda não cadastrou um meio de pagamento.</strong>
                          <button type="button" onClick={() => modalToggleHandler("payment")}>
                            + Cadastrar meio de pagamento
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row plan-payment-history" id="invoices">
            <div className="col-md-4">
              <h5 className="side-info-title">Histórico de cobrança</h5>
              <p className="side-info-description">Acompanhe todas transações e o detalhamento.</p>
            </div>

            <div className="col">
              <div className="box-widget mb30">
                <div className="inner">
                  <h3 className="f16">Últimas movimentações</h3>
                </div>
                <hr />
                <div className="transaction-table-wrap">
                  <table className="transactions-table">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Vencimento</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {billing.invoices.length ? (
                        billing.invoices.map((t) => {
                          const showPayBtn =
                            (t.status.code === "PENDING" && t.gateway === "IUGU") ||
                            (t.status.code === "PENDING" && t.gateway === "ASAAS" && !t.pixQrcode);

                          return (
                            <tr key={t.uuid}>
                              <td>
                                <span className="transaction-date">
                                  <FaCalendarAlt />
                                  <span>{moment(t.dueAt).format("DD/MM/YYYY [às] HH:mm")}</span>
                                </span>

                                <p>
                                  {t.description}
                                  <br />
                                  {t.subTitle && <small>{t.subTitle}</small>}
                                </p>
                              </td>

                              <td>{formatPrice(t.price)}</td>
                              <td>
                                <span className={`iumy-badge ${t.status.css}`}>
                                  {t.status.label}
                                </span>
                              </td>
                              <td>{moment(t.dueAt).format("DD/MM/YYYY")}</td>
                              <td className="actions">
                                <div>
                                  {showPayBtn && (
                                    <button
                                      type="button"
                                      className="btn size--small green"
                                      onClick={() => manualPayBilling(t.uuid, t.price)}
                                    >
                                      Pagar
                                    </button>
                                  )}

                                  {/* Asaas generate */}
                                  {t.status.code === "PENDING" &&
                                    t.gateway === "ASAAS" &&
                                    !t.pixQrcode && (
                                      <TooltipElem
                                        toolTipText="Pague por Pix!"
                                        content={
                                          <button
                                            type="button"
                                            className="btn size--small green"
                                            onClick={() => getASAASPix(t.uuid)}
                                          >
                                            <FaQrcode />
                                          </button>
                                        }
                                      />
                                    )}

                                  {t.status.code === "PENDING" && t.pixQrcode && (
                                    <TooltipElem
                                      toolTipText="Pague por Pix!"
                                      content={
                                        <button
                                          type="button"
                                          className="btn size--small green"
                                          onClick={() =>
                                            togglePixModal({
                                              pixQrcode: t.pixQrcode,
                                              pixQrcodeText: t.pixQrcodeText,
                                            })
                                          }
                                        >
                                          <FaQrcode />
                                        </button>
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <p className="text-center">Nenhuma cobrança encontrada</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modals */}
      {state.modalOpened === "billing" && (
        <BillingModal
          paymentCustomer={billing.paymentCustomer}
          modalToggleHandler={modalToggleHandler}
        />
      )}

      {state.modalOpened === "payment" && (
        <CreditCardModal modalToggleHandler={modalToggleHandler} />
      )}

      {state.showPixModal && (
        <PixModal pixQrcode={state.currentPix} modalToggleHandler={togglePixModal} />
      )}

      {state.modalOpened === "plans" && (
        <PlansModal
          plans={billing.plans}
          modalToggleHandler={modalToggleHandler}
          currentSubscription={billing?.currentSubscription}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  billing: state.billing.currentBilling,
  loading: state.billing.loading,
  currentMerchant: state.merchants.currentMerchant,
});

// const map
const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators(
    {
      ...BillingActions,
    },
    dispatch
  );
  return {
    ...binders,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingIndex);
