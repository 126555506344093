import React from "react";

import Modal from "components/modal";
import { selectText } from "helpers";

export default function PixModal({ modalToggleHandler, pixQrcode }) {
  return (
    <Modal
      showModal
      cancelHandler={modalToggleHandler}
      toggleModal={modalToggleHandler}
      className="pix-modal"
      title="Pague por Pix!"
      size="sm"
    >
      <div className="text-center">
        <h3>Atenção</h3>
        <p>Após realizar o pagamento aguarde alguns minutos e então recarregue a página.</p>
        <img
          style={{ marginBottom: 10 }}
          src={pixQrcode.pixQrcode}
          alt="Qr Code Pix"
          className="img-fluid"
        />

        <h6>Código copia e cola:</h6>
        <p style={{ color: "#0000008c", fontSize: 14, marginTop: 0 }}>
          Escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos. Depois, cole o
          seguinte código:
        </p>
        <code id="code" onClick={() => selectText("code", pixQrcode.pixQrcodeText)} role="textbox">
          {pixQrcode.pixQrcodeText}
        </code>
      </div>
    </Modal>
  );
}
