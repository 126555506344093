import { apiInstance as api } from "services/api";

import { doGetReturnsFailure, doGetReturnsSuccess } from "./reducer";
import { accessDotNotation, getErrorMessage } from "helpers";

// => Ajax
export const doGetReturns = (storeUuid, options) => async (dispatch) => {
  const defaultErrorMessage = "Falha ao carregar reversas";

  try {
    const { status, data } = await api(`/api/store/${storeUuid}/returnal`, {
      method: "get",
      params: options,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      dispatch(doGetReturnsSuccess(data));

      return {
        success: true,
        status,
        data,
      };
    }

    dispatch(doGetReturnsFailure(defaultErrorMessage));
    return {
      success: false,
      status,
      message: defaultErrorMessage,
    };
  } catch (ex) {
    const message = getErrorMessage(ex) || defaultErrorMessage;
    dispatch(doGetReturnsFailure(message));

    return {
      success: false,
      status: ex.status || 500,
      message,
    };
  }
};

export const doGetReturnDetail = async (storeUuid, returnalUuid) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao buscar detalhes da reversa",
    };
  } catch (ex) {
    const message = getErrorMessage(ex);
    return {
      success: false,
      status: ex.status || 500,
      message,
    };
  }
};

export const doApproveReturnRequest = async (storeUuid, returnalUuid) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/approve`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao aprovar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: ex.message,
    };
  }
};

export const doCancelReturnRequest = async (storeUuid, returnalUuid, cancelReason) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/cancel`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: { cancelReason },
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao aprovar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: accessDotNotation(ex, "response.data.error.message") || ex.message,
    };
  }
};

export const doUpdateReturnalRequest = async (storeUuid, returnalUuid, options = {}) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: options,
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao atualizar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: ex.message,
    };
  }
};

export const doUpdateReturnalItemRequest = async (
  storeUuid,
  returnalUuid,
  itemUuid,
  options = {}
) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/item/${itemUuid}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: options,
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao atualizar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: ex.message,
    };
  }
};

export const doRefundRequest = async (
  storeUuid,
  returnalUuid,
  totalRefundDone,
  paymentMethod,
  receitUrl
) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/refund`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        totalRefundDone,
        paymentMethod,
        receitUrl,
      },
    });

    if (status === 201) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao confirmar estorno",
    };
  } catch (ex) {
    const { error } = ex.response.data;

    return {
      success: false,
      status: ex.status || 500,
      message: error.message,
    };
  }
};

export const doExchangeRequest = async (storeUuid, returnalUuid, code, dueAt, price, freight) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/coupon`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code,
        dueAt,
        price,
        freight,
      },
    });

    if (status === 201) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao confirmar estorno",
    };
  } catch (ex) {
    const { error } = ex.response.data;

    return {
      success: false,
      status: ex.status || 500,
      message: error.message,
    };
  }
};

export const doEndRequest = async (storeUuid, returnalUuid) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/returnal/${returnalUuid}/end`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao finalizar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: ex.message,
    };
  }
};

// ==> App
export const doRemoveAppCustomRuleRequest = async (storeUuid, appSlug, ruleId) => {
  try {
    const {
      status,
      data: { data },
    } = await api(`/api/store/${storeUuid}/app/${appSlug}/rule/${ruleId}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (status === 200) {
      return {
        success: true,
        status,
        data,
      };
    }

    return {
      success: false,
      status,
      message: "Erro ao finalizar reversa",
    };
  } catch (ex) {
    return {
      success: false,
      status: ex.status || 500,
      message: ex.message,
    };
  }
};
