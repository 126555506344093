import React, { useState, useEffect } from "react";
import { FaTruckLoading, FaTruck } from "react-icons/fa";

import { getShippingStatsRequest } from "store/modules/merchants/actions";
import CardIcon from "../components/card-icon";

export default function SalesStatisticsContainer({ filter, storeUuid }) {
  const [state, setState] = useState({
    loading: true,
    error: null,
    avgTimeToDeliver: 0,
    avgTimeToPost: 0,
  });

  useEffect(() => {
    async function loadStats() {
      const { success, data, message } = await getShippingStatsRequest(storeUuid, filter);

      if (success)
        setState((oldState) => ({
          ...oldState,
          loading: false,
          avgTimeToDeliver: data.avgTimeToDeliver,
          avgTimeToPost: data.avgTimeToPost,
        }));

      setState((oldState) => ({
        ...oldState,
        loading: false,
        error: message,
      }));
    }

    loadStats();
  }, [filter]);

  return (
    <>
      <CardIcon
        number={state.avgTimeToPost}
        label="Tempo de envio"
        className="shipping-time"
        sufix=" dias"
        loading={state.loading}
        error={state.error}
        icon={<FaTruckLoading className="card-icon" fontSize={27} />}
      />

      <CardIcon
        number={state.avgTimeToDeliver}
        label="Tempo de entrega"
        className="delivery-time"
        sufix=" dias"
        loading={state.loading}
        error={state.error}
        icon={<FaTruck className="card-icon" fontSize={27} />}
      />
    </>
  );
}
