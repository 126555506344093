import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Classes
import SelectedFilter from "models/filters-manager";

// Helpers
import { cleanArray, accessDotNotation } from "helpers";

// Static methods classes
function convertFilterValue(values, filter, isRaw = false) {
  let returnValues;

  switch (filter.type) {
    case "checkbox": {
      returnValues = isRaw ? values.split(",") : values || [];
      returnValues = returnValues
        .map((k) => filter.options.find((o) => o.slug === k))
        .filter((o) => o !== undefined);
      break;
    }

    case "daterange": {
      returnValues = cleanArray(isRaw ? values.split("|").sort() : values || []);
      const [min, max] = returnValues;
      returnValues = returnValues.length > 0 ? [min, max] : [];
      break;
    }

    case "switch": {
      returnValues = values === null || values === "" ? filter.options[0] : values;
      break;
    }

    default:
      returnValues = values;
      break;
  }
  return returnValues;
}

function convertQsToObj(queryStr, availableFilters) {
  const qs = queryStr || window.location.search;
  const params = new URLSearchParams(qs);
  const selectedFilters = [];

  // Proccess filter
  [...params.entries()].forEach((tuple) => {
    const [key, rawValues] = tuple;
    const foundFilter = availableFilters.find((f) => f.param === key);
    const filterAlreadyAdded = selectedFilters.find((f) => f.key === key);

    if (filterAlreadyAdded || !foundFilter) return;

    const values = convertFilterValue(rawValues, foundFilter, true);
    const selectedFilter = new SelectedFilter({
      slug: key,
      label: foundFilter.label,
      rawValues: values,
      type: foundFilter.type,
      hidden: foundFilter.hidden,
      defaultValue: foundFilter.defaultValue,
    });

    selectedFilters.push(selectedFilter);
  });

  return selectedFilters;
}

function filtersToObj(filtersArr, exportForApi = false) {
  return filtersArr.reduce((acc, filter) => {
    if (exportForApi) {
      switch (filter.type) {
        case "daterange": {
          const [min, max] = filter.values;
          acc[`${filter.slug}_min`] = min;
          acc[`${filter.slug}_max`] = max;
          break;
        }

        case "checkbox": {
          acc[filter.slug] = filter.values.map((o) => o.slug);
          break;
        }

        default:
          acc[filter.slug] = filter.values;
          break;
      }
    } else {
      switch (filter.type) {
        case "daterange": {
          acc[filter.slug] = filter.values.join("|");
          break;
        }

        case "checkbox": {
          acc[filter.slug] = filter.values.map((o) => o.slug);
          break;
        }
        default:
          acc[filter.slug] = filter.values;
          break;
      }
    }

    return acc;
  }, {});
}

function updateUrlWithObject(urlObject) {
  // Update URL
  const queryString = new URLSearchParams(urlObject).toString();
  let url = `${window.location.origin}${window.location.pathname}`;
  url = queryString ? `${url}?${queryString}` : url;
  window.history.replaceState(null, null, url);
}

export default ({ children, resourceClass, loadAction, availableFilters, defaultFilters = {} }) => {
  // Redux
  const dispatch = useDispatch();
  const { currentMerchant } = useSelector((state) => state.merchants);

  // Catch from external
  const queryStr = window.location.search;
  const filtersFromQs = convertQsToObj(queryStr, availableFilters);
  const activeFilters = filtersToObj(filtersFromQs, false);
  const handleFilterTimer = useRef();

  // Minimal state
  const [state, setState] = useState({
    loading: true,
    allSelectorActive: false,

    list: [],
    listSelected: [],

    allItemsSelected: false,

    filters: filtersFromQs,

    metadata: {
      totalItems: 0,
      totalPages: 0,
      count: 0,
    },

    filterOpened: false,
  });

  // => Resource Actions
  async function getResourceMiddleware(currentMerchantUuid, _filters = {}) {
    return dispatch(loadAction(currentMerchantUuid, _filters));
  }

  async function loadResources(_filters = {}, firstLoading = false) {
    const Class = resourceClass;

    if (!firstLoading) {
      // Set Loader
      setState((s) => ({
        ...s,
        allSelectorActive: false,
        loading: true,
      }));
    }

    // Get orders
    const getResourcesResponse = await getResourceMiddleware(currentMerchant.uuid, {
      ...defaultFilters,
      ..._filters,
    });

    if (!getResourcesResponse.success) {
      if (getResourcesResponse.status === 301) return;

      toast.error(
        getResourcesResponse.message ||
          "Não foi possível carregar os recursos. Tente novamente mais tarde!"
      );
    }

    const list = [
      ...(getResourcesResponse.success
        ? getResourcesResponse.data.data.map((resource) => new Class(resource))
        : []),
    ];

    // Render
    setState((s) => ({
      ...s,

      // Orders
      list,

      // Update pagination settings
      metadata: {
        ...s.metadata,
        totalPages: accessDotNotation(getResourcesResponse, "data.metadata.totalPages") || 0,
        totalItems: accessDotNotation(getResourcesResponse, "data.metadata.totalItems") || 0,
        count: accessDotNotation(getResourcesResponse, "data.metadata.count") || 0,
      },

      loading: false,
    }));

    // return { data: getResourcesResponse, filters };
  }

  // => Filter actions
  async function handleFilter(slug, values) {
    const { filters } = state;
    const filtersCopy = filters.slice();
    const baseFilter = availableFilters.find((b) => b.param === slug);

    if (!baseFilter) return;

    // Find filter in array
    const fIndex = filtersCopy.findIndex((f) => f.slug === slug);

    // If found is a modify
    if (fIndex > -1) {
      const foundFilter = filtersCopy[fIndex];
      if (!values || values.length < 1) filtersCopy.splice(fIndex, 1);
      else {
        // Apply new values due rules.
        switch (foundFilter.type) {
          case "checkbox": {
            foundFilter.values = values.map((k) => baseFilter.options.find((o) => o.slug === k));
            break;
          }

          default:
            foundFilter.values = values;
            break;
        }
      }
    } else {
      values = convertFilterValue(values, baseFilter);
      const selectedFilter = new SelectedFilter({
        slug: baseFilter.param,
        label: baseFilter.label,
        rawValues: values,
        type: baseFilter.type,
        hidden: baseFilter.hidden,
        defaultValue: baseFilter.defaultValue,
      });
      filtersCopy.push(selectedFilter);
    }

    if (slug !== "page") {
      const pagination = filtersCopy.find((f) => f.slug === "page");

      if (pagination) pagination.values = 1;
      else {
        const selectedFilter = new SelectedFilter({
          slug: "page",
          label: "Paginação",
          rawValues: 1,
          type: "string",
          hidden: true,
          defaultValue: 1,
        });
        filtersCopy.push(selectedFilter);
      }
    }

    // Apply new filters to state
    setState((_state) => ({
      ..._state,
      filters: filtersCopy,
    }));

    clearInterval(handleFilterTimer.current);
    if (!["page", "page_size"].includes(slug)) {
      await new Promise((resolve) => {
        handleFilterTimer.current = setTimeout(() => {
          resolve(true);
        }, 500);
      });
    }

    // Make object to API models,
    // joining page metas & filters.
    const urlObject = {
      ...filtersToObj(filtersCopy),
    };

    updateUrlWithObject(urlObject);

    // Send request
    await loadResources({
      ...filtersToObj(filtersCopy, true),
    });
  }

  async function clearFilters() {
    // Make object to API models,
    // joining page metas & filters.
    const urlObject = {
      page: 1,
      page_size: 10,
    };

    // Apply new filters to state
    setState((s) => ({
      ...s,
      filters: [],
    }));

    updateUrlWithObject(urlObject);

    // Send request
    return loadResources(urlObject);
  }

  async function toggleFilter() {
    setState((_state) => ({
      ..._state,
      filterOpened: !_state.filterOpened,
    }));
  }

  // => Line actions
  function modifyLine(uuidArray, changeCb) {
    return setState((_state) => ({
      ..._state,
      list: _state.list.map((_resource) => {
        if (uuidArray.includes(_resource.uuid)) {
          return changeCb(_resource);
        }

        return _resource;
      }),
    }));
  }

  function getAllSelectedLines() {
    const { list } = state;

    const returnObj = {
      list: {
        objs: [],
        uuids: [],
      },

      length: 0,
    };

    // Map selected orders => packages
    returnObj.list.objs = list.filter((order) => {
      if (order.isSelected) {
        returnObj.list.uuids.push(order.uuid);

        return order;
      }
      return false;
    });

    returnObj.length = returnObj.list.objs.length;

    return returnObj;
  }

  function toggleSelectResource(resourceInput) {
    const selected = getAllSelectedLines();
    const unselectAll = selected.list.uuids.filter((r) => r !== resourceInput.uuid).length === 0;

    // console.log(
    //   `Resource ${resourceInput.uuid} ${resourceInput.isSelected ? "unselected" : "selected"}`
    // );

    if (unselectAll)
      setState((_state) => ({
        ..._state,
        allSelectorActive: false,
      }));

    return modifyLine([resourceInput.uuid], (resource) => {
      resource.isSelected = !resource.isSelected;
      return resource;
    });
  }

  function toggleSelectAllResources() {
    const { list } = state;

    const uuidsUnselected = list.filter((resource) => !resource.isSelected && resource.uuid);
    const uuidsSelected = list.filter((resource) => resource.isSelected && resource.uuid);

    const uuids = uuidsSelected > uuidsUnselected ? uuidsSelected : uuidsUnselected;

    setState((_state) => ({
      ..._state,
      allSelectorActive: !(uuidsSelected > uuidsUnselected),
    }));

    return modifyLine(
      uuids.map((_o) => _o.uuid),
      (resource) => {
        resource.isSelected = !resource.isSelected;

        return resource;
      }
    );
  }

  // Initial effect
  useEffect(() => {
    async function init() {
      const { filters, search } = state;

      // pre load orders
      await loadResources(
        {
          ...search,
          ...filtersToObj(filters, true),
        },
        true
      );
    }
    init();
  }, []);

  return (
    <div>
      {children({
        state,
        activeFilters,
        actions: {
          handleFilter,
          toggleFilter,
          modifyLine,
          clearFilters,

          // Resources
          loadResources,
          toggleSelectResource,
          toggleSelectAllResources,
        },
        helpers: {
          filtersToObj,
        },

        // Others
        currentMerchant,
        setState,
      })}
    </div>
  );
};
