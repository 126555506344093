/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaChevronRight, FaFilter, FaChevronLeft } from "react-icons/fa";
import MediaQuery from "react-responsive";
import ReactPaginate from "react-paginate";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

// Template
import PageList from "components/page-list";

// Actions
import { doGetOrders, doMassArchiveOrders, exportReport } from "store/modules/orders/actions";
import PageTitle from "components/page-title";

// Helpers
import { checkExtension, swalMiddleware } from "helpers";

// Components
import LoaderBlock from "components/loader-bg";
import DropdownButton from "components/dropdown-button/dropdown";
import Filters from "components/orders/filters";
import FiltersResume from "components/orders/filters-resume";
import FilterSearchInput from "components/orders/filters/filter-search-input";
import { screenSizes } from "helpers/constants";
import OrderSummary from "models/order-sumary";
import DesktopOrderList from "./components/desktop-list";
import MobileOrderList from "./components/mobile-list";

class Orders extends PageList {
  constructor(props) {
    super(props);

    this.resourceClass = OrderSummary;
    this.loadAction = doGetOrders;

    // this.state = {
    //   ...this.state,
    //   extensionEnabled: false,
    // };
  }

  async componentDidMount(...args) {
    super.componentDidMount.apply(this, args);

    const extensionEnabled = await checkExtension();

    this.setState((oldState) => ({
      ...oldState,
      extensionEnabled,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  get availableFilters() {
    return [
      {
        id: 1,
        label: "Data de Criação",
        param: "created_at",
        type: "daterange",
      },

      {
        id: 2,
        label: "Data de Atualização",
        param: "updated_at",
        type: "daterange",
      },

      {
        id: 3,
        label: "Status",
        param: "status",
        type: "checkbox",
        options: [
          {
            slug: "TRACKING_CODE_GENERATED",
            label: "Rastreio gerado",
          },
          {
            slug: "POSTED",
            label: "Postado",
          },

          {
            slug: "IN_TRANSIT",
            label: "Em trânsito",
          },

          {
            slug: "WAITING_WITHDRAW",
            label: "Aguardando retirada",
          },

          {
            slug: "IN_ROUTE_TO_DELIVERY",
            label: "Saiu p/ entrega",
          },

          {
            slug: "DELIVERED",
            label: "Entregue",
          },

          {
            slug: "EXCEPTION",
            label: "Atenção",
          },

          {
            slug: "RETURNED_TO_SENDER",
            label: "Devolvido",
          },

          {
            slug: "TAXED",
            label: "Taxado",
          },

          {
            slug: "PENDING",
            label: "Aguardando pagamento",
          },

          {
            slug: "PAID",
            label: "Pago",
          },

          {
            slug: "REFUNDED",
            label: "Reembolsado",
          },

          {
            slug: "CANCELLED",
            label: "Cancelado",
          },
        ],
      },

      {
        id: 4,
        label: "Pedido atrasado",
        param: "is_late",
        type: "switch",

        options: [false, true],
      },

      {
        id: 5,
        label: "Pacote parado",
        param: "is_stalled",
        type: "switch",

        options: [false, true],
      },

      {
        id: 6,
        label: "Pedido arquivado",
        param: "is_archived",
        type: "switch",

        options: [false, true],
      },

      {
        id: 7,
        label: "Pedido sem rastreio",
        param: "has_no_packages",
        type: "switch",
        options: [false, true],
      },

      {
        id: 8,
        label: "Paginação",
        param: "page",
        type: "string",
        defaultValue: 1,
        hidden: true,
      },

      {
        id: 9,
        label: "Itens por página",
        param: "page_size",
        type: "string",
        defaultValue: 10,
        hidden: true,
      },

      {
        id: 10,
        label: "Pesquisar",
        param: "search",
        type: "string",
        hidden: true,
      },
    ];
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleArchiveOrder(order, orderUuid) {
    const { currentMerchant } = this.props;
    const { filters } = this.state;

    const isSingle = typeof order === "object";
    let orderUuids = isSingle ? [orderUuid] : [];

    if (!isSingle) {
      // Map selected orders;
      const selected = this.getAllSelectedLines();

      // Check
      if (!selected.length) return toast.warning("Nenhum pedido selecionado para arquivar.");

      // Save orderUuids
      orderUuids = selected.list.uuids;
    }

    // Update Loader
    this.modifyLine(orderUuids, (o) => {
      o.isUpdating = !o.isUpdating;

      return o;
    });

    // Archive orders
    const { success } = await doMassArchiveOrders(currentMerchant.uuid, orderUuids);

    // If archive was success
    if (success) {
      toast.success(
        orderUuids.length > 1
          ? `${orderUuids.length} pedidos foram arquivados.`
          : `O pedido foi arquivo.`
      );

      // pre load orders
      return this.loadResources({
        ...Orders.filtersToObj(filters, true),
      });
    }

    toast.error("Ocorreu um erro ao arquivar os pedidos. Por favor contate o suporte.");

    // Update Loader
    this.modifyLine(orderUuids, (order) => {
      order.isUpdating = false;

      return order;
    });

    return false;
  }

  async exportReportHandler() {
    const { currentMerchant } = this.props;
    const { filters } = this.state;

    await exportReport(currentMerchant.uuid, PageList.filtersToObj(filters, true));

    swalMiddleware.fire({
      title: "Exportando relatório...",
      text: "Você receberá o relatório em seu e-mail dentro de alguns minutos.",
      icon: "iumy",
    });
  }

  /* ===============
   * Order managing options
   * =============== */
  getAllSelectedLines() {
    const { list } = this.state;

    const returnObj = {
      list: {
        objs: [],
        uuids: [],
      },

      packages: {
        obj: [],
        uuids: [],
      },

      length: 0,
    };

    // Map selected orders => packages
    returnObj.list.objs = list.filter((order) => {
      if (order.isSelected) {
        returnObj.list.uuids.push(order.uuid);

        // Map packages
        order.packages.forEach((_package) => {
          returnObj.packages.obj.push(_package);
          returnObj.packages.uuids.push(_package.uuid);
        });

        return order;
      }
      return false;
    });

    returnObj.length = returnObj.list.objs.length;

    return returnObj;
  }

  toggleSelectAllOrders() {
    const { list } = this.state;

    const uuidsUnselected = list.filter((order) => !order.isSelected && order.uuid);
    const uuidsSelected = list.filter((order) => order.isSelected && order.uuid);

    const uuids = uuidsSelected > uuidsUnselected ? uuidsSelected : uuidsUnselected;

    const allSelectorActive = !(uuidsSelected > uuidsUnselected);

    this.setState((state) => ({
      ...state,
      allSelectorActive,
    }));

    return this.modifyLine(
      uuids.map((_o) => _o.uuid),
      (order) => {
        order.isSelected = !order.isSelected;

        return order;
      }
    );
  }

  async updateShopifyAtExtension(order) {
    const { token, currentMerchant } = this.props;
    const { extensionEnabled } = this.state;

    if (!extensionEnabled) {
      window.open(process.env.REACT_APP_EXTENSION_URL, "_blank");
      return false;
    }

    const {
      list: { objs },
    } = this.getAllSelectedLines();

    if (!objs.length) return false;

    return window.chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: "BG/SYNC_SHOPIFY",
      payload: {
        token,
        orders: objs.map((o) => ({
          storeUuid: currentMerchant.uuid,
          orderUuid: o.uuid,
          referenceId: o.orderReferenceId,
          shopifyOrderUrl: o.shopifyOrderUrl,
        })),
      },
    });
  }

  render() {
    const { loading, metadata, filters, filterOpened, list, allSelectorActive, extensionEnabled } =
      this.state;
    const { currentMerchant, flags } = this.props;

    const activeFilters = PageList.filtersToObj(filters, false);

    return (
      <div className="page orders-list container list-with-filter">
        <PageTitle pageTitle="Pedidos" />

        <header className="page-header">
          <div className="row">
            <div className="col head-left flex-d-column">
              <h1 className="title">Pedidos</h1>
              <span className="ml25 gray">{metadata.totalItems} pedidos</span>
            </div>
            <div className="col flex-right flex-d-column">
              {/* <button className="btn default size--medium">
          <FaSyncAlt /> Importar Woocommerce
        </button> */}

              <button
                type="button"
                className="btn default size--medium d-block d-sm-none"
                onClick={() => this.toggleFilter()}
              >
                <FaFilter /> Filtrar
              </button>
            </div>
          </div>
        </header>

        <main className="container-page">
          <div className="filters">
            <button
              type="button"
              className="btn default size--medium d-none d-md-inline-block"
              onClick={() => this.toggleFilter()}
            >
              <FaFilter /> Filtrar
            </button>

            <FilterSearchInput
              searchValue={activeFilters.search}
              handleSearch={(searchValue) => this.handleFilter("search", searchValue)}
            />

            <DropdownButton
              label="Ações"
              className="d-none d-md-inline-block"
              classes="btn default size--medium"
              options={[
                ...(flags.showExportCsvButton
                  ? [
                      {
                        value: "export",
                        label: "Exportar CSV",
                        callback: () => this.exportReportHandler(),
                      },
                    ]
                  : []),

                {
                  value: "archive",
                  label: "Arquivar pedidos",
                  callback: () => this.handleArchiveOrder(),
                  disabled: activeFilters.isArchived || !list.filter((o) => o.isSelected).length,
                },
                ...(extensionEnabled
                  ? [
                      {
                        value: "sync-shopify",
                        label: "Sincronizar dados com a Shopify",
                        callback: () => this.updateShopifyAtExtension(),
                        className: "up-divider",
                        disabled: !list.filter((o) => o.isSelected).length,
                      },
                    ]
                  : []),
              ]}
            />
          </div>

          <FiltersResume
            activeFilters={filters.filter((f) => !f.hidden)}
            handleFilter={this.handleFilter.bind(this)}
            clearFilters={this.clearFilters.bind(this)}
          />

          {loading ? (
            <LoaderBlock blocks={8} width={5} />
          ) : (
            <>
              <MediaQuery maxWidth={screenSizes.TABLET_MAX}>
                <MobileOrderList orders={list} merchantId={currentMerchant.uuid} />
              </MediaQuery>
              <MediaQuery minWidth={screenSizes.DESKTOP_MIN}>
                <DesktopOrderList
                  allSelectorActive={allSelectorActive}
                  orders={list}
                  modifyLine={this.modifyLine.bind(this)}
                  archiveOrder={this.handleArchiveOrder.bind(this)}
                  selectOrder={this.toggleSelectResource.bind(this)}
                  selectAllOrders={this.toggleSelectAllOrders.bind(this)}
                  merchantId={currentMerchant.uuid}
                  activeFilters={activeFilters}
                />
              </MediaQuery>

              {!list.length && (
                <span className="nothing-here-message">
                  Ops, parece que ainda não tem nenhum pedido por aqui. :( Se você acabou de
                  realizar a integração <strong>iremos lhe avisar por e-mail.</strong>
                </span>
              )}
            </>
          )}
        </main>

        <footer className="footer-actions">
          <div className="row">
            <div className="col">
              <span className="items-per-page">
                <DropdownButton
                  label={activeFilters.page_size ? activeFilters.page_size : 10}
                  classes="btn default size--medium"
                  callback={(option) => {
                    this.handleFilter("page_size", option);
                  }}
                  options={[
                    {
                      value: 10,
                      label: 10,
                    },
                    {
                      value: 20,
                      label: 20,
                    },
                    {
                      value: 30,
                      label: 30,
                    },
                  ]}
                />
                <span>itens por página</span>
              </span>
            </div>

            <div className="col flex-right">
              {!loading && (
                <ReactPaginate
                  containerClassName="pagination"
                  previousLabel={<FaChevronLeft />}
                  nextLabel={<FaChevronRight />}
                  disableInitialCallback
                  marginPagesDisplayed={1}
                  breakLabel="..."
                  activeClassName="active"
                  initialPage={activeFilters.page ? activeFilters.page - 1 : 0}
                  pageCount={metadata.totalPages}
                  onPageChange={(e) => this.handleFilter("page", e.selected + 1)}
                />
              )}
            </div>
          </div>
        </footer>

        <Filters
          availableFilters={this.availableFilters}
          filterStatus={filterOpened}
          activeFilters={filters}
          toggleFilter={this.toggleFilter.bind(this)}
          handleFilter={this.handleFilter.bind(this)}
          clearFilters={this.clearFilters.bind(this)}
          handle={this.handleFilter.bind(this)}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Orders));
