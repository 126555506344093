import React, { useState } from "react";
import { toast } from "react-toastify";

// Sub components
import ModalComp from "components/modal";

export default function CheckItemsModal({ item, toggle, updateItemAction }) {
  const [state, setState] = useState({
    conferenceQuantity: item?.conferenceQuantity,
    conferenceComment: item?.conferenceComment,
    submiting: false,
  });

  // Main action
  const submitHandler = async () => {
    const { conferenceComment, conferenceQuantity } = state;
    setState((s) => ({ ...s, submiting: true }));

    const { success, message } = await updateItemAction(item.uuid, {
      conferenceComment,
      conferenceQuantity: parseInt(conferenceQuantity, 10),
    });

    if (!success) toast.error(message);

    return toggle();
  };

  return (
    <ModalComp
      showModal
      className="returnal-check-items"
      title="Editar conferência"
      size="sm"
      toggleModal={toggle}
      customActions={
        (!item.conferenceQuantity && state.conferenceQuantity !== item.quantity) ||
        (item.conferenceQuantity && state.conferenceQuantity !== item.conferenceQuantity)
          ? () => (
              <div className="actions">
                <button
                  type="button"
                  className="btn green size--medium mr10"
                  onClick={submitHandler}
                  disabled={state.submiting}
                >
                  {state.submiting ? "Aguarde..." : "Salvar"}
                </button>

                <button
                  type="button"
                  className="btn default size--medium"
                  onClick={toggle}
                  disabled={state.submiting}
                >
                  Cancelar
                </button>
              </div>
            )
          : null
      }
    >
      <div className="product-resume-card">
        {/* Product Thumb + Qty */}
        <div className="rpl--item-product-thumb-qty-wrap">
          <div className="rpl--item-product-thumb-wrap">
            <img src={item.imageSrc} alt={item.name} className="rpl--item-product-thumb" />
          </div>

          <span alt="Quantidade" className="rpl--item-product-qty">
            {item.quantity}
          </span>
        </div>

        {/* Product Name, Price, Reason... */}
        <div className="rpl--item-product-infos-wrap">
          <span className="rpl--item-product-title block">
            <strong>{item.title}</strong>
            <br />
            {item.variantTitle}
          </span>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="conferenceQuantity">
          Quantidade recebida
        </label>
        <select
          className="form-select"
          name="conferenceQuantity"
          id="conferenceQuantity"
          onChange={(e) => {
            const { name, value } = e.target;

            setState((s) => ({ ...s, conferenceQuantity: value }));
          }}
          value={state.conferenceQuantity}
        >
          <option value="0">Nenhuma</option>

          {Array.from(Array(item.quantity), (e, i) => (
            <option value={i + 1} key={i}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>

      {state.conferenceQuantity < item.quantity && (
        <div className="form-group">
          <label htmlFor="conferenceComment">Nota sobre conferência (opcional)</label>
          <textarea
            className="form-control"
            name="conferenceComment"
            id="conferenceComment"
            rows="3"
            placeholder="Ex: Um dos produtos não estava em condições"
            value={state.conferenceComment}
            onChange={(e) => {
              const { value } = e.target;
              setState((s) => ({ ...s, conferenceComment: value }));
            }}
          />
        </div>
      )}
    </ModalComp>
  );
}
