import { immerable } from "immer";

// Sub Models
import Package from "../tracking/package";
import Customer from "../customer";
import Price from "../price";
import Address from "../address";
import StatusReturnal from "./status-retunal";
import ReturnalItem from "./returnal-item";

export const ReturnStatusTypes = {
  PENDING_QUOTE: "PENDING_QUOTE", // AGUARDANDO QUOTAÇÃO
  PENDING: "PENDING_APPROVAL", // PENDENTE APROVAÇÃO

  APPROVAL_IN_PROGRESS: "APPROVAL_IN_PROGRESS",
  APPROVED: "APPROVED", // APROVADO
  TRANSIT: "IN_TRANSIT", // EM TRANSITO
  DELIVERED: "DELIVERED", // RECEBIDO
  CONFERRED: "CONFERRED", // CONFERIDO
  ENDED: "ENDED", // FINALIZADO
  CANCELLED: "CANCELLED", // FINALIZADO

  RETURN: "RETURN",
  EXCHANGE: "EXCHANGE",
};

export default class Returnal {
  constructor(options) {
    this[immerable] = true;

    const hasPackages = options?.packages?.length > 0;
    const selectedFreight = hasPackages
      ? options.packages[0]
      : options?.freights?.find((f) => f?.isSelected);

    // Basic infos
    this.name = options.name;
    this.uuid = options.uuid;
    this.totalPrice = new Price(options.totalPrice);
    this.returnalType = options.returnalType;
    this.selectedFreight = selectedFreight
      ? {
          ...selectedFreight,
          finalPrice: new Price(selectedFreight.totalPriceWithDiscount),
        }
      : null;

    this.cancelReason = options.cancelReason;

    // Order reference
    this.orderName = options.orderName;
    this.orderUuid = options.orderUuid;

    // Refund and Coupons
    this.transactions = options.transactions;
    this.coupons = options.coupons;

    // Status
    this.status = new StatusReturnal(options.status);
    this.computedStatus = new StatusReturnal(options.computedStatus);

    // Dates
    this.createdAt = options.createdAt;

    // Returnal items
    this.items = options.items.map((item) => new ReturnalItem(item));

    // Activity logs
    this.logs = options.logs
      ?.map((log) => ({
        description: log.description,
        doneBy: log.doneBy,
        createdAt: log.createdAt,
      }))
      .reverse();

    // Customer
    this.customer = new Customer(options.customer);

    // Returnal address
    this.address = new Address(options.address);

    // Packages
    this.packages = [...options?.packages?.map((p) => new Package(p))];
  }
}
