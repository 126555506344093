import { immerable } from "immer";
import { accessDotNotation } from "helpers";
import Price from "./price";

export default class OrderItem {
  constructor(options, order) {
    this[immerable] = true;

    const image = options.images.find((i) => i.isFeatured) || options.images[0];

    this.id = options.id;
    this.uuid = options.uuid;
    this.orderId = options.orderId;
    this.itemReferenceId = options.itemReferenceId;
    this.productReferenceId = options.productReferenceId;
    this.productReferenceVariantId = options.productReferenceVariantId;
    this.name = options.name;
    this.title = options.title;
    this.imageSrc = accessDotNotation(image, "url");
    this.variantTitle = options.variantTitle;
    this.quantity = options.quantity;
    this.sku = options.sku;
    this.fulfillmentStatus = options.fulfillmentStatus;
    this.fulfillmentService = options.fulfillmentService;
    this.unitPrice = new Price(options.price, order.currency);
    this.totalPrice = new Price(options.price * options.quantity, order.currency);
  }
}
