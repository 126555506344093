import { immerable } from "immer";

import MessageDto from "./message-template";
import OrderNote from "./order-note";
import Status from "./tracking/status";
import Price from "./price";
import Address from "./address";
import Customer from "./customer";
import OrderItem from "./order-item";
import Package from "./tracking/package";

export default class Order {
  constructor(options) {
    this[immerable] = true;

    this.uuid = options.uuid;
    this.storeId = options.storeId;
    this.appType = options.appType;
    this.shopifyOrderUrl = options.shopifyOrderUrl;
    this.orderReferenceId = options.orderReferenceId;
    this.name = options.name;
    this.orderNumber = options.orderNumber;
    this.gateway = options.gateway;
    this.createdAt = options.createdAt;
    this.updatedAt = options.updatedAt;
    this.paidAt = options.paidAt;
    this.cancelledAt = options.cancelledAt;
    this.cancelReason = options.cancelReason;
    this.lastIumyTrackerSync = options.lastIumyTrackerSync;

    this.notes = options.notes.map((raw) => new OrderNote(raw));

    // Statuses
    this.isHidden = options.isHidden;
    this.isArchived = options.isArchived;

    this.freightPrice = new Price(options.freightPrice, options.currency);
    this.totalPrice = new Price(options.totalPrice, options.currency);
    this.totalDiscount = new Price(options.totalDiscount, options.currency);
    this.totalPriceWithDiscount = new Price(options.totalPriceWithDiscount, options.currency);

    this.notifications = options.notifications.map((n) => new MessageDto(n));

    this.currency = options.currency;
    this.financialStatus = new Status(options.financialStatus);
    this.items = options.items.map((item) => new OrderItem(item, options));
    this.customer = options.customer ? new Customer(options.customer) : null;
    this.packages = options.packages.length
      ? options.packages.map((packageItem) => new Package(packageItem))
      : [];

    this.shippingAddress =
      options.shippingAddress !== null ? new Address(options.shippingAddress) : null;
    this.billingAddress =
      options.billingAddress !== null ? new Address(options.billingAddress) : null;
  }
}
