import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { sanitizeObject, swalMiddleware as Swal } from "helpers";
import isValidDomain from "is-valid-domain";
import classnames from "classnames";
import _ from "lodash";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Actions
import {
  Creators as MerchantActions,
  doCreateMerchantRequest,
  doSelectMerchant,
  doUpdateMerchantRequest,
} from "store/modules/merchants/actions";

import { doLoadUserRequest } from "store/modules/auth/thunks";

// Components
import { DTLayer } from "components/data-layer";
import LoaderBG from "components/loader-bg";
import PageTitle from "components/page-title";
import Merchant from "models/merchant";
import ImageUploader from "components/image-uploader";
import { FaChevronLeft } from "react-icons/fa6";

class CreateMerchant extends React.Component {
  constructor(props) {
    super(props);

    let state = {
      title: "Criar nova loja",
      description: "Você está prestes a decolar, aperte os cintos Iumer! 🚀",
      loading: false,
      uploadedLogo: null,
      disableCancel: true,
    };

    // Editing
    if (props.match.params.merchantId) {
      state.title = "Editar configurações";
      state.description = "Configure os detalhes da loja.";
      state.loading = true;
      state.editing = true;
    }

    // Creating
    else {
      state = {
        ...state,
        formikInitial: new Merchant({ contact: { entityType: "PF" } }),
      };
    }

    this.state = state;
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    if (this.state.editing) {
      const merchantObj = new Merchant(this.props.currentMerchant);
      const formikInitial = merchantObj.enableMasks();

      this.setState({
        ...this.state,
        formikInitial,
        loading: false,
      });
    }
  }

  async handleSubmit(values) {
    const { editing } = this.state;
    const { dispatch, history } = this.props;

    this.setState((state) => ({
      ...state,
      submiting: true,
    }));

    const sanitized = sanitizeObject(values, [
      "email",
      "description",
      "senderEmail",
      "icon",
      "logo",
      "domain",
    ]);

    if (!editing) {
      const createStoreResponse = await dispatch(doCreateMerchantRequest(sanitized));

      if (createStoreResponse.success) {
        await dispatch(doSelectMerchant(createStoreResponse.data.uuid));

        this.setState((state) => ({
          ...state,
          submiting: false,
        }));

        // TRIAL START
        if (createStoreResponse?.data?.hasCreatedTrialSubscription) {
          // Load user details sync
          await dispatch(doLoadUserRequest());

          // Data layer
          DTLayer.dispatch("trialStarted");

          return Swal.fire({
            title: "Seu trial começou!",
            text: "Que maravilha! Seu trial acaba de começar, o próximo passo é realizar a integração de sua loja para começar a visualizar os pedidos.",
            icon: "success",
          }).then(() => history.push(`/`));
        }

        return history.push(`/${createStoreResponse.data.uuid}/orders`);
        // todo
      }

      toast.error(createStoreResponse.message || "Ocorreu um erro ao tentar criar a loja.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      this.setState((oldState) => ({
        ...oldState,
        submiting: false,
        disableCancel: true,
        formikInitial: values,
      }));

      return false;
    }

    // Editing
    const result = await dispatch(doUpdateMerchantRequest(values.uuid, sanitized));

    if (result.success) {
      toast.success("Loja atualizada com sucesso.");
    } else {
      toast.error("Falha ao atualizar loja.");
    }

    this.setState((state) => ({
      ...state,
      submiting: false,
      disableCancel: true,
      formikInitial: values,
    }));
  }

  handleCancel() {
    this.formRef.current.resetForm();
    this.setState((state) => ({
      ...state,
      disableCancel: true,
    }));
  }

  render() {
    const { currentMerchant } = this.props;
    const { formikInitial, disableCancel, title, description } = this.state;
    return (
      <div className="page merchant-detail container">
        <PageTitle pageTitle={title} />

        <header className="page-header">
          <Link to={`/${currentMerchant?.uuid}/config`} className="f13 black-70 backbtn">
            <FaChevronLeft /> voltar
          </Link>
          <h1 className="title">{title}</h1>
          <span role="img" aria-label="Foguete">
            {description}
          </span>
        </header>
        <hr />

        {this.state.loading ? (
          <LoaderBG />
        ) : (
          <Formik
            innerRef={this.formRef}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(100, "O tamanho máximo do nome é de 100 caracteres")
                .required("Preencha o nome loja"),
              domain: Yup.string()
                .max(100, "O tamanho máximo do dominio é de 100 caracteres")
                .required("Preencha o domínio da loja")
                .test("test-name", "Utilize um domínio válido (sem www. ou http://).", (value) =>
                  isValidDomain(value, { subdomain: true })
                ),
              senderEmail: Yup.string()
                .email("E-mail inválido")
                .required("Preencha o e-mail de envio."),
              // contact: Yup.object({
              //   name: Yup.string()
              //     .max(100, "O tamanho máximo do nome é de 100 caracteres")
              //     .required("Preencha o nome do contato da loja"),
              //   document: Yup.string()
              //     .matches(
              //       /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
              //       "Formato de documento inválido."
              //     )
              //     .required("Número de documento obrigatório."),
              //   email: Yup.string().email("E-mail inválido").required("Preencha o e-mail"),
              //   phone: Yup.string()
              //     .matches(/(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/, "Digite um telefone completo.")
              //     .required("Número de telefone obrigatório."),
              //   whatsapp: Yup.string().matches(/(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/, {
              //     message: "Digite um WhatsApp válido.",
              //     excludeEmptyString: true,
              //   }),
              //   address: Yup.object({
              //     zipcode: Yup.string()
              //       .required("Preencha o cep.")
              //       .matches(/^[0-9]{5}-[\d]{3}$/, "Formato inválido"),
              //     neighborhood: Yup.string().required("Preencha o bairro."),
              //     address: Yup.string().required("Preencha o endereço."),
              //     number: Yup.string().required("Preencha o número."),
              //     city: Yup.string().required("Preencha a cidade."),
              //     state: Yup.string().required("Preencha o Estado."),
              //   }),
              // }),
            })}
            validate={(a1) => {
              if (!_.isEqual(a1, formikInitial))
                this.setState((state) => ({
                  ...state,
                  disableCancel: false,
                }));
              else {
                this.setState((state) => ({
                  ...state,
                  disableCancel: true,
                }));
              }
            }}
            initialValues={formikInitial}
            onSubmit={(values) => this.handleSubmit(values)}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <Form>
                {/* Configurações da Loja */}
                <div className="row mb35 mt35">
                  <div className="col-md-4 col-12">
                    <h5>Configurações da loja</h5>
                    <p>E-mail para recebimento dos pedidos</p>
                  </div>

                  <div className="col">
                    <div className="box-widget">
                      <div className="inner">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <span className="field-label" required>
                                Domínio da loja
                              </span>
                              <Field
                                name="domain"
                                className="form-control"
                                placeholder="minhaloja.com.br"
                              />
                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name="domain"
                              />
                              <small className="extra-small">URL da sua loja</small>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <span className="field-label" required>
                                E-mail remetente da loja.
                              </span>

                              <Field
                                name="senderEmail"
                                className="form-control"
                                placeholder="nao-responda@minhaloja.com"
                              />

                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name="senderEmail"
                              />

                              <small className="extra-small">
                                Os clientes verão isso caso você envie e-mail para eles
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                {/* Descrição da Loja */}
                <div className="row mb35 mt35">
                  <div className="col-md-4 col-12">
                    <h5>Identidade</h5>
                    <p>Informações utilizadas nos e-mails que seu cliente receberá.</p>
                  </div>

                  <div className="col">
                    <div className="box-widget mb15">
                      <div className="inner">
                        <h6>Icone</h6>

                        <div className="row">
                          <div className="col-12 col-md-3 mb15">
                            <strong className="mb5 block">Ícone da Loja</strong>

                            <Field name="icon">
                              {({ form }) => (
                                <ImageUploader
                                  containerClass="el-dropzone f14"
                                  preload={[values.icon]}
                                  onUpload={(result) => {
                                    if (result.success) {
                                      form.setFieldValue("icon", result.data.file);
                                    }
                                  }}
                                  onRemove={() => {
                                    form.setFieldValue("icon", "");
                                  }}
                                />
                              )}
                            </Field>

                            <small className="block text-center">
                              Imagem em png com 512 px x 512 px
                            </small>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <span className="field-label" required>
                                Nome da loja
                              </span>
                              <Field
                                name="name"
                                className="form-control"
                                placeholder="Minha Loja Online"
                              />
                              <ErrorMessage component="div" className="error-message" name="name" />
                            </div>

                            <div className="form-group">
                              <span className="field-label">Logotipo</span>

                              <Field name="logo">
                                {({ form }) => (
                                  <ImageUploader
                                    containerClass="el-dropzone"
                                    preload={[values.logo]}
                                    onUpload={(result) => {
                                      if (result.success) {
                                        form.setFieldValue("logo", result.data.file);
                                      }
                                    }}
                                    onRemove={() => {
                                      form.setFieldValue("logo", "");
                                    }}
                                  />
                                )}
                              </Field>
                              <small className="extra-small">
                                Imagem que será enviada nos e-mails da sua loja.
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <hr />
        <footer className="footer-actions">
          <div className="flex flex-right">
            <button
              className="btn default size--medium-2 mr10"
              onClick={() => this.handleCancel()}
              type="button"
              disabled={this.state.loading || disableCancel}
            >
              Cancelar
            </button>
            <button
              className={classnames("btn green size--medium-2 withLoader", {
                loading: this.state.submiting,
              })}
              disabled={this.state.loading || disableCancel}
              type="submit"
              onClick={() => {
                if (this.formRef.current) {
                  this.formRef.current.handleSubmit();
                }
              }}
            >
              <span className="text">Salvar</span>
              <div className="dots">
                <div className="dot dot-1" />
                <div className="dot dot-2" />
                <div className="dot dot-3" />
              </div>
            </button>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  currentMerchant: state.merchants.currentMerchant,
});

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({ ...MerchantActions }, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMerchant);
