import { createActions } from "reduxsauce";
import { apiInstance } from "services/api";

// Import helpers
import { accessDotNotation } from "helpers";

// Services
// Create actions and Types
export const { Types, Creators } = createActions(
  {
    doLoadNotificationsSuccess: ["payload"],
    doLoadNotificationsFailure: ["error"],

    doMarkNotificationReadSuccess: ["payload"],
    doMarkNotificationReadFailure: ["error"],

    doMarkAllNotificationsReadSuccess: ["payload"],
    doMarkAllNotificationsReadFailure: ["error"],
  },
  {
    prefix: "@notifications/",
  }
);

// Init methods
export function doLoadNotificationsRequest(options, reset = false) {
  return async (dispatch) => {
    try {
      const { data, success, message } = await getNotificationsRequest(options);

      if (success) {
        dispatch(Creators.doLoadNotificationsSuccess({ data, reset }));
        return { success: true, data };
      }

      dispatch(Creators.doLoadNotificationsFailure());
      return { success: false, message };
    } catch (ex) {
      dispatch(Creators.doLoadNotificationsFailure());
      return {
        success: false,
        message:
          accessDotNotation(ex, "response.data.error.message") ||
          "Erro ao tentar carregar as notificações.",
      };
    }
  };
}

export function doMarkNotificationReadRequest(notificationUuid) {
  return async (dispatch) => {
    try {
      const { success, data } = await updateNotificationRequest(notificationUuid, {
        isRead: true,
      });

      if (success) {
        dispatch(
          Creators.doMarkNotificationReadSuccess({
            notificationUuid,
          })
        );
        return { success: true, data };
      }
      dispatch(Creators.doMarkNotificationReadFailure(data.error.message));
      return { success: false, message: data.error.message };
    } catch (ex) {
      const errMsg =
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao marcar uma notificação como lida.";
      dispatch(Creators.doMarkNotificationReadFailure(errMsg));
      return {
        success: false,
        message: errMsg,
      };
    }
  };
}

export function doMarkAllNotificationsRead() {
  return async (dispatch) => {
    const { success, data, message } = await updateNotificationsBatchRequest(
      {
        target: "STORE_OWNER",
        channel: "IN_APP",
        isRead: false,
      },
      {
        isRead: true,
      }
    );

    if (success) {
      dispatch(Creators.doMarkAllNotificationsReadSuccess());
      return { success: true, data };
    }

    dispatch(Creators.doMarkAllNotificationsReadFailure(message));
    return { success: false, message };
  };
}

// Private Ajax
export async function getNotificationsRequest(options) {
  try {
    const { status, data: response } = await apiInstance("/api/notification", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params: options,
    });

    if (status === 200) {
      return { success: true, data: response };
    }
    return { success: false, message: response.error.message };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao tentar carregar as notificações.",
    };
  }
}

export async function updateNotificationRequest(notificationUuid, options) {
  try {
    const { status, data: response } = await apiInstance(`/api/notification/${notificationUuid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...options,
      },
    });

    if (status === 200) {
      return { success: true, data: response };
    }
    return { success: false, message: response.error.message };
  } catch (ex) {
    const errMsg =
      accessDotNotation(ex, "response.data.error.message") ||
      "Erro ao marcar uma notificação como lida.";
    return {
      success: false,
      message: errMsg,
    };
  }
}

export async function updateNotificationsRequest(notificationsArr) {
  try {
    const { status, data: response } = await apiInstance(`/api/notification`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(notificationsArr),
    });

    if (status === 200) {
      return { success: true, data: response };
    }
    return { success: false, message: response.error.message };
  } catch (ex) {
    const errMsg =
      accessDotNotation(ex, "response.data.error.message") || "Erro ao atualizar as notificações.";
    return {
      success: false,
      message: errMsg,
    };
  }
}

export async function updateNotificationsBatchRequest(params = {}, options = {}) {
  try {
    const { status, data: response } = await apiInstance(`/api/notification/batch`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params,
      data: JSON.stringify(options),
    });

    if (status === 204) {
      return { success: true, data: response };
    }

    return { success: false, message: response.message };
  } catch (ex) {
    const errMsg =
      accessDotNotation(ex, "response.data.error.message") || "Erro ao atualizar as notificações.";
    return {
      success: false,
      message: errMsg,
    };
  }
}

export async function getNotificationsTemplate(storeUuid, options) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/notification-template`, {
      method: "get",
      params: options,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") ||
        "Erro ao carregar templates da loja.",
    };
  }
}

export async function updateNotificationTemplate(storeUuid, notificationTemplateUuid, options) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/notification-template/${notificationTemplateUuid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(options),
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao atualizar o template.",
    };
  }
}

export async function updateNotificationCategory(storeUuid, options) {
  try {
    const response = await apiInstance(`/api/store/${storeUuid}/notification-template`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(options),
    });

    if (response.status === 201) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message:
        accessDotNotation(ex, "response.data.error.message") || "Erro ao atualizar o template.",
    };
  }
}

export async function getNotificationsTemplateById(storeUuid, notificationTemplateUuid) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/notification-template/${notificationTemplateUuid}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message: accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar template.",
    };
  }
}

export async function previewNotificationTemplate(storeUuid, notificationTemplateUuid, data) {
  try {
    const response = await apiInstance(
      `/api/store/${storeUuid}/notification-template/${notificationTemplateUuid}?preview=true`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
    return {
      success: false,
      message: response.error.message,
    };
  } catch (ex) {
    return {
      success: false,
      message: accessDotNotation(ex, "response.data.error.message") || "Erro ao carregar template.",
    };
  }
}
