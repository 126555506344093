/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { FaChevronLeft, FaExternalLinkAlt, FaWhatsapp } from "react-icons/fa";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MediaQuery from "react-responsive";
import moment from "moment";
import VMasker from "vanilla-masker";
import _ from "lodash";
import { format } from "@flasd/whatsapp-formatting";

// Components
import ProductThumb from "images/vectors/nophoto.svg";
import NotFound from "pages/main/not-found";
import Tabs, { Tab } from "components/tabs";
import PageTitle from "components/page-title";
import LoaderBlock from "components/loader-bg";
import Modal from "components/modal";

import Tooltip from "components/tooltip";

// Assets
import { ReactComponent as Shopifyicon } from "images/app-icons/without-bg/shopify.svg";

// Actions
import {
  getOrderDetailRequest,
  doMassUpdateOrders,
  doHidePackage,
  syncOrder,
  updatePackage,
} from "store/modules/orders/actions";

// Helpers & Classes
import { renderRoute } from "pages/main/routes-list";

import { screenSizes } from "helpers/constants";
import { checkExtension } from "helpers";
import Order from "models/order";
import Price from "models/price";
import DropdownButton from "components/dropdown-button/dropdown";
import DesktopTrackings from "./components/desktop-trackings";
import MobileTrackings from "./components/mobile-trackings";
import DesktopMessages from "./components/desktop-messages";
import MobileMessages from "./components/mobile-messages";
import OrderNotes from "./components/order-notes";

// Models
class OrderDetail extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      extensionEnabled: false,
      trackingLineOpened: null,
      order: null,
      loading: true,
      syncingOrder: false,
      historyNotifications: {
        previewOpened: false,
        previewContent: null,
      },
    };
  }

  async componentDidMount() {
    const extensionEnabled = await checkExtension();

    this.setState((oldState) => ({
      ...oldState,
      extensionEnabled,
    }));

    const { currentMerchant } = this.props;
    this._isMounted = true;

    const {
      match: { params },
    } = this.props;

    await this.loadOrder(currentMerchant.uuid, params.orderId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;

    const prevParams = prevProps.match.params;

    // Is dif?
    if (params.orderId !== prevParams.orderId || params.merchantId !== prevParams.merchantId) {
      this.loadOrder(params.merchantId, params.orderId);
    }
  }

  async loadOrder(storeUuid, orderUuid) {
    // const { dispatch } = this.props;

    // Set loading status
    this.setState((state) => ({
      ...state,
      loading: true,
    }));

    const response = await getOrderDetailRequest(storeUuid, orderUuid);

    let stateEvent;

    if (response.success) {
      stateEvent = {
        order: new Order(response.data),
      };
    } else {
      toast.error(response.message);
    }

    stateEvent = {
      ...stateEvent,
      loading: false,
    };

    this._isMounted &&
      this.setState((state) => ({
        ...state,
        ...stateEvent,
      }));

    return true;
  }

  togglePreview(message) {
    let previewMessage = "";
    if (message) {
      const content =
        message.channel === "WHATSAPP" ? (
          <span className="baloon" dangerouslySetInnerHTML={{ __html: format(message.content) }} />
        ) : (
          <iframe src={message.file} title="Pré visualização" height="500" />
        );
      previewMessage = (
        <div className={message.channel === "WHATSAPP" ? "whatsapp-preview" : "email-preview"}>
          {content}
        </div>
      );
    }

    this.setState((state) => ({
      ...state,
      historyNotifications: {
        ...state.historyNotifications,
        previewContent: previewMessage,
        previewOpened: !state.historyNotifications.previewOpened,
      },
    }));
  }

  // Priv func?
  openTrackingLine(trackActive) {
    this.setState((state) => ({
      ...state,
      trackingLineOpened: trackActive,
    }));
  }

  async toggleHidePackage(order, pkg, hide) {
    const { currentMerchant } = this.props;
    const isHidden = hide || !pkg.isHidden;

    const { success } = await doHidePackage(currentMerchant.uuid, order.uuid, pkg.uuid, {
      isHidden,
    });

    // If archive was success
    if (success) {
      const newOrder = order;

      // Map & Modify
      newOrder.packages.map((p) => {
        if (p.uuid === pkg.uuid) p.isHidden = isHidden;

        return p;
      });

      this.setState((state) => ({
        ...state,
        order: newOrder,
      }));

      return toast.success(`Visibilidade do pacote alterada.`);
    }

    toast.error(
      "Ocorreu um erro ao alterar a visibilidade do pacote. Por favor contate o suporte ou tente novamente."
    );

    return false;
  }

  async toggleTaxPaid(order, packageItem) {
    const { currentMerchant } = this.props;

    const newOrder = order;

    const { success } = await updatePackage(currentMerchant.uuid, order.uuid, packageItem.uuid, {
      isTaxPaid: !packageItem.isTaxPaid,
    });

    // Map & Modify
    if (success) {
      newOrder.packages.map((p) => {
        if (p.uuid === packageItem.uuid) p.isTaxPaid = !packageItem.isTaxPaid;

        return p;
      });

      this.setState((state) => ({
        ...state,
        order: newOrder,
      }));
    }
  }

  async toggleHideOrder(order, hide) {
    const { currentMerchant } = this.props;
    const orderUuids = [order.uuid];
    const isHidden = hide || !order.isHidden;

    // Archive orders
    const { success } = await doMassUpdateOrders(currentMerchant.uuid, orderUuids, {
      isHidden,
    });

    // If archive was success
    if (success) {
      const newOrder = order;
      newOrder.isHidden = isHidden;

      this.setState((state) => ({
        ...state,
        order: newOrder,
      }));

      return toast.success(`Visibilidade do pedido alterada.`);
    }

    toast.error(
      "Ocorreu um erro ao alterar a visibilidade do pedido. Por favor contate o suporte."
    );

    return false;
  }

  async toggleArchive(order, archive) {
    const { currentMerchant } = this.props;
    const orderUuids = [order.uuid];
    const isArchived = archive || !order.isArchived;

    // Archive orders
    const { success } = await doMassUpdateOrders(currentMerchant.uuid, orderUuids, {
      isArchived,
    });

    // If archive was success
    if (success) {
      const newOrder = order;
      newOrder.isArchived = isArchived;

      this.setState((state) => ({
        ...state,
        order: newOrder,
      }));

      return toast.success(`Status do pedido alterada.`);
    }

    toast.error("Ocorreu um erro ao alterar o status do pedido. Por favor contate o suporte.");

    return false;
  }

  async syncOrderAct() {
    const { currentMerchant } = this.props;
    const { order } = this.state;

    this.setState({ syncingOrder: true });

    const { success, message } = await syncOrder(currentMerchant.uuid, order.uuid);

    if (!success) {
      toast.error(message);
      return this.setState({ syncingOrder: false });
    }

    toast.success("Sincronização iniciada, aguarde.");

    return setTimeout(() => {
      this.setState({ syncingOrder: false });
    }, 10000);
  }

  async modifyOrder(cb) {
    const { order } = this.state;
    const newOrder = cb(order);
    return this.setState({ order: newOrder });
  }

  async updateShopifyAtExtension(order) {
    const { token, currentMerchant } = this.props;
    const { extensionEnabled } = this.state;

    if (!extensionEnabled) {
      window.open(process.env.REACT_APP_EXTENSION_URL, "_blank");
      return false;
    }
    return window.chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: "BG/SYNC_SHOPIFY",
      payload: {
        token,
        orders: [
          {
            storeUuid: currentMerchant.uuid,
            orderUuid: order.uuid,
            referenceId: order.orderReferenceId,
            shopifyOrderUrl: order.shopifyOrderUrl,
          },
        ],
      },
    });
  }

  render() {
    const { order, loading, syncingOrder, historyNotifications, extensionEnabled } = this.state;
    const { currentMerchant, streamerMode } = this.props;

    let render = null;

    if (!loading && !order) render = <NotFound />;
    else if (loading)
      render = (
        <div className="container">
          <LoaderBlock blocks={8} width={5} />
        </div>
      );
    else {
      const taxSum = _.chain(order.packages)
        .uniqBy((p) => p.trackingNumber)
        .sumBy((p) => p.taxValue.raw)
        .value();

      const messagesHistory = _.chain(order.notifications)
        .filter((m) => m.sentAt)
        .orderBy("sentAt", ["desc"])
        .value();

      render = (
        <div className="page order-detail container">
          <PageTitle pageTitle={`Pedido · ${order.name}`} />

          <header className="page-header">
            <Link to={`/${currentMerchant.uuid}/orders`} className="f13 black-70 backbtn">
              <FaChevronLeft /> ver todos os pedidos
            </Link>
            <h1 className="title">
              Pedido: {order.name}
              <small className="created-at">
                em {moment(order.createdAt).format("DD/MM/YYYY [às] HH:mm")}
              </small>
            </h1>

            <div className="header-actions">
              <DropdownButton
                label="Ações"
                className="d-none d-md-inline-block"
                classes="btn default size--medium"
                options={[
                  {
                    value: "hide",
                    label: !order.isHidden ? (
                      <>
                        Ocultar pedido{" "}
                        <Tooltip
                          childClassName="info"
                          placement="top"
                          id="hide-order-action"
                          content="i"
                          toolTipText="Oculta um pedido do plugin de rastreio ao cliente."
                        />
                      </>
                    ) : (
                      "Tornar pedido visível"
                    ),
                    callback: () => this.toggleHideOrder(order),
                  },
                  {
                    value: "archive",
                    label: !order.isArchived ? (
                      <>
                        Arquivar pedido
                        <Tooltip
                          childClassName="info"
                          placement="top"
                          id="archive-order-action"
                          content="i"
                          toolTipText="Remove um pedido das filtragens em quais aparece, mas não interrompe o rastreamento."
                        />
                      </>
                    ) : (
                      "Desarquivar pedido"
                    ),
                    callback: () => this.toggleArchive(order),
                  },
                  {
                    value: "sync_order",
                    label: syncingOrder ? "Sincronizando..." : "Forçar sincronização",
                    disabled: syncingOrder,
                    callback: () => {
                      this.syncOrderAct();
                    },
                  },
                  ...(order.appType === "SHOPIFY" && extensionEnabled
                    ? [
                        {
                          value: "sync-shopify",
                          label: "Sincronizar dados com a Shopify",
                          callback: () => this.updateShopifyAtExtension(order),
                          className: "up-divider",
                        },
                      ]
                    : []),
                ]}
              />
              {order.appType && order.appType === "SHOPIFY" && (
                <a href={order.shopifyOrderUrl} target="blank" className="shopify-link">
                  <Shopifyicon /> <span className="shop-order-title">{order.name}</span>{" "}
                  <FaExternalLinkAlt className="link-external" />
                </a>
              )}
              {order.isHidden && <span className="iumy-badge HIDDEN">Oculto</span>}
              {order.isArchived && <span className="iumy-badge ARCHIVED">Arquivado</span>}
              <span className={classnames("iumy-badge", order.financialStatus.code)}>
                {order.financialStatus.label}
              </span>
              {order.financialStatus.code === "PAID" && (
                <span className="paid-at">
                  em {moment(order.createdAt).format("DD/MM/YYYY [às] HH:mm")}
                </span>
              )}
            </div>

            {order.customer.hasNImported && (
              <div className="callout danger mt15 shopify-sync-warning">
                Os dados do pedido não foram importados devido ao seu Plano na Shopify, realize a
                sincronização manual do pedido.
                <button
                  type="button"
                  className="btn red"
                  onClick={() => this.updateShopifyAtExtension(order)}
                >
                  Sincronizar dados
                </button>
              </div>
            )}
          </header>

          <div className="order-simple-details">
            <div className="box-widget-mini customer-basic-details">
              <header>Cliente</header>

              <main className={`body ${streamerMode ? "blurry" : ""}`}>
                {order.customer && (
                  <>
                    <strong>
                      {order.customer.hasNImported ? (
                        "Não informado"
                      ) : (
                        <Link
                          to={`${renderRoute("orders", {
                            merchantId: currentMerchant.uuid,
                          })}?search=${order.customer.document || order.customer.email}`}
                          className="customer-item-info customer-name"
                        >
                          {order.customer.firstName} {order.customer.lastName}
                        </Link>
                      )}
                    </strong>
                    <span className="block customer-item-info customer-email">
                      {order.customer.hasNImported ? "E-mail não importado" : order.customer.email}
                    </span>
                    {order.customer && (
                      <>
                        <span className="block customer-item-info customer-document">
                          CPF:{" "}
                          {order.customer.document
                            ? VMasker.toPattern(order.customer.document, "999.999.999-99")
                            : "-"}
                        </span>
                        {order.shippingAddress.phone ? (
                          <a
                            href={`https://wa.me/${order.shippingAddress.phone}`}
                            className="customer-item-info customer-whatsapp"
                          >
                            <FaWhatsapp />
                            <strong>
                              {order.shippingAddress.phone &&
                                VMasker.toPattern(
                                  order.shippingAddress.phone,
                                  " +99 (99) 99999-9999"
                                )}
                            </strong>
                          </a>
                        ) : (
                          "Telefone não informado"
                        )}
                      </>
                    )}
                  </>
                )}
              </main>
            </div>

            <div className="box-widget-mini customer-shipping-details">
              <header>Entrega</header>

              <main className={`body ${streamerMode ? "blurry" : ""}`}>
                {order.shippingAddress && !order.customer.hasNImported ? (
                  <>
                    <strong className="customer-item-info customer-name">
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </strong>
                    <span className="customer-address">
                      {order.shippingAddress.address1} {order.shippingAddress.address2}
                    </span>
                    <br />
                    <span className="customer-address-city">{order.shippingAddress.city}</span>/
                    <span className="customer-address-state">
                      {order.shippingAddress.provinceCode}
                    </span>
                    <br />
                    <span className="customer-zip">
                      CEP:
                      <strong>
                        {order.shippingAddress.zipcode &&
                          VMasker.toPattern(order.shippingAddress.zipcode, " 99999-999")}
                      </strong>
                    </span>
                  </>
                ) : (
                  "Endereço de entrega não importado"
                )}
              </main>
            </div>

            <div className="box-widget-mini order-totals">
              <header>Valor Total</header>

              <main className="body">
                <strong className="top-total">{order.totalPriceWithDiscount.formated}</strong>

                <table className="total-values">
                  <thead>
                    <tr>
                      <th className="td-80" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Produtos</td>
                      <td>{order.totalPrice.formated}</td>
                    </tr>

                    <tr>
                      <td>Frete</td>
                      <td>{order.freightPrice.formated}</td>
                    </tr>

                    <tr>
                      <td className="td-a-top">Descontos</td>
                      <td>
                        -{order.totalDiscount.formated}
                        {/* <strong className="block f11 discount-description">Desconto</strong> */}
                      </td>
                    </tr>
                    {taxSum ? (
                      <tr>
                        <td className="td-a-top">Taxas</td>
                        <td>
                          <span className="iumy-badge TAXED mb5">{new Price(taxSum).formated}</span>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </main>
            </div>
          </div>

          <div className="order-complex-details">
            <Tabs className="iumy-tabs">
              <Tab title="Rastreio" className="tracking-list">
                {/* Aba Rastreio */}
                {order.packages.length ? (
                  <>
                    <MediaQuery maxWidth={screenSizes.TABLET_MAX}>
                      <MobileTrackings order={order} packages={order.packages} />
                    </MediaQuery>
                    <MediaQuery minWidth={screenSizes.DESKTOP_MIN}>
                      <DesktopTrackings
                        handleToggleTaxPaid={this.toggleTaxPaid.bind(this)}
                        handleTogglePackageVisibility={this.toggleHidePackage.bind(this)}
                        order={order}
                        packages={order.packages}
                      />
                    </MediaQuery>
                  </>
                ) : (
                  <div className="box-widget-mini not-found">
                    Nenhum código de rastreio para este pedido ainda.
                  </div>
                )}

                {/* {order.lastIumyTrackerSync && (
                  <div className="last-tracking-info">
                    Última atualização:{" "}
                    {moment(order.lastIumyTrackerSync).format("DD/MM/YYYY [às] HH:mm:ss")}
                  </div>
                )} */}
              </Tab>

              <Tab title="Resumo" className="product-list">
                <MediaQuery maxWidth={768}>
                  <div className="box-widget-mini product-item-mobile">
                    {order.items.map((item) => (
                      <main key={item.uuid}>
                        <div className="product-line">
                          <div className="product-thumb-wrap">
                            <img
                              src={item.imageSrc || ProductThumb}
                              className="thumb"
                              alt={item.name}
                            />
                          </div>

                          <div className="product-infos-wrap">
                            {item.name} <span className="block f13 sku">SKU: {item.sku}</span>
                          </div>
                        </div>

                        <div className="totals-line">
                          <table className="iumy-table">
                            <thead>
                              <tr>
                                <th>QTD</th>
                                <th>Valor Unit.</th>
                                <th>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item.quantity}</td>
                                <td>{item.unitPrice.formated}</td>
                                <td>{item.totalPrice.formated}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </main>
                    ))}
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={769}>
                  <table className="iumy-table floating-box">
                    <thead>
                      <tr>
                        <th className="td-8-p" />
                        <th className="td-50-p">Produtos</th>
                        <th>QTD</th>
                        <th>Valor Unit.</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>

                    <tbody>
                      {order.items.map((item) => (
                        <React.Fragment key={item.uuid}>
                          <tr className="spacing" />
                          <tr>
                            <td>
                              <img
                                src={item.imageSrc || ProductThumb}
                                className={`thumb ${streamerMode ? "blurry" : ""}`}
                                alt={item.name}
                              />
                            </td>
                            <td>
                              <div className={streamerMode ? "blurry" : ""}>
                                {item.name} <span className="block f13 sku">SKU: {item.sku}</span>
                              </div>
                            </td>
                            <td>{item.quantity}</td>
                            <td className="f13">{item.unitPrice.formated}</td>
                            <td className="f13 bold">{item.totalPrice.formated}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </MediaQuery>
                {!order.items.length && (
                  <div className="box-widget-mini not-found">
                    Nenhum produto encontrado para este pedido, favor verificar na plataforma
                    origem.
                  </div>
                )}
              </Tab>

              {/* Aba Mensagens */}
              <Tab title="Histórico de Mensagens" className="message-history">
                {order.notifications.length ? (
                  <>
                    <MediaQuery maxWidth={screenSizes.TABLET_MAX}>
                      <MobileMessages
                        togglePreview={this.togglePreview.bind(this)}
                        messages={messagesHistory}
                      />
                    </MediaQuery>
                    <MediaQuery minWidth={screenSizes.DESKTOP_MIN}>
                      <DesktopMessages
                        togglePreview={this.togglePreview.bind(this)}
                        messages={messagesHistory}
                        streamerMode={streamerMode}
                      />
                    </MediaQuery>
                  </>
                ) : (
                  <div className="box-widget-mini not-found">
                    Nenhuma mensagem enviada para esse cliente ainda.
                  </div>
                )}

                {/* <div className="box-widget-mini not-found">Nenhuma mensagem para este pedido ainda.</div> */}
              </Tab>

              <Tab
                title={
                  <>
                    Notas do pedido
                    {order.notes ? (
                      <span className="order-notes-qty">{order.notes.length}</span>
                    ) : (
                      ""
                    )}
                  </>
                }
                className="order-notes"
              >
                <OrderNotes
                  modifyOrder={this.modifyOrder.bind(this)}
                  storeUuid={currentMerchant.uuid}
                  orderUuid={order.uuid}
                  notes={order.notes}
                />
              </Tab>
            </Tabs>
          </div>
          <Modal
            className="previewModal"
            title="Pré-visualizar mensagem"
            showModal={historyNotifications.previewOpened}
            toggleModal={() => this.togglePreview()}
          >
            {historyNotifications.previewContent}
          </Modal>
        </div>
      );
    }

    return render;
  }
}

const mapDispatchToProps = (dispatch) => {
  const binders = bindActionCreators({}, dispatch);
  return {
    ...binders,
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
  streamerMode: state.auth.streamerMode,
  token: state.auth.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
